 (function(){// Input 0
var ba;function ma(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}var pa="function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,h){if(a==Array.prototype||a==Object.prototype)return a;a[b]=h.value;return a};
function ra(a){a=["object"==typeof globalThis&&globalThis,a,"object"==typeof window&&window,"object"==typeof self&&self,"object"==typeof global&&global];for(var b=0;b<a.length;++b){var h=a[b];if(h&&h.Math==Math)return h}throw Error("Cannot find global object");}var sa=ra(this);function ua(a,b){if(b)a:{var h=sa;a=a.split(".");for(var u=0;u<a.length-1;u++){var z=a[u];if(!(z in h))break a;h=h[z]}a=a[a.length-1];u=h[a];b=b(u);b!=u&&null!=b&&pa(h,a,{configurable:!0,writable:!0,value:b})}}
ua("Symbol",function(a){function b(z){if(this instanceof b)throw new TypeError("Symbol is not a constructor");return new h("jscomp_symbol_"+(z||"")+"_"+u++,z)}function h(z,n){this.b=z;pa(this,"description",{configurable:!0,writable:!0,value:n})}if(a)return a;h.prototype.toString=function(){return this.b};var u=0;return b});
ua("Symbol.iterator",function(a){if(a)return a;a=Symbol("Symbol.iterator");for(var b="Array Int8Array Uint8Array Uint8ClampedArray Int16Array Uint16Array Int32Array Uint32Array Float32Array Float64Array".split(" "),h=0;h<b.length;h++){var u=sa[b[h]];"function"===typeof u&&"function"!=typeof u.prototype[a]&&pa(u.prototype,a,{configurable:!0,writable:!0,value:function(){return ya(ma(this))}})}return a});function ya(a){a={next:a};a[Symbol.iterator]=function(){return this};return a}
function Aa(a){var b="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];return b?b.call(a):{next:ma(a)}}function Ba(a){if(!(a instanceof Array)){a=Aa(a);for(var b,h=[];!(b=a.next()).done;)h.push(b.value);a=h}return a}var Ca="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},Da;
if("function"==typeof Object.setPrototypeOf)Da=Object.setPrototypeOf;else{var Ea;a:{var Ga={a:!0},Ha={};try{Ha.__proto__=Ga;Ea=Ha.a;break a}catch(a){}Ea=!1}Da=Ea?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var Ia=Da;
function Ka(a,b){a.prototype=Ca(b.prototype);a.prototype.constructor=a;if(Ia)Ia(a,b);else for(var h in b)if("prototype"!=h)if(Object.defineProperties){var u=Object.getOwnPropertyDescriptor(b,h);u&&Object.defineProperty(a,h,u)}else a[h]=b[h]}function La(){this.B=!1;this.g=null;this.ba=void 0;this.b=1;this.U=0;this.l=null}function Ma(a){if(a.B)throw new TypeError("Generator is already running");a.B=!0}La.prototype.J=function(a){this.ba=a};function Oa(a,b){a.l={Ri:b,qj:!0};a.b=a.U}
La.prototype.return=function(a){this.l={return:a};this.b=this.U};function Pa(a,b,h){a.b=h;return{value:b}}function Ra(a){this.b=new La;this.g=a}function Sa(a,b){Ma(a.b);var h=a.b.g;if(h)return Ta(a,"return"in h?h["return"]:function(u){return{value:u,done:!0}},b,a.b.return);a.b.return(b);return Va(a)}
function Ta(a,b,h,u){try{var z=b.call(a.b.g,h);if(!(z instanceof Object))throw new TypeError("Iterator result "+z+" is not an object");if(!z.done)return a.b.B=!1,z;var n=z.value}catch(g){return a.b.g=null,Oa(a.b,g),Va(a)}a.b.g=null;u.call(a.b,n);return Va(a)}function Va(a){for(;a.b.b;)try{var b=a.g(a.b);if(b)return a.b.B=!1,{value:b.value,done:!1}}catch(h){a.b.ba=void 0,Oa(a.b,h)}a.b.B=!1;if(a.b.l){b=a.b.l;a.b.l=null;if(b.qj)throw b.Ri;return{value:b.return,done:!0}}return{value:void 0,done:!0}}
function Wa(a){this.next=function(b){Ma(a.b);a.b.g?b=Ta(a,a.b.g.next,b,a.b.J):(a.b.J(b),b=Va(a));return b};this.throw=function(b){Ma(a.b);a.b.g?b=Ta(a,a.b.g["throw"],b,a.b.J):(Oa(a.b,b),b=Va(a));return b};this.return=function(b){return Sa(a,b)};this[Symbol.iterator]=function(){return this}}function Xa(a,b){b=new Wa(new Ra(b));Ia&&a.prototype&&Ia(b,a.prototype);return b}ua("Object.is",function(a){return a?a:function(b,h){return b===h?0!==b||1/b===1/h:b!==b&&h!==h}});
ua("Array.prototype.includes",function(a){return a?a:function(b,h){var u=this;u instanceof String&&(u=String(u));var z=u.length;h=h||0;for(0>h&&(h=Math.max(h+z,0));h<z;h++){var n=u[h];if(n===b||Object.is(n,b))return!0}return!1}});function Ya(a,b,h){if(null==a)throw new TypeError("The 'this' value for String.prototype."+h+" must not be null or undefined");if(b instanceof RegExp)throw new TypeError("First argument to String.prototype."+h+" must not be a regular expression");return a+""}
ua("String.prototype.includes",function(a){return a?a:function(b,h){return-1!==Ya(this,b,"includes").indexOf(b,h||0)}});ua("Array.from",function(a){return a?a:function(b,h,u){h=null!=h?h:function(e){return e};var z=[],n="undefined"!=typeof Symbol&&Symbol.iterator&&b[Symbol.iterator];if("function"==typeof n){b=n.call(b);for(var g=0;!(n=b.next()).done;)z.push(h.call(u,n.value,g++))}else for(n=b.length,g=0;g<n;g++)z.push(h.call(u,b[g],g));return z}});
ua("String.prototype.codePointAt",function(a){return a?a:function(b){var h=Ya(this,null,"codePointAt"),u=h.length;b=Number(b)||0;if(0<=b&&b<u){b|=0;var z=h.charCodeAt(b);if(55296>z||56319<z||b+1===u)return z;b=h.charCodeAt(b+1);return 56320>b||57343<b?z:1024*(z-55296)+b+9216}}});
ua("String.fromCodePoint",function(a){return a?a:function(b){for(var h="",u=0;u<arguments.length;u++){var z=Number(arguments[u]);if(0>z||1114111<z||z!==Math.floor(z))throw new RangeError("invalid_code_point "+z);65535>=z?h+=String.fromCharCode(z):(z-=65536,h+=String.fromCharCode(z>>>10&1023|55296),h+=String.fromCharCode(z&1023|56320))}return h}});
ua("String.prototype.repeat",function(a){return a?a:function(b){var h=Ya(this,null,"repeat");if(0>b||1342177279<b)throw new RangeError("Invalid count value");b|=0;for(var u="";b;)if(b&1&&(u+=h),b>>>=1)h+=h;return u}});function ab(a,b){a=void 0!==a?String(a):" ";return 0<b&&a?a.repeat(Math.ceil(b/a.length)).substring(0,b):""}ua("String.prototype.padStart",function(a){return a?a:function(b,h){var u=Ya(this,null,"padStart");return ab(h,b-u.length)+u}});
ua("String.prototype.startsWith",function(a){return a?a:function(b,h){var u=Ya(this,b,"startsWith"),z=u.length,n=b.length;h=Math.max(0,Math.min(h|0,u.length));for(var g=0;g<n&&h<z;)if(u[h++]!=b[g++])return!1;return g>=n}});ua("Number.isFinite",function(a){return a?a:function(b){return"number"!==typeof b?!1:!isNaN(b)&&Infinity!==b&&-Infinity!==b}});ua("Number.isInteger",function(a){return a?a:function(b){return Number.isFinite(b)?b===Math.floor(b):!1}});
ua("Math.trunc",function(a){return a?a:function(b){b=Number(b);if(isNaN(b)||Infinity===b||-Infinity===b||0===b)return b;var h=Math.floor(Math.abs(b));return 0>b?-h:h}});ua("Number.MAX_SAFE_INTEGER",function(){return 9007199254740991});function bb(a,b){a instanceof String&&(a+="");var h=0,u={next:function(){if(h<a.length){var z=h++;return{value:b(z,a[z]),done:!1}}u.next=function(){return{done:!0,value:void 0}};return u.next()}};u[Symbol.iterator]=function(){return u};return u}
ua("Array.prototype.keys",function(a){return a?a:function(){return bb(this,function(b){return b})}});function cb(a,b){return Object.prototype.hasOwnProperty.call(a,b)}var db="function"==typeof Object.assign?Object.assign:function(a,b){for(var h=1;h<arguments.length;h++){var u=arguments[h];if(u)for(var z in u)cb(u,z)&&(a[z]=u[z])}return a};ua("Object.assign",function(a){return a||db});
function eb(a,b,h){a instanceof String&&(a=String(a));for(var u=a.length,z=0;z<u;z++){var n=a[z];if(b.call(h,n,z,a))return{i:z,v:n}}return{i:-1,v:void 0}}ua("Array.prototype.findIndex",function(a){return a?a:function(b,h){return eb(this,b,h).i}});ua("Object.entries",function(a){return a?a:function(b){var h=[],u;for(u in b)cb(b,u)&&h.push([u,b[u]]);return h}});ua("String.prototype.padEnd",function(a){return a?a:function(b,h){var u=Ya(this,null,"padStart");return u+ab(h,b-u.length)}});
ua("Array.prototype.find",function(a){return a?a:function(b,h){return eb(this,b,h).v}});
ua("Promise",function(a){function b(g){this.g=0;this.J=void 0;this.b=[];var e=this.l();try{g(e.resolve,e.reject)}catch(k){e.reject(k)}}function h(){this.b=null}function u(g){return g instanceof b?g:new b(function(e){e(g)})}if(a)return a;h.prototype.g=function(g){if(null==this.b){this.b=[];var e=this;this.l(function(){e.J()})}this.b.push(g)};var z=sa.setTimeout;h.prototype.l=function(g){z(g,0)};h.prototype.J=function(){for(;this.b&&this.b.length;){var g=this.b;this.b=[];for(var e=0;e<g.length;++e){var k=
g[e];g[e]=null;try{k()}catch(p){this.B(p)}}}this.b=null};h.prototype.B=function(g){this.l(function(){throw g;})};b.prototype.l=function(){function g(p){return function(v){k||(k=!0,p.call(e,v))}}var e=this,k=!1;return{resolve:g(this.ya),reject:g(this.B)}};b.prototype.ya=function(g){if(g===this)this.B(new TypeError("A Promise cannot resolve to itself"));else if(g instanceof b)this.Ba(g);else{a:switch(typeof g){case "object":var e=null!=g;break a;case "function":e=!0;break a;default:e=!1}e?this.ta(g):
this.U(g)}};b.prototype.ta=function(g){var e=void 0;try{e=g.then}catch(k){this.B(k);return}"function"==typeof e?this.Ia(e,g):this.U(g)};b.prototype.B=function(g){this.ba(2,g)};b.prototype.U=function(g){this.ba(1,g)};b.prototype.ba=function(g,e){if(0!=this.g)throw Error("Cannot settle("+g+", "+e+"): Promise already settled in state"+this.g);this.g=g;this.J=e;this.ra()};b.prototype.ra=function(){if(null!=this.b){for(var g=0;g<this.b.length;++g)n.g(this.b[g]);this.b=null}};var n=new h;b.prototype.Ba=
function(g){var e=this.l();g.ff(e.resolve,e.reject)};b.prototype.Ia=function(g,e){var k=this.l();try{g.call(e,k.resolve,k.reject)}catch(p){k.reject(p)}};b.prototype.then=function(g,e){function k(q,f){return"function"==typeof q?function(I){try{p(q(I))}catch(d){v(d)}}:f}var p,v,B=new b(function(q,f){p=q;v=f});this.ff(k(g,p),k(e,v));return B};b.prototype.catch=function(g){return this.then(void 0,g)};b.prototype.ff=function(g,e){function k(){switch(p.g){case 1:g(p.J);break;case 2:e(p.J);break;default:throw Error("Unexpected state: "+
p.g);}}var p=this;null==this.b?n.g(k):this.b.push(k)};b.resolve=u;b.reject=function(g){return new b(function(e,k){k(g)})};b.race=function(g){return new b(function(e,k){for(var p=Aa(g),v=p.next();!v.done;v=p.next())u(v.value).ff(e,k)})};b.all=function(g){var e=Aa(g),k=e.next();return k.done?u([]):new b(function(p,v){function B(I){return function(d){q[I]=d;f--;0==f&&p(q)}}var q=[],f=0;do q.push(void 0),f++,u(k.value).ff(B(q.length-1),v),k=e.next();while(!k.done)})};return b});
ua("WeakMap",function(a){function b(k){this.b=(e+=Math.random()+1).toString();if(k){k=Aa(k);for(var p;!(p=k.next()).done;)p=p.value,this.set(p[0],p[1])}}function h(){}function u(k){var p=typeof k;return"object"===p&&null!==k||"function"===p}function z(k){if(!cb(k,g)){var p=new h;pa(k,g,{value:p})}}function n(k){var p=Object[k];p&&(Object[k]=function(v){if(v instanceof h)return v;Object.isExtensible(v)&&z(v);return p(v)})}if(function(){if(!a||!Object.seal)return!1;try{var k=Object.seal({}),p=Object.seal({}),
v=new a([[k,2],[p,3]]);if(2!=v.get(k)||3!=v.get(p))return!1;v.delete(k);v.set(p,4);return!v.has(k)&&4==v.get(p)}catch(B){return!1}}())return a;var g="$jscomp_hidden_"+Math.random();n("freeze");n("preventExtensions");n("seal");var e=0;b.prototype.set=function(k,p){if(!u(k))throw Error("Invalid WeakMap key");z(k);if(!cb(k,g))throw Error("WeakMap key fail: "+k);k[g][this.b]=p;return this};b.prototype.get=function(k){return u(k)&&cb(k,g)?k[g][this.b]:void 0};b.prototype.has=function(k){return u(k)&&cb(k,
g)&&cb(k[g],this.b)};b.prototype.delete=function(k){return u(k)&&cb(k,g)&&cb(k[g],this.b)?delete k[g][this.b]:!1};return b});
ua("Map",function(a){function b(){var e={};return e.Gc=e.next=e.head=e}function h(e,k){var p=e.b;return ya(function(){if(p){for(;p.head!=e.b;)p=p.Gc;for(;p.next!=p.head;)return p=p.next,{done:!1,value:k(p)};p=null}return{done:!0,value:void 0}})}function u(e,k){var p=k&&typeof k;"object"==p||"function"==p?n.has(k)?p=n.get(k):(p=""+ ++g,n.set(k,p)):p="p_"+k;var v=e.g[p];if(v&&cb(e.g,p))for(e=0;e<v.length;e++){var B=v[e];if(k!==k&&B.key!==B.key||k===B.key)return{id:p,list:v,index:e,ob:B}}return{id:p,
list:v,index:-1,ob:void 0}}function z(e){this.g={};this.b=b();this.size=0;if(e){e=Aa(e);for(var k;!(k=e.next()).done;)k=k.value,this.set(k[0],k[1])}}if(function(){if(!a||"function"!=typeof a||!a.prototype.entries||"function"!=typeof Object.seal)return!1;try{var e=Object.seal({x:4}),k=new a(Aa([[e,"s"]]));if("s"!=k.get(e)||1!=k.size||k.get({x:4})||k.set({x:4},"t")!=k||2!=k.size)return!1;var p=k.entries(),v=p.next();if(v.done||v.value[0]!=e||"s"!=v.value[1])return!1;v=p.next();return v.done||4!=v.value[0].x||
"t"!=v.value[1]||!p.next().done?!1:!0}catch(B){return!1}}())return a;var n=new WeakMap;z.prototype.set=function(e,k){e=0===e?0:e;var p=u(this,e);p.list||(p.list=this.g[p.id]=[]);p.ob?p.ob.value=k:(p.ob={next:this.b,Gc:this.b.Gc,head:this.b,key:e,value:k},p.list.push(p.ob),this.b.Gc.next=p.ob,this.b.Gc=p.ob,this.size++);return this};z.prototype.delete=function(e){e=u(this,e);return e.ob&&e.list?(e.list.splice(e.index,1),e.list.length||delete this.g[e.id],e.ob.Gc.next=e.ob.next,e.ob.next.Gc=e.ob.Gc,
e.ob.head=null,this.size--,!0):!1};z.prototype.clear=function(){this.g={};this.b=this.b.Gc=b();this.size=0};z.prototype.has=function(e){return!!u(this,e).ob};z.prototype.get=function(e){return(e=u(this,e).ob)&&e.value};z.prototype.entries=function(){return h(this,function(e){return[e.key,e.value]})};z.prototype.keys=function(){return h(this,function(e){return e.key})};z.prototype.values=function(){return h(this,function(e){return e.value})};z.prototype.forEach=function(e,k){for(var p=this.entries(),
v;!(v=p.next()).done;)v=v.value,e.call(k,v[1],v[0],this)};z.prototype[Symbol.iterator]=z.prototype.entries;var g=0;return z});
ua("Set",function(a){function b(h){this.b=new Map;if(h){h=Aa(h);for(var u;!(u=h.next()).done;)this.add(u.value)}this.size=this.b.size}if(function(){if(!a||"function"!=typeof a||!a.prototype.entries||"function"!=typeof Object.seal)return!1;try{var h=Object.seal({x:4}),u=new a(Aa([h]));if(!u.has(h)||1!=u.size||u.add(h)!=u||1!=u.size||u.add({x:4})!=u||2!=u.size)return!1;var z=u.entries(),n=z.next();if(n.done||n.value[0]!=h||n.value[1]!=h)return!1;n=z.next();return n.done||n.value[0]==h||4!=n.value[0].x||
n.value[1]!=n.value[0]?!1:z.next().done}catch(g){return!1}}())return a;b.prototype.add=function(h){h=0===h?0:h;this.b.set(h,h);this.size=this.b.size;return this};b.prototype.delete=function(h){h=this.b.delete(h);this.size=this.b.size;return h};b.prototype.clear=function(){this.b.clear();this.size=0};b.prototype.has=function(h){return this.b.has(h)};b.prototype.entries=function(){return this.b.entries()};b.prototype.values=function(){return this.b.values()};b.prototype.keys=b.prototype.values;b.prototype[Symbol.iterator]=
b.prototype.values;b.prototype.forEach=function(h,u){var z=this;this.b.forEach(function(n){return h.call(u,n,n,z)})};return b});
// Input 1
Resources={regexCategories:{IsBasicLatin:[["0000","007F"]],"IsLatin-1Supplement":[["0080","00FF"]],"IsLatinExtended-A":[["0100","017F"]],"IsLatinExtended-B":[["0180","024F"]],IsIPAExtensions:[["0250","02AF"]],IsSpacingModifierLetters:[["02B0","02FF"]],IsCombiningDiacriticalMarks:[["0300","036F"]],IsGreek:[["0370","03FF"]],IsGreekandCoptic:[["0370","03FF"]],IsCyrillic:[["0400","04FF"]],IsCyrillicSupplementary:[["0500","052F"]],IsCyrillicSupplement:[["0500","052F"]],IsArmenian:[["0530","058F"]],IsHebrew:[["0590",
"05FF"]],IsArabic:[["0600","06FF"]],IsSyriac:[["0700","074F"]],IsArabicSupplement:[["0750","077F"]],IsThaana:[["0780","07BF"]],IsNKo:[["07C0","07FF"]],IsSamaritan:[["0800","083F"]],IsMandaic:[["0840","085F"]],IsDevanagari:[["0900","097F"]],IsBengali:[["0980","09FF"]],IsGurmukhi:[["0A00","0A7F"]],IsGujarati:[["0A80","0AFF"]],IsOriya:[["0B00","0B7F"]],IsTamil:[["0B80","0BFF"]],IsTelugu:[["0C00","0C7F"]],IsKannada:[["0C80","0CFF"]],IsMalayalam:[["0D00","0D7F"]],IsSinhala:[["0D80","0DFF"]],IsThai:[["0E00",
"0E7F"]],IsLao:[["0E80","0EFF"]],IsTibetan:[["0F00","0FFF"]],IsMyanmar:[["1000","109F"]],IsGeorgian:[["10A0","10FF"]],IsHangulJamo:[["1100","11FF"]],IsEthiopic:[["1200","137F"]],IsEthiopicSupplement:[["1380","139F"]],IsCherokee:[["13A0","13FF"]],IsUnifiedCanadianAboriginalSyllabics:[["1400","167F"]],IsOgham:[["1680","169F"]],IsRunic:[["16A0","16FF"]],IsTagalog:[["1700","171F"]],IsHanunoo:[["1720","173F"]],IsBuhid:[["1740","175F"]],IsTagbanwa:[["1760","177F"]],IsKhmer:[["1780","17FF"]],IsMongolian:[["1800",
"18AF"]],IsUnifiedCanadianAboriginalSyllabicsExtended:[["18B0","18FF"]],IsLimbu:[["1900","194F"]],IsTaiLe:[["1950","197F"]],IsNewTaiLue:[["1980","19DF"]],IsKhmerSymbols:[["19E0","19FF"]],IsBuginese:[["1A00","1A1F"]],IsTaiTham:[["1A20","1AAF"]],IsBalinese:[["1B00","1B7F"]],IsSundanese:[["1B80","1BBF"]],IsBatak:[["1BC0","1BFF"]],IsLepcha:[["1C00","1C4F"]],IsOlChiki:[["1C50","1C7F"]],IsVedicExtensions:[["1CD0","1CFF"]],IsPhoneticExtensions:[["1D00","1D7F"]],IsPhoneticExtensionsSupplement:[["1D80","1DBF"]],
IsCombiningDiacriticalMarksSupplement:[["1DC0","1DFF"]],IsLatinExtendedAdditional:[["1E00","1EFF"]],IsGreekExtended:[["1F00","1FFF"]],IsGeneralPunctuation:[["2000","206F"]],IsSuperscriptsandSubscripts:[["2070","209F"]],IsCurrencySymbols:[["20A0","20CF"]],IsCombiningMarksforSymbols:[["20D0","20FF"]],IsCombiningDiacriticalMarksforSymbols:[["20D0","20FF"]],IsLetterlikeSymbols:[["2100","214F"]],IsNumberForms:[["2150","218F"]],IsArrows:[["2190","21FF"]],IsMathematicalOperators:[["2200","22FF"]],IsMiscellaneousTechnical:[["2300",
"23FF"]],IsControlPictures:[["2400","243F"]],IsOpticalCharacterRecognition:[["2440","245F"]],IsEnclosedAlphanumerics:[["2460","24FF"]],IsBoxDrawing:[["2500","257F"]],IsBlockElements:[["2580","259F"]],IsGeometricShapes:[["25A0","25FF"]],IsMiscellaneousSymbols:[["2600","26FF"]],IsDingbats:[["2700","27BF"]],"IsMiscellaneousMathematicalSymbols-A":[["27C0","27EF"]],"IsSupplementalArrows-A":[["27F0","27FF"]],IsBraillePatterns:[["2800","28FF"]],"IsSupplementalArrows-B":[["2900","297F"]],"IsMiscellaneousMathematicalSymbols-B":[["2980",
"29FF"]],IsSupplementalMathematicalOperators:[["2A00","2AFF"]],IsMiscellaneousSymbolsandArrows:[["2B00","2BFF"]],IsGlagolitic:[["2C00","2C5F"]],"IsLatinExtended-C":[["2C60","2C7F"]],IsCoptic:[["2C80","2CFF"]],IsGeorgianSupplement:[["2D00","2D2F"]],IsTifinagh:[["2D30","2D7F"]],IsEthiopicExtended:[["2D80","2DDF"]],"IsCyrillicExtended-A":[["2DE0","2DFF"]],IsSupplementalPunctuation:[["2E00","2E7F"]],IsCJKRadicalsSupplement:[["2E80","2EFF"]],IsKangxiRadicals:[["2F00","2FDF"]],IsIdeographicDescriptionCharacters:[["2FF0",
"2FFF"]],IsCJKSymbolsandPunctuation:[["3000","303F"]],IsHiragana:[["3040","309F"]],IsKatakana:[["30A0","30FF"]],IsBopomofo:[["3100","312F"]],IsHangulCompatibilityJamo:[["3130","318F"]],IsKanbun:[["3190","319F"]],IsBopomofoExtended:[["31A0","31BF"]],IsCJKStrokes:[["31C0","31EF"]],IsKatakanaPhoneticExtensions:[["31F0","31FF"]],IsEnclosedCJKLettersandMonths:[["3200","32FF"]],IsCJKCompatibility:[["3300","33FF"]],IsCJKUnifiedIdeographsExtensionA:[["3400","4DBF"]],IsYijingHexagramSymbols:[["4DC0","4DFF"]],
IsCJKUnifiedIdeographs:[["4E00","9FFF"]],IsYiSyllables:[["A000","A48F"]],IsYiRadicals:[["A490","A4CF"]],IsLisu:[["A4D0","A4FF"]],IsVai:[["A500","A63F"]],"IsCyrillicExtended-B":[["A640","A69F"]],IsBamum:[["A6A0","A6FF"]],IsModifierToneLetters:[["A700","A71F"]],"IsLatinExtended-D":[["A720","A7FF"]],IsSylotiNagri:[["A800","A82F"]],IsCommonIndicNumberForms:[["A830","A83F"]],"IsPhags-pa":[["A840","A87F"]],IsSaurashtra:[["A880","A8DF"]],IsDevanagariExtended:[["A8E0","A8FF"]],IsKayahLi:[["A900","A92F"]],
IsRejang:[["A930","A95F"]],"IsHangulJamoExtended-A":[["A960","A97F"]],IsJavanese:[["A980","A9DF"]],IsCham:[["AA00","AA5F"]],"IsMyanmarExtended-A":[["AA60","AA7F"]],IsTaiViet:[["AA80","AADF"]],"IsEthiopicExtended-A":[["AB00","AB2F"]],IsMeeteiMayek:[["ABC0","ABFF"]],IsHangulSyllables:[["AC00","D7AF"]],"IsHangulJamoExtended-B":[["D7B0","D7FF"]],IsHighSurrogates:[["D800","DB7F"]],IsHighPrivateUseSurrogates:[["DB80","DBFF"]],IsLowSurrogates:[["DC00","DFFF"]],IsPrivateUse:[["E000","F8FF"],["F0000","FFFFD"],
["100000","10FFFD"]],IsPrivateUseArea:[["E000","F8FF"]],IsCJKCompatibilityIdeographs:[["F900","FAFF"]],IsAlphabeticPresentationForms:[["FB00","FB4F"]],"IsArabicPresentationForms-A":[["FB50","FDFF"]],IsVariationSelectors:[["FE00","FE0F"]],IsVerticalForms:[["FE10","FE1F"]],IsCombiningHalfMarks:[["FE20","FE2F"]],IsCJKCompatibilityForms:[["FE30","FE4F"]],IsSmallFormVariants:[["FE50","FE6F"]],"IsArabicPresentationForms-B":[["FE70","FEFF"]],IsHalfwidthandFullwidthForms:[["FF00","FFEF"]],IsSpecials:[["FEFF",
"FEFF"],["FFF0","FFFF"]],IsLinearBSyllabary:[["10000","1007F"]],IsLinearBIdeograms:[["10080","100FF"]],IsAegeanNumbers:[["10100","1013F"]],IsAncientGreekNumbers:[["10140","1018F"]],IsAncientSymbols:[["10190","101CF"]],IsPhaistosDisc:[["101D0","101FF"]],IsLycian:[["10280","1029F"]],IsCarian:[["102A0","102DF"]],IsOldItalic:[["10300","1032F"]],IsGothic:[["10330","1034F"]],IsUgaritic:[["10380","1039F"]],IsOldPersian:[["103A0","103DF"]],IsDeseret:[["10400","1044F"]],IsShavian:[["10450","1047F"]],IsOsmanya:[["10480",
"104AF"]],IsCypriotSyllabary:[["10800","1083F"]],IsImperialAramaic:[["10840","1085F"]],IsPhoenician:[["10900","1091F"]],IsLydian:[["10920","1093F"]],IsKharoshthi:[["10A00","10A5F"]],IsOldSouthArabian:[["10A60","10A7F"]],IsAvestan:[["10B00","10B3F"]],IsInscriptionalParthian:[["10B40","10B5F"]],IsInscriptionalPahlavi:[["10B60","10B7F"]],IsOldTurkic:[["10C00","10C4F"]],IsRumiNumeralSymbols:[["10E60","10E7F"]],IsBrahmi:[["11000","1107F"]],IsKaithi:[["11080","110CF"]],IsCuneiform:[["12000","123FF"]],IsCuneiformNumbersandPunctuation:[["12400",
"1247F"]],IsEgyptianHieroglyphs:[["13000","1342F"]],IsBamumSupplement:[["16800","16A3F"]],IsKanaSupplement:[["1B000","1B0FF"]],IsByzantineMusicalSymbols:[["1D000","1D0FF"]],IsMusicalSymbols:[["1D100","1D1FF"]],IsAncientGreekMusicalNotation:[["1D200","1D24F"]],IsTaiXuanJingSymbols:[["1D300","1D35F"]],IsCountingRodNumerals:[["1D360","1D37F"]],IsMathematicalAlphanumericSymbols:[["1D400","1D7FF"]],IsMahjongTiles:[["1F000","1F02F"]],IsDominoTiles:[["1F030","1F09F"]],IsPlayingCards:[["1F0A0","1F0FF"]],
IsEnclosedAlphanumericSupplement:[["1F100","1F1FF"]],IsEnclosedIdeographicSupplement:[["1F200","1F2FF"]],IsMiscellaneousSymbolsAndPictographs:[["1F300","1F5FF"]],IsEmoticons:[["1F600","1F64F"]],IsTransportAndMapSymbols:[["1F680","1F6FF"]],IsAlchemicalSymbols:[["1F700","1F77F"]],IsCJKUnifiedIdeographsExtensionB:[["20000","2A6DF"]],IsCJKUnifiedIdeographsExtensionC:[["2A700","2B73F"]],IsCJKUnifiedIdeographsExtensionD:[["2B740","2B81F"]],IsCJKCompatibilityIdeographsSupplement:[["2F800","2FA1F"]],IsTags:[["E0000",
"E007F"]],IsVariationSelectorsSupplement:[["E0100","E01EF"]],"IsSupplementaryPrivateUseArea-A":[["F0000","FFFFF"]],"IsSupplementaryPrivateUseArea-B":[["100000","10FFFF"]],C:"Cc|Cf|Cn|Co",Cc:[["0","1F"],["7F","9F"]],Cf:[["AD","AD"],["600","604"],["6DD","6DD"],["70F","70F"],["200B","200F"],["202A","202E"],["2060","2064"],["206A","206F"],["FEFF","FEFF"],["FFF9","FFFB"],["110BD","110BD"],["1D173","1D17A"],["E0001","E0001"],["E0020","E007F"]],Cn:[["378","379"],["37F","383"],["38B","38B"],["38D","38D"],
["3A2","3A2"],["528","52F"],["530","530"],["557","558"],["560","560"],["588","588"],["58B","58E"],["590","590"],["5C8","5CF"],["5EB","5EF"],["5F5","5FF"],["605","605"],["61C","61D"],["70E","70E"],["74B","74C"],["7B2","7BF"],["7FB","7FF"],["82E","82F"],["83F","83F"],["85C","85D"],["85F","85F"],["860","89F"],["8A1","8A1"],["8AD","8E3"],["8FF","8FF"],["978","978"],["980","980"],["984","984"],["98D","98E"],["991","992"],["9A9","9A9"],["9B1","9B1"],["9B3","9B5"],["9BA","9BB"],["9C5","9C6"],["9C9","9CA"],
["9CF","9D6"],["9D8","9DB"],["9DE","9DE"],["9E4","9E5"],["9FC","9FF"],["A00","A00"],["A04","A04"],["A0B","A0E"],["A11","A12"],["A29","A29"],["A31","A31"],["A34","A34"],["A37","A37"],["A3A","A3B"],["A3D","A3D"],["A43","A46"],["A49","A4A"],["A4E","A50"],["A52","A58"],["A5D","A5D"],["A5F","A65"],["A76","A7F"],["A80","A80"],["A84","A84"],["A8E","A8E"],["A92","A92"],["AA9","AA9"],["AB1","AB1"],["AB4","AB4"],["ABA","ABB"],["AC6","AC6"],["ACA","ACA"],["ACE","ACF"],["AD1","ADF"],["AE4","AE5"],["AF2","AFF"],
["B00","B00"],["B04","B04"],["B0D","B0E"],["B11","B12"],["B29","B29"],["B31","B31"],["B34","B34"],["B3A","B3B"],["B45","B46"],["B49","B4A"],["B4E","B55"],["B58","B5B"],["B5E","B5E"],["B64","B65"],["B78","B7F"],["B80","B81"],["B84","B84"],["B8B","B8D"],["B91","B91"],["B96","B98"],["B9B","B9B"],["B9D","B9D"],["BA0","BA2"],["BA5","BA7"],["BAB","BAD"],["BBA","BBD"],["BC3","BC5"],["BC9","BC9"],["BCE","BCF"],["BD1","BD6"],["BD8","BE5"],["BFB","BFF"],["C00","C00"],["C04","C04"],["C0D","C0D"],["C11","C11"],
["C29","C29"],["C34","C34"],["C3A","C3C"],["C45","C45"],["C49","C49"],["C4E","C54"],["C57","C57"],["C5A","C5F"],["C64","C65"],["C70","C77"],["C80","C81"],["C84","C84"],["C8D","C8D"],["C91","C91"],["CA9","CA9"],["CB4","CB4"],["CBA","CBB"],["CC5","CC5"],["CC9","CC9"],["CCE","CD4"],["CD7","CDD"],["CDF","CDF"],["CE4","CE5"],["CF0","CF0"],["CF3","CFF"],["D00","D01"],["D04","D04"],["D0D","D0D"],["D11","D11"],["D3B","D3C"],["D45","D45"],["D49","D49"],["D4F","D56"],["D58","D5F"],["D64","D65"],["D76","D78"],
["D80","D81"],["D84","D84"],["D97","D99"],["DB2","DB2"],["DBC","DBC"],["DBE","DBF"],["DC7","DC9"],["DCB","DCE"],["DD5","DD5"],["DD7","DD7"],["DE0","DF1"],["DF5","DFF"],["E00","E00"],["E3B","E3E"],["E5C","E7F"],["E80","E80"],["E83","E83"],["E85","E86"],["E89","E89"],["E8B","E8C"],["E8E","E93"],["E98","E98"],["EA0","EA0"],["EA4","EA4"],["EA6","EA6"],["EA8","EA9"],["EAC","EAC"],["EBA","EBA"],["EBE","EBF"],["EC5","EC5"],["EC7","EC7"],["ECE","ECF"],["EDA","EDB"],["EE0","EFF"],["F48","F48"],["F6D","F70"],
["F98","F98"],["FBD","FBD"],["FCD","FCD"],["FDB","FFF"],["10C6","10C6"],["10C8","10CC"],["10CE","10CF"],["1249","1249"],["124E","124F"],["1257","1257"],["1259","1259"],["125E","125F"],["1289","1289"],["128E","128F"],["12B1","12B1"],["12B6","12B7"],["12BF","12BF"],["12C1","12C1"],["12C6","12C7"],["12D7","12D7"],["1311","1311"],["1316","1317"],["135B","135C"],["137D","137F"],["139A","139F"],["13F5","13FF"],["169D","169F"],["16F1","16FF"],["170D","170D"],["1715","171F"],["1737","173F"],["1754","175F"],
["176D","176D"],["1771","1771"],["1774","177F"],["17DE","17DF"],["17EA","17EF"],["17FA","17FF"],["180F","180F"],["181A","181F"],["1878","187F"],["18AB","18AF"],["18F6","18FF"],["191D","191F"],["192C","192F"],["193C","193F"],["1941","1943"],["196E","196F"],["1975","197F"],["19AC","19AF"],["19CA","19CF"],["19DB","19DD"],["1A1C","1A1D"],["1A5F","1A5F"],["1A7D","1A7E"],["1A8A","1A8F"],["1A9A","1A9F"],["1AAE","1AAF"],["1AB0","1AFF"],["1B4C","1B4F"],["1B7D","1B7F"],["1BF4","1BFB"],["1C38","1C3A"],["1C4A",
"1C4C"],["1C80","1CBF"],["1CC8","1CCF"],["1CF7","1CFF"],["1DE7","1DFB"],["1F16","1F17"],["1F1E","1F1F"],["1F46","1F47"],["1F4E","1F4F"],["1F58","1F58"],["1F5A","1F5A"],["1F5C","1F5C"],["1F5E","1F5E"],["1F7E","1F7F"],["1FB5","1FB5"],["1FC5","1FC5"],["1FD4","1FD5"],["1FDC","1FDC"],["1FF0","1FF1"],["1FF5","1FF5"],["1FFF","1FFF"],["2065","2069"],["2072","2073"],["208F","208F"],["209D","209F"],["20BB","20CF"],["20F1","20FF"],["218A","218F"],["23F4","23FF"],["2427","243F"],["244B","245F"],["2700","2700"],
["2B4D","2B4F"],["2B5A","2BFF"],["2C2F","2C2F"],["2C5F","2C5F"],["2CF4","2CF8"],["2D26","2D26"],["2D28","2D2C"],["2D2E","2D2F"],["2D68","2D6E"],["2D71","2D7E"],["2D97","2D9F"],["2DA7","2DA7"],["2DAF","2DAF"],["2DB7","2DB7"],["2DBF","2DBF"],["2DC7","2DC7"],["2DCF","2DCF"],["2DD7","2DD7"],["2DDF","2DDF"],["2E3C","2E7F"],["2E9A","2E9A"],["2EF4","2EFF"],["2FD6","2FDF"],["2FE0","2FEF"],["2FFC","2FFF"],["3040","3040"],["3097","3098"],["3100","3104"],["312E","312F"],["3130","3130"],["318F","318F"],["31BB",
"31BF"],["31E4","31EF"],["321F","321F"],["32FF","32FF"],["4DB6","4DBF"],["9FCD","9FFF"],["A48D","A48F"],["A4C7","A4CF"],["A62C","A63F"],["A698","A69E"],["A6F8","A6FF"],["A78F","A78F"],["A794","A79F"],["A7AB","A7F7"],["A82C","A82F"],["A83A","A83F"],["A878","A87F"],["A8C5","A8CD"],["A8DA","A8DF"],["A8FC","A8FF"],["A954","A95E"],["A97D","A97F"],["A9CE","A9CE"],["A9DA","A9DD"],["A9E0","A9FF"],["AA37","AA3F"],["AA4E","AA4F"],["AA5A","AA5B"],["AA7C","AA7F"],["AAC3","AADA"],["AAF7","AAFF"],["AB00","AB00"],
["AB07","AB08"],["AB0F","AB10"],["AB17","AB1F"],["AB27","AB27"],["AB2F","AB2F"],["AB30","ABBF"],["ABEE","ABEF"],["ABFA","ABFF"],["D7A4","D7AF"],["D7C7","D7CA"],["D7FC","D7FF"],["FA6E","FA6F"],["FADA","FAFF"],["FB07","FB12"],["FB18","FB1C"],["FB37","FB37"],["FB3D","FB3D"],["FB3F","FB3F"],["FB42","FB42"],["FB45","FB45"],["FBC2","FBD2"],["FD40","FD4F"],["FD90","FD91"],["FDC8","FDCF"],["FDD0","FDEF"],["FDFE","FDFF"],["FE1A","FE1F"],["FE27","FE2F"],["FE53","FE53"],["FE67","FE67"],["FE6C","FE6F"],["FE75",
"FE75"],["FEFD","FEFE"],["FF00","FF00"],["FFBF","FFC1"],["FFC8","FFC9"],["FFD0","FFD1"],["FFD8","FFD9"],["FFDD","FFDF"],["FFE7","FFE7"],["FFEF","FFEF"],["FFF0","FFF8"],["FFFE","FFFF"],["1000C","1000C"],["10027","10027"],["1003B","1003B"],["1003E","1003E"],["1004E","1004F"],["1005E","1007F"],["100FB","100FF"],["10103","10106"],["10134","10136"],["1018B","1018F"],["1019C","101CF"],["101FE","101FF"],["10200","1027F"],["1029D","1029F"],["102D1","102DF"],["102E0","102FF"],["1031F","1031F"],["10324","1032F"],
["1034B","1034F"],["10350","1037F"],["1039E","1039E"],["103C4","103C7"],["103D6","103DF"],["103E0","103FF"],["1049E","1049F"],["104AA","104AF"],["104B0","107FF"],["10806","10807"],["10809","10809"],["10836","10836"],["10839","1083B"],["1083D","1083E"],["10856","10856"],["10860","108FF"],["1091C","1091E"],["1093A","1093E"],["10940","1097F"],["109B8","109BD"],["109C0","109FF"],["10A04","10A04"],["10A07","10A0B"],["10A14","10A14"],["10A18","10A18"],["10A34","10A37"],["10A3B","10A3E"],["10A48","10A4F"],
["10A59","10A5F"],["10A80","10AFF"],["10B36","10B38"],["10B56","10B57"],["10B73","10B77"],["10B80","10BFF"],["10C49","10C4F"],["10C50","10E5F"],["10E7F","10E7F"],["10E80","10FFF"],["1104E","11051"],["11070","1107F"],["110C2","110CF"],["110E9","110EF"],["110FA","110FF"],["11135","11135"],["11144","1114F"],["11150","1117F"],["111C9","111CF"],["111DA","111DF"],["111E0","1167F"],["116B8","116BF"],["116CA","116CF"],["116D0","11FFF"],["1236F","123FF"],["12463","1246F"],["12474","1247F"],["12480","12FFF"],
["1342F","1342F"],["13430","167FF"],["16A39","16A3F"],["16A40","16EFF"],["16F45","16F4F"],["16F7F","16F8E"],["16FA0","1AFFF"],["1B002","1B0FF"],["1B100","1CFFF"],["1D0F6","1D0FF"],["1D127","1D128"],["1D1DE","1D1FF"],["1D246","1D24F"],["1D250","1D2FF"],["1D357","1D35F"],["1D372","1D37F"],["1D380","1D3FF"],["1D455","1D455"],["1D49D","1D49D"],["1D4A0","1D4A1"],["1D4A3","1D4A4"],["1D4A7","1D4A8"],["1D4AD","1D4AD"],["1D4BA","1D4BA"],["1D4BC","1D4BC"],["1D4C4","1D4C4"],["1D506","1D506"],["1D50B","1D50C"],
["1D515","1D515"],["1D51D","1D51D"],["1D53A","1D53A"],["1D53F","1D53F"],["1D545","1D545"],["1D547","1D549"],["1D551","1D551"],["1D6A6","1D6A7"],["1D7CC","1D7CD"],["1D800","1E7FF"],["1E800","1EDFF"],["1EE04","1EE04"],["1EE20","1EE20"],["1EE23","1EE23"],["1EE25","1EE26"],["1EE28","1EE28"],["1EE33","1EE33"],["1EE38","1EE38"],["1EE3A","1EE3A"],["1EE3C","1EE41"],["1EE43","1EE46"],["1EE48","1EE48"],["1EE4A","1EE4A"],["1EE4C","1EE4C"],["1EE50","1EE50"],["1EE53","1EE53"],["1EE55","1EE56"],["1EE58","1EE58"],
["1EE5A","1EE5A"],["1EE5C","1EE5C"],["1EE5E","1EE5E"],["1EE60","1EE60"],["1EE63","1EE63"],["1EE65","1EE66"],["1EE6B","1EE6B"],["1EE73","1EE73"],["1EE78","1EE78"],["1EE7D","1EE7D"],["1EE7F","1EE7F"],["1EE8A","1EE8A"],["1EE9C","1EEA0"],["1EEA4","1EEA4"],["1EEAA","1EEAA"],["1EEBC","1EEEF"],["1EEF2","1EEFF"],["1EF00","1EFFF"],["1F02C","1F02F"],["1F094","1F09F"],["1F0AF","1F0B0"],["1F0BF","1F0C0"],["1F0D0","1F0D0"],["1F0E0","1F0FF"],["1F10B","1F10F"],["1F12F","1F12F"],["1F16C","1F16F"],["1F19B","1F1E5"],
["1F203","1F20F"],["1F23B","1F23F"],["1F249","1F24F"],["1F252","1F2FF"],["1F321","1F32F"],["1F336","1F336"],["1F37D","1F37F"],["1F394","1F39F"],["1F3C5","1F3C5"],["1F3CB","1F3DF"],["1F3F1","1F3FF"],["1F43F","1F43F"],["1F441","1F441"],["1F4F8","1F4F8"],["1F4FD","1F4FF"],["1F53E","1F53F"],["1F544","1F54F"],["1F568","1F5FA"],["1F641","1F644"],["1F650","1F67F"],["1F6C6","1F6FF"],["1F774","1F77F"],["1F780","1FFFD"],["1FFFE","1FFFF"],["2A6D7","2A6DF"],["2A6E0","2A6FF"],["2B735","2B73F"],["2B81E","2B81F"],
["2B820","2F7FF"],["2FA1E","2FA1F"],["2FA20","2FFFD"],["2FFFE","2FFFF"],["30000","3FFFD"],["3FFFE","3FFFF"],["40000","4FFFD"],["4FFFE","4FFFF"],["50000","5FFFD"],["5FFFE","5FFFF"],["60000","6FFFD"],["6FFFE","6FFFF"],["70000","7FFFD"],["7FFFE","7FFFF"],["80000","8FFFD"],["8FFFE","8FFFF"],["90000","9FFFD"],["9FFFE","9FFFF"],["A0000","AFFFD"],["AFFFE","AFFFF"],["B0000","BFFFD"],["BFFFE","BFFFF"],["C0000","CFFFD"],["CFFFE","CFFFF"],["D0000","DFFFD"],["DFFFE","DFFFF"],["E0000","E0000"],["E0002","E001F"],
["E0080","E00FF"],["E01F0","E0FFF"],["E1000","EFFFD"],["EFFFE","EFFFF"],["FFFFE","FFFFF"],["10FFFE","10FFFF"]],Co:[["E000","F8FF"],["F0000","FFFFD"],["100000","10FFFD"]],L:"Ll|Lm|Lo|Lt|Lu",Ll:[["61","7A"],["B5","B5"],["DF","F6"],["F8","FF"],["101","101"],["103","103"],["105","105"],["107","107"],["109","109"],["10B","10B"],["10D","10D"],["10F","10F"],["111","111"],["113","113"],["115","115"],["117","117"],["119","119"],["11B","11B"],["11D","11D"],["11F","11F"],["121","121"],["123","123"],["125","125"],
["127","127"],["129","129"],["12B","12B"],["12D","12D"],["12F","12F"],["131","131"],["133","133"],["135","135"],["137","138"],["13A","13A"],["13C","13C"],["13E","13E"],["140","140"],["142","142"],["144","144"],["146","146"],["148","149"],["14B","14B"],["14D","14D"],["14F","14F"],["151","151"],["153","153"],["155","155"],["157","157"],["159","159"],["15B","15B"],["15D","15D"],["15F","15F"],["161","161"],["163","163"],["165","165"],["167","167"],["169","169"],["16B","16B"],["16D","16D"],["16F","16F"],
["171","171"],["173","173"],["175","175"],["177","177"],["17A","17A"],["17C","17C"],["17E","180"],["183","183"],["185","185"],["188","188"],["18C","18D"],["192","192"],["195","195"],["199","19B"],["19E","19E"],["1A1","1A1"],["1A3","1A3"],["1A5","1A5"],["1A8","1A8"],["1AA","1AB"],["1AD","1AD"],["1B0","1B0"],["1B4","1B4"],["1B6","1B6"],["1B9","1BA"],["1BD","1BF"],["1C6","1C6"],["1C9","1C9"],["1CC","1CC"],["1CE","1CE"],["1D0","1D0"],["1D2","1D2"],["1D4","1D4"],["1D6","1D6"],["1D8","1D8"],["1DA","1DA"],
["1DC","1DD"],["1DF","1DF"],["1E1","1E1"],["1E3","1E3"],["1E5","1E5"],["1E7","1E7"],["1E9","1E9"],["1EB","1EB"],["1ED","1ED"],["1EF","1F0"],["1F3","1F3"],["1F5","1F5"],["1F9","1F9"],["1FB","1FB"],["1FD","1FD"],["1FF","1FF"],["201","201"],["203","203"],["205","205"],["207","207"],["209","209"],["20B","20B"],["20D","20D"],["20F","20F"],["211","211"],["213","213"],["215","215"],["217","217"],["219","219"],["21B","21B"],["21D","21D"],["21F","21F"],["221","221"],["223","223"],["225","225"],["227","227"],
["229","229"],["22B","22B"],["22D","22D"],["22F","22F"],["231","231"],["233","239"],["23C","23C"],["23F","240"],["242","242"],["247","247"],["249","249"],["24B","24B"],["24D","24D"],["24F","293"],["295","2AF"],["371","371"],["373","373"],["377","377"],["37B","37D"],["390","390"],["3AC","3CE"],["3D0","3D1"],["3D5","3D7"],["3D9","3D9"],["3DB","3DB"],["3DD","3DD"],["3DF","3DF"],["3E1","3E1"],["3E3","3E3"],["3E5","3E5"],["3E7","3E7"],["3E9","3E9"],["3EB","3EB"],["3ED","3ED"],["3EF","3F3"],["3F5","3F5"],
["3F8","3F8"],["3FB","3FC"],["430","45F"],["461","461"],["463","463"],["465","465"],["467","467"],["469","469"],["46B","46B"],["46D","46D"],["46F","46F"],["471","471"],["473","473"],["475","475"],["477","477"],["479","479"],["47B","47B"],["47D","47D"],["47F","47F"],["481","481"],["48B","48B"],["48D","48D"],["48F","48F"],["491","491"],["493","493"],["495","495"],["497","497"],["499","499"],["49B","49B"],["49D","49D"],["49F","49F"],["4A1","4A1"],["4A3","4A3"],["4A5","4A5"],["4A7","4A7"],["4A9","4A9"],
["4AB","4AB"],["4AD","4AD"],["4AF","4AF"],["4B1","4B1"],["4B3","4B3"],["4B5","4B5"],["4B7","4B7"],["4B9","4B9"],["4BB","4BB"],["4BD","4BD"],["4BF","4BF"],["4C2","4C2"],["4C4","4C4"],["4C6","4C6"],["4C8","4C8"],["4CA","4CA"],["4CC","4CC"],["4CE","4CF"],["4D1","4D1"],["4D3","4D3"],["4D5","4D5"],["4D7","4D7"],["4D9","4D9"],["4DB","4DB"],["4DD","4DD"],["4DF","4DF"],["4E1","4E1"],["4E3","4E3"],["4E5","4E5"],["4E7","4E7"],["4E9","4E9"],["4EB","4EB"],["4ED","4ED"],["4EF","4EF"],["4F1","4F1"],["4F3","4F3"],
["4F5","4F5"],["4F7","4F7"],["4F9","4F9"],["4FB","4FB"],["4FD","4FD"],["4FF","4FF"],["501","501"],["503","503"],["505","505"],["507","507"],["509","509"],["50B","50B"],["50D","50D"],["50F","50F"],["511","511"],["513","513"],["515","515"],["517","517"],["519","519"],["51B","51B"],["51D","51D"],["51F","51F"],["521","521"],["523","523"],["525","525"],["527","527"],["561","587"],["1D00","1D2B"],["1D6B","1D77"],["1D79","1D9A"],["1E01","1E01"],["1E03","1E03"],["1E05","1E05"],["1E07","1E07"],["1E09","1E09"],
["1E0B","1E0B"],["1E0D","1E0D"],["1E0F","1E0F"],["1E11","1E11"],["1E13","1E13"],["1E15","1E15"],["1E17","1E17"],["1E19","1E19"],["1E1B","1E1B"],["1E1D","1E1D"],["1E1F","1E1F"],["1E21","1E21"],["1E23","1E23"],["1E25","1E25"],["1E27","1E27"],["1E29","1E29"],["1E2B","1E2B"],["1E2D","1E2D"],["1E2F","1E2F"],["1E31","1E31"],["1E33","1E33"],["1E35","1E35"],["1E37","1E37"],["1E39","1E39"],["1E3B","1E3B"],["1E3D","1E3D"],["1E3F","1E3F"],["1E41","1E41"],["1E43","1E43"],["1E45","1E45"],["1E47","1E47"],["1E49",
"1E49"],["1E4B","1E4B"],["1E4D","1E4D"],["1E4F","1E4F"],["1E51","1E51"],["1E53","1E53"],["1E55","1E55"],["1E57","1E57"],["1E59","1E59"],["1E5B","1E5B"],["1E5D","1E5D"],["1E5F","1E5F"],["1E61","1E61"],["1E63","1E63"],["1E65","1E65"],["1E67","1E67"],["1E69","1E69"],["1E6B","1E6B"],["1E6D","1E6D"],["1E6F","1E6F"],["1E71","1E71"],["1E73","1E73"],["1E75","1E75"],["1E77","1E77"],["1E79","1E79"],["1E7B","1E7B"],["1E7D","1E7D"],["1E7F","1E7F"],["1E81","1E81"],["1E83","1E83"],["1E85","1E85"],["1E87","1E87"],
["1E89","1E89"],["1E8B","1E8B"],["1E8D","1E8D"],["1E8F","1E8F"],["1E91","1E91"],["1E93","1E93"],["1E95","1E9D"],["1E9F","1E9F"],["1EA1","1EA1"],["1EA3","1EA3"],["1EA5","1EA5"],["1EA7","1EA7"],["1EA9","1EA9"],["1EAB","1EAB"],["1EAD","1EAD"],["1EAF","1EAF"],["1EB1","1EB1"],["1EB3","1EB3"],["1EB5","1EB5"],["1EB7","1EB7"],["1EB9","1EB9"],["1EBB","1EBB"],["1EBD","1EBD"],["1EBF","1EBF"],["1EC1","1EC1"],["1EC3","1EC3"],["1EC5","1EC5"],["1EC7","1EC7"],["1EC9","1EC9"],["1ECB","1ECB"],["1ECD","1ECD"],["1ECF",
"1ECF"],["1ED1","1ED1"],["1ED3","1ED3"],["1ED5","1ED5"],["1ED7","1ED7"],["1ED9","1ED9"],["1EDB","1EDB"],["1EDD","1EDD"],["1EDF","1EDF"],["1EE1","1EE1"],["1EE3","1EE3"],["1EE5","1EE5"],["1EE7","1EE7"],["1EE9","1EE9"],["1EEB","1EEB"],["1EED","1EED"],["1EEF","1EEF"],["1EF1","1EF1"],["1EF3","1EF3"],["1EF5","1EF5"],["1EF7","1EF7"],["1EF9","1EF9"],["1EFB","1EFB"],["1EFD","1EFD"],["1EFF","1F07"],["1F10","1F15"],["1F20","1F27"],["1F30","1F37"],["1F40","1F45"],["1F50","1F57"],["1F60","1F67"],["1F70","1F7D"],
["1F80","1F87"],["1F90","1F97"],["1FA0","1FA7"],["1FB0","1FB4"],["1FB6","1FB7"],["1FBE","1FBE"],["1FC2","1FC4"],["1FC6","1FC7"],["1FD0","1FD3"],["1FD6","1FD7"],["1FE0","1FE7"],["1FF2","1FF4"],["1FF6","1FF7"],["210A","210A"],["210E","210F"],["2113","2113"],["212F","212F"],["2134","2134"],["2139","2139"],["213C","213D"],["2146","2149"],["214E","214E"],["2184","2184"],["2C30","2C5E"],["2C61","2C61"],["2C65","2C66"],["2C68","2C68"],["2C6A","2C6A"],["2C6C","2C6C"],["2C71","2C71"],["2C73","2C74"],["2C76",
"2C7B"],["2C81","2C81"],["2C83","2C83"],["2C85","2C85"],["2C87","2C87"],["2C89","2C89"],["2C8B","2C8B"],["2C8D","2C8D"],["2C8F","2C8F"],["2C91","2C91"],["2C93","2C93"],["2C95","2C95"],["2C97","2C97"],["2C99","2C99"],["2C9B","2C9B"],["2C9D","2C9D"],["2C9F","2C9F"],["2CA1","2CA1"],["2CA3","2CA3"],["2CA5","2CA5"],["2CA7","2CA7"],["2CA9","2CA9"],["2CAB","2CAB"],["2CAD","2CAD"],["2CAF","2CAF"],["2CB1","2CB1"],["2CB3","2CB3"],["2CB5","2CB5"],["2CB7","2CB7"],["2CB9","2CB9"],["2CBB","2CBB"],["2CBD","2CBD"],
["2CBF","2CBF"],["2CC1","2CC1"],["2CC3","2CC3"],["2CC5","2CC5"],["2CC7","2CC7"],["2CC9","2CC9"],["2CCB","2CCB"],["2CCD","2CCD"],["2CCF","2CCF"],["2CD1","2CD1"],["2CD3","2CD3"],["2CD5","2CD5"],["2CD7","2CD7"],["2CD9","2CD9"],["2CDB","2CDB"],["2CDD","2CDD"],["2CDF","2CDF"],["2CE1","2CE1"],["2CE3","2CE4"],["2CEC","2CEC"],["2CEE","2CEE"],["2CF3","2CF3"],["2D00","2D25"],["2D27","2D27"],["2D2D","2D2D"],["A641","A641"],["A643","A643"],["A645","A645"],["A647","A647"],["A649","A649"],["A64B","A64B"],["A64D",
"A64D"],["A64F","A64F"],["A651","A651"],["A653","A653"],["A655","A655"],["A657","A657"],["A659","A659"],["A65B","A65B"],["A65D","A65D"],["A65F","A65F"],["A661","A661"],["A663","A663"],["A665","A665"],["A667","A667"],["A669","A669"],["A66B","A66B"],["A66D","A66D"],["A681","A681"],["A683","A683"],["A685","A685"],["A687","A687"],["A689","A689"],["A68B","A68B"],["A68D","A68D"],["A68F","A68F"],["A691","A691"],["A693","A693"],["A695","A695"],["A697","A697"],["A723","A723"],["A725","A725"],["A727","A727"],
["A729","A729"],["A72B","A72B"],["A72D","A72D"],["A72F","A731"],["A733","A733"],["A735","A735"],["A737","A737"],["A739","A739"],["A73B","A73B"],["A73D","A73D"],["A73F","A73F"],["A741","A741"],["A743","A743"],["A745","A745"],["A747","A747"],["A749","A749"],["A74B","A74B"],["A74D","A74D"],["A74F","A74F"],["A751","A751"],["A753","A753"],["A755","A755"],["A757","A757"],["A759","A759"],["A75B","A75B"],["A75D","A75D"],["A75F","A75F"],["A761","A761"],["A763","A763"],["A765","A765"],["A767","A767"],["A769",
"A769"],["A76B","A76B"],["A76D","A76D"],["A76F","A76F"],["A771","A778"],["A77A","A77A"],["A77C","A77C"],["A77F","A77F"],["A781","A781"],["A783","A783"],["A785","A785"],["A787","A787"],["A78C","A78C"],["A78E","A78E"],["A791","A791"],["A793","A793"],["A7A1","A7A1"],["A7A3","A7A3"],["A7A5","A7A5"],["A7A7","A7A7"],["A7A9","A7A9"],["A7FA","A7FA"],["FB00","FB06"],["FB13","FB17"],["FF41","FF5A"],["10428","1044F"],["1D41A","1D433"],["1D44E","1D454"],["1D456","1D467"],["1D482","1D49B"],["1D4B6","1D4B9"],["1D4BB",
"1D4BB"],["1D4BD","1D4C3"],["1D4C5","1D4CF"],["1D4EA","1D503"],["1D51E","1D537"],["1D552","1D56B"],["1D586","1D59F"],["1D5BA","1D5D3"],["1D5EE","1D607"],["1D622","1D63B"],["1D656","1D66F"],["1D68A","1D6A5"],["1D6C2","1D6DA"],["1D6DC","1D6E1"],["1D6FC","1D714"],["1D716","1D71B"],["1D736","1D74E"],["1D750","1D755"],["1D770","1D788"],["1D78A","1D78F"],["1D7AA","1D7C2"],["1D7C4","1D7C9"],["1D7CB","1D7CB"]],Lm:[["2B0","2C1"],["2C6","2D1"],["2E0","2E4"],["2EC","2EC"],["2EE","2EE"],["374","374"],["37A",
"37A"],["559","559"],["640","640"],["6E5","6E6"],["7F4","7F5"],["7FA","7FA"],["81A","81A"],["824","824"],["828","828"],["971","971"],["E46","E46"],["EC6","EC6"],["10FC","10FC"],["17D7","17D7"],["1843","1843"],["1AA7","1AA7"],["1C78","1C7D"],["1D2C","1D6A"],["1D78","1D78"],["1D9B","1DBF"],["2071","2071"],["207F","207F"],["2090","209C"],["2C7C","2C7D"],["2D6F","2D6F"],["2E2F","2E2F"],["3005","3005"],["3031","3035"],["303B","303B"],["309D","309E"],["30FC","30FE"],["A015","A015"],["A4F8","A4FD"],["A60C",
"A60C"],["A67F","A67F"],["A717","A71F"],["A770","A770"],["A788","A788"],["A7F8","A7F9"],["A9CF","A9CF"],["AA70","AA70"],["AADD","AADD"],["AAF3","AAF4"],["FF70","FF70"],["FF9E","FF9F"],["16F93","16F9F"]],Lo:[["AA","AA"],["BA","BA"],["1BB","1BB"],["1C0","1C3"],["294","294"],["5D0","5EA"],["5F0","5F2"],["620","63F"],["641","64A"],["66E","66F"],["671","6D3"],["6D5","6D5"],["6EE","6EF"],["6FA","6FC"],["6FF","6FF"],["710","710"],["712","72F"],["74D","7A5"],["7B1","7B1"],["7CA","7EA"],["800","815"],["840",
"858"],["8A0","8A0"],["8A2","8AC"],["904","939"],["93D","93D"],["950","950"],["958","961"],["972","977"],["979","97F"],["985","98C"],["98F","990"],["993","9A8"],["9AA","9B0"],["9B2","9B2"],["9B6","9B9"],["9BD","9BD"],["9CE","9CE"],["9DC","9DD"],["9DF","9E1"],["9F0","9F1"],["A05","A0A"],["A0F","A10"],["A13","A28"],["A2A","A30"],["A32","A33"],["A35","A36"],["A38","A39"],["A59","A5C"],["A5E","A5E"],["A72","A74"],["A85","A8D"],["A8F","A91"],["A93","AA8"],["AAA","AB0"],["AB2","AB3"],["AB5","AB9"],["ABD",
"ABD"],["AD0","AD0"],["AE0","AE1"],["B05","B0C"],["B0F","B10"],["B13","B28"],["B2A","B30"],["B32","B33"],["B35","B39"],["B3D","B3D"],["B5C","B5D"],["B5F","B61"],["B71","B71"],["B83","B83"],["B85","B8A"],["B8E","B90"],["B92","B95"],["B99","B9A"],["B9C","B9C"],["B9E","B9F"],["BA3","BA4"],["BA8","BAA"],["BAE","BB9"],["BD0","BD0"],["C05","C0C"],["C0E","C10"],["C12","C28"],["C2A","C33"],["C35","C39"],["C3D","C3D"],["C58","C59"],["C60","C61"],["C85","C8C"],["C8E","C90"],["C92","CA8"],["CAA","CB3"],["CB5",
"CB9"],["CBD","CBD"],["CDE","CDE"],["CE0","CE1"],["CF1","CF2"],["D05","D0C"],["D0E","D10"],["D12","D3A"],["D3D","D3D"],["D4E","D4E"],["D60","D61"],["D7A","D7F"],["D85","D96"],["D9A","DB1"],["DB3","DBB"],["DBD","DBD"],["DC0","DC6"],["E01","E30"],["E32","E33"],["E40","E45"],["E81","E82"],["E84","E84"],["E87","E88"],["E8A","E8A"],["E8D","E8D"],["E94","E97"],["E99","E9F"],["EA1","EA3"],["EA5","EA5"],["EA7","EA7"],["EAA","EAB"],["EAD","EB0"],["EB2","EB3"],["EBD","EBD"],["EC0","EC4"],["EDC","EDF"],["F00",
"F00"],["F40","F47"],["F49","F6C"],["F88","F8C"],["1000","102A"],["103F","103F"],["1050","1055"],["105A","105D"],["1061","1061"],["1065","1066"],["106E","1070"],["1075","1081"],["108E","108E"],["10D0","10FA"],["10FD","1248"],["124A","124D"],["1250","1256"],["1258","1258"],["125A","125D"],["1260","1288"],["128A","128D"],["1290","12B0"],["12B2","12B5"],["12B8","12BE"],["12C0","12C0"],["12C2","12C5"],["12C8","12D6"],["12D8","1310"],["1312","1315"],["1318","135A"],["1380","138F"],["13A0","13F4"],["1401",
"166C"],["166F","167F"],["1681","169A"],["16A0","16EA"],["1700","170C"],["170E","1711"],["1720","1731"],["1740","1751"],["1760","176C"],["176E","1770"],["1780","17B3"],["17DC","17DC"],["1820","1842"],["1844","1877"],["1880","18A8"],["18AA","18AA"],["18B0","18F5"],["1900","191C"],["1950","196D"],["1970","1974"],["1980","19AB"],["19C1","19C7"],["1A00","1A16"],["1A20","1A54"],["1B05","1B33"],["1B45","1B4B"],["1B83","1BA0"],["1BAE","1BAF"],["1BBA","1BE5"],["1C00","1C23"],["1C4D","1C4F"],["1C5A","1C77"],
["1CE9","1CEC"],["1CEE","1CF1"],["1CF5","1CF6"],["2135","2138"],["2D30","2D67"],["2D80","2D96"],["2DA0","2DA6"],["2DA8","2DAE"],["2DB0","2DB6"],["2DB8","2DBE"],["2DC0","2DC6"],["2DC8","2DCE"],["2DD0","2DD6"],["2DD8","2DDE"],["3006","3006"],["303C","303C"],["3041","3096"],["309F","309F"],["30A1","30FA"],["30FF","30FF"],["3105","312D"],["3131","318E"],["31A0","31BA"],["31F0","31FF"],["3400","4DB5"],["4E00","9FCC"],["A000","A014"],["A016","A48C"],["A4D0","A4F7"],["A500","A60B"],["A610","A61F"],["A62A",
"A62B"],["A66E","A66E"],["A6A0","A6E5"],["A7FB","A801"],["A803","A805"],["A807","A80A"],["A80C","A822"],["A840","A873"],["A882","A8B3"],["A8F2","A8F7"],["A8FB","A8FB"],["A90A","A925"],["A930","A946"],["A960","A97C"],["A984","A9B2"],["AA00","AA28"],["AA40","AA42"],["AA44","AA4B"],["AA60","AA6F"],["AA71","AA76"],["AA7A","AA7A"],["AA80","AAAF"],["AAB1","AAB1"],["AAB5","AAB6"],["AAB9","AABD"],["AAC0","AAC0"],["AAC2","AAC2"],["AADB","AADC"],["AAE0","AAEA"],["AAF2","AAF2"],["AB01","AB06"],["AB09","AB0E"],
["AB11","AB16"],["AB20","AB26"],["AB28","AB2E"],["ABC0","ABE2"],["AC00","D7A3"],["D7B0","D7C6"],["D7CB","D7FB"],["F900","FA6D"],["FA70","FAD9"],["FB1D","FB1D"],["FB1F","FB28"],["FB2A","FB36"],["FB38","FB3C"],["FB3E","FB3E"],["FB40","FB41"],["FB43","FB44"],["FB46","FBB1"],["FBD3","FD3D"],["FD50","FD8F"],["FD92","FDC7"],["FDF0","FDFB"],["FE70","FE74"],["FE76","FEFC"],["FF66","FF6F"],["FF71","FF9D"],["FFA0","FFBE"],["FFC2","FFC7"],["FFCA","FFCF"],["FFD2","FFD7"],["FFDA","FFDC"],["10000","1000B"],["1000D",
"10026"],["10028","1003A"],["1003C","1003D"],["1003F","1004D"],["10050","1005D"],["10080","100FA"],["10280","1029C"],["102A0","102D0"],["10300","1031E"],["10330","10340"],["10342","10349"],["10380","1039D"],["103A0","103C3"],["103C8","103CF"],["10450","1049D"],["10800","10805"],["10808","10808"],["1080A","10835"],["10837","10838"],["1083C","1083C"],["1083F","10855"],["10900","10915"],["10920","10939"],["10980","109B7"],["109BE","109BF"],["10A00","10A00"],["10A10","10A13"],["10A15","10A17"],["10A19",
"10A33"],["10A60","10A7C"],["10B00","10B35"],["10B40","10B55"],["10B60","10B72"],["10C00","10C48"],["11003","11037"],["11083","110AF"],["110D0","110E8"],["11103","11126"],["11183","111B2"],["111C1","111C4"],["11680","116AA"],["12000","1236E"],["13000","1342E"],["16800","16A38"],["16F00","16F44"],["16F50","16F50"],["1B000","1B001"],["1EE00","1EE03"],["1EE05","1EE1F"],["1EE21","1EE22"],["1EE24","1EE24"],["1EE27","1EE27"],["1EE29","1EE32"],["1EE34","1EE37"],["1EE39","1EE39"],["1EE3B","1EE3B"],["1EE42",
"1EE42"],["1EE47","1EE47"],["1EE49","1EE49"],["1EE4B","1EE4B"],["1EE4D","1EE4F"],["1EE51","1EE52"],["1EE54","1EE54"],["1EE57","1EE57"],["1EE59","1EE59"],["1EE5B","1EE5B"],["1EE5D","1EE5D"],["1EE5F","1EE5F"],["1EE61","1EE62"],["1EE64","1EE64"],["1EE67","1EE6A"],["1EE6C","1EE72"],["1EE74","1EE77"],["1EE79","1EE7C"],["1EE7E","1EE7E"],["1EE80","1EE89"],["1EE8B","1EE9B"],["1EEA1","1EEA3"],["1EEA5","1EEA9"],["1EEAB","1EEBB"],["20000","2A6D6"],["2A700","2B734"],["2B740","2B81D"],["2F800","2FA1D"]],Lt:[["1C5",
"1C5"],["1C8","1C8"],["1CB","1CB"],["1F2","1F2"],["1F88","1F8F"],["1F98","1F9F"],["1FA8","1FAF"],["1FBC","1FBC"],["1FCC","1FCC"],["1FFC","1FFC"]],Lu:[["41","5A"],["C0","D6"],["D8","DE"],["100","100"],["102","102"],["104","104"],["106","106"],["108","108"],["10A","10A"],["10C","10C"],["10E","10E"],["110","110"],["112","112"],["114","114"],["116","116"],["118","118"],["11A","11A"],["11C","11C"],["11E","11E"],["120","120"],["122","122"],["124","124"],["126","126"],["128","128"],["12A","12A"],["12C",
"12C"],["12E","12E"],["130","130"],["132","132"],["134","134"],["136","136"],["139","139"],["13B","13B"],["13D","13D"],["13F","13F"],["141","141"],["143","143"],["145","145"],["147","147"],["14A","14A"],["14C","14C"],["14E","14E"],["150","150"],["152","152"],["154","154"],["156","156"],["158","158"],["15A","15A"],["15C","15C"],["15E","15E"],["160","160"],["162","162"],["164","164"],["166","166"],["168","168"],["16A","16A"],["16C","16C"],["16E","16E"],["170","170"],["172","172"],["174","174"],["176",
"176"],["178","179"],["17B","17B"],["17D","17D"],["181","182"],["184","184"],["186","187"],["189","18B"],["18E","191"],["193","194"],["196","198"],["19C","19D"],["19F","1A0"],["1A2","1A2"],["1A4","1A4"],["1A6","1A7"],["1A9","1A9"],["1AC","1AC"],["1AE","1AF"],["1B1","1B3"],["1B5","1B5"],["1B7","1B8"],["1BC","1BC"],["1C4","1C4"],["1C7","1C7"],["1CA","1CA"],["1CD","1CD"],["1CF","1CF"],["1D1","1D1"],["1D3","1D3"],["1D5","1D5"],["1D7","1D7"],["1D9","1D9"],["1DB","1DB"],["1DE","1DE"],["1E0","1E0"],["1E2",
"1E2"],["1E4","1E4"],["1E6","1E6"],["1E8","1E8"],["1EA","1EA"],["1EC","1EC"],["1EE","1EE"],["1F1","1F1"],["1F4","1F4"],["1F6","1F8"],["1FA","1FA"],["1FC","1FC"],["1FE","1FE"],["200","200"],["202","202"],["204","204"],["206","206"],["208","208"],["20A","20A"],["20C","20C"],["20E","20E"],["210","210"],["212","212"],["214","214"],["216","216"],["218","218"],["21A","21A"],["21C","21C"],["21E","21E"],["220","220"],["222","222"],["224","224"],["226","226"],["228","228"],["22A","22A"],["22C","22C"],["22E",
"22E"],["230","230"],["232","232"],["23A","23B"],["23D","23E"],["241","241"],["243","246"],["248","248"],["24A","24A"],["24C","24C"],["24E","24E"],["370","370"],["372","372"],["376","376"],["386","386"],["388","38A"],["38C","38C"],["38E","38F"],["391","3A1"],["3A3","3AB"],["3CF","3CF"],["3D2","3D4"],["3D8","3D8"],["3DA","3DA"],["3DC","3DC"],["3DE","3DE"],["3E0","3E0"],["3E2","3E2"],["3E4","3E4"],["3E6","3E6"],["3E8","3E8"],["3EA","3EA"],["3EC","3EC"],["3EE","3EE"],["3F4","3F4"],["3F7","3F7"],["3F9",
"3FA"],["3FD","42F"],["460","460"],["462","462"],["464","464"],["466","466"],["468","468"],["46A","46A"],["46C","46C"],["46E","46E"],["470","470"],["472","472"],["474","474"],["476","476"],["478","478"],["47A","47A"],["47C","47C"],["47E","47E"],["480","480"],["48A","48A"],["48C","48C"],["48E","48E"],["490","490"],["492","492"],["494","494"],["496","496"],["498","498"],["49A","49A"],["49C","49C"],["49E","49E"],["4A0","4A0"],["4A2","4A2"],["4A4","4A4"],["4A6","4A6"],["4A8","4A8"],["4AA","4AA"],["4AC",
"4AC"],["4AE","4AE"],["4B0","4B0"],["4B2","4B2"],["4B4","4B4"],["4B6","4B6"],["4B8","4B8"],["4BA","4BA"],["4BC","4BC"],["4BE","4BE"],["4C0","4C1"],["4C3","4C3"],["4C5","4C5"],["4C7","4C7"],["4C9","4C9"],["4CB","4CB"],["4CD","4CD"],["4D0","4D0"],["4D2","4D2"],["4D4","4D4"],["4D6","4D6"],["4D8","4D8"],["4DA","4DA"],["4DC","4DC"],["4DE","4DE"],["4E0","4E0"],["4E2","4E2"],["4E4","4E4"],["4E6","4E6"],["4E8","4E8"],["4EA","4EA"],["4EC","4EC"],["4EE","4EE"],["4F0","4F0"],["4F2","4F2"],["4F4","4F4"],["4F6",
"4F6"],["4F8","4F8"],["4FA","4FA"],["4FC","4FC"],["4FE","4FE"],["500","500"],["502","502"],["504","504"],["506","506"],["508","508"],["50A","50A"],["50C","50C"],["50E","50E"],["510","510"],["512","512"],["514","514"],["516","516"],["518","518"],["51A","51A"],["51C","51C"],["51E","51E"],["520","520"],["522","522"],["524","524"],["526","526"],["531","556"],["10A0","10C5"],["10C7","10C7"],["10CD","10CD"],["1E00","1E00"],["1E02","1E02"],["1E04","1E04"],["1E06","1E06"],["1E08","1E08"],["1E0A","1E0A"],
["1E0C","1E0C"],["1E0E","1E0E"],["1E10","1E10"],["1E12","1E12"],["1E14","1E14"],["1E16","1E16"],["1E18","1E18"],["1E1A","1E1A"],["1E1C","1E1C"],["1E1E","1E1E"],["1E20","1E20"],["1E22","1E22"],["1E24","1E24"],["1E26","1E26"],["1E28","1E28"],["1E2A","1E2A"],["1E2C","1E2C"],["1E2E","1E2E"],["1E30","1E30"],["1E32","1E32"],["1E34","1E34"],["1E36","1E36"],["1E38","1E38"],["1E3A","1E3A"],["1E3C","1E3C"],["1E3E","1E3E"],["1E40","1E40"],["1E42","1E42"],["1E44","1E44"],["1E46","1E46"],["1E48","1E48"],["1E4A",
"1E4A"],["1E4C","1E4C"],["1E4E","1E4E"],["1E50","1E50"],["1E52","1E52"],["1E54","1E54"],["1E56","1E56"],["1E58","1E58"],["1E5A","1E5A"],["1E5C","1E5C"],["1E5E","1E5E"],["1E60","1E60"],["1E62","1E62"],["1E64","1E64"],["1E66","1E66"],["1E68","1E68"],["1E6A","1E6A"],["1E6C","1E6C"],["1E6E","1E6E"],["1E70","1E70"],["1E72","1E72"],["1E74","1E74"],["1E76","1E76"],["1E78","1E78"],["1E7A","1E7A"],["1E7C","1E7C"],["1E7E","1E7E"],["1E80","1E80"],["1E82","1E82"],["1E84","1E84"],["1E86","1E86"],["1E88","1E88"],
["1E8A","1E8A"],["1E8C","1E8C"],["1E8E","1E8E"],["1E90","1E90"],["1E92","1E92"],["1E94","1E94"],["1E9E","1E9E"],["1EA0","1EA0"],["1EA2","1EA2"],["1EA4","1EA4"],["1EA6","1EA6"],["1EA8","1EA8"],["1EAA","1EAA"],["1EAC","1EAC"],["1EAE","1EAE"],["1EB0","1EB0"],["1EB2","1EB2"],["1EB4","1EB4"],["1EB6","1EB6"],["1EB8","1EB8"],["1EBA","1EBA"],["1EBC","1EBC"],["1EBE","1EBE"],["1EC0","1EC0"],["1EC2","1EC2"],["1EC4","1EC4"],["1EC6","1EC6"],["1EC8","1EC8"],["1ECA","1ECA"],["1ECC","1ECC"],["1ECE","1ECE"],["1ED0",
"1ED0"],["1ED2","1ED2"],["1ED4","1ED4"],["1ED6","1ED6"],["1ED8","1ED8"],["1EDA","1EDA"],["1EDC","1EDC"],["1EDE","1EDE"],["1EE0","1EE0"],["1EE2","1EE2"],["1EE4","1EE4"],["1EE6","1EE6"],["1EE8","1EE8"],["1EEA","1EEA"],["1EEC","1EEC"],["1EEE","1EEE"],["1EF0","1EF0"],["1EF2","1EF2"],["1EF4","1EF4"],["1EF6","1EF6"],["1EF8","1EF8"],["1EFA","1EFA"],["1EFC","1EFC"],["1EFE","1EFE"],["1F08","1F0F"],["1F18","1F1D"],["1F28","1F2F"],["1F38","1F3F"],["1F48","1F4D"],["1F59","1F59"],["1F5B","1F5B"],["1F5D","1F5D"],
["1F5F","1F5F"],["1F68","1F6F"],["1FB8","1FBB"],["1FC8","1FCB"],["1FD8","1FDB"],["1FE8","1FEC"],["1FF8","1FFB"],["2102","2102"],["2107","2107"],["210B","210D"],["2110","2112"],["2115","2115"],["2119","211D"],["2124","2124"],["2126","2126"],["2128","2128"],["212A","212D"],["2130","2133"],["213E","213F"],["2145","2145"],["2183","2183"],["2C00","2C2E"],["2C60","2C60"],["2C62","2C64"],["2C67","2C67"],["2C69","2C69"],["2C6B","2C6B"],["2C6D","2C70"],["2C72","2C72"],["2C75","2C75"],["2C7E","2C80"],["2C82",
"2C82"],["2C84","2C84"],["2C86","2C86"],["2C88","2C88"],["2C8A","2C8A"],["2C8C","2C8C"],["2C8E","2C8E"],["2C90","2C90"],["2C92","2C92"],["2C94","2C94"],["2C96","2C96"],["2C98","2C98"],["2C9A","2C9A"],["2C9C","2C9C"],["2C9E","2C9E"],["2CA0","2CA0"],["2CA2","2CA2"],["2CA4","2CA4"],["2CA6","2CA6"],["2CA8","2CA8"],["2CAA","2CAA"],["2CAC","2CAC"],["2CAE","2CAE"],["2CB0","2CB0"],["2CB2","2CB2"],["2CB4","2CB4"],["2CB6","2CB6"],["2CB8","2CB8"],["2CBA","2CBA"],["2CBC","2CBC"],["2CBE","2CBE"],["2CC0","2CC0"],
["2CC2","2CC2"],["2CC4","2CC4"],["2CC6","2CC6"],["2CC8","2CC8"],["2CCA","2CCA"],["2CCC","2CCC"],["2CCE","2CCE"],["2CD0","2CD0"],["2CD2","2CD2"],["2CD4","2CD4"],["2CD6","2CD6"],["2CD8","2CD8"],["2CDA","2CDA"],["2CDC","2CDC"],["2CDE","2CDE"],["2CE0","2CE0"],["2CE2","2CE2"],["2CEB","2CEB"],["2CED","2CED"],["2CF2","2CF2"],["A640","A640"],["A642","A642"],["A644","A644"],["A646","A646"],["A648","A648"],["A64A","A64A"],["A64C","A64C"],["A64E","A64E"],["A650","A650"],["A652","A652"],["A654","A654"],["A656",
"A656"],["A658","A658"],["A65A","A65A"],["A65C","A65C"],["A65E","A65E"],["A660","A660"],["A662","A662"],["A664","A664"],["A666","A666"],["A668","A668"],["A66A","A66A"],["A66C","A66C"],["A680","A680"],["A682","A682"],["A684","A684"],["A686","A686"],["A688","A688"],["A68A","A68A"],["A68C","A68C"],["A68E","A68E"],["A690","A690"],["A692","A692"],["A694","A694"],["A696","A696"],["A722","A722"],["A724","A724"],["A726","A726"],["A728","A728"],["A72A","A72A"],["A72C","A72C"],["A72E","A72E"],["A732","A732"],
["A734","A734"],["A736","A736"],["A738","A738"],["A73A","A73A"],["A73C","A73C"],["A73E","A73E"],["A740","A740"],["A742","A742"],["A744","A744"],["A746","A746"],["A748","A748"],["A74A","A74A"],["A74C","A74C"],["A74E","A74E"],["A750","A750"],["A752","A752"],["A754","A754"],["A756","A756"],["A758","A758"],["A75A","A75A"],["A75C","A75C"],["A75E","A75E"],["A760","A760"],["A762","A762"],["A764","A764"],["A766","A766"],["A768","A768"],["A76A","A76A"],["A76C","A76C"],["A76E","A76E"],["A779","A779"],["A77B",
"A77B"],["A77D","A77E"],["A780","A780"],["A782","A782"],["A784","A784"],["A786","A786"],["A78B","A78B"],["A78D","A78D"],["A790","A790"],["A792","A792"],["A7A0","A7A0"],["A7A2","A7A2"],["A7A4","A7A4"],["A7A6","A7A6"],["A7A8","A7A8"],["A7AA","A7AA"],["FF21","FF3A"],["10400","10427"],["1D400","1D419"],["1D434","1D44D"],["1D468","1D481"],["1D49C","1D49C"],["1D49E","1D49F"],["1D4A2","1D4A2"],["1D4A5","1D4A6"],["1D4A9","1D4AC"],["1D4AE","1D4B5"],["1D4D0","1D4E9"],["1D504","1D505"],["1D507","1D50A"],["1D50D",
"1D514"],["1D516","1D51C"],["1D538","1D539"],["1D53B","1D53E"],["1D540","1D544"],["1D546","1D546"],["1D54A","1D550"],["1D56C","1D585"],["1D5A0","1D5B9"],["1D5D4","1D5ED"],["1D608","1D621"],["1D63C","1D655"],["1D670","1D689"],["1D6A8","1D6C0"],["1D6E2","1D6FA"],["1D71C","1D734"],["1D756","1D76E"],["1D790","1D7A8"],["1D7CA","1D7CA"]],M:"Mc|Me|Mn",Mc:[["903","903"],["93B","93B"],["93E","940"],["949","94C"],["94E","94F"],["982","983"],["9BE","9C0"],["9C7","9C8"],["9CB","9CC"],["9D7","9D7"],["A03","A03"],
["A3E","A40"],["A83","A83"],["ABE","AC0"],["AC9","AC9"],["ACB","ACC"],["B02","B03"],["B3E","B3E"],["B40","B40"],["B47","B48"],["B4B","B4C"],["B57","B57"],["BBE","BBF"],["BC1","BC2"],["BC6","BC8"],["BCA","BCC"],["BD7","BD7"],["C01","C03"],["C41","C44"],["C82","C83"],["CBE","CBE"],["CC0","CC4"],["CC7","CC8"],["CCA","CCB"],["CD5","CD6"],["D02","D03"],["D3E","D40"],["D46","D48"],["D4A","D4C"],["D57","D57"],["D82","D83"],["DCF","DD1"],["DD8","DDF"],["DF2","DF3"],["F3E","F3F"],["F7F","F7F"],["102B","102C"],
["1031","1031"],["1038","1038"],["103B","103C"],["1056","1057"],["1062","1064"],["1067","106D"],["1083","1084"],["1087","108C"],["108F","108F"],["109A","109C"],["17B6","17B6"],["17BE","17C5"],["17C7","17C8"],["1923","1926"],["1929","192B"],["1930","1931"],["1933","1938"],["19B0","19C0"],["19C8","19C9"],["1A19","1A1B"],["1A55","1A55"],["1A57","1A57"],["1A61","1A61"],["1A63","1A64"],["1A6D","1A72"],["1B04","1B04"],["1B35","1B35"],["1B3B","1B3B"],["1B3D","1B41"],["1B43","1B44"],["1B82","1B82"],["1BA1",
"1BA1"],["1BA6","1BA7"],["1BAA","1BAA"],["1BAC","1BAD"],["1BE7","1BE7"],["1BEA","1BEC"],["1BEE","1BEE"],["1BF2","1BF3"],["1C24","1C2B"],["1C34","1C35"],["1CE1","1CE1"],["1CF2","1CF3"],["302E","302F"],["A823","A824"],["A827","A827"],["A880","A881"],["A8B4","A8C3"],["A952","A953"],["A983","A983"],["A9B4","A9B5"],["A9BA","A9BB"],["A9BD","A9C0"],["AA2F","AA30"],["AA33","AA34"],["AA4D","AA4D"],["AA7B","AA7B"],["AAEB","AAEB"],["AAEE","AAEF"],["AAF5","AAF5"],["ABE3","ABE4"],["ABE6","ABE7"],["ABE9","ABEA"],
["ABEC","ABEC"],["11000","11000"],["11002","11002"],["11082","11082"],["110B0","110B2"],["110B7","110B8"],["1112C","1112C"],["11182","11182"],["111B3","111B5"],["111BF","111C0"],["116AC","116AC"],["116AE","116AF"],["116B6","116B6"],["16F51","16F7E"],["1D165","1D166"],["1D16D","1D172"]],Me:[["488","489"],["20DD","20E0"],["20E2","20E4"],["A670","A672"]],Mn:[["300","36F"],["483","487"],["591","5BD"],["5BF","5BF"],["5C1","5C2"],["5C4","5C5"],["5C7","5C7"],["610","61A"],["64B","65F"],["670","670"],["6D6",
"6DC"],["6DF","6E4"],["6E7","6E8"],["6EA","6ED"],["711","711"],["730","74A"],["7A6","7B0"],["7EB","7F3"],["816","819"],["81B","823"],["825","827"],["829","82D"],["859","85B"],["8E4","8FE"],["900","902"],["93A","93A"],["93C","93C"],["941","948"],["94D","94D"],["951","957"],["962","963"],["981","981"],["9BC","9BC"],["9C1","9C4"],["9CD","9CD"],["9E2","9E3"],["A01","A02"],["A3C","A3C"],["A41","A42"],["A47","A48"],["A4B","A4D"],["A51","A51"],["A70","A71"],["A75","A75"],["A81","A82"],["ABC","ABC"],["AC1",
"AC5"],["AC7","AC8"],["ACD","ACD"],["AE2","AE3"],["B01","B01"],["B3C","B3C"],["B3F","B3F"],["B41","B44"],["B4D","B4D"],["B56","B56"],["B62","B63"],["B82","B82"],["BC0","BC0"],["BCD","BCD"],["C3E","C40"],["C46","C48"],["C4A","C4D"],["C55","C56"],["C62","C63"],["CBC","CBC"],["CBF","CBF"],["CC6","CC6"],["CCC","CCD"],["CE2","CE3"],["D41","D44"],["D4D","D4D"],["D62","D63"],["DCA","DCA"],["DD2","DD4"],["DD6","DD6"],["E31","E31"],["E34","E3A"],["E47","E4E"],["EB1","EB1"],["EB4","EB9"],["EBB","EBC"],["EC8",
"ECD"],["F18","F19"],["F35","F35"],["F37","F37"],["F39","F39"],["F71","F7E"],["F80","F84"],["F86","F87"],["F8D","F97"],["F99","FBC"],["FC6","FC6"],["102D","1030"],["1032","1037"],["1039","103A"],["103D","103E"],["1058","1059"],["105E","1060"],["1071","1074"],["1082","1082"],["1085","1086"],["108D","108D"],["109D","109D"],["135D","135F"],["1712","1714"],["1732","1734"],["1752","1753"],["1772","1773"],["17B4","17B5"],["17B7","17BD"],["17C6","17C6"],["17C9","17D3"],["17DD","17DD"],["180B","180D"],["18A9",
"18A9"],["1920","1922"],["1927","1928"],["1932","1932"],["1939","193B"],["1A17","1A18"],["1A56","1A56"],["1A58","1A5E"],["1A60","1A60"],["1A62","1A62"],["1A65","1A6C"],["1A73","1A7C"],["1A7F","1A7F"],["1B00","1B03"],["1B34","1B34"],["1B36","1B3A"],["1B3C","1B3C"],["1B42","1B42"],["1B6B","1B73"],["1B80","1B81"],["1BA2","1BA5"],["1BA8","1BA9"],["1BAB","1BAB"],["1BE6","1BE6"],["1BE8","1BE9"],["1BED","1BED"],["1BEF","1BF1"],["1C2C","1C33"],["1C36","1C37"],["1CD0","1CD2"],["1CD4","1CE0"],["1CE2","1CE8"],
["1CED","1CED"],["1CF4","1CF4"],["1DC0","1DE6"],["1DFC","1DFF"],["20D0","20DC"],["20E1","20E1"],["20E5","20F0"],["2CEF","2CF1"],["2D7F","2D7F"],["2DE0","2DFF"],["302A","302D"],["3099","309A"],["A66F","A66F"],["A674","A67D"],["A69F","A69F"],["A6F0","A6F1"],["A802","A802"],["A806","A806"],["A80B","A80B"],["A825","A826"],["A8C4","A8C4"],["A8E0","A8F1"],["A926","A92D"],["A947","A951"],["A980","A982"],["A9B3","A9B3"],["A9B6","A9B9"],["A9BC","A9BC"],["AA29","AA2E"],["AA31","AA32"],["AA35","AA36"],["AA43",
"AA43"],["AA4C","AA4C"],["AAB0","AAB0"],["AAB2","AAB4"],["AAB7","AAB8"],["AABE","AABF"],["AAC1","AAC1"],["AAEC","AAED"],["AAF6","AAF6"],["ABE5","ABE5"],["ABE8","ABE8"],["ABED","ABED"],["FB1E","FB1E"],["FE00","FE0F"],["FE20","FE26"],["101FD","101FD"],["10A01","10A03"],["10A05","10A06"],["10A0C","10A0F"],["10A38","10A3A"],["10A3F","10A3F"],["11001","11001"],["11038","11046"],["11080","11081"],["110B3","110B6"],["110B9","110BA"],["11100","11102"],["11127","1112B"],["1112D","11134"],["11180","11181"],
["111B6","111BE"],["116AB","116AB"],["116AD","116AD"],["116B0","116B5"],["116B7","116B7"],["16F8F","16F92"],["1D167","1D169"],["1D17B","1D182"],["1D185","1D18B"],["1D1AA","1D1AD"],["1D242","1D244"],["E0100","E01EF"]],N:"Nd|Nl|No",Nd:[["30","39"],["660","669"],["6F0","6F9"],["7C0","7C9"],["966","96F"],["9E6","9EF"],["A66","A6F"],["AE6","AEF"],["B66","B6F"],["BE6","BEF"],["C66","C6F"],["CE6","CEF"],["D66","D6F"],["E50","E59"],["ED0","ED9"],["F20","F29"],["1040","1049"],["1090","1099"],["17E0","17E9"],
["1810","1819"],["1946","194F"],["19D0","19D9"],["1A80","1A89"],["1A90","1A99"],["1B50","1B59"],["1BB0","1BB9"],["1C40","1C49"],["1C50","1C59"],["A620","A629"],["A8D0","A8D9"],["A900","A909"],["A9D0","A9D9"],["AA50","AA59"],["ABF0","ABF9"],["FF10","FF19"],["104A0","104A9"],["11066","1106F"],["110F0","110F9"],["11136","1113F"],["111D0","111D9"],["116C0","116C9"],["1D7CE","1D7FF"]],Nl:[["16EE","16F0"],["2160","2182"],["2185","2188"],["3007","3007"],["3021","3029"],["3038","303A"],["A6E6","A6EF"],["10140",
"10174"],["10341","10341"],["1034A","1034A"],["103D1","103D5"],["12400","12462"]],No:[["B2","B3"],["B9","B9"],["BC","BE"],["9F4","9F9"],["B72","B77"],["BF0","BF2"],["C78","C7E"],["D70","D75"],["F2A","F33"],["1369","137C"],["17F0","17F9"],["19DA","19DA"],["2070","2070"],["2074","2079"],["2080","2089"],["2150","215F"],["2189","2189"],["2460","249B"],["24EA","24FF"],["2776","2793"],["2CFD","2CFD"],["3192","3195"],["3220","3229"],["3248","324F"],["3251","325F"],["3280","3289"],["32B1","32BF"],["A830",
"A835"],["10107","10133"],["10175","10178"],["1018A","1018A"],["10320","10323"],["10858","1085F"],["10916","1091B"],["10A40","10A47"],["10A7D","10A7E"],["10B58","10B5F"],["10B78","10B7F"],["10E60","10E7E"],["11052","11065"],["1D360","1D371"],["1F100","1F10A"]],P:"Pc|Pd|Pe|Pf|Pi|Po|Ps",Pc:[["5F","5F"],["203F","2040"],["2054","2054"],["FE33","FE34"],["FE4D","FE4F"],["FF3F","FF3F"]],Pd:[["2D","2D"],["58A","58A"],["5BE","5BE"],["1400","1400"],["1806","1806"],["2010","2015"],["2E17","2E17"],["2E1A","2E1A"],
["2E3A","2E3B"],["301C","301C"],["3030","3030"],["30A0","30A0"],["FE31","FE32"],["FE58","FE58"],["FE63","FE63"],["FF0D","FF0D"]],Pe:[["29","29"],["5D","5D"],["7D","7D"],["F3B","F3B"],["F3D","F3D"],["169C","169C"],["2046","2046"],["207E","207E"],["208E","208E"],["232A","232A"],["2769","2769"],["276B","276B"],["276D","276D"],["276F","276F"],["2771","2771"],["2773","2773"],["2775","2775"],["27C6","27C6"],["27E7","27E7"],["27E9","27E9"],["27EB","27EB"],["27ED","27ED"],["27EF","27EF"],["2984","2984"],
["2986","2986"],["2988","2988"],["298A","298A"],["298C","298C"],["298E","298E"],["2990","2990"],["2992","2992"],["2994","2994"],["2996","2996"],["2998","2998"],["29D9","29D9"],["29DB","29DB"],["29FD","29FD"],["2E23","2E23"],["2E25","2E25"],["2E27","2E27"],["2E29","2E29"],["3009","3009"],["300B","300B"],["300D","300D"],["300F","300F"],["3011","3011"],["3015","3015"],["3017","3017"],["3019","3019"],["301B","301B"],["301E","301F"],["FD3F","FD3F"],["FE18","FE18"],["FE36","FE36"],["FE38","FE38"],["FE3A",
"FE3A"],["FE3C","FE3C"],["FE3E","FE3E"],["FE40","FE40"],["FE42","FE42"],["FE44","FE44"],["FE48","FE48"],["FE5A","FE5A"],["FE5C","FE5C"],["FE5E","FE5E"],["FF09","FF09"],["FF3D","FF3D"],["FF5D","FF5D"],["FF60","FF60"],["FF63","FF63"]],Pf:[["BB","BB"],["2019","2019"],["201D","201D"],["203A","203A"],["2E03","2E03"],["2E05","2E05"],["2E0A","2E0A"],["2E0D","2E0D"],["2E1D","2E1D"],["2E21","2E21"]],Pi:[["AB","AB"],["2018","2018"],["201B","201C"],["201F","201F"],["2039","2039"],["2E02","2E02"],["2E04","2E04"],
["2E09","2E09"],["2E0C","2E0C"],["2E1C","2E1C"],["2E20","2E20"]],Po:[["21","23"],["25","27"],["2A","2A"],["2C","2C"],["2E","2F"],["3A","3B"],["3F","40"],["5C","5C"],["A1","A1"],["A7","A7"],["B6","B7"],["BF","BF"],["37E","37E"],["387","387"],["55A","55F"],["589","589"],["5C0","5C0"],["5C3","5C3"],["5C6","5C6"],["5F3","5F4"],["609","60A"],["60C","60D"],["61B","61B"],["61E","61F"],["66A","66D"],["6D4","6D4"],["700","70D"],["7F7","7F9"],["830","83E"],["85E","85E"],["964","965"],["970","970"],["AF0","AF0"],
["DF4","DF4"],["E4F","E4F"],["E5A","E5B"],["F04","F12"],["F14","F14"],["F85","F85"],["FD0","FD4"],["FD9","FDA"],["104A","104F"],["10FB","10FB"],["1360","1368"],["166D","166E"],["16EB","16ED"],["1735","1736"],["17D4","17D6"],["17D8","17DA"],["1800","1805"],["1807","180A"],["1944","1945"],["1A1E","1A1F"],["1AA0","1AA6"],["1AA8","1AAD"],["1B5A","1B60"],["1BFC","1BFF"],["1C3B","1C3F"],["1C7E","1C7F"],["1CC0","1CC7"],["1CD3","1CD3"],["2016","2017"],["2020","2027"],["2030","2038"],["203B","203E"],["2041",
"2043"],["2047","2051"],["2053","2053"],["2055","205E"],["2CF9","2CFC"],["2CFE","2CFF"],["2D70","2D70"],["2E00","2E01"],["2E06","2E08"],["2E0B","2E0B"],["2E0E","2E16"],["2E18","2E19"],["2E1B","2E1B"],["2E1E","2E1F"],["2E2A","2E2E"],["2E30","2E39"],["3001","3003"],["303D","303D"],["30FB","30FB"],["A4FE","A4FF"],["A60D","A60F"],["A673","A673"],["A67E","A67E"],["A6F2","A6F7"],["A874","A877"],["A8CE","A8CF"],["A8F8","A8FA"],["A92E","A92F"],["A95F","A95F"],["A9C1","A9CD"],["A9DE","A9DF"],["AA5C","AA5F"],
["AADE","AADF"],["AAF0","AAF1"],["ABEB","ABEB"],["FE10","FE16"],["FE19","FE19"],["FE30","FE30"],["FE45","FE46"],["FE49","FE4C"],["FE50","FE52"],["FE54","FE57"],["FE5F","FE61"],["FE68","FE68"],["FE6A","FE6B"],["FF01","FF03"],["FF05","FF07"],["FF0A","FF0A"],["FF0C","FF0C"],["FF0E","FF0F"],["FF1A","FF1B"],["FF1F","FF20"],["FF3C","FF3C"],["FF61","FF61"],["FF64","FF65"],["10100","10102"],["1039F","1039F"],["103D0","103D0"],["10857","10857"],["1091F","1091F"],["1093F","1093F"],["10A50","10A58"],["10A7F",
"10A7F"],["10B39","10B3F"],["11047","1104D"],["110BB","110BC"],["110BE","110C1"],["11140","11143"],["111C5","111C8"],["12470","12473"]],Ps:[["28","28"],["5B","5B"],["7B","7B"],["F3A","F3A"],["F3C","F3C"],["169B","169B"],["201A","201A"],["201E","201E"],["2045","2045"],["207D","207D"],["208D","208D"],["2329","2329"],["2768","2768"],["276A","276A"],["276C","276C"],["276E","276E"],["2770","2770"],["2772","2772"],["2774","2774"],["27C5","27C5"],["27E6","27E6"],["27E8","27E8"],["27EA","27EA"],["27EC","27EC"],
["27EE","27EE"],["2983","2983"],["2985","2985"],["2987","2987"],["2989","2989"],["298B","298B"],["298D","298D"],["298F","298F"],["2991","2991"],["2993","2993"],["2995","2995"],["2997","2997"],["29D8","29D8"],["29DA","29DA"],["29FC","29FC"],["2E22","2E22"],["2E24","2E24"],["2E26","2E26"],["2E28","2E28"],["3008","3008"],["300A","300A"],["300C","300C"],["300E","300E"],["3010","3010"],["3014","3014"],["3016","3016"],["3018","3018"],["301A","301A"],["301D","301D"],["FD3E","FD3E"],["FE17","FE17"],["FE35",
"FE35"],["FE37","FE37"],["FE39","FE39"],["FE3B","FE3B"],["FE3D","FE3D"],["FE3F","FE3F"],["FE41","FE41"],["FE43","FE43"],["FE47","FE47"],["FE59","FE59"],["FE5B","FE5B"],["FE5D","FE5D"],["FF08","FF08"],["FF3B","FF3B"],["FF5B","FF5B"],["FF5F","FF5F"],["FF62","FF62"]],S:"Sc|Sk|Sm|So",Sc:[["24","24"],["A2","A5"],["58F","58F"],["60B","60B"],["9F2","9F3"],["9FB","9FB"],["AF1","AF1"],["BF9","BF9"],["E3F","E3F"],["17DB","17DB"],["20A0","20BA"],["A838","A838"],["FDFC","FDFC"],["FE69","FE69"],["FF04","FF04"],
["FFE0","FFE1"],["FFE5","FFE6"]],Sk:[["5E","5E"],["60","60"],["A8","A8"],["AF","AF"],["B4","B4"],["B8","B8"],["2C2","2C5"],["2D2","2DF"],["2E5","2EB"],["2ED","2ED"],["2EF","2FF"],["375","375"],["384","385"],["1FBD","1FBD"],["1FBF","1FC1"],["1FCD","1FCF"],["1FDD","1FDF"],["1FED","1FEF"],["1FFD","1FFE"],["309B","309C"],["A700","A716"],["A720","A721"],["A789","A78A"],["FBB2","FBC1"],["FF3E","FF3E"],["FF40","FF40"],["FFE3","FFE3"]],Sm:[["2B","2B"],["3C","3E"],["7C","7C"],["7E","7E"],["AC","AC"],["B1",
"B1"],["D7","D7"],["F7","F7"],["3F6","3F6"],["606","608"],["2044","2044"],["2052","2052"],["207A","207C"],["208A","208C"],["2118","2118"],["2140","2144"],["214B","214B"],["2190","2194"],["219A","219B"],["21A0","21A0"],["21A3","21A3"],["21A6","21A6"],["21AE","21AE"],["21CE","21CF"],["21D2","21D2"],["21D4","21D4"],["21F4","22FF"],["2308","230B"],["2320","2321"],["237C","237C"],["239B","23B3"],["23DC","23E1"],["25B7","25B7"],["25C1","25C1"],["25F8","25FF"],["266F","266F"],["27C0","27C4"],["27C7","27E5"],
["27F0","27FF"],["2900","2982"],["2999","29D7"],["29DC","29FB"],["29FE","2AFF"],["2B30","2B44"],["2B47","2B4C"],["FB29","FB29"],["FE62","FE62"],["FE64","FE66"],["FF0B","FF0B"],["FF1C","FF1E"],["FF5C","FF5C"],["FF5E","FF5E"],["FFE2","FFE2"],["FFE9","FFEC"],["1D6C1","1D6C1"],["1D6DB","1D6DB"],["1D6FB","1D6FB"],["1D715","1D715"],["1D735","1D735"],["1D74F","1D74F"],["1D76F","1D76F"],["1D789","1D789"],["1D7A9","1D7A9"],["1D7C3","1D7C3"],["1EEF0","1EEF1"]],So:[["A6","A6"],["A9","A9"],["AE","AE"],["B0",
"B0"],["482","482"],["60E","60F"],["6DE","6DE"],["6E9","6E9"],["6FD","6FE"],["7F6","7F6"],["9FA","9FA"],["B70","B70"],["BF3","BF8"],["BFA","BFA"],["C7F","C7F"],["D79","D79"],["F01","F03"],["F13","F13"],["F15","F17"],["F1A","F1F"],["F34","F34"],["F36","F36"],["F38","F38"],["FBE","FC5"],["FC7","FCC"],["FCE","FCF"],["FD5","FD8"],["109E","109F"],["1390","1399"],["1940","1940"],["19DE","19FF"],["1B61","1B6A"],["1B74","1B7C"],["2100","2101"],["2103","2106"],["2108","2109"],["2114","2114"],["2116","2117"],
["211E","2123"],["2125","2125"],["2127","2127"],["2129","2129"],["212E","212E"],["213A","213B"],["214A","214A"],["214C","214D"],["214F","214F"],["2195","2199"],["219C","219F"],["21A1","21A2"],["21A4","21A5"],["21A7","21AD"],["21AF","21CD"],["21D0","21D1"],["21D3","21D3"],["21D5","21F3"],["2300","2307"],["230C","231F"],["2322","2328"],["232B","237B"],["237D","239A"],["23B4","23DB"],["23E2","23F3"],["2400","2426"],["2440","244A"],["249C","24E9"],["2500","25B6"],["25B8","25C0"],["25C2","25F7"],["2600",
"266E"],["2670","26FF"],["2701","2767"],["2794","27BF"],["2800","28FF"],["2B00","2B2F"],["2B45","2B46"],["2B50","2B59"],["2CE5","2CEA"],["2E80","2E99"],["2E9B","2EF3"],["2F00","2FD5"],["2FF0","2FFB"],["3004","3004"],["3012","3013"],["3020","3020"],["3036","3037"],["303E","303F"],["3190","3191"],["3196","319F"],["31C0","31E3"],["3200","321E"],["322A","3247"],["3250","3250"],["3260","327F"],["328A","32B0"],["32C0","32FE"],["3300","33FF"],["4DC0","4DFF"],["A490","A4C6"],["A828","A82B"],["A836","A837"],
["A839","A839"],["AA77","AA79"],["FDFD","FDFD"],["FFE4","FFE4"],["FFE8","FFE8"],["FFED","FFEE"],["FFFC","FFFD"],["10137","1013F"],["10179","10189"],["10190","1019B"],["101D0","101FC"],["1D000","1D0F5"],["1D100","1D126"],["1D129","1D164"],["1D16A","1D16C"],["1D183","1D184"],["1D18C","1D1A9"],["1D1AE","1D1DD"],["1D200","1D241"],["1D245","1D245"],["1D300","1D356"],["1F000","1F02B"],["1F030","1F093"],["1F0A0","1F0AE"],["1F0B1","1F0BE"],["1F0C1","1F0CF"],["1F0D1","1F0DF"],["1F110","1F12E"],["1F130","1F16B"],
["1F170","1F19A"],["1F1E6","1F202"],["1F210","1F23A"],["1F240","1F248"],["1F250","1F251"],["1F300","1F320"],["1F330","1F335"],["1F337","1F37C"],["1F380","1F393"],["1F3A0","1F3C4"],["1F3C6","1F3CA"],["1F3E0","1F3F0"],["1F400","1F43E"],["1F440","1F440"],["1F442","1F4F7"],["1F4F9","1F4FC"],["1F500","1F53D"],["1F540","1F543"],["1F550","1F567"],["1F5FB","1F640"],["1F645","1F64F"],["1F680","1F6C5"],["1F700","1F773"]],Z:"Zl|Zp|Zs",Zl:[["2028","2028"]],Zp:[["2029","2029"]],Zs:[["20","20"],["A0","A0"],["1680",
"1680"],["180E","180E"],["2000","200A"],["202F","202F"],["205F","205F"],["3000","3000"]],"~i":[["3A","3A"],["41","5A"],["5F","5F"],["61","7A"],["C0","D6"],["D8","F6"],["F8","2FF"],["370","37D"],["37F","1FFF"],["200C","200D"],["2070","218F"],["2C00","2FEF"],["3001","D7FF"],["F900","FDCF"],["FDF0","FFFD"],["10000","EFFFF"]],"~c":[["2D","2E"],["30","3A"],["41","5A"],["5F","5F"],["61","7A"],["B7","B7"],["C0","D6"],["D8","F6"],["F8","37D"],["37F","1FFF"],["200C","200D"],["203F","2040"],["2070","218F"],
["2C00","2FEF"],["3001","D7FF"],["F900","FDCF"],["FDF0","FFFD"],["10000","EFFFF"]],w:"Ll|Lm|Lo|Lt|Lu|Mc|Me|Mn|Nd|Nl|No|Sc|Sk|Sm|So"},functions:{"http://www.w3.org/2005/xpath-functions":{QName:[2],abs:[1],"accumulator-after":[1],"accumulator-before":[1],"adjust-date-to-timezone":[1,2],"adjust-dateTime-to-timezone":[1,2],"adjust-time-to-timezone":[1,2],"analyze-string":[2,3],apply:[2],"available-environment-variables":[0],"available-system-properties":[0],avg:[1],"base-uri":[0,1],"boolean":[1],ceiling:[1],
"codepoint-equal":[2],"codepoints-to-string":[1],"collation-key":[1,2],collection:[0,1],compare:[2,3],concat:[3],contains:[2,3],"contains-token":[2,3],"copy-of":[0,1],count:[1],current:[0],"current-date":[0],"current-dateTime":[0],"current-group":[0],"current-grouping-key":[0],"current-merge-group":[0,1],"current-merge-key":[0],"current-output-uri":[0],"current-time":[0],data:[0,1],dateTime:[2],"day-from-date":[1],"day-from-dateTime":[1],"days-from-duration":[1],"deep-equal":[2,3],"default-collation":[0],
"default-language":[0],"distinct-values":[1,2],doc:[1],"doc-available":[1],document:[1,2],"document-uri":[0,1],"element-available":[1],"element-with-id":[1,2],empty:[1],"encode-for-uri":[1],"ends-with":[2,3],"environment-variable":[1],error:[0,1,2,3],"escape-html-uri":[1],"exactly-one":[1],exists:[1],"false":[0],filter:[2],floor:[1],"fold-left":[3],"fold-right":[3],"for-each":[2],"for-each-pair":[3],"format-date":[2,5],"format-dateTime":[2,5],"format-integer":[2,3],"format-number":[2,3],"format-time":[2,
5],"function-arity":[1],"function-available":[1,2],"function-lookup":[2],"function-name":[1],"generate-id":[0,1],"has-children":[0,1],head:[1],"hours-from-dateTime":[1],"hours-from-duration":[1],"hours-from-time":[1],id:[1,2],idref:[1,2],"implicit-timezone":[0],"in-scope-prefixes":[1],"index-of":[2,3],innermost:[1],"insert-before":[3],"iri-to-uri":[1],"json-doc":[1,2],"json-to-xml":[1,2],key:[2,3],lang:[1,2],last:[0],"load-xquery-module":[1,2],"local-name":[0,1],"local-name-from-QName":[1],"lower-case":[1],
matches:[2,3],max:[1,2],min:[1,2],"minutes-from-dateTime":[1],"minutes-from-duration":[1],"minutes-from-time":[1],"month-from-date":[1],"month-from-dateTime":[1],"months-from-duration":[1],name:[0,1],"namespace-uri":[0,1],"namespace-uri-for-prefix":[2],"namespace-uri-from-QName":[1],nilled:[0,1],"node-name":[0,1],"normalize-space":[0,1],"normalize-unicode":[1,2],not:[1],number:[0,1],"one-or-more":[1],outermost:[1],"parse-ietf-date":[1],"parse-json":[1,2],"parse-xml":[1],"parse-xml-fragment":[1],path:[0,
1],position:[0],"prefix-from-QName":[1],put:[2],"random-number-generator":[0,1],"regex-group":[1],remove:[2],replace:[3,4],"resolve-QName":[2],"resolve-uri":[1,2],reverse:[1],root:[0,1],round:[1,2],"round-half-to-even":[1,2],"seconds-from-dateTime":[1],"seconds-from-duration":[1],"seconds-from-time":[1],serialize:[1,2],"serialize-json":[1,2],snapshot:[0,1],sort:[1,3],"starts-with":[2,3],"static-base-uri":[0],"stream-available":[1],string:[0,1],"string-join":[1,2],"string-length":[0,1],"string-to-codepoints":[1],
subsequence:[2,3],substring:[2,3],"substring-after":[2,3],"substring-before":[2,3],sum:[1,2],"system-property":[1],tail:[1],"timezone-from-date":[1],"timezone-from-dateTime":[1],"timezone-from-time":[1],tokenize:[1,2,3],trace:[1,2],transform:[1],translate:[3],"true":[0],"type-available":[1],unordered:[1],"unparsed-entity-public-id":[1,2],"unparsed-entity-uri":[1,2],"unparsed-text":[1,2],"unparsed-text-available":[1,2],"unparsed-text-lines":[1,2],"upper-case":[1],"uri-collection":[0,1],"xml-to-json":[1,
2],"year-from-date":[1],"year-from-dateTime":[1],"years-from-duration":[1],"zero-or-one":[1]},"http://www.w3.org/2005/xpath-functions/array":{append:[2],filter:[2],flatten:[1],"fold-left":[3],"fold-right":[3],"for-each":[2],"for-each-pair":[3],get:[2],head:[1],"insert-before":[3],join:[1],remove:[2],reverse:[1],size:[1],sort:[1,2],subarray:[2,3],tail:[1]},"http://www.w3.org/2005/xpath-functions/map":{contains:[2],entry:[2],"for-each":[2],get:[2],keys:[1],merge:[1],put:[3],remove:[2],size:[1]},"http://www.w3.org/2005/xpath-functions/math":{acos:[1],
asin:[1],atan:[1],atan2:[2],cos:[1],exp:[1],exp10:[1],log:[1],log10:[1],pi:[0],pow:[2],sin:[1],sqrt:[1],tan:[1]},"http://www.w3.org/2001/XMLSchema":{numeric:[1],string:[1],"boolean":[1],duration:[1],dateTime:[1],date:[1],time:[1],gYearMonth:[1],gMonth:[1],gMonthDay:[1],gYear:[1],gDay:[1],hexBinary:[1],base64Binary:[1],anyURI:[1],QName:[1],untypedAtomic:[1],decimal:[1],"float":[1],"double":[1],integer:[1],nonPositiveInteger:[1],negativeInteger:[1],"long":[1],"int":[1],"short":[1],"byte":[1],nonNegativeInteger:[1],
positiveInteger:[1],unsignedLong:[1],unsignedInt:[1],unsignedShort:[1],unsignedByte:[1],yearMonthDuration:[1],dayTimeDuration:[1],dateTimeStamp:[1],normalizedString:[1],token:[1],language:[1],Name:[1],NMTOKEN:[1],NCName:[1],ID:[1],IDREF:[1],ENTITY:[1],NMTOKENS:[1],ENTITIES:[1],IDREFS:[1],error:[1]},"http://saxonica.com/ns/interactiveXSLT":{apply:[2],call:[3],contains:[2],eval:[1],event:[0],get:[2],location:[0],page:[0],"query-params":[0],source:[0],style:[1],window:[0]}},elements:{accept:{},accumulator:{},
"accumulator-rule":{},"analyze-string":{},"apply-imports":{},"apply-templates":{},assert:{},attribute:{},"attribute-set":{},"break":{},"call-template":{},"catch":{},"character-map":{},choose:{},comment:{},"context-item":{},copy:{},"copy-of":{},"decimal-format":{},document:{},element:{},evaluate:{},expose:{},fallback:{},"for-each":{},"for-each-group":{},fork:{},"function":{},"global-context-item":{},"if":{},"import":{},include:{},iterate:{},key:{},map:{},"map-entry":{},"matching-substring":{},merge:{},
"merge-action":{},"merge-key":{},"merge-source":{},message:{},mode:{},namespace:{},"namespace-alias":{},"next-iteration":{},"next-match":{},"non-matching-substring":{},number:{},"on-completion":{},"on-empty":{},"on-non-empty":{},otherwise:{},output:{},"output-character":{},override:{},"package":{},param:{},"perform-sort":{},"preserve-space":{},"processing-instruction":{},"result-document":{},sequence:{},sort:{},"source-document":{},"strip-space":{},stylesheet:{},template:{},text:{},transform:{},"try":{},
"use-package":{},"value-of":{},variable:{},when:{},"where-populated":{},"with-param":{}},compiler:{}};
// Input 2
var gb=function(){var a;return{getPlatform:function(){return a},setPlatform:function(b){a=b},ui:function(b,h,u,z){return a.addMultipleDocs(b,h,u,z)},createDocument:function(){return a.createDocument()},xh:function(){return a.getDefaultDeliverMessage()},td:function(){return a.inBrowser()},mc:function(b){return a.isHTMLElement(b)},sl:function(b,h){return a.makeBuffer(b,h)},Bj:function(b,h,u){return a.makeHttpRequest(b,h,u)},parseXmlFromString:function(b,h,u){return a.parseXmlFromString(b,h,u)},readFile:function(b,
h){return a.readFile(b,h)},Gf:function(b){return a.resource(b)},Gl:function(b){return a.resourcePromise(b)},Ob:function(b,h){return a.resolveUri(b,h)},Sa:function(b){return a.saxonPrint(b)},Jl:function(b){return a.setBaseUri(b)},newURL:function(b,h){return a.newURL(b,h)},fileURL:function(b){return a.fileURL(b)},Ol:function(){return{version:"3.0",vendor:"Saxonica","vendor-url":"http://www.saxonica.com/","product-name":"Saxon-JS","product-version":"2.1","is-schema-aware":"no","supports-serialization":"yes",
"supports-backwards-compatibility":"yes","supports-namespace-axis":"yes","supports-streaming":"no","supports-dynamic-evaluation":"yes","supports-higher-order-functions":"yes","xpath-version":"3.1","xsd-version":"1.1"}},Si:function(){return a.extraProperties},fi:function(b){return a.toUTF8(b)},gl:function(b){return a.fromUTF8(b)}}}();
// Input 3
function J(a,b,h){h=void 0===h?null:h;var u=Error.call(this);this.message=u.message;"stack"in u&&(this.stack=u.stack);this.name="XError";this.message=void 0===a?"Saxon-JS run-time error":a;this.code=void 0===b?"FORG0001":b;h&&hb(this,h)}Ka(J,Error);ba=J.prototype;ba.toString=function(){return this.name+":"+this.message+"; code:"+this.code};function hb(a,b){function h(u,z){for(;u;){if(u[z])return u[z];u=u.parentNode}return null}a.xsltLineNr=h(b,"line");a.xsltModule=h(b,"module")}ba.getStackTrace=function(){return null.getStackTrace()};
ba.getMessage=function(){var a="Error "+this.code;null!=this.xsltLineNr&&(a+=" at "+this.xsltModule+"#"+this.xsltLineNr);return a+="\n    "+this.message};ba.code=null;ba.errorObject=null;ba.xsltLineNr=null;ba.xsltModule=null;ba.Ze=!1;
// Input 4
var ib=function(){function a(e){z("Internal error: "+e+"\n"+Error().stack);throw new J("Internal error: "+e,"SXJS0004");}function b(e){if(null===e)return"#null";if("undefined"===typeof e)return"#undefined";if(e.showValue)return e.showValue();if(e.N)return"expression "+e.N;if(n&&n.X(e)){if(n.ze(e))return"attribute @"+e.name+"='"+e.value+"'";if(n.Ud(e))return"ns:"+e.prefix+"="+e.uri;var k={1:"element <"+e.tagName+">",2:"attribute @"+e.name,3:"text:"+(e.nodeValue?e.nodeValue.substring(0,8):"''"),7:"pi: "+
e.target+"("+e.tagName+")",8:"comment ("+e.nodeValue+")",9:"document "+(e._saxonDocumentNumber?"#"+e._saxonDocumentNumber:"(No nr / #0)"),11:"docFrag"}[e.nodeType];if("9"===e.nodeType||"11"===e.nodeType){var p;for(p=0;p<e.childNodes.length;p++)k+="~>"+b(e.childNodes[p])}return k}if(e.code)return"AS"===e.code?'xdm:"'+e.toString()+'"':"ADI"===e.code?"xdm:"+e.toBig().toFixed():"AB"===e.code?"xdm:"+e.toString():"atomic:"+e.code+"('"+e.toString()+"')";if("object"===typeof e&&"length"in e){if(1===e.length)return b(e[0]);
k="(";for(p=0;p<e.length&&3>p;p++)0!==p&&(k+=","),k+=b(e[p]);4<=e.length&&(k+=",...("+e.length+")");return k+")"}return"function"===typeof e?e.toString():"("+typeof e+") "+e}function h(e){return"{'N':'"+e.N+"','C':["+(e.C||[]).map(function(k){return h(k)}).join(",")+"]}"}function u(e,k){for(;e;){if(e[k])return e[k];e=e.parentNode}return null}function z(e,k){(void 0===k?1:k)<=g&&gb.getPlatform().saxonPrint(e)}var n,g=1;Error.stackTraceLimit=100;return{assert:function(e,k){e||a(void 0===k?"assertion failed":
k)},la:a,location:function(e){return u(e,"module")+"#"+u(e,"line")},Oj:function(e){n=e},Sa:z,showValue:b,Yh:function(e){return e.showValue?e.showValue():n&&n.X(e)?b(e):e.code?"AS"===e.code?'xdm:"'+e.toString()+'"':"ADI"===e.code?"xdm:"+e.toBig().toFixed():"AB"===e.code?"xdm:"+e.toString():"atomic:"+e.code+"('"+e.toString()+"')":e.aj?e.toString():"("+typeof e+") "+e},Xj:function(e){if(!e)return"null expr";var k=e.N||"?",p;for(p in e)e.hasOwnProperty(p)&&!["C","N","parentNode","ELAB","PUSH"].includes(p)&&
(k+=" "+p+"="+e[p]);e.C&&(k+=" ("+e.C.map(function(v){return v.N}).join(",")+")");return k+=" at "+this.location(e)},Kl:h,setLogLevel:function(e){"number"==typeof e&&(g=e)},xf:g}}();
// Input 5
var kb=function(){function a(){function g(e){if(!(this instanceof g))return void 0===e?a():new g(e);if(e instanceof g)this.na=e.na,this.e=e.e,this.c=e.c.slice();else{var k,p,v;0===e&&0>1/e?e="-0":n.test(e=""+e)||u(NaN);this.na="-"==e.charAt(0)?(e=e.slice(1),-1):1;-1<(k=e.indexOf("."))&&(e=e.replace(".",""));0<(p=e.search(/e/i))?(0>k&&(k=p),k+=+e.slice(p+1),e=e.substring(0,p)):0>k&&(k=e.length);for(p=0;"0"==e.charAt(p);p++);if(p==(v=e.length))this.c=[this.e=0];else{for(;"0"==e.charAt(--v););this.e=
k-p-1;this.c=[];for(k=0;p<=v;this.c[k++]=+e.charAt(p++));}}this.constructor=g}g.prototype=z;g.he=20;g.We=1;g.ie=-7;g.Ue=21;return g}function b(g,e,k){var p=g.constructor,v=e-(g=new p(g)).e,B=g.c;B.length>++e&&h(g,v,p.We);B[0]?k?v=e:(B=g.c,v=g.e+v+1):++v;for(;B.length<v;B.push(0));v=g.e;return 1===k||k&&(e<=v||v<=p.ie)?(0>g.na&&B[0]?"-":"")+(1<B.length?B[0]+"."+B.join("").slice(1):B[0])+(0>v?"e":"e+")+v:g.toString()}function h(g,e,k,p){p=void 0===p?!1:p;var v=g.c,B=g.e+e+1;1===k?p=5<=v[B]:2===k?p=
5<v[B]||5==v[B]&&(p||0>B||void 0!==v[B+1]||v[B-1]&1):3===k?p=p||void 0!==v[B]||0>B:(p=!1,0!==k&&u("!Big.RM!"));if(1>B||!v[0])p?(g.e=-e,g.c=[1]):g.c=[g.e=0];else{v.length=B--;if(p)for(;9<++v[B];)v[B]=0,B--||(++g.e,v.unshift(1));for(B=v.length;!v[--B];v.pop());}return g}function u(g){g=Error(g);g.name="BigError";throw g;}var z={},n=/^-?(\d+(\.\d*)?|\.\d+)(e[+-]?\d+)?$/i;z.abs=function(){var g=new this.constructor(this);g.na=1;return g};z.cmp=function(g){var e=this.c,k=(g=new this.constructor(g)).c,
p=this.na,v=g.na,B=this.e,q=g.e;if(!e[0]||!k[0])return e[0]?p:k[0]?-v:0;if(p!=v)return p;g=0>p;if(B!=q)return B>q^g?1:-1;p=-1;for(v=(B=e.length)<(q=k.length)?B:q;++p<v;)if(e[p]!=k[p])return e[p]>k[p]^g?1:-1;return B==q?0:B>q^g?1:-1};z.Ub=function(g,e,k){var p=this.constructor,v=this.c,B=(g=new p(g)).c,q=this.na===g.na?1:-1;e="undefined"!==typeof e?e:p.he;k="undefined"!==typeof k?k:p.We;(e!==~~e||0>e||1E6<e)&&u("!Big.DP!");if(!v[0]||!B[0])return v[0]==B[0]&&u(NaN),B[0]||u(q/0),new p(0*q);var f,I=B.slice(),
d=p=B.length,c=v.length,l=v.slice(0,p),t=l.length,r=g,A=r.c=[],M=0,X=e+(r.e=this.e-g.e)+1;r.na=q;q=0>X?0:X;for(I.unshift(0);t++<p;l.push(0));do{for(f=0;10>f;f++){if(p!=(t=l.length))var ka=p>t?1:-1;else{var qa=-1;for(ka=0;++qa<p;)if(B[qa]!=l[qa]){ka=B[qa]>l[qa]?1:-1;break}}if(0>ka){for(g=t==p?B:I;t;){if(l[--t]<g[t]){for(qa=t;qa&&!l[--qa];l[qa]=9);--l[qa];l[t]+=10}l[t]-=g[t]}for(;!l[0];l.shift());}else break}A[M++]=ka?f:++f;l[0]&&ka?l[t]=v[d]||0:l=[v[d]]}while((d++<c||void 0!==l[0])&&q--);A[0]||1==
M||(A.shift(),r.e--);M>X&&h(r,e,k,void 0!==l[0]);return r};z.Ki=function(){var g=kb(0);return!this.cmp(g)};z.g=function(g){return 0<this.cmp(g)};z.l=function(g){return-1<this.cmp(g)};z.yj=function(){var g=kb(2).pow(52);return 0>this.cmp(g)};z.B=function(g){return 1>this.cmp(g)};z.sub=z.Ee=function(g){var e=this.constructor;var k=this.na,p=(g=new e(g)).na;if(k!=p)return g.na=-p,this.Je(g);var v=this.c.slice();var B=this.e;var q=g.c,f=g.e;if(!v[0]||!q[0])return q[0]?(g.na=-p,g):new e(v[0]?this:0);if(k=
B-f){(e=0>k)?(k=-k,B=v):(f=B,B=q);B.reverse();for(p=k;p--;B.push(0));B.reverse()}else for(B=((e=v.length<q.length)?v:q).length,k=p=0;p<B;p++)if(v[p]!=q[p]){e=v[p]<q[p];break}e&&(B=v,v=q,q=B,g.na=-g.na);if(0<(p=(B=q.length)-(e=v.length)))for(;p--;v[e++]=0);for(p=e;B>k;){if(v[--B]<q[B]){for(e=B;e&&!v[--e];v[e]=9);--v[e];v[B]+=10}v[B]-=q[B]}for(;0===v[--p];v.pop());for(;0===v[0];)v.shift(),--f;v[0]||(g.na=1,v=[f=0]);g.c=v;g.e=f;return g};z.zf=function(g){var e=this,k=e.constructor,p=e.na,v=(g=new k(g)).na;
g.c[0]||u(NaN);e.na=g.na=1;var B=1==g.cmp(e);e.na=p;g.na=v;if(B)return new k(e);e=e.Ub(g,0,0);return this.Ee(e.Ic(g))};z.add=z.Je=function(g){var e=this.constructor;var k=this.na,p=(g=new e(g)).na;if(k!=p)return g.na=-p,this.Ee(g);p=this.e;var v=this.c,B=g.e,q=g.c;if(!v[0]||!q[0])return q[0]?g:new e(v[0]?this:0*k);v=v.slice();if(k=p-B){0<k?(B=p,e=q):(k=-k,e=v);for(e.reverse();k--;e.push(0));e.reverse()}0>v.length-q.length&&(e=q,q=v,v=e);k=q.length;for(p=0;k;)p=(v[--k]=v[k]+q[k]+p)/10|0,v[k]%=10;p&&
(v.unshift(p),++B);for(k=v.length;0===v[--k];v.pop());g.c=v;g.e=B;return g};z.pow=function(g){var e=this,k=new e.constructor(1),p=k,v=0>g;(g!==~~g||-1E6>g||1E6<g)&&u("!pow!");for(g=v?-g:g;;){g&1&&(p=p.Ic(e));g>>=1;if(!g)break;e=e.Ic(e)}return v?k.Ub(p):p};z.round=function(g,e){var k=this,p=k.constructor;null==g?g=0:(g!==~~g||0>g||1E6<g)&&u("!round!");h(k=new p(k),g,null==e?p.We:e);return k};z.sqrt=function(){var g=this.constructor;var e=this.c;var k=this.na;var p=this.e;var v=new g("0.5");if(!e[0])return new g(this);
0>k&&u(NaN);k=Math.sqrt(this.toString());0===k||k===1/0?(k=e.join(""),k.length+p&1||(k+="0"),e=new g(Math.sqrt(k).toString()),e.e=((p+1)/2|0)-(0>p||p&1)):e=new g(k.toString());k=e.e+(g.he+=4);do p=e,e=v.Ic(p.Je(this.Ub(p)));while(p.c.slice(0,k).join("")!==e.c.slice(0,k).join(""));h(e,g.he-=4,g.We);return e};z.J=z.Ic=function(g){var e=this.constructor;var k=this.c,p=(g=new e(g)).c,v=k.length,B=p.length,q=this.e,f=g.e;g.na=this.na==g.na?1:-1;if(!k[0]||!p[0])return new e(0*g.na);g.e=q+f;v<B&&(e=k,k=
p,p=e,f=v,v=B,B=f);for(e=Array(f=v+B);f--;e[f]=0);for(q=B;q--;){B=0;for(f=v+q;f>q;)B=e[f]+p[q]*k[f-q-1]+B,e[f--]=B%10,B=B/10|0;e[f]=(e[f]+B)%10}B&&++g.e;e[0]||e.shift();for(q=e.length;!e[--q];e.pop());g.c=e;return g};z.toString=z.valueOf=z.toJSON=function(){var g=this.constructor,e=this.e,k=this.c.join(""),p=k.length;if(e<=g.ie||e>=g.Ue)k=k.charAt(0)+(1<p?"."+k.slice(1):"")+(0>e?"e":"e+")+e;else if(0>e){for(;++e;k="0"+k);k="0."+k}else if(0<e)if(++e>p)for(e-=p;e--;k+="0");else e<p&&(k=k.slice(0,e)+
"."+k.slice(e));else 1<p&&(k=k.charAt(0)+"."+k.slice(1));return 0>this.na&&this.c[0]?"-"+k:k};z.b=function(g){null==g?g=this.c.length-1:(g!==~~g||0>g||1E6<g)&&u("!toExp!");return b(this,g,1)};z.toFixed=function(g){var e=this.constructor,k=e.ie,p=e.Ue;e.ie=-(e.Ue=1/0);if(null==g)var v=this.toString();else g===~~g&&0<=g&&1E6>=g&&(v=b(this,this.e+g),0>this.na&&this.c[0]&&0>v.indexOf("-")&&(v="-"+v));e.ie=k;e.Ue=p;v||u("!toFix!");return v};z.toPrecision=function(g){if(null==g)return this.toString();(g!==
~~g||1>g||1E6<g)&&u("!toPre!");return b(this,g-1,2)};return a()}();
// Input 6
function lb(){}function mb(a){return a.replace(/[ \n\r\t]+/g," ").replace(/^[ ]|[ ]$/g,"")}function nb(a){if(32>a&&![9,10,13].includes(a)||55295<a&&57344>a||65533<a&&65536>a||1114111<a)throw new J("Invalid XML character 0x"+a.toString(16),"FOCH0001");return a}
function qb(a,b){if(sb(a)||sb(b)){a:{a=tb(a.toString());b=tb(b.toString());for(var h=0;;){if(h===a.length){a=h===b.length?0:-1;break a}if(h===b.length){a=1;break a}if(a[h]!==b[h]){a=a[h]-b[h];break a}h++}}return 0>a?-1:0===a?0:1}return a<b?-1:a===b?0:1}function sb(a){return Array.from(a).some(function(b){return 65535<b.codePointAt(0)})}function tb(a){return Array.from(a).map(function(b){return b.codePointAt(0)})}function wb(a){return String.fromCodePoint.apply(String,Ba(a.map(nb)))};
// Input 7
var xb=function(){function a(){null===h&&(h=gb.Gf("regexCategories"));return h}function b(g,e,k,p){function v(y,K){function U(P){for(P=P.toString(16);4>P.length;)P="0"+P;return"\\u"+(u&&4<P.length?"{"+P+"}":P)}function O(P,V,R){if(R){R=P;P=[];0!==R[0][0]&&P.push([0,R[0][0]-1]);for(var w=1;w<R.length;w++){var Y=R[w-1][1]+1,ia=R[w][0]-1;ia>=Y&&P.push([Y,ia])}R=R[R.length-1][1];(u||65535>R)&&P.push([R+1,u?1114111:65535])}R="";for(w=0;w<P.length;w++)if(u||65536>P[w][1])R+=U(P[w][0]),P[w][1]>P[w][0]&&
(R+="-"+U(P[w][1]));P=R;V||(P="["+P+"]");return P}function Z(P){return P.sort(function(V,R){return V[0]-R[0]})}function aa(P,V,R,w){var Y=[];Array.isArray(P)||q("Ranges must be array");P.forEach(function(ia){Y.push([parseInt(ia[0],16),parseInt(ia[1],16)])});R&&w&&Z(Y);return O(Y,V,R)}var ca=y.charAt(K+1);0>"nrt\\|.-^?*+{}()[]pPsSiIcCdDwW$".indexOf(ca)&&(0!==A||!/[0-9]/.test(ca))&&q("\\"+ca+" is not allowed in XPath regular expressions");switch(ca){case "p":case "P":var oa=y.indexOf("}",K);y=y.substring(K+
3,oa);B("x")&&(y=y.replace(/[ \n\r\t]+/g,""));var ea=a();(K=ea[y])||q("Unknown category "+y);if(1===y.length){var na=[];K.split("|").forEach(function(P){na=na.concat(ea[P])})}else na=K;I+=aa(na,0<A,"P"===ca,1===y.length);K=oa;break;case "d":I+=aa(a().Nd,0<A,!1,!1);K++;break;case "i":I+=O(d,0<A,!1);K++;break;case "c":I+=O(c,0<A,!1);K++;break;case "I":I+=O(d,0<A,!0);K++;break;case "C":I+=O(c,0<A,!0);K++;break;case "w":case "W":var x=a(),F=[];x.w.split("|").forEach(function(P){F=F.concat(x[P])});I+=
aa(F,0<A,"W"===ca,!0);K++;break;case "-":0<A&&(I+="\\");I+=ca;K++;break;default:/[0-9]/.test(ca)?0<A?q("Numeric escape within charclass"):(t[parseInt(ca,10)]||q("No capturing expression #"+ca),I+="\\"+ca,9>=r&&/[0-9]/.test(y.charAt(K+2))&&(I+="(?:)"),K++):(I+="\\"+ca,K++)}return{Bf:I,i:K}}function B(y){return 0<=e.indexOf(y)}function q(y){throw new J("Invalid XPath regular expression: "+y,"FORX0002");}k=void 0===k?null:k;p=void 0===p?null:p;var f=0;e=e?e.toString():"";if(!u&&k&&sb(k))throw new J("Cannot handle non-BMP characters with regular expressions in this browser",
"SXJS0002");k=(B("i")?"i":"")+(B("m")?"m":"")+(B("g")?"g":"")+(u?"u":"");if(B("j"))return new RegExp(g,e.replace("j",""));if(B("q"))return new RegExp(g.replace(/[\[\]\/\{\}\(\)\*\+\?\.\\^$|]/g,"\\$&"),k);for(var I="",d=[[58,58],[65,90],[95,95],[97,122],[192,214],[216,246],[248,767],[880,893],[895,8191],[8204,8205],[8304,8591],[11264,12271],[12289,55295],[63744,64975],[65008,65533],[65536,983039]],c=[[45,46],[48,58],[65,90],[95,95],[97,122],[183,183],[192,214],[216,246],[248,893],[895,8191],[8204,
8205],[8255,8256],[8304,8591],[11264,12271],[12289,55295],[63744,64975],[65008,65533],[65536,983039]],l=[],t=[],r=1,A=0,M=!1,X=!1,ka=0;ka<g.length;ka++){var qa=g.charAt(ka);switch(qa){case "[":0<A&&q("Nested square brackets");for(var Q=!1,ja=!1,D=ka+2;D<g.length-3;D++){if(!Q&&"-["===g.substring(D,D+2)){Q=g.substring(ka+1,D);var C=ja=void 0;for(C=D+2;C<g.length;C++)if("]"===g.charAt(C)&&"\\"!==g.charAt(C-1)){ja=g.substring(D+2,C+1);break}"^"===Q&&q("Invalid subtraction");g=g.substring(0,ka)+"(?!["+
ja+")["+Q+"]"+g.substring(C+2);ka--;ja=!0;break}else if(!Q&&"]"===g.charAt(D))break;Q="\\"===g.charAt(D)&&!Q}if(ja){M=!0;break}"]"===g.charAt(ka+1)?q("Character group is empty"):"[^]"===g.substring(ka,ka+3)&&q("Invalid character group [^]");A++;I+=qa;break;case "]":0===A&&q("Unmatched ']'");A--;I+=qa;X=!0;break;case "(":0===A&&(D=!0,ka+2<g.length&&"?"===g.charAt(ka+1)&&(":"===g.charAt(ka+2)||M&&"!"===g.charAt(ka+2)?D=!1:q("'(?' must be followed by ':'")),l.push(D?r++:-1),X=!1,D&&f++);I+=qa;break;
case ")":0===A&&(0===l.length&&q("Unmatched ')'"),X=l.pop(),0<X&&(t[X]=!0));I+=qa;X=!0;break;case ".":I=0===A&&B("s")?I+"[^]":I+qa;break;case "{":0===A&&(X||q("'{' not allowed here"),X=g.indexOf("}",ka+1),(0>X||!/^[0-9]+(,[0-9]*)?$/.test(g.substring(ka+1,X)))&&q("Invalid quantifier {"+g.substring(ka+1,X+1)),X=!1);I+=qa;break;case " ":case "\n":case "\r":case "\t":if(!B("x")||0<A)I+=qa;break;case "\\":if(B("q"))I+="\\\\";else if(ka+1<g.length)B("x")&&/\s/.test(g.charAt(ka+1))&&ka++,ka=v(g,ka),I=ka.Bf,
ka=ka.i;else throw new J("No character for escape code in XPath regex","FORX0002");X=!0;break;default:X=!0,I+=qa}}0<A&&q("Unmatched '['");0!==l.length&&q("Unmatched '(");p&&p(f);try{return new RegExp(I,k)}catch(y){if(/^[imsxjqg]*$/.test(e))throw new J("Invalid regular expression /"+g+"/: "+y.message.substring(/Invalid |Range out/.test(y.message)?y.message.lastIndexOf(":"):0),"FORX0002");throw new J("Invalid regex flags: "+e,"FORX0001");}}var h=null,u=!1;try{u=1==="\ud83d\ude80".match(new RegExp(/./,
"gu")).length}catch(g){u=!1}var z=null,n=null;return{Yf:function(){z=b("^\\i\\c*$","");n=b("^\\c+$","")},Jj:a,Ke:b,Fi:function(g,e,k){e=e.toString();if(/j/.test(e))return g;if(/q/.test(e))return g.replace(/\$/g,"$$$$");if(/(^|[^\\])\$([^0-9]|$)/.test(g)||/(^|[^\\])\\([^\\$]|$)/.test(g))throw new J("Invalid replacement string: "+g,"FORX0004");var p=e=!1,v="",B;for(B=0;B<g.length;B++){var q=g.charAt(B);switch(q){case "\\":e&&(v+=q);e=!e;break;case "$":e?(v+="$$",e=!e):p=!0;break;case "0":p?(v+="$&",
p=!1):v+=q;break;case "1":case "2":case "3":case "4":case "5":case "6":case "7":case "8":case "9":p?parseInt(q,10)<=k&&(v+="$"+q,p=!1):v+=q;break;default:v+=q}}return v},Mb:function(g){return z.test(g)&&!/:/.test(g)},tj:function(g){return z.test(g)},sj:function(g){return n.test(g)},Eh:function(g){return z.test(g)&&/^[^:]+(:[^:]+)?$/.test(g)},trim:function(g){return g.replace(/^[ \t\r\n]+|[ \t\r\n]+$/g,"")},collapse:function(g){return this.trim(g).replace(/^[ \t\r\n]+/g," ")}}}();
// Input 8
function yb(a){ib.assert(this.fc().test(a));this.code=a}ba=yb.prototype;ba.fc=function(){return/.*/};ba.toString=function(){return""};ba.compareTo=function(){return null};ba.equals=function(){return!0};ba.hashCode=function(){return""};ba.matchKey=function(){return this.code+"_"+this.toString()};ba.isNumeric=function(){return/^A[DFO]/.test(this.code)};function zb(a){return/^A[SUZ]/.test(a.code)}ba.showValue=function(){return Ab.wd(this.code)+"('"+this.toString()+"')"};
function Bb(){yb.apply(this,arguments)}Ka(Bb,yb);Bb.prototype.isNumeric=function(){return!0};Bb.prototype.toDouble=function(){return new Cb(this.toNumber())};Bb.prototype.toNumber=function(){return NaN};Bb.prototype.toBig=function(){return this.toDouble().toBig()};function Db(a,b){yb.call(this,void 0===b?"AS":b);this.value=a||"";"string"!==typeof a&&ib.la("bad input to string constructor "+ib.showValue(a));this.b=0}Ka(Db,yb);ba=Db.prototype;ba.fc=function(){return/^A(S|Z$|U$)/};ba.toString=function(){return this.value};
ba.compareTo=function(a){return a instanceof Db?qb(this.value,a.value):Eb(this.code,a.code)};ba.equals=function(a){return 0===this.compareTo(a)};ba.hashCode=function(){if(6>=this.value.length)return this.value;var a=this.b,b=this.value.length;if(0===a&&0<b){for(var h=0;h<b;h++)a=31*a+this.value.charCodeAt(h++);this.b=a&=16777215}return Fb(a)};ba.matchKey=function(){return"s"+this.toString()};
function Gb(a,b){yb.call(this,void 0===b?"AX":b);if(!(a instanceof Uint8Array))throw new J("Uint8Array expected");this.value=a}Ka(Gb,yb);ba=Gb.prototype;ba.fc=function(){return/^A[X2]$/};function Hb(a){return a.reduce(function(b,h){return b+h.toString(16).padStart(2,"0")},"").toUpperCase()}
ba.toString=function(){if("AX"===this.code)var a=Hb(this.value);else{a=this.value;var b=0,h=0,u=[];if(0===a.length)a="";else{do{var z=a[b++];var n=a[b++];var g=a[b++];var e=z<<16|n<<8|g;z=e>>18&63;n=e>>12&63;g=e>>6&63;e&=63;u[h++]="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".charAt(z)+"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".charAt(n)+"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".charAt(g)+"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".charAt(e)}while(b<
a.length);b=u.join("");a=a.length%3;a=(a?b.slice(0,a-3):b)+"===".slice(a||3)}}return a};ba.compareTo=function(a){return a instanceof Gb&&this.code===a.code?qb(Hb(this.value),Hb(a.value)):Eb(this.code,a.code)};ba.equals=function(a){if(a instanceof Gb&&this.code===a.code){var b=a.value;return this.value.length===b.length&&this.value.every(function(h,u){return h===b[u]})}return Eb(this.code,a.code)};ba.hashCode=function(){return(new Db(Hb(this.value.slice(0,8)))).hashCode()};
ba.matchKey=function(){return("AX"===this.code?"h":"i")+this.toString()};function Cb(a,b){Bb.call(this,void 0===b?"AO":b);this.value=a;"number"!==typeof a&&ib.la("type of value supplied to XdmDouble is not number: "+typeof a)}Ka(Cb,Bb);ba=Cb.prototype;ba.fc=function(){return/^A[OF]$/};
ba.toString=function(){if(0===this.value)return 0>1/this.value?"-0":"0";if(this.value===Number.POSITIVE_INFINITY)return"INF";if(this.value===Number.NEGATIVE_INFINITY)return"-INF";var a=this.value,b=Math.abs(a);if(1E6<=b||1E-6>b){b=a.toExponential();var h=b.indexOf("e");a=b.substring(0,h);b=b.substring(h+1);"AF"===this.code&&(a=parseFloat(a),a=(0>a?a-5E-8:a+5E-8).toString(),a=a.substring(0,a.indexOf(".")+8),a=a.replace(/\.?0+$/,""));a.includes(".")||(a+=".0");return a+"E"+b.replace("+","")}return"AF"===
this.code&&0!==a%1?(b=(0>a?a-5E-8:a+5E-8).toString(),b=b.substring(0,b.indexOf(".")+6),b.replace(/0+$/,"")):a.toString()};ba.equals=function(a){return 0===this.compareTo(a)};ba.compareTo=function(a){return a.isNumeric()?(a=a.toNumber(),"AF"===this.code&&Math.fround&&(a=Math.fround(a)),this.value<a?-1:this.value===a?0:1):Eb(this.code,a.code)};
ba.Bg=function(a){return a instanceof Cb||a instanceof Ib?this.value===a.value||isNaN(this.value)&&isNaN(a.value):a instanceof Jb?Ab.XS.ub.O(this).equals(a):!1};ba.hashCode=function(){return isNaN(this.value)?"NaN":isFinite(this.value)?Fb(this.value):0>this.value?"-INF":"INF"};ba.matchKey=function(){return"n"+this.hashCode()};ba.toNumber=function(){return this.value};ba.toDouble=function(){return this};ba.abs=function(){return new Cb(Math.abs(this.value),this.code)};
ba.Af=function(){return new Cb(-this.value,this.code)};ba.dg=function(){return new Cb(Math.ceil(this.value),this.code)};ba.floor=function(){return new Cb(Math.floor(this.value),this.code)};ba.round=function(a){0===a?a=Math.round(this.value):(a=Math.pow(10,-a),a*=Math.round(this.value/a));return new Cb(a,this.code)};ba.Hf=function(a){return isFinite(this.value)?(a=(new Jb(kb(this.value))).Hf(a),0===a.toNumber()&&0>this.value&&(a=Ab.XS.double.fromString("-0")),Ab.bb[this.code].O(a)):this};
function Ib(a,b){Bb.call(this,void 0===b?"ADI":b);"number"!==typeof a&&ib.la("type of value supplied to XdmInteger is not number: "+typeof a);this.value=a}Ka(Ib,Bb);ba=Ib.prototype;ba.fc=function(){return/^ADI/};ba.toString=function(){return this.value.toString()};ba.equals=function(a){return 0===this.compareTo(a)};ba.compareTo=function(a){return a.isNumeric()?this.value-a.toNumber():Eb(this.code,a.code)};ba.hashCode=function(){return Fb(this.value)};
ba.Bg=function(a){return a instanceof Cb||a instanceof Ib?this.value===a.value:a instanceof Jb?Ab.XS.ub.O(this).equals(a):!1};ba.matchKey=function(){return"n"+this.hashCode()};ba.toDouble=function(){return new Cb(this.value)};ba.toNumber=function(){return this.value};ba.toBig=function(){return new kb(this.value)};ba.abs=function(){return new Ib(Math.abs(this.value))};ba.Af=function(){return new Ib(-this.value)};ba.dg=function(){return this};ba.floor=function(){return this};
ba.round=function(a){return 0>a?(a=Math.pow(10,-a),new Ib(Math.round(this.value/a)*a)):this};ba.Hf=function(a){return 0>a?(a=Math.pow(10,-a),new Ib(parseInt(kb(this.value).Ub(a,100,2).round(0,2).Ic(a),10))):this};function Jb(a,b){Bb.call(this,void 0===b?"AD":b);this.value=new kb(a)}Ka(Jb,Bb);ba=Jb.prototype;ba.fc=function(){return/^AD/};ba.toString=function(){return this.value.toFixed()};ba.equals=function(a){return 0===this.compareTo(a)};
ba.compareTo=function(a){return a instanceof Jb?this.value.cmp(a.value):/^AD/.test(a.code)?this.value.cmp(a.toBig()):a.isNumeric()?this.value.cmp(new kb(a.toDouble())):Eb(this.code,a.code)};ba.hashCode=function(){var a=this.value;return a.zf(kb(1)).Ki()&&a.abs().yj()?this.toNumber().toString(36):this.toString().substring(0,8)};ba.Bg=function(a){return a instanceof Cb||a instanceof Ib?0===this.compareTo(Ab.XS.ub.O(a)):a instanceof Jb?0===this.compareTo(a):!1};ba.matchKey=function(){return"n"+this.hashCode()};
ba.toBig=function(){return this.value};ba.toDouble=function(){return new Cb(parseFloat(this.value.toFixed()))};ba.toNumber=function(){return parseFloat(this.value.toFixed())};ba.abs=function(){return new Jb(this.value.abs(),this.code)};ba.Af=function(){var a=kb(this.value);a.na=-a.na;return new Jb(a,"AD"===this.code?"AD":"ADI")};ba.dg=function(){return new Jb(this.value.round(0,0>this.value.na?0:3),this.code)};ba.floor=function(){return new Jb(this.value.round(0,0>this.value.na?3:0),this.code)};
ba.round=function(a){function b(n,g){n=new kb(n);n.e+=g;return n}var h=this.value,u=0;0>a&&(u=a,a=0,h=b(h,u));var z=0<h.na?kb(0):this.floor().abs();h=h.Je(z).round(a,1).Ee(z);0!==u&&(h=b(h,-u));return new Jb(h,"AD"===this.code?"AD":"ADI")};ba.Hf=function(a){0>a?(a=kb(10).pow(-a),a=this.value.Ub(a,100,2).round(0,2).Ic(a)):a=this.value.round(a,2);return new Jb(a,"AD"===this.code?"AD":"ADI")};
function Kb(a){yb.call(this,"AB");"boolean"!==typeof a&&ib.la("type of value supplied to XdmBoolean is not boolean: "+typeof a);this.value=a}Ka(Kb,yb);ba=Kb.prototype;ba.fc=function(){return/^AB$/};ba.toString=function(){return this.value.toString()};ba.toBoolean=function(){return this.value};ba.compareTo=function(a){return a instanceof Kb?(this.value?1:0)-(a.value?1:0):Eb(this.code,a.code)};ba.equals=function(a){return 0===this.compareTo(a)};ba.hashCode=function(){return this.value.toString()};
ba.matchKey=function(){return"b"+this.toString()};function Lb(a,b,h){yb.call(this,"AQ");this.prefix=a=a?a:"";this.uri=b?b:"";this.local=h;if(""!==a&&!xb.Mb(a))throw new J("Invalid prefix: '"+a+"'","FORG0001");if(!xb.Mb(h))throw new J("Invalid local name: '"+h+"' (prefix='"+a+"', uri='"+b+"')","FORG0001");}Ka(Lb,yb);ba=Lb.prototype;ba.fc=function(){return/^AQ$/};ba.$=function(){return"Q{"+this.uri+"}"+this.local};ba.toString=function(){return(0<this.prefix.length?this.prefix+":":"")+this.local};
ba.equals=function(a){a instanceof Lb||Eb(this.code,a.code);return a instanceof Lb&&this.local===a.local&&this.uri===a.uri};ba.compareTo=function(){throw new J("QNames are not ordered","FORG0006");};ba.hashCode=function(){return this.uri.substring(this.uri.length-3)+":"+(new Db(this.local)).hashCode()};ba.matchKey=function(){return"q"+this.$()};ba.sd=function(a){return this.uri===a};ba.Jc=function(a){return new Lb(a,this.uri,this.local)};
function Mb(a,b,h){yb.call(this,void 0===h?"AM":h);this.Pa=a;this.Ga=b;a instanceof Date||ib.la("type of UTCdate supplied to XdmDateTime is not Date");b&&"number"!==typeof b&&ib.la("type of value for timezone offset supplied to XdmDateTime is not number: "+typeof b)}Ka(Mb,yb);ba=Mb.prototype;ba.fc=function(){return/^A([AGHIJKMT]|MP)$/};ba.proxy=function(){var a=new Date;return new Date(this.Pa.getTime()+6E4*(this.Ga||0===this.Ga?this.Ga:-a.getTimezoneOffset()))};
function Nb(a,b){b=b.substr(0,b.length-1);if(0===a.Pa.getUTCMilliseconds())b=b.replace(".000","");else for(;"0"===b.charAt(b.length-1);)b=b.substr(0,b.length-1);b=b.replace(/^-0?0?/,"-");return b=b.replace(/^\+0?0?/,"")}
ba.toString=function(){var a=Nb(this,this.proxy().toISOString()),b="-"===a.charAt(0)?1:0;return function(h){switch(h){case "AM":case "AMP":return a;case "AA":return a.substring(0,10+b);case "AT":return a.substring(11+b);case "AH":return a.substring(0,7+b);case "AG":return a.substring(0,4+b);case "AJ":return"-"+a.substring(4+b,10+b);case "AI":return"-"+a.substring(4+b,7+b);case "AK":return"--"+a.substring(7+b,10+b);default:throw"type not recognised "+h;}}(this.code)+Ob(this)};
ba.compareTo=function(a){(/^A[GHIJK]/.test(this.code)||/^A[GHIJK]/.test(a.code))&&Eb(this.code,a.code);return Pb(this,a)};function Pb(a,b){if(b instanceof Mb&&(b.code.startsWith(a.code)||a.code.startsWith(b.code)))return+a.Pa.getTime()-b.Pa.getTime();Eb(a.code,b.code)}ba.equals=function(a){return 0===Pb(this,a)};ba.hashCode=function(){return Fb(this.Pa.getTime()-1454346732296)};ba.matchKey=function(){return"t"+this.hashCode()};
function Qb(a,b){return Ab.XS.dateTime.fromDate(new Date(a.Pa.getTime()+6E4*b),a.Ga)}
function Rb(a,b){var h=a.toString(),u=parseInt(h.substring(5,7),10),z=parseInt(h.substring(8,10),10),n=parseInt(h.substring(0,4),10)+Math.floor((u+b-1)/12),g=0>n;b=(u+b-1)%12+1;for(0>=b&&(b+=12);!Ab.wj([n,b,z,0,0,0]);)--z;return Ab.bb[a.code].fromString((g?"-"+(1E4+-n).toString().substring(1):(1E4+n).toString().substring(1))+"-"+(100+b).toString().substring(1)+"-"+(100+z).toString().substring(1)+h.substring(10))}
function Ob(a){if(a=a.Ga){var b=Math.abs(a);return(0>a?"-":"+")+(100+Math.floor(b/60)).toString().substring(1)+":"+(100+b%60).toString().substring(1)}return 0===a?"Z":""}function Sb(a,b,h){yb.call(this,void 0===h?"AR":h);this.Wa=a;this.Ra=b;"number"===typeof a&&"number"===typeof b||ib.la("type of value supplied to XdmDuration is not number: "+typeof a+", "+typeof b);if(0<a&&0>b||0>a&&0<b)throw new J("Months/seconds must both be negative or both positive","FORG0001");}Ka(Sb,yb);ba=Sb.prototype;
ba.fc=function(){return/^AR[DY]?$/};function Tb(a){function b(u,z){z.forEach(function(n){1E3!==n?(h.push(Math.floor(u/n)),u%=n):h.push(u/n)})}var h=[];b(Math.abs(a.Wa),[12,1]);b(Math.abs(a.Ra),[864E5,36E5,6E4,1E3]);return h}function Ub(a){a=a.Wa+a.Ra;return 0>a?-1:0<a?1:0}
ba.toString=function(){var a=Ub(this);if(0===a)var b="ARY"===this.code?"P0M":"PT0S";else b=0>a?"-P":"P",Tb(this).forEach(function(h,u){3===u&&(b+="T");0!==h&&(6===u?b+=h/1E3+"S":b+=h+"YMDHMS".split("")[u])}),b=b.replace(/T$/,"");return b};
ba.compareTo=function(a){if(a instanceof Sb){if(Ab.XS.yearMonthDuration.matches(this)&&Ab.XS.yearMonthDuration.matches(a))return this.Wa-a.Wa;if(Ab.XS.dayTimeDuration.matches(this)&&Ab.XS.dayTimeDuration.matches(a))return this.Ra-a.Ra;throw new J("Cannot compare durations that mix months and seconds","XPTY0004");}return Eb(this.code,a.code)};ba.equals=function(a){a instanceof Sb||Eb(this.code,a.code);return a instanceof Sb&&this.Wa===a.Wa&&this.Ra===a.Ra};
ba.hashCode=function(){return this.Wa.toString()+":"+this.Ra.toString()};ba.matchKey=function(){return"d"+this.hashCode()};function Fb(a){return Number.isInteger(a)&&Math.abs(a)<Math.pow(2,52)?a.toString(36):Ab.XS.ub.fromNumber(a).toString().substring(0,8)}function Eb(a,b){throw new J("Cannot compare "+Ab.wd(a)+" with "+Ab.wd(b),"XPTY0004");};
// Input 9
var Ab=function(){function a(){function w(){return!0}function Y(N,H,E,da){var S=na[E],ta=ea[N]=na[H]={name:N,code:H,mb:S,matches:function(wa){return wa instanceof yb&&wa.code.startsWith(H)},wc:function(wa){da(wa)||g(wa.toString(),H);wa.code=H;return wa},fromString:function(wa){return ta.wc(S.fromString(wa))},O:function(wa){return ta.wc(S.O(wa))}}}function ia(N,H,E){var da=H.replace(/.$/,"");Y(N,H,da,E);var S=ea[N],ta=na[da];S.fromString=function(wa){/INF|NaN/.test(wa)&&g(wa,H,null,"FOCA0002");return S.wc(ta.fromString(wa))};
S.fromNumber=function(wa){wa=ta.fromNumber(wa);var va=wa.toNumber();isFinite(va)||g(wa.toString(),H,null,"FOCA0002");E(wa)||g(wa.toString(),H);wa.code=H;return wa}}function T(N,H,E,da){var S=H.replace(/.$/,"");Y(N,H,S,E);var ta=na[H],wa=na[S];ta.wc=function(va){va=da(va.toString());E(va)||g(va,H);return new Db(va,H)};ta.fromString=function(va){return ta.wc(wa.fromString(da(va)))}}function fa(N,H,E){Y(N,H,"AR",E);var da=ea[N];da.wc=function(S){if(S instanceof Sb)return new Sb("ARY"===H?S.Wa:0,"ARD"===
H?S.Ra:0,H);E(S)||g(S.toString(),H);S.code=H;return S};da.Vb=function(S,ta){return da.wc(aa.Vb(S,ta))};da.O=function(S){return aa.matches(S)?da.wc(S):R.string.matches(S)||R.untypedAtomic.matches(S)?c(S.toString(),H):da.wc(aa.O(S))}}function xa(N,H){return function(E){E=E.toNumber();return E>=N&&E<=H}}for(var m in ea){var G=ea[m];na[G.code]=G}v("gYear","AG");v("gYearMonth","AH");v("gMonth","AI");v("gMonthDay","AJ");v("gDay","AK");ia("nonPositiveInteger","ADIN",function(N){return 0>=N.toNumber()});
ia("negativeInteger","ADINN",function(N){return 0>N.toNumber()});ia("long","ADIL",function(N){N=N.toBig();return 0<=N.cmp(kb("-9223372036854775808"))&&0>=N.cmp(kb("9223372036854775807"))});ia("int","ADILI",xa(-2147483648,2147483647));ia("short","ADILIS",xa(-32768,32767));ia("byte","ADILISB",xa(-128,127));ia("nonNegativeInteger","ADIP",function(N){return 0<=N.toNumber()});ia("positiveInteger","ADIPP",function(N){return 0<N.toNumber()});ia("unsignedLong","ADIPL",function(N){return 0<=N.toNumber()&&
0>=N.toBig().cmp(kb("18446744073709551615"))});ia("unsignedInt","ADIPLI",function(N){return 0<=N.toNumber()&&0>=N.toBig().cmp(kb("4294967295"))});ia("unsignedShort","ADIPLIS",xa(0,65535));ia("unsignedByte","ADIPLISB",xa(0,255));T("normalizedString","ASN",w,function(N){return N.replace(/[ \r\t\n]/g," ")});T("token","ASNT",w,mb);T("language","ASNTL",function(N){return/^[a-zA-Z]{1,8}(-[a-zA-Z0-9]{1,8})*$/.test(N)},mb);T("NMTOKEN","ASNTK",xb.sj,mb);T("Name","ASNTN",xb.tj,mb);T("NCName","ASNTNC",xb.Mb,
mb);T("ID","ASNTNCI",xb.Mb,mb);T("IDREF","ASNTNCR",xb.Mb,mb);T("ENTITY","ASNTNCE",xb.Mb,mb);fa("yearMonthDuration","ARY",function(N){return 0===N.Ra});fa("dayTimeDuration","ARD",function(N){return 0===N.Wa});ea.dayTimeDuration.qh=function(N){return new Sb(0,6E4*N,"ARD")};R.language=ea.language;R.Kg=ea.NCName;R.dayTimeDuration=ea.dayTimeDuration;R.yearMonthDuration=ea.yearMonthDuration;R.wh=ea.gYearMonth;R.vh=ea.gYear;R.uh=ea.gMonthDay;R.th=ea.gMonth;R.sh=ea.gDay;a=function(){}}function b(w,Y){return Y?
-w:w}function h(w,Y){return Y*(void 0===w?0:Math.round(1E3*parseFloat(w)))}function u(w,Y){return Y*(void 0===w?0:parseInt(w,10))}function z(w){return w.mb&&!["ADI","ARY","ARD"].includes(w.code)?z(w.mb):w}function n(w,Y){throw new J("Casting from "+l(w.code)+" to "+l(Y)+" is not allowed","XPTY0004");}function g(w,Y,ia,T){ia=void 0===ia?null:ia;T=void 0===T?"FORG0001":T;throw new J('Supplied value "'+w+'" is not a valid '+l(Y)+(ia?" ("+ia+")":""),T);}function e(w,Y){w=xb.trim(w);/.\.$/.test(w)&&(w+=
"0");if(/^[-+]?([0-9]+(\.[0-9]*)?|\.[0-9]+)([Ee][-+]?[0-9]+)?$/.test(w))return parseFloat(w);if(/^NaN$/.test(w))return NaN;if(/^-INF$/.test(w))return-Infinity;if(/^(\+)?INF$/.test(w))return Infinity;Y&&g(w,"AO");return NaN}function k(w){w=w.replace(/\s/g,"");/^(....)*$/.test(w)||g(w,"A2","length must be a multiple of 4");/^[A-Za-z0-9+/]*=?=?$/.test(w)||g(w,"A2","only [A-Za-z0-9+/] allowed, or '=' at the end");/^([^=]*|.*[AQgw]==|.*[AEIMQUYcgkosw048]=)$/.test(w)||g(w,"A2","invalid characters before '='");
var Y=0,ia=[];if(0===w.length)return new Uint8Array(0);do{var T="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".indexOf(w.charAt(Y++));var fa="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".indexOf(w.charAt(Y++));var xa="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".indexOf(w.charAt(Y++));var m="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=".indexOf(w.charAt(Y++));var G=T<<18|fa<<12|xa<<6|m;T=G>>16&255;fa=G>>8&255;G&=
255;64===xa?ia.push(T):64===m?ia.push(T,fa):ia.push(T,fa,G)}while(Y<w.length);return new Uint8Array(ia)}function p(w){w=xb.trim(w);/^([a-fA-F0-9][a-fA-F0-9])*$/.test(w)||g(w,"AX");return(w=w.match(/../g))?new Uint8Array(w.map(function(Y){return parseInt(Y,16)})):new Uint8Array(0)}function v(w,Y){ea[w]=na[Y]={name:w,code:Y,matches:function(ia){return ia instanceof Mb&&ia.code.startsWith(Y)},fromString:function(ia){return I(ia,Y)},fromDate:function(ia,T){return new Mb(ia,T,Y)},O:function(ia){if(ia instanceof
Mb&&ia.code.startsWith(Y))return ia;if(R.dateTime.matches(ia)||R.md.matches(ia)){var T=na[Y];return T.fromString(T.fromDate(ia.Pa,ia.Ga).toString())}if(/^A[SZ]/.test(ia.code))return I(ia.value,Y);n(ia,Y)}}}function B(w){if(-1!==w.indexOf(".",w.length-4)){w=w.split(".");for(var Y=w[1];3>Y.length;)Y+="0";w=w[0]+"."+Y}return w}function q(w){var Y=w[0],ia=w[1],T=w[2],fa=w[3],xa=w[4];w=w[5];return!(1>ia||12<ia||1>T||T>[31,0!==Y%4||0===Y%100&&0!==Y%400?28:29,31,30,31,30,31,31,30,31,30,31][ia-1]||24<=fa||
60<=xa||60<=w)}function f(w,Y){w=w.split(/[\-T:]/).map(function(T){return parseFloat(T)});var ia=w[0];if(1>ia||9999<ia)throw new J("Year "+ia+" is out of range","FODT0001");q(w)||g(Y,"AM")}function I(w,Y){var ia=w.match(x[Y]);null===ia&&g(w,Y);var T=ia[2]?ia[2]:"",fa=F[Y](ia[1])+T;/24:00:00(\.0+)?([Z+\-]|$)/.test(fa)&&(fa="AT"===Y?fa.substring(0,10)+"T00:00:00":Qb(I(fa.substring(0,10),"AA"),1440)+"T00:00:00");if("-"===fa.charAt(0)){f(fa.substring(1,20),w);w=fa.substr(1).split(/[\-T:]/).map(function(m){return parseFloat(m)});
fa=w[5];var xa=new Date(Date.UTC(-w[0],w[1]-1,w[2],w[3],w[4],fa,Math.round(1E3*(fa-Math.floor(fa)))))}else f(fa.substring(0,19),w),fa=fa.split(/[\-T:]/),w=fa.map(function(m){return parseFloat(m)}),xa=w[5],xa=new Date(Date.UTC(fa[0],w[1]-1,w[2],w[3],w[4],xa,Math.round(1E3*(xa-Math.floor(xa))))),100>w[0]&&xa.setUTCFullYear(fa[0]);xa=""===T?new Date(xa.getTime()+6E4*(new Date).getTimezoneOffset()):new Date(xa.getTime()-6E4*d(T));return new Mb(xa,d(ia[2]?ia[2]:""),Y)}function d(w){if(""===w)return null;
if("Z"===w)return 0;var Y=w.split(":"),ia=/^-.*/.test(Y[0]),T=Math.abs(parseInt(Y[0],10));Y=parseInt(Y[1],10);if(14<T||59<Y)throw new J("Invalid timezone "+w,"FORG0001");return(ia?-1:1)*(60*T+Y)}function c(w,Y){var ia=w.match(x[Y]);null!==ia&&/\d/.test(w)&&!/T$/.test(w)||g(w,Y);return new Sb(P[V[Y][0]](ia,w),P[V[Y][1]](ia,w),Y)}function l(w){var Y=na[w];return Y?"xs:"+Y.name:w}var t={name:"anyAtomicType",code:"A",matches:function(w){return w instanceof yb}},r={name:"untypedAtomic",code:"AZ",fromString:function(w){return new Db(w,
"AZ")},matches:function(w){return"AZ"===w.code},O:function(w){return new Db(w.toString(),"AZ")}},A={name:"string",code:"AS",fromString:function(w){return new Db(w,"AS")},matches:function(w){return/^AS/.test(w.code)},O:function(w){return new Db(w.toString(),"AS")}},M={name:"anyURI",code:"AU",fromString:function(w){w=w.replace(/[ \r\t\n]+/g," ").replace(/^[ ]|[ ]$/g,"");return new Db(w,"AU")},matches:function(w){return"AU"===w.code},O:function(w){if(w instanceof Db)return R.anyURI.fromString(w.toString());
n(w,"AU")}},X={name:"double",code:"AO",fromString:function(w){return new Cb(e(w,!0))},nf:function(w){return new Cb(e(w,!1))},fromNumber:function(w){return new Cb(w)},matches:function(w){return"AO"===w.code},O:function(w){if(null===w||"undefined"==typeof w)return R.double.fromNumber(NaN);if(R.string.matches(w)||R.untypedAtomic.matches(w))return R.double.fromString(w.toString());if(w.isNumeric())return R.double.fromNumber(w.toNumber());if(R.boolean.matches(w))return R.double.fromNumber(w.toBoolean()?
1:0);n(w,"AO")},NaN:null};X.NaN=X.fromNumber(NaN);var ka={name:"decimal",code:"AD",fromString:function(w){w=xb.trim(w).replace(/^\+/,"");if(/^[-+]?([0-9]+(\.[0-9]*)?|\.[0-9]+)$/.test(w))return new Jb(new kb(w));g(w,"AD")},pb:function(w){return new Jb(w)},fromNumber:function(w){isFinite(w)||g(w,"AD",null,"FOCA0002");return new Jb(new kb(w))},matches:function(w){return w instanceof Jb||w instanceof Ib},O:function(w){switch(w.code.substring(0,2)){case "AD":return new Jb(w.value,"AD");case "AS":case "AZ":return R.ub.fromString(w.toString());
case "AO":case "AF":return R.ub.fromNumber(w.toNumber());case "AB":return R.ub.fromString(w.toBoolean()?"1":"0");default:n(w,"AD")}}},qa={name:"float",code:"AF",fromString:function(w){return R.float.fromNumber(e(w,!0))},fromNumber:function(w){Math.fround&&(w=Math.fround(w));return new Cb(w,"AF")},matches:function(w){return"AF"===w.code},O:function(w){return R.float.fromNumber(R.double.O(w).toNumber())}},Q={name:"boolean",code:"AB",fd:new Kb(!0),je:new Kb(!1),fromString:function(w){switch(xb.trim(w)){case "true":case "1":return this.fd;
case "false":case "0":return this.je;default:g(w,"AB")}},fromBoolean:function(w){return w?this.fd:this.je},matches:function(w){return w instanceof Kb},O:function(w){switch(w.code.substring(0,2)){case "AS":case "AZ":return R.boolean.fromString(w.toString());case "AD":case "AF":case "AO":return w=w.toNumber(),R.boolean.fromBoolean(0!==w&&!isNaN(w));case "AB":return w;default:n(w,"AB")}}},ja={name:"integer",code:"ADI",mb:"decimal",matches:function(w){return/^ADI/.test(w.code)},fromString:function(w){if(/^\s*[-+]?[0-9]+\s*$/.test(w))return 9<
w.length?new Jb(w,"ADI"):new Ib(parseInt(w,10));g(w,"ADI")},fromNumber:function(w){isFinite(w)||g(w,"ADI",null,"FOCA0002");w=Math.trunc(w);return Math.abs(w)>Number.MAX_SAFE_INTEGER?new Jb(new kb(w),"ADI"):new Ib(w)},pb:function(w){return new Jb(w,"ADI")},valid:function(w){return 0===w.value.zf(1)},O:function(w){switch(w.code.substring(0,2)){case "AS":case "AZ":return R.integer.fromString(w.toString());case "AF":case "AO":return R.integer.fromNumber(w.toNumber());case "AD":return R.integer.pb(w.toBig().round(0,
0));case "AB":return R.integer.fromNumber(w.toBoolean()?1:0);default:n(w,"AB")}},cl:function(w){return"number"===typeof w?R.integer.fromNumber(w):"boolean"===typeof w?R.integer.fromNumber(w?1:0):"string"===typeof w?R.integer.fromString(w):R.integer.fromString(w.toString())}},D={name:"numeric",code:"Anumeric",vl:["double","decimal","float"],matches:function(w){return w instanceof Cb||w instanceof Ib||w instanceof Jb},O:function(w){if(R.string.matches(w)||R.untypedAtomic.matches(w))return R.double.fromString(w.toString());
if(R.numeric.matches(w))return w;if(R.boolean.matches(w))return R.double.fromNumber(w.toBoolean()?1:0);n(w,"Anumeric")},Fe:function(w){var Y=R.double;if(null===w)return Y.fromNumber(Number.NaN);if(R.boolean.matches(w))return Y.fromNumber(w.toBoolean()?1:0);if(R.numeric.matches(w))return Y.O(w);if(R.string.matches(w)||R.untypedAtomic.matches(w))try{return Y.fromString(w.toString())}catch(ia){return Y.fromNumber(Number.NaN)}else return Y.fromNumber(Number.NaN)}},C={name:"QName",code:"AQ",matches:function(w){return w instanceof
Lb},fromParts:function(w,Y,ia){return new Lb(w,Y,ia)},fromString:function(w,Y){w=xb.trim(w);if("Q{"===w.substring(0,2))return R.QName.fromEQName(w);xb.Eh(w)||g(w,"AQ");w=w.split(":");if(1===w.length)return R.QName.fromParts("",Y(""),w[0]);Y=Y(w[0]);if(null===Y)throw new J("Undeclared prefix: "+w[0],"FONS0004");return R.QName.fromParts(w[0],Y,w[1])},fromEQName:function(w){var Y=w.indexOf("{"),ia=w.indexOf("}");if(!(-1===Y&&-1===ia||-1<Y&&-1<ia))throw new J("Invalid EQName: "+w,"FORG0001");Y=mb(w.substring(Y+
1,ia));if("http://www.w3.org/2000/xmlns/"===Y)throw new J("Use of reserved namespace: "+Y,"XQST0070");if(/{/.test(Y))throw new J("Invalid URI: "+Y,"FORG0001");return R.QName.fromParts("",Y,w.substring(ia+1))},O:function(w,Y){if(R.string.matches(w)||R.untypedAtomic.matches(w)){if(Y)return R.QName.fromString(w.toString(),Y);throw new J("Casting to QName requires a namespace resolver","XPTY0117");}if(R.QName.matches(w))return w;n(w,"AQ")}},y={name:"notation",code:"AN",fromString:function(w){return this.O(w)},
matches:function(){return!1},O:function(){throw new J("Casting to xs:NOTATION is not possible","XPST0080");}},K={name:"dateTime",code:"AM",matches:function(w){return/^AM/.test(w.code)},fromString:function(w){return I(w,"AM")},fromDate:function(w,Y){return new Mb(w,void 0===Y?null:Y,"AM")},O:function(w){switch(w.code.substring(0,2)){case "AM":return w;case "AA":return R.dateTime.fromDate(w.Pa,w.Ga);case "AS":case "AZ":return R.dateTime.fromString(w.toString());default:n(w,"AM")}}},U={name:"date",code:"AA",
matches:function(w){return"AA"===w.code},fromString:function(w){return I(w,"AA")},fromDate:function(w,Y){w=(new Mb(w,Y,"AA")).toString();var ia="-"===w.charAt(0)?11:10;w=R.dateTime.fromString(w.substring(0,ia)+"T00:00:00"+w.substring(ia));return new Mb(w.Pa,Y,"AA")},O:function(w){switch(w.code.substring(0,2)){case "AM":return R.md.fromDate(w.Pa,w.Ga);case "AA":return w;case "AS":case "AZ":return R.md.fromString(w.toString());default:n(w,"AA")}}},O={name:"time",code:"AT",matches:function(w){return"AT"===
w.code},fromString:function(w){return I(w,"AT")},fromDate:function(w,Y){w=new Mb(w,Y,"AT");w=R.dateTime.fromString("1972-01-01T"+w.toString());return new Mb(w.Pa,Y,"AT")},O:function(w){switch(w.code.substring(0,2)){case "AM":return R.time.fromDate(w.Pa,w.Ga);case "AT":return w;case "AS":case "AZ":return R.time.fromString(w.toString());default:n(w,"AT")}}},Z={name:"dateTimeStamp",code:"AMP",matches:function(w){return"AMP"===w.code},fromString:function(w){return I(w,"AMP")},fromDate:function(w,Y){return new Mb(w,
Y,"AMP")},O:function(w){switch(w.code.substring(0,2)){case "AM":return"AMP"===w.code?w:R.Md.fromDate(w.Pa,w.Ga);case "AA":return R.Md.fromDate(w.Pa,w.Ga);case "AS":case "AZ":return R.Md.fromString(w.toString());default:n(w,"AM")}}},aa={name:"duration",code:"AR",matches:function(w){return/^AR/.test(w.code)},fromString:function(w){return c(w,"AR")},Vb:function(w,Y){return new Sb(w,Y,"AR")},O:function(w){if(R.duration.matches(w))return w;if(R.string.matches(w)||R.untypedAtomic.matches(w))return R.duration.fromString(w.toString());
n(w,"AR")}},ca={name:"hexBinary",code:"AX",matches:function(w){return"AX"===w.code},fromString:function(w){return new Gb(p(w),"AX")},fromUint8Array:function(w){return new Gb(w,"AX")},fromUtf8EncodedString:function(w){return new Gb(gb.fi(w),"AX")},O:function(w){if(w instanceof Gb)return new Gb(w.value,"AX");if(R.string.matches(w)||R.untypedAtomic.matches(w))return new Gb(p(w.toString()),"AX");n(w,"AX")}},oa={name:"base64Binary",code:"A2",matches:function(w){return"A2"===w.code},fromString:function(w){return new Gb(k(w),
"A2")},fromUint8Array:function(w){return new Gb(w,"A2")},O:function(w){if(w instanceof Gb)return new Gb(w.value,"A2");if(R.string.matches(w)||R.untypedAtomic.matches(w))return new Gb(k(w.toString()),"A2");n(w,"A2")},fromUtf8EncodedString:function(w){return new Gb(gb.fi(w),"A2")}},ea={anyAtomicType:t,untypedAtomic:r,string:A,anyURI:M,"double":X,decimal:ka,"float":qa,"boolean":Q,integer:ja,NOTATION:y,numeric:D,QName:C,dateTime:K,date:U,time:O,dateTimeStamp:Z,duration:aa,hexBinary:ca,base64Binary:oa},
na={},x={AM:/^\s*(-?(?:[1-9][0-9]{4,}|[0-9]{4})-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(?:\.[0-9]+)?)(Z|([+\-])[0-9]{2}:[0-9]{2})?\s*$/,AA:/^\s*(-?(?:[1-9][0-9]{4,}|[0-9]{4})-[0-9]{2}-[0-9]{2})(Z|([+\-])[0-9]{2}:[0-9]{2})?\s*$/,AT:/^\s*([0-9]{2}:[0-9]{2}:[0-9]{2}(?:\.[0-9]+)?)(Z|([+\-])[0-9]{2}:[0-9]{2})?\s*$/,AMP:/^\s*(-?[0-9]+-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(?:\.[0-9]+)?)(Z|([+\-])[0-9]{2}:[0-9]{2})\s*$/,AH:/^\s*(-?(?:[1-9][0-9]{4,}|[0-9]{4})-[0-9]{2})(Z|([+\-])[0-9]{2}:[0-9]{2})?\s*$/,
AG:/^\s*(-?[1-9][0-9]{4,}|-?[0-9]{4})(Z|([+\-])[0-9]{2}:[0-9]{2})?\s*$/,AJ:/^\s*--([0-9]{2}-[0-9]{2})(Z|([+\-])[0-9]{2}:[0-9]{2})?\s*$/,AI:/^\s*--([0-9]{2})(Z|([+\-])[0-9]{2}:[0-9]{2})?\s*$/,AK:/^\s*---([0-9]{2})(Z|([+\-])[0-9]{2}:[0-9]{2})?\s*$/,AR:/^\s*-?P(?:([0-9]+)Y)?(?:([0-9]+)M)?(?:([0-9]+)D)?(?:T(?:([0-9]+)H)?(?:([0-9]+)M)?(?:([0-9]+(?:\.[0-9]*)?)S)?)?\s*$/,ARD:/^\s*-?P(?:([0-9]+)D)?(?:T(?:([0-9]+)H)?(?:([0-9]+)M)?(?:([0-9]+(?:\.[0-9]*)?)S)?)?\s*$/,ARY:/^\s*-?P(?:([0-9]+)Y)?(?:([0-9]+)M)?\s*$/},
F={AM:function(w){return B(w)},AA:function(w){return w+"T00:00:00"},AT:function(w){return"1972-01-01T"+B(w)},AMP:function(w){return B(w)},AH:function(w){return w+"-01T00:00:00"},AG:function(w){return w+"-01-01T00:00:00"},AJ:function(w){return"1972-"+w+"T00:00:00"},AI:function(w){return"1972-"+w+"-01T00:00:00"},AK:function(w){return"1972-01-"+w+"T00:00:00"}},P=[function(w,Y){return b(u(w[1],12)+u(w[2],1),Y.match(/^\s*-.*/))},function(w,Y){return b(u(w[3],864E5)+u(w[4],36E5)+u(w[5],6E4)+h(w[6],1),Y.match(/^\s*-.*/))},
function(w,Y){return b(u(w[1],864E5)+u(w[2],36E5)+u(w[3],6E4)+h(w[4],1),Y.match(/^\s*-.*/))},function(){return 0}],V={AR:[0,1],ARD:[3,2],ARY:[0,3]},R={bg:t,anyURI:M,base64Binary:oa,boolean:Q,md:U,dateTime:K,Md:Z,dayTimeDuration:null,ub:ka,double:X,duration:aa,float:qa,sh:null,th:null,uh:null,vh:null,wh:null,hexBinary:ca,integer:ja,language:null,Kg:null,Ck:y,numeric:D,QName:C,string:A,time:O,untypedAtomic:r,yearMonthDuration:null};return{Yf:a,qa:function(w){return t.matches(w)},wj:q,Nj:z,vg:function(w,
Y,ia,T){if(w instanceof Jb||Y instanceof Jb)return ja.pb(T(w.toBig(),Y.toBig()));ia=ia(w.toNumber(),Y.toNumber());return Math.abs(ia)<Math.pow(2,52)?ja.fromNumber(ia):ja.pb(T(w.toBig(),Y.toBig()))},wd:l,Ag:function(w,Y){var ia=Ab.bb[Y];if(ia.matches(w))return w;if(R.ub.matches(w)?/^A[OFD]$/.test(Y):R.float.matches(w)&&/^A[OF]$/.test(Y))return ia.O(w);throw new J("Incompatible operands: "+l(w.code)+" ("+w.toString()+") and "+l(Y),"XPTY0004");},type:ea,bb:na,XS:R}}();
// Input 10
var Vb=function(){function a(q){return Math.abs(q)<Math.pow(2,52)}function b(q,f){return p.matches(q)&&p.matches(f)?p:k}function h(q){throw new J("Division by zero",void 0===q?"FOAR0001":q);}function u(q){throw new J("Numeric overflow/underflow",void 0===q?"FOAR0002":q);}function z(q,f,I){throw new J("Arithmetic operation "+I+" not available for operands "+(Ab.wd(q.code)+" and "+Ab.wd(f.code)),"XPTY0004");}function n(q,f,I){function d(X){return/^A[AMT]/.test(X.code)}var c=g.untypedAtomic,l=g.dayTimeDuration,
t=g.yearMonthDuration;if(c.matches(q))return q=g.double.fromString(q.value),n(q,f,I);if(c.matches(f))return f=g.double.fromString(f.value),n(q,f,I);if((l.matches(q)||t.matches(q))&&g.numeric.matches(f)){var r="u"+I+"n";var A=q.code;var M=f.code}else(l.matches(f)||t.matches(f))&&g.numeric.matches(q)?(r="n"+I+"u",A=q.code,M=f.code):p.matches(q)&&p.matches(f)?(r="i"+I+"i",A=M="ADI"):e.matches(q)||e.matches(f)?(r="d"+I+"d",A=M="AO"):v.matches(q)||v.matches(f)?(r="f"+I+"f",A=M="AF"):k.matches(q)||k.matches(f)?
(r="c"+I+"c",A=M="AD"):d(q)&&d(f)?(r="t"+I+"t",A=q.code,M=f.code):d(q)&&(l.matches(f)||t.matches(f))?(r="t"+I+"u",A=q.code,M=f.code):(l.matches(q)||t.matches(q))&&d(f)?(r="u"+I+"t",A=q.code,M=f.code):l.matches(q)&&l.matches(f)||t.matches(q)&&t.matches(f)?(r="u"+I+"u",A=M=q.code):z(I,q,f);B[r]||z(I,q,f);return B[r](Ab.Ag(q,A),Ab.Ag(f,M))}var g=Ab.XS,e=g.double,k=g.ub,p=g.integer,v=g.float,B={"d+d":function(q,f){q=q.toNumber();f=f.toNumber();return e.fromNumber(q+f)},"d-d":function(q,f){q=q.toNumber();
f=f.toNumber();return e.fromNumber(q-f)},"d*d":function(q,f){q=q.toNumber();f=f.toNumber();return e.fromNumber(q*f)},"d/d":function(q,f){q=q.toNumber();f=f.toNumber();return e.fromNumber(q/f)},"d%d":function(q,f){var I=q.toNumber();f=f.toNumber();return!isFinite(I)||isNaN(f)||0===f?e.fromNumber(NaN):isFinite(f)&&0!==I?e.fromNumber(I%f):q},"d~d":function(q,f){q=q.toNumber();f=f.toNumber();0===f&&h();(!isFinite(q)&&isFinite(f)||isNaN(q)||isNaN(f))&&u();f=Math.trunc(q/f);isFinite(f)||u();return a(f)?
p.fromNumber(f):e.fromNumber(f)},"f+f":function(q,f){q=q.toNumber();f=f.toNumber();return v.fromNumber(q+f)},"f-f":function(q,f){q=q.toNumber();f=f.toNumber();return v.fromNumber(q-f)},"f*f":function(q,f){q=q.toNumber();f=f.toNumber();return v.fromNumber(q*f)},"f/f":function(q,f){q=q.toNumber();f=f.toNumber();return v.fromNumber(q/f)},"f%f":function(q,f){var I=q.toNumber();f=f.toNumber();if(!isFinite(I)||isNaN(f)||0===f)return v.fromNumber(NaN);if(!isFinite(f)||0===I)return q;q=I%f;isFinite(q)||h();
return v.fromNumber(q)},"f~f":function(q,f){q=q.toNumber();f=f.toNumber();0===f&&h();(!isFinite(q)&&isFinite(f)||isNaN(q)||isNaN(f))&&u();f=Math.trunc(q/f);isFinite(f)||u();return a(f)?p.fromNumber(f):v.fromNumber(f)},"i+i":function(q,f){return Ab.vg(q,f,function(I,d){return I+d},function(I,d){return I.Je(d)})},"i-i":function(q,f){return Ab.vg(q,f,function(I,d){return I-d},function(I,d){return I.Ee(d)})},"i*i":function(q,f){return Ab.vg(q,f,function(I,d){return I*d},function(I,d){return I.Ic(d)})},
"i/i":function(q,f){f.equals(Ab.XS.integer.fromNumber(0))&&h();q=q.toBig();f=f.toBig();return k.pb(q.Ub(f))},"i%i":function(q,f){var I=q.toNumber(),d=f.toNumber();if(!isFinite(I)||isNaN(d)||0===d)return 0===d&&h(),p.fromNumber(NaN);if(!isFinite(d)||0===I)return q;I=q.toNumber()%f.toNumber();isFinite(I)||h();return a(I)?p.fromNumber(I):p.pb(kb(q.toBig().zf(f.toBig())))},"i~i":function(q,f){var I=q.toNumber()/f.toNumber();isFinite(I)||h();return a(I)?p.fromNumber(Math.trunc(I)):p.pb(kb(q.toBig().Ub(f.toBig())).round(0,
0))},"c+c":function(q,f){var I=q.toBig(),d=f.toBig();return b(q,f).pb(I.Je(d))},"c-c":function(q,f){var I=q.toBig(),d=f.toBig();return b(q,f).pb(I.Ee(d))},"c*c":function(q,f){var I=q.toBig(),d=f.toBig();return b(q,f).pb(I.Ic(d))},"c/c":function(q,f){try{var I=q.toBig(),d=f.toBig();return k.pb(I.Ub(d))}catch(c){h()}},"c%c":function(q,f){try{var I=q.toBig(),d=f.toBig();return k.pb(I.zf(d))}catch(c){h()}},"c~c":function(q,f){try{var I=q.toBig(),d=f.toBig(),c=I.Ub(d).round(0,0);return p.pb(c)}catch(l){h()}},
"a+a":function(q,f){return n(q,f,"+")},"a-a":function(q,f){return n(q,f,"-")},"a*a":function(q,f){return n(q,f,"*")},"a/a":function(q,f){return n(q,f,"/")},"a%a":function(q,f){return n(q,f,"%")},"a~a":function(q,f){return n(q,f,"~")},"u+u":function(q,f){var I=g.dayTimeDuration,d=g.yearMonthDuration;I.matches(q)&&I.matches(f)||d.matches(q)&&d.matches(f)||z(q,f,"+");return d.matches(q)?d.Vb(q.Wa+f.Wa,0):I.Vb(0,q.Ra+f.Ra)},"u-u":function(q,f){var I=g.dayTimeDuration,d=g.yearMonthDuration;I.matches(q)&&
I.matches(f)||d.matches(q)&&d.matches(f)||z(q,f,"-");return d.matches(q)?d.Vb(q.Wa-f.Wa,0):I.Vb(0,q.Ra-f.Ra)},"u*n":function(q,f){var I=g.dayTimeDuration,d=g.yearMonthDuration;I.matches(q)||d.matches(q)||z(q,f,"*");f=f.toNumber();if(!isFinite(f))throw new J("Cannot multiply duration by "+isNaN(f)?"NaN":"+/-INF",isNaN(f)?"FOCA0005":"FODT0002");return d.matches(q)?d.Vb(Math.round(q.Wa*f),0):I.Vb(0,Math.round(q.Ra*f))},"n*u":function(q,f){return Vb["u*n"](f,q)},"u/n":function(q,f){var I=g.dayTimeDuration,
d=g.yearMonthDuration;I.matches(q)||d.matches(q)||z(q,f,"/");f=f.toNumber();0===f&&h("FODT0002");if(isNaN(f))throw new J("Cannot divide duration by NaN","FOCA0005");f=d.matches(q)?q.Wa/f:q.Ra/f;isFinite(f)||u("FODT0002");return d.matches(q)?d.Vb(Math.round(f),0):I.Vb(0,Math.round(f))},"u/u":function(q,f){var I=g.dayTimeDuration,d=g.yearMonthDuration;I.matches(q)&&I.matches(f)||d.matches(q)&&d.matches(f)||z(q,f,"/");I=new kb(0);q=new kb(d.matches(q)?q.Wa:q.Ra);f=new kb(d.matches(f)?f.Wa:f.Ra);0===
f.cmp(I)&&h();try{return k.pb(q.Ub(f))}catch(c){u()}},"t+u":function(q,f){var I=g.dayTimeDuration,d=g.yearMonthDuration;I.matches(f)||d.matches(f)||z(q,f,"+");return I.matches(f)?Ab.bb[q.code].fromDate(new Date(q.Pa.getTime()+f.Ra),q.Ga):Rb(q,f.Wa)},"u+t":function(q,f){return Vb["t+u"](f,q)},"t-u":function(q,f){var I=g.dayTimeDuration,d=g.yearMonthDuration;I.matches(f)||d.matches(f)||z(q,f,"-");return I.matches(f)?Ab.bb[q.code].fromDate(new Date(q.Pa.getTime()-f.Ra),q.Ga):Rb(q,-f.Wa)},"t-t":function(q,
f){return g.dayTimeDuration.Vb(0,q.Pa.getTime()-f.Pa.getTime())}};return B}();
// Input 11
function Wb(a){function b(){function n(q){throw new J("In alphaCode '"+a+"' @ "+u+": "+q);}function g(q){var f=u>=a.length?-1:a.charAt(u++);f!==q&&n("Expected '"+q+"', found '"+f+"'")}function e(){for(var q=0,f=u;u<a.length;)switch(a.charAt(u++)){case "{":q++;break;case "}":q--;break;case "]":case ",":if(0===q)return a.substring(f,--u);break;case " ":if(0===q)return a.substring(f,u-1)}return a.substring(f,u)}"string"!==typeof a&&ib.la("No input to alphaParser");var k={};z.push(u);var p=a.charAt(u);
/[01+?*]/.test(p)?(u++,h.ae(k,"o",p)):h.ae(k,"o","1");if((p=e())&&!/^[ANFE]/.test(p))throw ib.la("Invalid alphaCode '"+a+"'");for(h.ae(k,"p",p);u<a.length;)switch(p=a.charAt(u),p){case "]":case ",":return p=a.substring(z.pop(),u),h.ae(k,"code",p),k;case " ":u++;break;case "n":case "c":u++;var v=e();/^~/.test(v)&&(v="Q{http://www.w3.org/2001/XMLSchema}"+v.substring(1));h.ae(k,p,v);break;case "k":case "r":case "v":case "e":u++;g("[");v=b();g("]");h.Wj(k,p,v);break;case "a":case "m":case "u":case "i":case "d":u++;
g("[");v=[];if("]"===a.charAt(u))u++,h.Wh(k,p,v);else for(;;){var B=b();v.push(B);if(","===a.charAt(u))u++;else{g("]");h.Wh(k,p,v);break}}break;case "t":n("Tuple types not yet implemented");break;default:n("Expected one of n|c|t|k|r|v|a|u, found '"+p+"'")}p=a.substring(z.pop(),u);h.ae(k,"code",p);z.pop();return k}var h=Xb,u=0,z=[];this.b=b}var Xb={tl:function(){return{}},ae:function(a,b,h){a[b]=h},Wj:function(a,b,h){a[b]=h},Wh:function(a,b,h){a[b]=h}};function Yb(a){return(new Wb(a)).b()}
function Zb(a){var b=(a.o||"1")+a.p;a.n&&(b+=" n"+a.n);a.c&&(b+=" c"+a.c);a.k&&(b+=" k["+Zb(a.k)+"]");a.v&&(b+=" v["+Zb(a.v)+"]");a.r&&(b+=" r["+Zb(a.r)+"]");if(a.a){b+=" a";for(var h=0;h<a.a.length;h++)b+=(0===h?"[":",")+Zb(a.a[h]);b+="]"}if(a.m){b+=" m";for(h=0;h<a.m.length;h++)b+=(0===h?"[":",")+Zb(a.m[h]);b+="]"}return b}
function $b(a,b){if(a.startsWith(b))return b;if(b.startsWith(a))return a;for(var h=Math.min(a.length,b.length);0<h;){if(a.substring(0,h)===b.substring(0,h))return a.substring(0,h);h--}return""};
// Input 12
var ac=Ab.XS;function bc(){}ba=bc.prototype;ba.filter=function(a){return new cc(this,a)};ba.next=function(){ib.la("next()");return null};ba.wf=function(a){for(var b=1,h;null!==(h=this.next());)if(b++===a)return h;return null};ba.mapOneToOne=function(a){return new fc(this,a)};ba.mapOneToMany=function(a){return new gc(this,a)};ba.forEachItem=function(a){for(var b;null!==(b=this.next());)a(b)};ba.some=function(a){for(var b;null!==(b=this.next());)if(a(b))return!0;return!1};
ba.every=function(a){for(var b;null!==(b=this.next());)if(!a(b))return!1;return!0};ba.count=function(){var a=0;this.forEachItem(function(){a++});return a};ba.expand=function(){var a=[];this.forEachItem(function(b){a.push(b)});return a};ba.showValue=function(){return"Iterator"};function hc(a){this.b=a}Ka(hc,bc);ba=hc.prototype;ba.next=function(){var a=this.b;this.b=null;return a};ba.wf=function(a){return 1===a?this.b:null};ba.wa=function(){return this.b};
ba.expand=function(){return null===this.b?[]:[this.b]};ba.showValue=function(){return"Singleton of "+ib.showValue(this.b)};function ic(){}Ka(ic,bc);ba=ic.prototype;ba.next=function(){return null};ba.wa=function(){return null};ba.expand=function(){return[]};ba.count=function(){return 0};ba.filter=function(){return this};ba.wf=function(){return null};ba.mapOneToOne=function(){return this};ba.mapOneToMany=function(){return this};ba.forEachItem=function(){};ba.some=function(){return!1};ba.every=function(){return!0};
ba.showValue=function(){return"EmptyIter"};function jc(a){Array.isArray(a)||ib.la("ForArray expects array. Supplied value: "+ib.showValue(a));this.b=a;this.g=0}Ka(jc,bc);ba=jc.prototype;ba.next=function(){return this.g<this.b.length?this.b[this.g++]:null};ba.wa=function(){return this.g<this.b.length?this.b[this.g]:null};ba.expand=function(){return this.b};ba.count=function(){return this.b.length};ba.wf=function(a){return this.b[a-1]};ba.showValue=function(){return"ForArray "+this.b};
function cc(a,b){this.b=a;(this.g=b)||ib.la("Filter iterator undefined predicate")}Ka(cc,bc);cc.prototype.next=function(){for(;;){var a=this.b.next();if(!a)return null;var b=this.g(a);if("done"===b)return null;if(b)return a}};function gc(a,b){this.l=a;this.g=b;this.b=null}Ka(gc,bc);
gc.prototype.next=function(){for(;;){if(null!==this.b){var a=this.b.next();if(a)return a}a=this.l.next();if(null===a)return null;(this.b=this.g(a))&&!this.b.next&&ib.la("Result of mapper is not an iterator, mapper = "+this.g)}};function fc(a,b){this.b=a;this.l=0;"object"===typeof a&&"next"in a||ib.la("Base not an iterator");this.g=b}Ka(fc,bc);fc.prototype.next=function(){for(;;){var a=this.b.next();if(null===a)return null;if(a=this.g(a,this.l++))return a}};function kc(a,b){this.b=a;this.g=b}
Ka(kc,bc);kc.prototype.next=function(){var a=this.b;return a?(this.b=this.g(a),a):null};kc.prototype.wa=function(){return this.b};function lc(a){Array.isArray(a)||ib.la("Join iterator expects array");this.b=a}Ka(lc,bc);lc.prototype.next=function(){for(;;){if(0===this.b.length)return null;var a=this.b[0].next();if(null===a)this.b.shift();else return a}};function mc(a,b,h){this.b=nc(a);this.g=nc(b);this.l=h;this.current=null}Ka(mc,bc);
mc.prototype.next=function(){var a=this.b.wa(),b=this.g.wa();return a&&b?(a=this.l(a,b),0>a?this.current=this.b.next():0<a?this.current=this.g.next():(this.current=this.b.next(),this.g.next()),this.current):a?this.current=this.b.next():b?this.current=this.g.next():this.current=null};function oc(a,b,h){this.b=nc(a);this.g=nc(b);this.l=h;this.current=null}Ka(oc,bc);
oc.prototype.next=function(){for(var a=this.b.wa(),b=this.g.wa();a&&b;){var h=this.l(a,b);if(0>h)this.b.next(),a=this.b.wa();else if(0<h)this.g.next(),b=this.g.wa();else return this.current=this.b.next(),this.g.next(),this.current}return this.current=null};function pc(a,b,h){this.b=nc(a);this.g=nc(b);this.l=h;this.current=null}Ka(pc,bc);
pc.prototype.next=function(){for(var a=this.b.wa(),b=this.g.wa();a&&b;){b=this.l(a,b);if(0>b)return this.current=this.b.next();0<b?this.g.next():(this.b.next(),this.g.next(),a=this.b.wa());b=this.g.wa()}return a&&!b?this.current=this.b.next():this.current=null};function qc(a){this.g=a;this.b=a.next()}Ka(qc,bc);function nc(a){return"peek"in a?a:new qc(a)}qc.prototype.next=function(){var a=this.b;a&&(this.b=this.g.next());return a};qc.prototype.wa=function(){return this.b};
qc.prototype.showValue=function(){return"LookAhead of "+this.g.showValue()};function rc(a){this.b=a;this.g=-1;this.position=0;this.current=null}Ka(rc,bc);rc.prototype.next=function(){this.position++;this.current=this.b.next();null===this.current&&(this.position=-1);return this.current};rc.prototype.last=function(){if(0>this.g)if(this.b instanceof sc)this.g=this.b.expand().length;else{for(var a=[],b;null!==(b=this.b.next());)a.push(b);this.g=this.position+a.length;this.b=sc(a)}return this.g};
rc.prototype.showValue=function(){return"Tracker of "+this.b.showValue()};function tc(a){this.current=a;this.position=1}Ka(tc,bc);tc.prototype.next=function(){return null};tc.prototype.last=function(){return 1};function uc(a){this.b=a}Ka(uc,bc);uc.prototype.next=function(){var a=this.b.next();return a.done?null:a.value};function vc(a,b,h){this.b=a;this.g=h;for(this.l=1;!(this.l++>=b);)if(null==this.b.next()){this.g=Number.NEGATIVE_INFINITY;break}}Ka(vc,bc);
vc.prototype.next=function(){return this.l++<=this.g?this.b.next():null};var wc=new ic;function xc(a){return new hc(a)}function zc(a){return new lc(a)}function Ac(a){return new tc(a)}function Bc(a){return new rc(a)}function sc(a){return new jc(a)}function Cc(a){return new hc(ac.string.fromString(a))}function Dc(a){return new hc(ac.integer.fromNumber(a))}function Ec(a){return new hc(ac.double.fromNumber(a))}function Fc(a){return new hc(ac.ub.pb(a))}
function Gc(a){return new hc(ac.boolean.fromBoolean(a))}var Hc={};Hc.hk=bc;Hc.nk=pc;Hc.pk=wc;Hc.qk=ic;Hc.sk=cc;Hc.tk=sc;Hc.uk=jc;Hc.vk=uc;Hc.yk=oc;Hc.zk=zc;Hc.Ak=lc;Hc.Bk=qc;Hc.Fk=gc;Hc.Gk=fc;Hc.Jk=xc;Hc.Kk=hc;Hc.Lk=Ac;Hc.Mk=tc;Hc.Nk=kc;Hc.Ok=vc;Hc.Rk=Bc;Hc.Tk=rc;Hc.Wk=mc;Hc.Al=Gc;Hc.Bl=Fc;Hc.Cl=Ec;Hc.Dl=function(a){return new hc(ac.float.fromNumber(a))};Hc.El=Dc;Hc.Fl=Cc;
// Input 13
// Input 14
var Ic={dd:"http://www.w3.org/1999/XSL/Transform",Ea:"http://www.w3.org/2005/xpath-functions",Ih:"http://www.w3.org/2005/xpath-functions/math",map:"http://www.w3.org/2005/xpath-functions/map",hd:"http://www.w3.org/2005/xpath-functions/array",xml:"http://www.w3.org/XML/1998/namespace",qc:"http://www.w3.org/2001/XMLSchema",Ql:"http://www.w3.org/2001/XMLSchema-instance",Mi:"http://www.w3.org/2005/xqt-errors",Vd:"http://saxonica.com/ns/interactiveXSLT",Hh:"http://saxonica.com/ns/globalJS",zb:"http://saxon.sf.net/",
Qi:"http://ns.saxonica.com/xslt/export",Jc:{xsl:"http://www.w3.org/1999/XSL/Transform",fn:"http://www.w3.org/2005/xpath-functions",math:"http://www.w3.org/2005/xpath-functions/math",map:"http://www.w3.org/2005/xpath-functions/map",array:"http://www.w3.org/2005/xpath-functions/array",xml:"http://www.w3.org/XML/1998/namespace",xs:"http://www.w3.org/2001/XMLSchema",xsi:"http://www.w3.org/2001/XMLSchema-instance",err:"http://www.w3.org/2005/xqt-errors",ixsl:"http://saxonica.com/ns/interactiveXSLT",js:"http://saxonica.com/ns/globalJS",
saxon:"http://saxon.sf.net/",ex:"http://ns.saxonica.com/xslt/export"},Ig:"http://ns.saxonica.com/anonymous-type"};
// Input 15
var Kc=function(){function a(n){return n?9===n.nodeType?n:a(n.ownerDocument):null}var b=Ab.XS,h=1,u={gd:function(n){switch(n.nodeType){case 9:case 11:return wc;case 2:return n.parent?new kc(n.parent,function(g){return g.parentNode}):wc;default:return(n=n.parentNode)?new kc(n,function(g){return g.parentNode}):wc}},$a:function(n){return zc([xc(n),u.gd(n)])},La:function(n){if(1===n.nodeType){for(var g=n.attributes,e=[],k=0;k<g.length;k++){var p=g[k].name;("xmlns"!==p.substring(0,5)||5!==p.length&&":"!==
p.charAt(5))&&e.push(new Jc(b.QName.fromParts(g[k].prefix,g[k].namespaceURI,g[k].localName),g[k].value,n))}return sc(e)}return wc},Ma:function(n){function g(e){return new kc(e.firstChild,function(k){return k.nextSibling})}if(null===n)return wc;switch(n.nodeType){case 9:return g(n).filter(function(e){return!(7===e.nodeType&&"xml"===e.target||3===e.nodeType)});case 11:case 1:return g(n);default:return wc}},mf:function(n){return u.Ma(n).mapOneToMany(function(g){return u.ve(g)})},ve:function(n){return zc([xc(n),
u.mf(n)])},oh:function(n){switch(n.nodeType){case 9:case 11:return wc;case 2:case 13:return n=Kc.Za(n),null===n?wc:zc([u.mf(n),u.oh(n)]);default:return u.$a(n).mapOneToMany(function(g){return u.ph(g).mapOneToMany(u.ve)})}},ph:function(n){switch(n.nodeType){case 9:case 11:case 2:case 13:return wc;default:return new kc(n.nextSibling,function(g){return g.nextSibling})}},wb:function(n){return 1===n.nodeType?sc(Kc.Uc(n).map(function(g){return new Lc(g.prefix,g.uri,n)})):wc},parent:function(n){return xc(Kc.Za(n))},
zg:function(n){return u.$a(n).mapOneToMany(function(g){return u.Zc(g).mapOneToMany(function(e){return sc(u.ve(e).expand().reverse())})})},Zc:function(n){switch(n.nodeType){case 9:case 11:case 2:case 13:return wc;default:return new kc(n.previousSibling,function(g){return g.previousSibling})}},self:function(n){return xc(n)}},z={Ka:u,Xg:{ancestor:u.gd,"ancestor-or-self":u.$a,attribute:u.La,child:u.Ma,descendant:u.mf,"descendant-or-self":u.ve,following:u.oh,"following-sibling":u.ph,namespace:u.wb,parent:u.parent,
preceding:u.zg,"preceding-sibling":u.Zc,self:u.self},Qc:a,zc:function(n){return u.$a(n).expand().pop()},Ec:function(n){n._saxonDocumentNumber||(n._saxonDocumentNumber=h++);return n},Hi:function(n){return Kc.Ec(n)._saxonDocumentNumber},da:function(n){if(!n||!n.nodeType)return null;switch(n.nodeType){case 1:return b.QName.fromParts(n.prefix,n.namespaceURI,n.localName);case 2:if("xml"===n.prefix)return b.QName.fromParts(n.prefix,Ic.xml,n.localName);if(!n.Hc)throw new J("ATTRIBUTE_NODE not local","SXJS0004");
return n.Hc;case 7:return b.QName.fromParts("","",n.target);case 13:return n.prefix?b.QName.fromParts("","",n.prefix):null;default:return null}},kl:function(n,g){if(!n||!n.nodeType)return!1;switch(n.nodeType){case 1:case 2:return n.localName===g;case 7:return n.target===g;case 13:return n.prefix===g;default:return!1}},sd:function(n,g){if(!n||!n.nodeType)return!1;switch(n.nodeType){case 1:return(n.namespaceURI||"")===g;case 2:return"xml"===n.prefix?Ic.xml===g:n.namespaceURI===g;default:return""===
g}},Nc:function(n,g){var e=[];for(n=n.firstChild;null!==n;)1!==n.nodeType||g&&g!==n.tagName||e.push(n),n=n.nextSibling;return e},firstElementChild:function(n,g){for(n=n.firstChild;null!==n;){if(1===n.nodeType&&(!g||g===n.tagName))return n;n=n.nextSibling}return null},Sd:function(n){var g=n.expand();if(1<g.length){var e=!0,k=!0,p=!0;g.forEach(function(v){Kc.X(v)?(p=!1,Kc.Za(v)?e=!1:v._saxonDocumentNumber?e=!1:Kc.Ec(v)):k=!1});if(!k&&!p)throw new J("Cannot sort a sequence including both nodes and non-nodes",
"XPTY0018");e||p||g.sort(Kc.sb);return sc(g).mapOneToOne(function(v,B){return 0<B&&Kc.isSameNode(v,g[B-1])?null:v})}return sc(g)},sb:function(n,g){if(Kc.isSameNode(n,g))return 0;var e=Kc.Za(n),k=Kc.Za(g);if(null===e)return null===k?(e=Kc.Ec,e(n)._saxonDocumentNumber-e(g)._saxonDocumentNumber):n===k?-1:Kc.sb(n,k);if(null===k)return g===e?1:Kc.sb(e,g);if(e===k){k=n.nodeType;var p=g.nodeType;if(2===k){if(2===p){e=e.attributes;for(k=0;k<e.length;k++){p=b.QName.fromParts(e[k].prefix,e[k].namespaceURI,
e[k].localName);if(p.equals(Kc.da(n)))return-1;if(p.equals(Kc.da(g)))return 1}throw new J("Att not found in parent","SXJS0004");}return 13===p?1:-1}if(13===k)return 13===p?n.prefix<g.prefix?-1:1:-1;if(2===p||13===p)return 1;e=n;for(k=g;;){e=e.previousSibling;if(!e)return-1;if(e===g)return 1;k=k.previousSibling;if(!k)return 1;if(k===n)return-1}}else{if(n===k)return-1;if(g===e)return 1;p=u.gd(e).count();var v=u.gd(k).count();return p===v?Kc.sb(e,k):p>v?Kc.sb(e,g):Kc.sb(n,k)}},Za:function(n){return Kc.ze(n)?
n.parent:n.parentNode},kf:function(n,g,e){n.setAttributeNS("http://www.w3.org/2000/xmlns/",""===g?"xmlns":"xmlns:"+g,e)},Uc:function(n,g){g=void 0===g?u.$a:g;var e=[{prefix:"xml",uri:Ic.xml}],k={xml:!0};for(n=g(n);null!==(g=n.next());)if(1===g.nodeType){var p=Kc.da(g);""===p.prefix&&""===p.uri&&(k[""]=!0);g=g.attributes;for(p=0;p<g.length;p++){var v=g[p].name;"xmlns"===v.substring(0,5)&&(5!==v.length||k[""]?":"===v.charAt(5)&&(v=v.substring(6),k[v]||(k[v]=!0,e.push({prefix:v,uri:g[p].value}))):(k[""]=
!0,""!==g[p].value&&e.push({prefix:"",uri:g[p].value})))}}else break;return e},gj:function(n){if(n.oi){a:{n=n.oi;for(g in n)if(n.hasOwnProperty(g)){var g=!1;break a}g=!0}return!g}g=n.attributes;for(n=0;n<g.length;n++){var e=g[n].name;if("xmlns"===e.substring(0,5)&&(5===e.length||":"===e.charAt(5)))return!0}return!1},Ah:function(n){for(n=n.firstChild;null!==n;){if(1===n.nodeType&&Kc.gj(n)||!Kc.Ah(n))return!1;n=n.nextSibling}return!0},ll:function(n){return(n=n.attributes.getNamedItem("xmlns"))&&""===
n.value},dj:function(n,g){for(;n&&1===n.nodeType;){if(n.hasAttribute(g))return n.getAttribute(g);n=n.parentNode}return null},ej:function(n,g,e){for(;n&&1===n.nodeType;){if(n.hasAttributeNS(g,e))return n.getAttributeNS(g,e);n=n.parentNode}return null},getAttribute:function(n,g,e){return n.hasAttribute(g)?n.getAttribute(g):2<arguments.length?e:null},hl:function(n,g){return parseInt(n.getAttribute(g),10)},Rj:function(n,g,e){var k=b.QName;if(/:/.test(g))return k.fromString(g,function(p){return"xml"===
p?Ic.xml:n.lookupNamespaceURI(p)});e=e?n.lookupNamespaceURI(null)||"":"";return k.fromParts("",e,g)},ze:function(n){return n instanceof Jc},Ud:function(n){return n instanceof Lc},Ae:function(n){return n instanceof abstractNode&&(9===n.nodeType||11===n.nodeType)},Ch:function(n){return n instanceof abstractNode&&1===n.nodeType},X:function(n){return n instanceof abstractNode&&!(7===n.nodeType&&"xml"===n.target)||Kc.ze(n)||Kc.Ud(n)},pl:function(n){return n instanceof abstractNode&&7===n.nodeType},isSameNode:function(n,
g){return n===g||Kc.Ud(n)&&Kc.Ud(g)&&n.parentNode&&g.parentNode&&n.parentNode===g.parentNode&&n.prefix===g.prefix||Kc.ze(n)&&Kc.ze(g)&&n.parent&&g.parent&&n.parent===g.parent&&Kc.da(n).equals(Kc.da(g))},uj:function(n,g){return n===g||9===n&&11===g||9===g&&11===n},ql:function(n){return n instanceof abstractNode&&3===n.nodeType},ud:function(n){return/^(?:[A-Za-z][A-Za-z0-9+.-]+:)?\/\//i.test(n)},baseURI:function(n){function g(k){var p=Kc.Za(k);return p?Kc.baseURI(p):k._saxonBaseUri?k._saxonBaseUri:
null}if(null===n||Kc.Ud(n))return null;if(1===n.nodeType&&n.hasAttribute("xml:base")){var e=n.getAttribute("xml:base");return Kc.ud(e)?e:g(n)?gb.Ob(e.toString(),g(n)).toString():null}return g(n)}};ib.Oj(z);return z}();
// Input 16
var Mc=function(){function a(k,p){if(""===p)return k.kb;var v=p.charAt(0);return k.Da.hasOwnProperty(v)?a(k.Da[v],p.substring(1)):[]}function b(k,p){for(var v=a(k,k.options.hash(p)),B=0;B<v.length;B++)if(k.options.ad(v[B].k,p))return v[B];return null}function h(k,p,v,B){if(""===B){for(B=0;B<k.kb.length;B++)if(k.options.ad(k.kb[B].k,p)){k.kb[B]={k:p,v:v};return}k.kb.push({k:p,v:v})}else{var q=B.charAt(0);if(k.Da.hasOwnProperty(q))var f=k.Da[q];else f=new n(k.options),f.id=g++,k.Da[q]=f;h(f,p,v,B.substring(1))}}
function u(k,p,v,B){var q=new n(k.options),f=!1;if(""===B)q.kb=k.kb.map(function(c){return k.options.ad(c.k,p)?(f=!0,{k:p,v:v}):c}),f||q.kb.push({k:p,v:v}),q.Da=k.Da;else{var I=B.charAt(0);for(d in k.Da)k.Da.hasOwnProperty(d)&&(d===I?(q.Da[d]=u(k.Da[d],p,v,B.substring(1)),f=!0):q.Da[d]=k.Da[d]);if(!f){var d=new n(k.options);d=u(d,p,v,B.substring(1));q.Da[I]=d}q.kb=k.kb}return q}function z(k,p,v){var B=new n(k.options);if(""===v)B.kb=k.kb.filter(function(I){return!k.options.ad(I.k,p)}),B.Da=k.Da;else{var q=
v.charAt(0),f;for(f in k.Da)k.Da.hasOwnProperty(f)&&(B.Da[f]=f===q?z(k.Da[f],p,v.substring(1)):k.Da[f]);B.kb=k.kb}return B}function n(k){this.kb=[];this.Da={};this.options=k||{hash:e.hash,ad:e.ad}}var g=0,e={hash:function(k){return k.hashCode()},ad:function(k,p){try{return k.isNumeric()?k.Bg(p):k.equals(p)&&(void 0!==k.Ga&&null!==k.Ga)===(void 0!==p.Ga&&null!==p.Ga)}catch(v){return!1}}};n.prototype={kb:[],Da:{},options:e,containsKey:function(k){return null!==b(this,k)},get:function(k){k=b(this,k);
return null===k?[]:k.v},evaluate:function(k){k=b(this,k[0][0]);return null===k?wc:sc(k.v)},inSituPut:function(k,p){h(this,k,p,this.options.hash(k))},put:function(k,p){return u(this,k,p,this.options.hash(k))},remove:function(k){return z(this,k,this.options.hash(k))},forAllPairs:function(k){this.kb.forEach(k);for(var p in this.Da)this.Da.hasOwnProperty(p)&&this.Da[p].forAllPairs(k)},keys:function(){var k=[];this.forAllPairs(function(p){k.push(p.k)});return k},conforms:function(k,p,v){var B=this;return sc(B.keys()).every(function(q){var f=
B.get(q);return k(q)&&v(f.length)&&sc(f).every(p)})},showValue:function(){var k="HashTrie map{",p="";this.forAllPairs(function(v){k+=p;p=", ";k+=ib.showValue(v.k);k+=": ";k+=ib.showValue(v.v)});return k+="}"}};return n}();
// Input 17
var Nc={aa:null,rc:null,Vf:null,Xe:null,cc:null,Hd:null,dc:null,Sg:null,Wf:null,Tg:null,Ng:null,gb:null,mi:null,Id:null,Pg:null,Uf:null,oe:null,Mg:null,Qg:null,Lg:null,Rg:null,me:null,ke:null,Ve:null,Ye:null,Xf:null,Rf:null,Te:null};function Oc(a,b){this.T=a;this.fa=b;this.g=this.b=null}ba=Oc.prototype;ba.V=function(){if(!this.g)if(this.b)this.g=Yb(this.b);else{var a=Object.assign(this.T.V()||{});a.o=Pc(this.fa);this.g=a}return this.g};
ba.ha=function(){this.b||(this.b=this.g?Zb(this.g):(Pc(this.fa)||"1")+this.T.ha());return this.b};function Qc(a,b){return new Oc(a.T,b)}function Sc(a,b){var h=a.fa;return new Oc(a.T,1===h||1===b?1:2===b?h:2===h?b:Tc(h)||Tc(b)?7:6===h&&6===b?6:7)}ba.matches=function(a){switch(a.length){case 0:var b=Tc(this.fa);break;case 1:b=1!==this.fa;break;default:b=Uc(this.fa)}if(!b)return!1;var h=this.T.ia();return a.every(function(u){return h(u)})};
ba.toString=function(){return this.T instanceof Vc&&1===this.fa?"empty-sequence()":this.T.toString()+Pc(this.fa)};ba.equals=function(a){return this.ha()===a.ha()};function Wc(a){if(/^[01?+*]/.test(a)){var b=a.charAt(0);var h=a.substring(1)}else b="1",h=a;h=Xc(h);b=new Oc(h,Yc(b));b.b=a;return b}function Zc(a){var b=$c(a);return new Oc(b,Yc(a.o))}function ad(a){ib.assert(a instanceof bd);return new Oc(a,2)}
function cd(){function a(h,u){return new Oc(h,u)}function b(h,u){return a(new dd(Ab.bb[h]),Yc(u))}ed=new fd;gd=new hd;id=new jd;kd=new ld;md=new nd;od=new pd;qd=new rd;sd=new Vc;td=new ud(9);vd=new ud(1);wd=new ud(2);xd=new ud(3);yd=new ud(8);zd=new ud(7);Ad=new ud(13);Bd=new dd(Ab.XS.double);Cd=new dd(Ab.XS.float);Dd=new dd(Ab.XS.string);Ed=new dd(Ab.XS.ub);Fd=new dd(Ab.XS.integer);Gd=new dd(Ab.XS.dayTimeDuration);Hd=new dd(Ab.XS.bg);Id.NMTOKENS=Ab.type.NMTOKEN;Id.IDREFS=Ab.type.IDREF;Id.ENTITIES=
Ab.type.ENTITY;Object.assign(Nc,{aa:a(Jd,7),rc:a(Jd,2),Vf:a(Jd,3),Xe:b("A","1"),cc:b("A","?"),Hd:b("A","*"),dc:b("AS","1"),Sg:b("AZ","1"),Wf:b("AZ","?"),Tg:b("AZ","*"),Ng:b("AS","?"),gb:b("AB","1"),mi:b("AB","?"),Id:b("ADI","1"),Pg:b("AD","1"),Uf:b("ADI","?"),oe:b("AO","1"),Mg:b("AO","?"),Qg:b("AF","1"),Lg:b("AU","?"),Rg:b("AQ","1"),me:a(gd,3),ke:a(gd,7),Ve:b("ADI","*"),Ye:a(md,2),Xf:a(od,2),Rf:a(md,7),Te:a(sd,1)})};
// Input 18
function Kd(a){Array.isArray(a)||ib.la("XdmArray value must be an array. Supplied:"+a);this.value=a}ba=Kd.prototype;ba.get=function(a){if("number"!=typeof a)throw new J("Array subscript is not numeric","XPTY0004");if(1>a||a>this.value.length)throw new J("Array index ("+a+") out of bounds (1 to "+this.value.length+")","FOAY0001");return this.value[a-1]};ba.evaluate=function(a){return sc(this.value[a[0][0]-1])};ba.size=function(){return this.value.length};
ba.showValue=function(){for(var a="[",b=0;b<this.value.length&&3>b;b++)0!==b&&(a+=","),a+=ib.showValue(this.value[b]);4<=this.value.length&&(a+=",...("+this.value.length+")");return a+"]"};ba.flatten=function(a){for(var b=0;b<this.value.length;b++)for(var h=this.value[b],u=0;u<h.length;u++)a(h[u])};ba.conforms=function(a,b,h){var u=Ab.XS.integer.fromNumber(this.value.length);if(!a(u))return!1;for(a=0;a<this.value.length;a++)if(u=this.value[a],!h(u.length)||!sc(u).every(b))return!1;return!0};
// Input 19
var Md=function(){function a(n,g,e){function k(q,f){function I(d){return d instanceof Lc?"a namespace":"an attribute"}if(f)throw new J("Cannot create "+I(q)+" node on element '"+u.da(n)+"' after creating children","XTDE0410");if(11===n.nodeType||9===n.nodeType)throw new J("Cannot add "+I(q)+" to a document node","XTDE0420");}function p(q,f){var I=f._saxonIsLocal?f._saxonIsLocal:!1;if(f instanceof Kd)f.value.forEach(function(t){t.forEach(function(r){p(q,r)})});else{if(f instanceof Mc)throw new J("A map can't be a child of an XML node",
"XSLT"===e.fixed.options.language?"XTDE0450":"XPTY0004");if(f instanceof Ld.XdmFunction)throw new J("A function can't be a child of an XML node","XSLT"===e.fixed.options.language?"XTDE0450":"XPTY0004");u.X(f)?B=!1:(f=u.Qc(e.ja).createTextNode((B?" ":"")+f.toString()),B=!0);if(1===q.nodeType||11===q.nodeType){if(10!==f.nodeType)if(u.Ud(f))if(gb.mc(q))q.appendChild(q.ownerDocument.importNode(f,!0));else if(k(f,v),""===f.prefix){if(""===q.namespaceURI)throw new J("Cannot add a default namespace to a no-namespace element",
"XTDE0440");if(q.hasAttributeNS("","xmlns")){if(q.getAttributeNS("","xmlns")!==f.uri)throw new J("Cannot have two different default namespaces","XTDE0430");}else gb.mc(q)||q.setAttributeNS("http://www.w3.org/2000/xmlns/","xmlns",f.uri)}else{I="xmlns:"+f.prefix;var d=f.uri;"~"===f.uri&&null!==Ic.Jc[f.prefix]&&(d=Ic.Jc[f.prefix]);if(q.hasAttributeNS("http://www.w3.org/2000/xmlns/",f.prefix)&&q.getAttributeNS("http://www.w3.org/2000/xmlns/",f.prefix)!==d)throw new J("Cannot have two namespaces with the same prefix '"+
f.prefix+"'","XTDE0430");q.setAttributeNS("http://www.w3.org/2000/xmlns/",I,d)}else if(2===f.nodeType){k(f,v);if(""!==f.namespaceURI&&(I=f.name.split(":"),2!==I.length||q.lookupNamespaceURI(I[0])!==f.namespaceURI))if(2===I.length&&null===q.lookupNamespaceURI(I[0]))q.setAttributeNS("http://www.w3.org/2000/xmlns/","xmlns:"+I[0],f.namespaceURI);else for(d=2===I.length?I[0]:"ns";;)if(null===q.lookupNamespaceURI(d)){q.setAttributeNS("http://www.w3.org/2000/xmlns/","xmlns:"+d,f.namespaceURI);f.name=d+":"+
I[I.length-1];break}else d+="_1";q.setAttributeNS(""===f.namespaceURI?null:f.namespaceURI,f.name,f.value)}else if(3===f.nodeType)f.nodeValue&&""!==f.nodeValue&&((I=q.lastChild)&&3===I.nodeType?I.nodeValue=I.nodeValue+f.nodeValue:q.appendChild(h(f,e,""))),v=!0;else if(9===f.nodeType||11===f.nodeType)z.Ma(f).forEachItem(function(t){p(q,t)});else{v=!0;if(!(gb.td()&&e.ja===window.document||1!==f.nodeType||gb.mc(f))){d=f.prefix||"";var c=f.lookupNamespaceURI(d)||"",l=f.namespaceURI||"";c!==l&&u.kf(f,d,
l);!f.lookupNamespaceURI(null)&&q.lookupNamespaceURI(null)&&u.kf(f,"","")}I&&gb.mc(q)===gb.mc(f)||(f=h(f,e,"c"));q.appendChild(f);f._saxonIsLocal=!1}}else if(9===q.nodeType)null!==f&&(q.documentElement=f);else throw new J("Unknown parent node type "+q.nodeType,"SXJS0003");}}e||ib.la("No context in makeComplexContent for "+ib.showValue(n));var v=!1,B=!1;g.forEachItem(function(q){p(n,q)})}function b(n,g){g&&0<=g.indexOf("l")&&(n._saxonIsLocal=!0);return n}function h(n,g,e){var k=e&&0<=e.indexOf("c");
if(u.X(n)){var p=function(q,f){e&&0<=e.indexOf("m")&&(f._saxonOriginal=q)};switch(n.nodeType){case 9:case 11:return k=u.Ec(g.ja.createDocumentFragment()),k._saxonBaseUri=n._saxonBaseUri,k._saxonDocUri=n._saxonDocUri,a(k,z.Ma(n).mapOneToOne(function(q){return h(q,g,e)}),g),p(n,k),b(k,e);case 13:return new Lc(n.prefix,n.uri);case 1:var v=u.da(n);var B=g.createElement(v.uri,v.toString());!k||gb.td()&&gb.mc(B)&&g.ja===window.document||u.Uc(n).forEach(function(q){u.kf(B,q.prefix,q.uri)});z.La(n).forEachItem(function(q){B.setAttributeNS(""===
q.namespaceURI?null:q.namespaceURI,q.name,q.value)});z.Ma(n).forEachItem(function(q){q=h(q,g,e);1===q.nodeType&&""===u.da(q).uri&&""!==v.uri&&u.kf(q,"","");B.appendChild(q)});B._saxonBaseUri=u.baseURI(n);p(n,B);return b(B,e);case 10:return null;case 2:return new Jc(u.da(n),n.value,null);default:return B=gb.td()?window.document.importNode(n,!0):n.cloneNode(g.ja,n,!0),p(n,B),b(B,e)}}else return n}var u=Kc,z=u.Ka;return{dl:h,Aj:a}}();
// Input 20
var Ld=function(){function a(k,p){k=g.call(this,k,"F"===k.alphaTree.p?k.alphaTree.a.length:1)||this;k.B=p;return k}function b(k,p,v){k=g.call(this,k,k.alphaTree.a.length)||this;k.B=p;k.J=v;return k}function h(k,p,v){k=g.call(this,k,k.alphaTree.a.length)||this;k.body=Nd.ea(p,"body");k.J=p.C;k.B=v;return k}function u(k,p){var v=g.call(this,k,k.alphaTree.a.length)||this;v.J=k.componentBinding;v.B=p.fixed.Na[v.J];v.body=Nd.ea(v.B.actor,"body");v.name=Ab.XS.QName.fromEQName(v.B.actor.name);return v}function z(k,
p,v){var B=g.call(this,k,1)||this;B.name=p;if(k=e[p.local]){var q=Ab.type[k].fromString;ib.assert(q);B.evaluate=function(d){d=B.b(d,v)[0][0].toString();d=mb(d).split(" ");return sc(d.map(q))}}else if("QName"===p.local){var f=Nd.be(v,!0);B.evaluate=function(d){d=B.b(d,v)[0][0].toString();return xc(Ab.XS.QName.fromString(d,f))}}else{var I=Ab.type[p.local].O;ib.assert(I);B.evaluate=function(d){return xc(I(B.b(d,v)[0][0]))}}return B}function n(k,p,v,B,q){var f=g.call(this,k,v)||this;f.name=p;f.ka=[Ic.Ih,
Ic.map,Ic.hd,Ic.zb].includes(p.uri)?Nd.Oi:Nd.Ni;f.J=B;if(k.defaultToDot&&0===v||["accumulator-after","accumulator-before","lang"].includes(p.local))f.B=Od(q);return f}function g(k,p){this.Ab=k;this.l=k.alphaTree;this.arity=p;this.resultType=this.l.r?Zc(this.l.r):Wc("*");this.g=[];if(k.variadic){k=Zc(this.l.a[0]);for(var v=0;v<p;v++)this.g.push(k)}else for(k=0;k<p;k++)this.g[k]=Zc(this.l.a[k])}g.prototype.aj=function(){return this.arity};g.prototype.kc=function(){return null};g.prototype.evaluate=
function(){ib.la("Not implemented");return wc};g.prototype.Ac=function(){return this.kc()?this.kc().$()+"#"+this.arity:"anonymous function (arity "+this.arity+")"};g.prototype.showValue=function(){return this.Ac()};g.prototype.b=function(k,p){var v=this;p=p&&"apply"===p.name;if(k.length!==this.arity)throw new J("Too "+(k.length<this.arity?"few":"many")+" arguments in call to "+this.Ac()+": required "+this.arity+", supplied "+k.length,p?"FOAP0001":"XPTY0004");p=[];for(var B=0;B<k.length;B++)p[B]=Nd.gg(k[B],
this.g[B],function(){return"dynamic call of "+v.Ac()});return p};g.prototype.convertResult=function(k){var p=this;return sc(Nd.gg(k,this.resultType,function(){return"result of dynamic call of "+p.Ac()}))};g.prototype.sg=function(){return new Pd(this.g,this.resultType)};Ka(n,g);n.prototype.kc=function(){return this.name};n.prototype.evaluate=function(k,p,v){var B=this.kc().local;if("current"===B)throw new J("current() function cannot be evaluated dynamically","XTDE1360");if("current-output-uri"===
B)return wc;if("regex-group"===B)return Cc("");var q=p;this.B&&(q=p.ca(),q.focus=Ac(this.B));p=["string-length","normalize-space"].includes(B);var f=this.Ab.defaultToDot;B="number"===B;if((p||f||B)&&0===k.length){Qd(q,"XPDY0002",". (dot)");v=Od(q);if(p||B)v=Nd.hc(v).next();p&&(v=Ab.XS.string.O(v));k.push([v])}else k=this.b(k,v);return this.ka(this.kc(),k,q,this.J)};var e={NMTOKENS:"NMTOKEN",IDREFS:"IDREF",ENTITIES:"ENTITY"};Ka(z,g);z.prototype.kc=function(){return this.name};Ka(u,g);u.prototype.kc=
function(){return this.name};u.prototype.evaluate=function(k,p,v){k=this.b(k,v);p=p.ca(!0);Rd(p);p.sa="function";p.ib={};p.cb=null;p.focus=null;p.ua=this.B;for(v=0;v<k.length;v++)p.pa[v]=k[v];return Nd.evaluate(this.body,p)};Ka(h,g);h.prototype.evaluate=function(k,p,v){k=this.b(k,v);p=p.ca(!0);Rd(p);p.sa="function";p.ib={};p.cb=null;p.focus=null;p.pa=this.B.pa.slice();p.ua=this.B.ua;v=this.J;for(var B=0;B<k.length;B++)p.pa[parseInt(v[B].slot,10)]=k[B];return Nd.evaluate(this.body,p)};Ka(b,g);b.prototype.Ac=
function(){return"partially-applied "+this.B.Ac()};b.prototype.evaluate=function(k,p,v){for(var B=[],q=0;q<this.B.arity;q++)if(B[q]=this.J[q]||k.shift(),!B[q])throw new J("Too few arguments in call of "+this.Ac(),"XPTY0004");return this.B.evaluate(B,p,v)};Ka(a,g);a.prototype.Ac=function(){return"coerced "+this.B.Ac()};a.prototype.evaluate=function(k,p,v){k=this.b(k,v);return this.convertResult(this.B.evaluate(k,p,v).expand())};return{XdmFunction:g,Se:n,Qf:z,Tf:u,li:h,ki:b,Jg:a}}();
// Input 21
function bd(){this.b=this.l=null}ba=bd.prototype;ba.ia=function(){return function(){return!0}};function Sd(a,b){a.b=b}function Td(a,b){a.l=b.replace(/^[01?*+]/,"")}ba.V=function(){if(!this.b)if(this.l)this.b=Yb(this.l);else return null;return this.b};ba.ha=function(){if(!this.l)if(this.b)Td(this,Zb(this.b));else return null;return this.l};function Ud(a){return/^N/.test(a.V().p)}ba.qa=function(a){a=void 0===a?"A":a;return this.V().p.startsWith(a)};function Vd(a){return a instanceof ld}
ba.isNumeric=function(){return Vd(this)||/^A[ODF]/.test(this.V().p.substring(0))};ba.Td=function(){return/^F/.test(this.V().p)};ba.oj=function(){return!0};ba.yc=function(){return null};ba.qd=function(){return null};ba.sf=function(){return null};ba.toString=function(){return this.ha()};ba.equals=function(a){return this.ha()===a.ha()};function fd(){bd.call(this);Td(this,"");this.b={p:""}}var ed;Ka(fd,bd);fd.prototype.ia=function(){return function(){return!0}};fd.prototype.toString=function(){return"item()"};
var Jd=new fd;function hd(){bd.call(this);Td(this,"N");this.b={p:"N"}}var gd;Ka(hd,bd);hd.prototype.ia=function(){return function(a){return Kc.X(a)}};hd.prototype.toString=function(){return"node()"};function jd(){bd.call(this);Td(this,"N u[NT,NP,NC,NE]");this.b={p:"N",u:[{p:"NT"},{p:"NP"},{p:"NC"},{p:"NE"}]}}var id;Ka(jd,bd);jd.prototype.ia=function(){return function(a){return Kc.X(a)&&[1,3,8,7].includes(a.nodeType)}};jd.prototype.toString=function(){return"node()"};
function ud(a){bd.call(this);this.kind=a;a=Wd(a);Td(this,a);this.b={p:a}}var td,vd,wd,yd,xd,zd,Ad;Ka(ud,bd);function Wd(a){switch(a){case 9:return"ND";case 1:return"NE";case 2:return"NA";case 8:return"NC";case 3:return"NT";case 7:return"NP";case 13:return"NN";case 0:return"N";default:throw new J("Unknown node kind "+a+" in NodeKindTest");}}
ud.prototype.ia=function(){var a=this;switch(this.kind){case 9:return function(b){return Kc.X(b)&&(9===b.nodeType||11===b.nodeType)};default:return function(b){return Kc.X(b)&&b.nodeType===a.kind}}};
ud.prototype.toString=function(){switch(this.kind){case 9:return"document-node()";case 1:return"element(*)";case 2:return"attribute(*)";case 8:return"comment()";case 3:return"text()";case 7:return"processing-instruction()";case 13:return"namespace-node()";case 0:return"node()";default:throw new J("Unknown node kind "+this.kind+" in NodeKindTest");}};
function Xd(a){switch(a){case 9:return td;case 1:return vd;case 2:return wd;case 8:return yd;case 3:return xd;case 7:return zd;case 13:return Ad;default:throw new J("Unknown node kind "+a);}}function Yd(a,b){ud.call(this,a);this.name=b;a=Wd(a);Td(this,a+" n"+b.$());this.b={p:a,n:b.$()}}Ka(Yd,ud);Yd.prototype.ia=function(){var a=this,b=this.name;return function(h){var u;if(u=Kc.X(h)&&h.nodeType===a.kind)u=(h=Kc.da(h))&&b.equals(h);return u}};
Yd.prototype.toString=function(){return ud.prototype.toString.call(this).replace(/\(\*?\)/,"("+this.V().n+")")};function Zd(a,b){ud.call(this,a);this.localName=b;a=Wd(a);Td(this,a+" n*:"+b);this.b={p:a,n:"*:"+b}}Ka(Zd,ud);Zd.prototype.ia=function(){var a=this;return function(b){return Kc.X(b)&&b.nodeType===a.kind&&Kc.da(b).local===a.localName}};Zd.prototype.toString=function(){return ud.prototype.toString.call(this).replace(/\(\*?\)/,"(*:"+this.localName+")")};
function $d(a,b){ud.call(this,a);this.uri=b;a=Wd(a);Td(this,a+" nQ{"+b+"}*");this.b={p:a,n:"Q{"+b+"}*"}}Ka($d,ud);$d.prototype.ia=function(){var a=this;return function(b){return Kc.X(b)&&b.nodeType===a.kind&&Kc.da(b).uri===a.uri}};$d.prototype.toString=function(){return ud.prototype.toString.call(this).replace(/\(\*?\)/,"Q{"+this.uri+"}*)")};function ae(a){ud.call(this,9);this.g=a;Td(this,"ND e["+a.ha()+"]");this.b={p:"ND",e:a.V()}}Ka(ae,ud);
ae.prototype.ia=function(){var a=this;return function(b){return Kc.X(b)&&(9===b.nodeType||11===b.nodeType)&&a.g.ia()(b.firstChild)}};ae.prototype.toString=function(){return"document-node("+this.g.toString()+")"};function dd(a){bd.call(this);this.cd=a;Td(this,a.code);this.b={p:a.code}}Ka(dd,bd);dd.prototype.ia=function(){var a=this;return function(b){return a.cd.matches(b)}};dd.prototype.toString=function(){return"xs:"+this.cd.name};
function ld(){bd.call(this);Td(this,"A m[AO,AD,AF]");this.b={p:"A",m:[{p:"AO"},{p:"AD"},{p:"AF"}]}}var kd;Ka(ld,bd);ld.prototype.ia=function(){return function(a){return/^A[ODF]/.test(a.code)}};ld.prototype.toString=function(){return"xs:numeric"};function Vc(){bd.call(this)}var sd;Ka(Vc,bd);Vc.prototype.ha=function(){return"E"};Vc.prototype.V=function(){return{p:"E"}};Vc.prototype.ia=function(){return function(){return!1}};Vc.prototype.toString=function(){return"xs:error"};
function be(){bd.call(this);this.g=[];this.resultType=null}Ka(be,bd);
be.prototype.Nb=function(a){if(!(a instanceof be))return ce.bc;if(a instanceof pd||a instanceof rd)return ce.Rb;if(!(a instanceof Pd||a instanceof de||a instanceof ee))return ce.sc;if(this.g.length!==a.g.length)return ce.bc;var b=function(u,z,n){return u.map(function(g,e){return n(g,z[e])})}(this.g,a.g,ce.Vh),h=ce.Vh(this.resultType,a.resultType);return b.every(function(u){return u===ce.Bb})&&h===ce.Bb?ce.Bb:b.every(function(u){return[ce.Bb,ce.sc].includes(u)&&[ce.Bb,ce.Rb].includes(h)})?ce.Rb:b.every(function(u){return[ce.Bb,
ce.Rb].includes(u)&&[ce.Bb,ce.sc].includes(h)})?ce.sc:ce.Og};be.prototype.rf=function(){return Nc.aa};function nd(){be.call(this);Td(this,"F");this.b={p:"F"}}var md;Ka(nd,be);nd.prototype.ia=function(){return function(a){return a instanceof Ld.XdmFunction||a instanceof Kd||a instanceof Mc||a instanceof Nd.Sf}};nd.prototype.Nb=function(a){return a instanceof nd?ce.Bb:/^F/.test(a.V().p)?ce.Rb:ce.bc};nd.prototype.toString=function(){return"function(*)"};
function Pd(a,b){be.call(this);ib.assert(b instanceof Oc);ib.assert(a.every(function(h){return h instanceof Oc}));this.g=a;this.resultType=b;Td(this,"F a["+a.map(function(h){return h.ha()}).join(",")+"] r["+b.ha()+"]");Sd(this,{p:"F",a:a.map(function(h){return h.V()}),r:b.V()})}Ka(Pd,be);
Pd.prototype.ia=function(){var a=this;return function(b){return b instanceof Kd?1===a.g.length&&2===a.g[0].fa&&b.conforms(a.g[0].T.ia(),a.resultType.T.ia(),fe(a.resultType.fa)):b instanceof Mc?1===a.g.length&&2===a.g[0].fa&&a.g[0].T instanceof dd&&b.conforms(function(){return!0},a.resultType.T.ia(),fe(a.resultType.fa)):b instanceof Ld.XdmFunction?(b=a.Nb(b.sg()),[ce.Bb,ce.Rb].includes(b)):!1}};function ge(a,b){var h=[];b.forEach(function(u){h.push(a.g[u])});return new Pd(h,a.resultType)}
Pd.prototype.rf=function(){return this.resultType};Pd.prototype.toString=function(){return"(function("+this.g.map(function(a){return a.toString()}).join(",")+") as "+this.resultType.toString()+")"};function pd(){be.call(this);Td(this,"FM");this.b={p:"FM"}}var od;Ka(pd,be);pd.prototype.qd=function(){return Hd};pd.prototype.sf=function(){return Nc.aa};pd.prototype.ia=function(){return function(a){return a instanceof Mc||a instanceof Nd.Sf}};pd.prototype.toString=function(){return"map(*)"};
function de(a,b){be.call(this);ib.assert(a instanceof bd);ib.assert(b instanceof Oc);this.B=a;this.valueType=b;Td(this,"FM k["+a.ha()+"] v["+b.ha()+"]");this.b={p:"FM",k:a.V(),v:b.V()}}Ka(de,be);ba=de.prototype;ba.ia=function(){var a=this;return function(b){return b instanceof Mc&&b.conforms(a.B.ia(),a.valueType.T.ia(),fe(a.valueType.fa))}};ba.toString=function(){return"map("+this.B.toString()+","+this.valueType.toString()+")"};ba.qd=function(){return this.B};ba.sf=function(){return this.valueType};
ba.rf=function(){return this.valueType};function rd(){be.call(this);Td(this,"FA");this.b={p:"FA"}}var qd;Ka(rd,be);rd.prototype.ia=function(){return function(a){return a instanceof Kd}};rd.prototype.toString=function(){return"array(*)"};rd.prototype.yc=function(){return Nc.aa};function ee(a){be.call(this);ib.assert(a instanceof Oc);this.B=a;Td(this,"FA v["+a.ha()+"]");this.b={p:"FA",v:a.V()}}Ka(ee,be);ee.prototype.ia=function(){var a=this;return function(b){return b instanceof Kd&&b.value.every(function(h){return a.B.matches(h)})}};
ee.prototype.toString=function(){return"array("+this.B.toString()+")"};ee.prototype.yc=function(){return this.B};ee.prototype.rf=function(){return this.B};function he(a,b){bd.call(this);this.g=a;this.B=b;var h=a.V(),u=b.V();h=$b(h.p,u.p);Td(this,h+" u["+a.ha()+","+b.ha()+"]");this.b={p:h,u:[a.V(),b.V()]}}Ka(he,bd);he.prototype.ia=function(){var a=this.g.ia(),b=this.B.ia();return function(h){return a(h)||b(h)}};he.prototype.qd=function(){var a=this.g.qd(),b=this.B.qd();return new he(a,b)};
he.prototype.yc=function(){var a=this.g.yc(),b=this.B.yc(),h=a.fa;return h===b.fa?new Oc(new he(a.T,b.T),h):Nc.aa};function ie(a,b){bd.call(this);this.g=a;this.B=b;var h=a.V(),u=b.V();h=h.p.startsWith(u.p)?h.p:u.p.startsWith(h.p)?u.p:h.p.charAt(0)===u.p.charAt(0)?h.p.charAt(0):"";Td(this,h+" i["+a.ha()+","+b.ha()+"]");this.b={p:h,i:[a.V(),b.V()]}}Ka(ie,bd);ie.prototype.ia=function(){var a=this.g.ia(),b=this.B.ia();return function(h){return a(h)&&b(h)}};
function je(a,b){bd.call(this);this.g=a;this.B=b;var h=a.V(),u=b.V();h=h.p.startsWith(u.p)?h.p:u.p.startsWith(h.p)?u.p:h.p.charAt(0)===u.p.charAt(0)?h.p.charAt(0):"";Td(this,h+" d["+a.ha()+","+b.ha()+"]");this.b={p:h,d:[a.V(),b.V()]}}Ka(je,bd);je.prototype.ia=function(){var a=this.g.ia(),b=this.B.ia();return function(h){return a(h)&&!b(h)}};var ke={};function Xc(a){var b=ke[a];if(b)return b;b=Yb(a);b=$c(b);return ke[a]=b}
function $c(a){var b=a.p;if(""===b)return ed;if(a.u)return b=$c(a.u[0]),a.u.slice(1).reduce(function(h,u){return new he(h,$c(u))},b);if(a.i)return b=$c(a.i[0]),a.i.slice(1).reduce(function(h,u){return new ie(h,$c(u))},b);if(a.d)return new je($c(a.d[0]),$c(a.d[1]));switch(b.charAt(0)){case "A":if("Q{http://www.w3.org/2001/XMLSchema}numeric"===a.n)return kd;if(a.m){a=a.m;if(3===a.length&&a.some(function(h){return"AO"===h.p})&&a.some(function(h){return"AD"===h.p})&&a.some(function(h){return"AF"===h.p}))return kd;
ib.la("Unhandled atomic union type")}else return new dd(Ab.bb[b]);break;case "F":switch(b){case "FA":return a.v?new ee(Zc(a.v)):qd;case "FM":return a.k&&a.v?new de($c(a.k),Zc(a.v)):od;default:return a.a?(b=a.a.map(Zc),a=a.r?Zc(a.r):Nc.aa,new Pd(b,a)):md}case "N":if("N"===b)return gd;b={ND:9,NE:1,NA:2,NT:3,NC:8,NP:7,NN:13}[b];return a.n?(a=a.n,/\}\*$/.test(a)?new $d(b,a.substring(2,a.length-2)):/^\*:/.test(a)?new Zd(b,a.substring(2)):new Yd(b,Ab.XS.QName.fromEQName(a))):a.e?(a=$c(a.e),new ae(a)):new ud(b);
case "E":return sd}}var le={IDREFS:"ASNTNCR",ENTITIES:"ASNTNCE",NMTOKENS:"ASNTK"},Id={},Hd,Bd,Cd,Dd,Ed,Fd,Gd;
// Input 22
var ce=function(){function a(e,k){ib.assert(e instanceof bd);ib.assert(k instanceof bd);var p=e.ha()+"<>"+k.ha(),v=g[p];if(v)return v;e=z(e,k);return g[p]=e}function b(e,k){ib.assert(e instanceof bd);ib.assert(k instanceof bd);switch(a(e,k)){case 0:case 1:return e;case 2:return k;case 3:case 4:return e=e.V(),k=k.V(),$c({p:$b(e.p,k.p),u:[e,k]})}}function h(e,k){ib.assert(e instanceof bd);ib.assert(k instanceof bd);switch(a(e,k)){case 0:case 1:return k;case 2:return e;case 3:return e=e.V(),k=k.V(),
$c({p:e.p.startsWith(k.p)?e.p:k.p.startsWith(e.p)?k.p:e.p.charAt(0)===k.p.charAt(0)?e.p.charAt(0):"",i:[e,k]});case 4:return sd}}function u(e,k){return 0===e&&0===k?0:0!==e&&1!==e||0!==k&&1!==k?0!==e&&2!==e||0!==k&&2!==k?4===e||4===k?4:3:2:1}function z(e,k){function p(c,l){return(c||"")===(l||"")?0:c&&!l?2:l&&!c?1:4}function v(c){return 1===c?2:2===c?1:c}function B(c){return c.n||c.e||c.u||c.i||c.d}var q=e.V(),f=k.V(),I=e.ha(),d=k.ha();if("F"===q.p){if(!(e instanceof be&&k instanceof be))return 3;
ib.assert(e.Nb);return e.Nb(k)}if(I===d)return 0;if(e instanceof Vc)return 2;if(k instanceof Vc)return 1;if(Vd(e))return Vd(k)?0:/^A[ODF]/.test(f.p)?1:"A"===f.p||""===f.p?2:4;if(Vd(k))return v(z(k,e));if(q.p===f.p){if(Ud(e))return q.u&&f.u?3:q.e&&f.e?p(q.e,f.e):q.n&&f.n?p(q.n,f.n):B(q)&&B(f)?4:B(q)&&!B(f)?2:B(f)&&!B(q)?1:0;if(e.qa())return 0;if("FA"===q.p)return e=e.yc?e.yc():Nc.aa,k=k.yc?k.yc():Nc.aa,n(e,k);if("FM"===q.p)return q=a(e.qd(),k.qd()),k=n(e.sf(),k.sf()),u(q,k);if(!(e instanceof be&&k instanceof
be))return 3;ib.assert(e.Nb);return e.Nb(k)}return q.p.startsWith(f.p)?q.u?3:2:f.p.startsWith(q.p)?q.u?3:1:4}function n(e,k){ib.assert(e instanceof Oc);ib.assert(k instanceof Oc);var p=e.fa;var v=k.fa;p=p===v?ce.Bb:(p|v)===p?ce.Rb:(p|v)===v?ce.sc:0===(p|v)?ce.bc:ce.Og;e=a(e.T,k.T);return u(p,e)}var g={};return{Bb:0,Rb:1,sc:2,Og:3,bc:4,Nb:a,vj:function(e,k){ib.assert(e instanceof bd);ib.assert(k instanceof bd);e=a(e,k);return 0===e||2===e},mk:b,Xd:function(e){ib.assert(0<e.length);return e.slice(1).reduce(function(k,
p){return b(k,p)},e[0])},hj:function(e){ib.assert(0<e.length);return e.slice(1).reduce(function(k,p){return h(k,p)},e[0])},Vh:n}}();
// Input 23
function Uc(a){return 0!==(a&4)}function Tc(a){return 0!==(a&1)}function me(a){var b=0;a.forEach(function(h){return b|=h});5===b&&(b=7);return b}function Yc(a){return{0:1,1:2,"?":3,"+":6,"*":7}[a]}function ne(a,b){return(Tc(a)&&Tc(b)?1:0)+(1!==a||1!==b?2:0)+(1===a&&!Uc(b)||1===b&&!Uc(a)?0:4)}
function oe(a){switch(a){case "1":return function(b){return 1===b};case "0":return function(b){return 0===b};case "?":return function(b){return 2>b};case "+":return function(b){return 0<b};case "*":return function(){return!0}}}function fe(a){switch(a){case 1:return function(b){return 0===b};case 2:return function(b){return 1===b};case 4:return function(b){return 1<b};case 3:return function(b){return 2>b};case 6:return function(b){return 0<b};default:return function(){return!0}}}
function Pc(a){switch(a){case 3:return"?";case 2:return"";case 7:return"*";case 6:return"+";case 4:return"+";case 1:return"0";default:return"*"}};
// Input 24
function pe(a,b,h){a=a.expand();b=b.expand();a=sc(a);b=sc(b);for(var u,z;;){u=a.next();z=b.next();if(null===u)return null===z;if(null===z||!qe(u,z,h))return!1}}
function qe(a,b,h){var u=Kc.Ka;if(a===b)return!0;if(Kc.X(a)){if(!Kc.X(b)||!Kc.uj(a.nodeType,b.nodeType))return!1;switch(a.nodeType){case 9:case 11:return pe(u.Ma(a),u.Ma(b));case 1:if(!Kc.da(a).equals(Kc.da(b)))return!1;var z=u.La(a).expand();if(z.length!==u.La(b).count())return!1;for(var n=0;n<z.length;n++){var g=z[n],e=Kc.da(g),k=e.uri?e.uri:null;if(!b.hasAttributeNS(k,e.local)||b.getAttributeNS(k,e.local)!==g.value)return!1}return pe(u.Ma(a),u.Ma(b),h);case 2:return Kc.da(a).equals(Kc.da(b))&&
a.value===b.value;case 13:return a.prefix===b.prefix&&a.uri===b.uri;case 7:return a.target===b.target&&(h?h(a.textContent,b.textContent):a.textContent===b.textContent);case 3:case 4:case 8:return h?h(a.textContent,b.textContent):a.textContent===b.textContent;default:throw new J("Unknown node type "+a.nodeType,"SXJS0003");}}else{if(a instanceof Kd){if(!(b instanceof Kd&&b.value.length===a.value.length))return!1;for(u=0;u<a.value.length;u++)if(!pe(sc(a.value[u]),sc(b.value[u]),h))return!1;return!0}if(a instanceof
Mc){if(!(b instanceof Mc))return!1;h=a.keys();u=b.keys();return h.length!==u.length?!1:sc(h).every(function(p){return b.containsKey(p)&&pe(sc(a.get(p)),sc(b.get(p)))})}if(a instanceof Ld.XdmFunction||b instanceof Ld.XdmFunction)throw new J("Function types, other than map(*) or array(*) cannot be tested for equality","FOTY0015");if(re.Bc(a)&&re.Bc(b))return!0;if(h&&zb(a)&&zb(b))return h(a.value,b.value);try{return a.equals(b)}catch(p){return!1}}}
function se(a,b,h){for(var u=a.next(),z=b.next();;){if(null===u)return null===z?0:-1;if(null===z)return 1;if(h)if(qe(u,z,h.equals))u=a.next(),z=b.next();else return re.Bc(u)?-1:/^AS/.test(u.code)&&/^AS/.test(z.code)?h.compare(u.value,z.value):u.compareTo(z);else if(qe(u,z))u=a.next(),z=b.next();else return re.Bc(u)?-1:u.compareTo(z)}};
// Input 25
var re=function(){function a(C,y){return C.equals(y)}function b(C,y){return C.compareTo(y)}function h(C){return null===C}function u(C){return(D.double.matches(C)||D.float.matches(C))&&isNaN(C.toNumber())}function z(C,y){return function(K,U){return y(K)||y(U)?y(K)?y(U):!1:C(K,U)}}function n(C,y){return function(K,U){return y(K)||y(U)?y(K)?y(U)?0:-1:1:C(K,U)}}function g(C,y){return null===C?NaN:D.numeric.matches(C)?C.toNumber():y?D.double.fromString(C.toString()).toNumber():D.double.nf(C.toString()).toNumber()}
function e(C){return function(y,K){y=g(y,C);K=g(K,C);return isNaN(y)?isNaN(K)?0:-1:isNaN(K)?1:y-K}}function k(C){return function(y,K){y=g(y,C);K=g(K,C);return isNaN(y)?isNaN(K):isNaN(K)?!1:0===y-K}}function p(C,y){return function(K,U){K=K.toString();U=U.toString();var O=C(K.toLowerCase(),U.toLowerCase());if(0===O){for(O=0;O<K.length;O++)if(K.charAt(O)!==U.charAt(O))return y===(K.charAt(O)===K.charAt(O).toLowerCase())?-1:1;return 0}return O}}function v(C){return function(y,K){return zb(y)&&zb(K)?C(y.value,
K.value):y.equals(K)}}function B(C){return function(y,K){return zb(y)&&zb(K)?C(y.value,K.value):y.compareTo(K)}}function q(C,y){var K=C.split("|");if("DESC"===K[0]){var U=q(C.slice(5),y);return{equals:U.equals,compare:function(aa,ca){return U.compare(ca,aa)}}}var O={equals:null,compare:null};switch(K[0]){case "NC":return O.equals=k(!1),O.compare=e(!1),O;case "QUNC":return O.equals=k(!0),O.compare=e(!0),O;case "DblSC":return O.equals=z(a,u),O.compare=n(n(b,u),h),O;case "CCC":return O.equals=v(qa().equals),
O.compare=n(B(qa().compare),h),O;case "TEXT":C=K[1]||"http://www.w3.org/2005/xpath-functions/collation/codepoint";var Z=M(C,y);ka(Z,C);O.equals=z(function(aa,ca){return Z.equals(aa.toString(),ca.toString())},h);O.compare=n(function(aa,ca){return B(Z.compare)(D.string.O(aa),D.string.O(ca))},h);return O;case "CAVC":case "DecSC":case "GAC":case "AtSC":case "CalVC":return{equals:z(a,h),compare:n(n(b,u),h)};case "EQC":return O.equals=z(a,h),O.compare=function(aa,ca){return O.equals(aa,ca)?0:1},O;case "CAC":return C=
K[1]||"http://www.w3.org/2005/xpath-functions/collation/codepoint",Z=M(C,y),ka(Z,C),O.equals=function(aa,ca){return Z.equals(aa.toString(),ca.toString())},O.compare=function(aa,ca){return Z.compare(aa.toString(),ca.toString())},O;default:throw new J("Unknown comparer "+C,"SXJS0003");}}function f(C,y){var K=y.fixed.ka,U=null;C.comp&&(U=C.comp,U=U.replace(/^DESC\|/,""));var O=K.xc(K.ea(C,"collation"),y).next(),Z=K.xc(K.ea(C,"order"),y).next(),aa=K.xc(K.ea(C,"lang"),y).next(),ca=K.xc(K.ea(C,"caseOrder"),
y).next();C=K.xc(K.ea(C,"dataType"),y).next();ca=null===O&&null!==ca?ca.toString():"#default";try{aa=null===O&&null!==aa&&""!==aa.toString()?D.language.fromString(aa.toString()):null}catch(oa){throw new J("@lang: "+oa.message,"XTDE0030");}"CalVC"===U&&(C=null);Z=null===Z?"ascending":Z.toString();C=null===C?null:C.toString();O=null===O?null===aa?"http://www.w3.org/2005/xpath-functions/collation/codepoint":"http://www.w3.org/2013/collation/UCA?lang="+aa.toString():O.toString();return{Ld:U,collation:O,
order:Z,lang:aa,gf:ca,dataType:C}}function I(C,y){return d(f(C,y),y)}function d(C,y){function K(O){return"descending"===C.order?function(Z,aa){return O(aa,Z)}:O}y=M(C.collation,y);ka(y,C.collation);y=y.compare;var U="#default"!==C.gf?p(y,"lower-first"===C.gf):B(y);if("number"===C.dataType)return K(e(!1));if("text"===C.dataType)return K(n(function(O,Z){return U(D.string.O(O),D.string.O(Z))},h));if(C.Ld){y=C.Ld;if("DblSC"===y)return K(n(n(b,u),h));if("CalVC"===y||"DecSC"===y)return K(n(b,h));if("CCC"===
y)return K(n(U,h))}return K(U)}function c(C,y){switch(C){case "=":case "eq":return 0===y;case "<":case "lt":return 0>y;case ">":case "gt":return 0<y;case ">=":case "ge":return 0<=y;case "<=":case "le":return 0>=y;case "!=":case "ne":return 0!==y;default:throw new J("Unknown operator "+C,"SXJS0003");}}function l(C,y){return C.map(function(K){var U=y.fixed.ka;K=U.ea(K,"dataType");U=null===K?null:U.evaluate(K,y).next();return"number"===U?function(O){return null===O?null:D.double.nf(O.toString())}:"text"===
U?function(O){return null===O?null:D.string.O(O)}:function(O){return O}})}function t(C,y){return C.substring(0,y.length)===y}function r(C,y){return C.substring(C.length-y.length)===y}function A(C,y){return y&&(y=y.next())?y.toString():C.collation||Nd.tg(C,"defaultCollation")}function M(C,y){y=C?t(C,"http://www.w3.org/2013/collation/UCA")&&36<C.length?X(C,C.substring(37),y):t(C,"http://saxon.sf.net/collation")&&29<C.length?X(C,C.substring(30),y):y.fixed.options.jd[C]:qa();if(!y)throw new J("Unrecognized collation "+
C,"FOCH0002");return y}function X(C,y){if(""===y)y=qa();else if("strength=secondary"===y||"strength=2"===y)y=Q();else{var K={};y.split(";").forEach(function(Z){Z=Z.split("=");K[Z[0]]=Z[1]});y={};if("no"===K.fallback)throw new J("Unsupported collation "+C,"FOCH0002");if(K.strength)if("primary"===K.strength||"1"===K.strength)y.sensitivity="base";else if("secondary"===K.strength||"2"===K.strength)y.sensitivity="accent";else if("tertiary"===K.strength||"3"===K.strength)y.sensitivity="variant";else if("quaternary"===
K.strength||"4"===K.strength||"identical"===K.strength||"5"===K.strength)y.sensitivity="variant",y.ignorePunctuation=!1;if(K.caseFirst){var U=K.caseFirst;["upper","lower","false"].includes(U)||(U="false");y.caseFirst=U}"yes"===K.numeric&&(y.numeric=!0);"blanked"===K.alternate&&(y.ignorePunctuation=!0);var O=new Intl.Collator(K.lang,y);y={equals:function(Z,aa){return 0===O.compare(Z,aa)},compare:function(Z,aa){return O.compare(Z,aa)},startsWith:function(Z,aa){return Z.length<aa.length?!1:0===O.compare(Z.substring(0,
aa.length),aa)},endsWith:function(Z,aa){return Z.length<aa.length?!1:0===O.compare(Z.substring(Z.length-aa.length),aa)},indexOf:function(Z,aa){if(Z.length<aa.length)return-1;for(var ca=0;ca<=Z.length-aa.length;ca++)if(0===O.compare(Z.substring(ca,ca+aa.length),aa))return ca;return-1},contains:function(Z,aa){if(Z.length<aa.length)return!1;for(var ca=0;ca<=Z.length-aa.length;ca++)if(0===O.compare(Z.substring(ca,ca+aa.length),aa))return!0;return!1},collationKey:function(Z){return Z}}}if(!y)throw new J("Unrecognized collation "+
C,"FOCH0002");return y}function ka(C,y){if(!C.hasOwnProperty("compare")||!C.hasOwnProperty("equals"))throw new J("Collation "+y+" cannot be used for sorting","XTDE1035");}function qa(){return{equals:function(C,y){return C===y},compare:function(C,y){return qb(C,y)},contains:function(C,y){return-1!==C.indexOf(y)},startsWith:function(C,y){return t(C,y)},endsWith:function(C,y){return r(C,y)},indexOf:function(C,y){return C.indexOf(y)},collationKey:function(C){return C}}}function Q(){function C(y){return y?
(y+"").toUpperCase():""}return{equals:function(y,K){return C(y)===C(K)},compare:function(y,K){return qb(C(y),C(K))},contains:function(y,K){return-1!==C(y).indexOf(C(K))},startsWith:function(y,K){return t(C(y),C(K))},endsWith:function(y,K){return r(C(y),C(K))},indexOf:function(y,K){return C(y).indexOf(C(K))},collationKey:function(y){return C(y)}}}function ja(C){return{equals:function(y,K){return C(y)===C(K)},compare:function(y,K){return qb(C(y),C(K))},contains:function(y,K){return-1!=C(y).indexOf(C(K))},
startsWith:function(y,K){return t(C(y),C(K))},endsWith:function(y,K){return r(C(y),C(K))},indexOf:function(y,K){return C(y).indexOf(C(K))},collationKey:function(y){return C(y)}}}var D=Ab.XS;return{re:q,vi:I,yi:function(){function C(y){return y.toUpperCase()}return ja(function(y){return y?(y+"").replace(/[a-z]/g,C):""})},Yg:Q,Ci:B,Di:qa,Kd:"http://www.w3.org/2005/xpath-functions/collation/codepoint",Ei:d,Li:v,Xi:"http://www.w3.org/2010/09/qt-fots-catalog/collation/caseblind",gc:function(C,y,K,U,O){function Z(P,
V){return ca(P)?ca(V)?D.string.O(P):aa(V)?D.double.O(P):"AQ"===V.code?Ab.XS.QName.O(P,Nd.be(O,!1)):Ab.bb[V.code].O(P):P}ib.assert(U.equals&&U.compare);for(var aa=D.numeric.matches,ca=D.untypedAtomic.matches,oa="="===K?U.equals:"!="===K?function(P,V){return!U.equals(P,V)}:function(P,V){return c(K,U.compare(P,V))},ea,na;null!==(ea=C.next());)for(var x=sc(y);null!==(na=x.next());){var F=Z(ea,na);na=Z(na,ea);if(!(aa(F)&&isNaN(F.value)||aa(na)&&isNaN(na.value))&&oa(F,na))return Gc(!0)}return Gc(!1)},$i:function(C){return{equals:function(y,
K){var U=D.numeric.matches;return U(y)||U(K)?D.numeric.Fe(y).toNumber()===D.numeric.Fe(K).toNumber():C.equals(y,K)},compare:C.compare}},pf:function(C,y,K){return M(A(C,y),K)},qg:M,Wb:function(C,y,K,U){y=A(C,y);U=M(y,U)[K];if(!U)throw new J("Collation "+y+" has no "+K+" method","FOCH0002",C);return U},bj:l,Bh:"http://www.w3.org/2005/xpath-functions/collation/html-ascii-case-insensitive",Bc:u,Yj:f,Zj:function(C){var y=Nd.Va(C),K=Nd.Db(C,"sortKey");return function(U){var O=Bc(y(U)),Z=[],aa=K.map(function(x){return I(x,
U)}),ca=K.map(Nd.Va),oa=l(K,U),ea=U.ca(!1);ea.sa="sort key";ea.focus=O;var na=0;O.forEachItem(function(x){var F=K.map(function(P,V){return oa[V](ca[V](ea).next())});Z.push({item:x,keys:F,Yd:na++})});Z.sort(function(x,F){for(var P=0;P<aa.length;P++){var V=x.keys[P],R=F.keys[P];if(null==V){if(null==R)continue;return-1}if(null!=R&&(V=aa[P](V,R),0!==V))return V}return x.Yd>F.Yd?1:x.Yd===F.Yd?0:-1});return sc(Z.map(function(x){return x.item}))}},Jf:c,ei:function(C,y){switch(C){case "=":case "eq":return y;
case "!=":case "ne":return!y;case "<":case "lt":case ">":case "gt":case ">=":case "ge":case "<=":case "le":throw new J("Operator "+C+" not allowed for unordered types","SXJS0003");default:throw new J("Unknown operator "+C,"SXJS0003");}},Kf:function(C,y){function K(ca){return zb(ca)?O(ca.matchKey()):ca.matchKey()}var U=new Mc,O=C.collationKey;if(!O)throw new J("Collation is unsuitable for keys and grouping","XTDE1110");var Z={ad:function(ca,oa){return pe(sc(ca),sc(oa),C.equals)},hash:function(ca){return ca.map(K).join()}},
aa={ad:function(ca,oa){return K(ca)===K(oa)},hash:function(ca){return K(ca)}};U.options=y?Z:aa;return U},ak:"http://www.w3.org/2013/collation/UCA",fk:"http://www.w3.org/xslts/collation/caseblind"}}();
// Input 26
var te=function(){var a={};return{register:function(b,h){a[b]=h},find:function(b){return a[b]},wl:a}}();
// Input 27
var ue=function(){function a(b){var h=Kc.Ka,u={"p.any":function(){return function(){return!0}},"p.never":function(){return function(){return!1}},"p.booleanExp":function(z){var n=Nd.Va(z);return function(g,e){e=e.ca();e.focus=Ac(g);return Nd.Od(n(e))}},"p.genNode":function(z){var n=Nd.Va(z);return function(g,e){if(!Kc.X(g))return!1;for(var k=Kc.Ka.$a(g),p;null!==(p=k.next());){var v=e.ca(!1);v.focus=Ac(p);v.Zb=[];for(p=n(v);null!==(v=p.next());)if(Kc.isSameNode(v,g))return!0}return!1}},"p.genPos":function(z){var n=
Xc(z.test).ia(),g=Nd.Va(z);return function(e,k){if(!n(e))return!1;var p=k.fixed.ka;k=k.ca(!1);var v=h.Ma(Kc.Za(e)).filter(n);k.focus=Bc(v);for(k.Zb=[];null!=(v=k.focus.next());)if(v===e&&p.Od(g(k)))return!0;return!1}},"p.nodeSet":function(z){var n=Nd.Va(z);return function(g,e){return n(e).some(function(k){return k===g})}},"p.nodeTest":function(z){return Xc(z.test).ia()},"p.simPos":function(z){var n=Xc(z.test).ia(),g=parseInt(z.pos,10);return function(e){return n(e)&&h.Zc(e).filter(n).count()===g-
1}},"p.venn":function(z){var n=z.C,g=a(n[0]),e=a(n[1]);z=z.op;switch(z){case "union":return function(k,p){return g(k,p)||e(k,p)};case "intersect":return function(k,p){return g(k,p)&&e(k,p)};case "except":return function(k,p){return g(k,p)&&!e(k,p)};default:throw new J("unknown op "+z,"SXJS0003");}},"p.withCurrent":function(z){var n=a(z.C[0]);return function(g,e){e=e.ca(!0);e.pa[0]=[g];return n(g,e)}},"p.withPredicate":function(z){z=z.C;var n=a(z[0]),g=Nd.nb(z[1]);return function(e,k){if(!n(e,k))return!1;
k=k.ca(!1);k.focus=Ac(e);k.Zb=[];return Nd.Od(g(k))}},"p.withUpper":function(z){var n=z.C,g=a(n[0]),e=a(n[1]),k=z.axis;return"parent"===k?"true"===z.upFirst?function(p,v){var B=Kc.Za(p);return B&&e(B,v)&&g(p,v)}:function(p,v){var B=Kc.Za(p);return B&&g(p,v)&&e(B,v)}:function(p,v){if(!g(p,v))return!1;p=Kc.Xg[k](p);for(var B;null!==(B=p.next());)if(e(B,v))return!0;return!1}}}[b.N];if(u)return u(b);throw new J("Unimplemented pattern "+b.N,"SXJS0002");}return{vd:a,matches:function(b,h,u){u.xb.push(null);
b=b(h,u);u.xb.pop();return b}}}();
// Input 28
var ve=function(){function a(u,z,n,g){function e(r){for(var A=[],M=r.length-1;0<=M;M--)A.push({Zd:r[M],action:r[M].C[1],pattern:ue.vd(r[M].C[0])});return A}function k(r){r._saxonAccumulators||(r._saxonAccumulators={});r._saxonAccumulators[n]={};var A=p(d,r);A&&(t=B(A,r));r._saxonAccumulators[n].pre=t;h.Ma(r).forEachItem(k);(A=p(c,r))&&(t=B(A,r));r._saxonAccumulators[n].post=t}function p(r,A){for(var M=0,X=r.length;M<X;M++)if(ue.matches(r[M].pattern,A,l))return r[M];return null}function v(r){return{visibility:"PRIVATE",
Ua:r,actor:r,ef:r.binds.split(" ").map(function(A){return parseInt(A,10)})}}function B(r,A){var M=g.ca(!0);M.focus=Ac(A);M.pa=[t];M.ua=v(z);M.sa="accumulator";return q.evaluate(r.action,M).expand()}var q=g.fixed.ka,f=q.Db(z,"pre")[0],I=q.Db(z,"post")[0],d=e(q.Db(f,"accRule")),c=e(q.Db(I,"accRule")),l=g.ca(!0);l.focus=Ac(u);l.pa=[];l.ua=v(z);var t=q.evaluate(q.ea(z,"init"),l).expand();k(u)}function b(u,z,n,g,e){var k=g.fixed.ka.ue(u,g).Jb[z];if(!k)throw new J("Unknown accumulator "+z,"XTDE3340");if(g.Qe&&
!g.Qe.some(function(p){return p===z}))throw new J("Inapplicable accumulator "+z,"XTDE3362");if(!Kc.X(n))throw new J("Cannot evaluate accumulator: context item is not a node","XTTE3360");if(2===n.nodeType||13===n.nodeType)throw new J("Accumulators are not available on attribute or namespace nodes","XTTE3360");if(n._saxonOriginal)return b(u,z,n._saxonOriginal,g,e);u=k.Gg;n._saxonAccumulators&&n._saxonAccumulators.hasOwnProperty(u)||a(Kc.zc(n),k.ti,u,g);return n._saxonAccumulators[u][e]}var h=Kc.Ka;
return{si:function(u,z,n,g){return b(u,z,n,g,"pre")},ri:function(u,z,n,g){return b(u,z,n,g,"post")}}}();
// Input 29
var we=function(){function a(v,B,q,f,I){I=void 0===I?null:I;var d=k.string.fromString(B);if(v&&v.containsKey(d)){v=v.get(d);if("function"===q){B=new Pd([Nc.dc],Nc.dc);if(1===v.length&&B.ia()(v[0]))return v[0];throw new J("Option "+d+" must be a function(xs:string) as xs:string","XPTY0004");}if(1===v.length&&Ab.type[q].matches(v[0])){if(I&&0>I.indexOf(v[0].value))throw new J("Invalid value "+d+"="+v[0],"FOJS0005");return v[0].value}throw new J("Invalid option: "+B+": must be "+q,"XPTY0004");}return f}
function b(v,B){function q(M){throw new J(M,"FOJS0001");}function f(){return A=I()}function I(){for(A="";;){if(r>=v.length)return["eof"];var M=v.charAt(r++);if(!/[ \n\r\t]/.test(M))break}switch(M){case "[":case "{":case "]":case "}":case ":":case ",":return[M];case '"':for(M=!1;;){var X=v.charAt(r++);if('"'!==X||M)A+=X,M="\\"===X&&!M;else return["string",A];r>=v.length&&q("Unclosed quotes in string literal")}case "-":case "0":case "1":case "2":case "3":case "4":case "5":case "6":case "7":case "8":case "9":for(A+=
M;r<v.length;)if(M=v.charAt(r++),/[0-9\-+.eE]/.test(M))A+=M;else{r--;break}return["number",/^-?(0|[1-9][0-9]*)(\.[0-9]+)?([eE][+-]?[0-9]+)?$/.test(A)?A:q("Invalid JSON number "+A)];case "t":case "f":case "n":for(A+=M;;)if(M=v.charAt(r),"a"<=M&&"z">=M){if(A+=M,++r>=v.length)break}else break;if(["true","false","null"].includes(A))return[A];q("Unknown constant "+A);break;default:return q("Unexpected character '"+M+"' ("+tb(M)+") at position "+r),-1}}function d(M){A[0]!==M&&q("Expected "+M+", found "+
A[0])}function c(M){return A[0]===M?(f(),!0):!1}function l(M){if(A[0]===M)return f(),!0;if(","===A[0])return f(),!1;q("Expected ',' or '"+M+"', found '"+A[0]+"'")}function t(){switch(A[0]){case "[":f();B.ai();if(!c("]")){do t();while(!l("]"))}B.lh();break;case "{":f();B.bi();if(!c("}")){do d("string"),B.hh(A[1]),f(),d(":"),f(),t();while(!l("}"))}B.mh();break;case "string":B.kh(A[1]);f();break;case "number":B.jh(A[1]);f();break;case "true":case "false":B.gh(A[0]);f();break;case "null":B.ih();f();break;
default:q("Unexpected token "+A[0])}}v=v.replace(/^[\uFEFF]/,"");var r=0,A=f();t();d("eof");return B.result()}function h(v){try{return JSON.parse('"'+v+'"')}catch(B){throw new J(B.toString(),"FOJS0001");}}function u(v,B){var q=B.Cb,f=B.Jd;var I=f?function(d){return f.evaluate([[Ab.XS.string.fromString(z(d))]],q).next().toString()}:function(){return"\ufffd"};v=v.replace(/[\x00-\x08\x0B\x0C\x0E-\x0F\x80-\x9F\uFFFF]/g,I);return v=v.replace(/[\uD800-\uDFFF]+/g,function(d){for(var c="",l=0;l<d.length;l++){var t=
d.charAt(l);c+=1===l%2==="\udc00">t||l===d.length-1&&"\udc00">t?I(t):t}return c})}function z(v){for(v=v.charCodeAt(0).toString(16);4>v.length;)v="0"+v;return"\\u"+v.toUpperCase()}function n(v,B){this.b=[];this.B=[];this.Cb=B;a(v,"liberal","boolean",!1);this.Jd=a(v,"fallback","function",null);this.l=a(v,"escape","boolean",!1);this.J=a(v,"duplicates","string","use-first",["reject","use-first","use-last"]);if(this.l&&this.Jd)throw new J("parse-json: cannot use fallback with escape:true()","FOJS0005");
}function g(v,B){this.b=[];this.J=[];this.B=[];this.Cb=B;a(v,"liberal","boolean",!1);if(a(v,"validate","boolean",!1))throw new J("json-to-xml: validate option not supported in a non-schema-aware processor","FOJS0004");this.Jd=a(v,"fallback","function",null);this.l=a(v,"escape","boolean",!1);this.U=a(v,"duplicates","string","use-first",["reject","use-first","retain"]);if(this.l&&this.Jd)throw new J("json-to-xml: cannot use fallback with escape:true()","FOJS0005");}var e=Ic.Ea,k=Ab.XS,p=Kc.Ka;n.prototype=
{b:[],B:[],Cb:null,l:!1,Jd:null,J:"use-first",U:function(v){return this.l?JSON.stringify(v).replace(/^"|"$/g,"").replace(/\\"/g,'"').replace(/[\uD800-\uDFFF]/g,z):u(v,this)},wa:function(){return this.b[this.b.length-1]},g:function(v){if(0===this.b.length)this.b.push(v);else if(this.wa()instanceof Kd)this.wa().value.push(v);else{var B=this.wa(),q=k.string.fromString(this.B.pop());if(B.containsKey(q))switch(this.J){case "reject":throw new J("json-to-xml: duplicate key '"+q.toString()+"'","FOJS0003");
case "use-first":return}B.inSituPut(q,v)}},ih:function(){this.g([])},gh:function(v){this.g([k.boolean.fromString(v)])},jh:function(v){this.g([k.double.fromNumber(parseFloat(v))])},kh:function(v){this.g([k.string.fromString(this.U(h(v)))])},hh:function(v){this.B.push(this.U(h(v)))},ai:function(){this.b.push(new Kd([]))},lh:function(){this.g([this.b.pop()])},bi:function(){this.b.push(new Mc)},mh:function(){this.g([this.b.pop()])},result:function(){return void 0!==this.wa()[0]?this.wa()[0]:null}};g.prototype=
{b:[],J:[{}],B:[],Cb:null,Jd:null,l:!1,U:"use-first",ba:function(v){return this.l?JSON.stringify(v).replace(/^"|"$/g,"").replace(/\\"/g,'"').replace(/[\uD800-\uDFFF]/g,z):u(v,this)},wa:function(){return this.b[this.b.length-1]},g:function(v){if(0===this.b.length)v.setAttribute("xmlns",e),this.b.push(v);else{var B=this.wa();if("map"===B.tagName){var q=this.J.pop(),f=this.B[this.B.length-1];if(q in f)switch(this.U){case "reject":throw new J("parse-json: duplicate key '"+q.toString()+"'","FOJS0003");
case "use-first":return}f[q]=v;v.setAttribute("key",q);this.l&&/\\/.test(q.toString())&&v.setAttribute("escaped-key","true")}B.appendChild(v)}},ih:function(){var v=this.Cb.ja.createElementNS(e,"null");this.g(v)},gh:function(v){var B=this.Cb.ja.createElementNS(e,"boolean");B.appendChild(this.Cb.ja.createTextNode(v.toString()));this.g(B)},jh:function(v){var B=this.Cb.ja.createElementNS(e,"number");B.appendChild(this.Cb.ja.createTextNode(v.toString()));this.g(B)},kh:function(v){var B=this.Cb.ja.createElementNS(e,
"string");if(""!==v){v=this.ba(h(v));var q=this.Cb.ja.createTextNode(v);this.l&&/\\/.test(v)&&B.setAttribute("escaped","true");B.appendChild(q)}this.g(B)},hh:function(v){this.J.push(this.ba(h(v)))},ai:function(){var v=this.Cb.ja.createElementNS(e,"array");this.b.push(v)},lh:function(){this.g(this.b.pop())},bi:function(){var v=this.Cb.ja.createElementNS(e,"map");this.b.push(v);this.B.push({})},mh:function(){this.B.pop();this.g(this.b.pop())},result:function(){return this.wa()}};return{He:function(v,
B,q){return b(v,new n(B,q))},Lj:function(v,B,q){return b(v,new g(B,q))},dk:function(v,B){function q(r){throw new J("Invalid input to xml-to-json(): "+r,"FOJS0006");}function f(r){0!==t.Nc(r).length&&q("node "+r.tagName+" must have no element children")}function I(r){p.Ma(r).filter(function(A){return 3===A.nodeType&&A.nodeValue&&""!==xb.trim(A.nodeValue)}).next()&&q("node "+r.tagName+" must have no text content")}function d(r){try{return k.boolean.fromString(r).toBoolean()}catch(A){q("invalid boolean: '"+
r+"'")}}function c(r){try{JSON.parse('"'+r+'"')}catch(A){throw new J("invalid escape sequence: "+A.toString(),"FOJS0007");}}function l(r,A){A=void 0===A?!0:A;if(9===r.nodeType||11===r.nodeType){var M=t.Nc(r);1!==M.length&&q("document node has "+M.length+" element children");return l(M[0])}if(1===r.nodeType){var X=!1;if(r.namespaceURI===e)switch(p.La(r).forEachItem(function(C){if(0>C.name.indexOf(":")||C.namespaceURI===e)"key"===C.name||"escaped-key"===C.name?"escaped-key"===C.name?(A||"map"===t.Za(r).localName||
q("@escaped-key is allowed only on children of map"),d(C.value)&&c(r.getAttribute("key"))):A||"map"===t.Za(r).localName||q("@key is allowed only on children of map"):"escaped"===C.name?("string"!==r.localName&&q("@escaped is allowed only on string"),X=d(C.value)):q("invalid attribute "+C.name)}),r.hasAttribute("escaped-key")&&!A&&"map"!==t.Za(r).localName&&q("escaped-key is allowed only on children of map"),r.localName){case "null":return p.Ma(r).filter(function(C){return 8!==C.nodeType&&7!==C.nodeType}).next()&&
q("null element must have no content"),"null";case "string":f(r);M=r.textContent;if(X)M=M.replace(/^"/g,'\\"').replace(/^[\b]/g,"\\b").replace(/^\f/g,"\\f").replace(/^\n/g,"\\n").replace(/^\r/g,"\\r").replace(/^\t/g,"\\t").replace(/^\//g,"\\/").replace(/([^\\])"/g,'$1\\"').replace(/([^\\])[\b]/g,"$1\\b").replace(/([^\\])\f/g,"$1\\f").replace(/([^\\])\n/g,"$1\\n").replace(/([^\\])\r/g,"$1\\r").replace(/([^\\])\t/g,"$1\\t").replace(/([^\\])\//g,"$1\\/").replace(/[\u0001-\u001F\u007F-\u009F]/g,z),c(M);
else return JSON.stringify(M).replace(/\//g,"\\/");return'"'+M+'"';case "number":f(r);try{M=k.double.fromString(r.textContent)}catch(C){q("invalid number")}isFinite(M.toNumber())||q("NaN and INF not allowed");return M.toString();case "boolean":return f(r),d(r.textContent),k.boolean.fromString(r.textContent).toString();case "array":I(r);var ka="[",qa="";t.Nc(r).forEach(function(C){ka+=qa+l(C,!1);qa=","});return ka+"]";case "map":I(r);var Q="{",ja="",D={};t.Nc(r).forEach(function(C){C.hasAttribute("key")||
q("child element of map must have a key");var y=t.getAttribute(C,"key"),K=y,U=t.getAttribute(C,"escaped-key"),O=!1;U&&d(U)&&(O=!0,K=h(y));D[K]&&q("duplicate key '"+y+"'");D[K]=!0;Q+=ja+(O?'"'+y+'"':JSON.stringify(y).replace(/\//g,"\\/"))+":"+l(C,!1);ja=","});return Q+"}";default:q("unknown local name "+r.localName)}else q("wrong namespace "+r.namespaceURI)}else t.X(r)||q("not a node"),Error.b&&Error.b(),q("wrong node kind "+r.nodeType)}var t=Kc;a(B,"indent","boolean",!1);return l(v)}}}();
// Input 30
var xe=function(){function a(n,g,e,k,p){e="xml"===e?g.fixed.vb:g.fixed.pc;g=g.fixed.Cd;var v=e[n];if(!v||v instanceof J){v=g[n];if(!v){try{v=k()}catch(B){v=B}g[n]=v}v=p(v);e[n]=v}if(v instanceof J)throw v;return v}function b(n,g){g||(g="utf-8");n=n.responseText||n;if(g.startsWith("utf")&&0<=n.indexOf("\ufffd"))throw new J("External file contains non-Unicode characters","FOUT1190");return n}function h(n){if(0<=n.indexOf("\x00"))throw new J("External file contains non-XML characters","FOUT1190");return n}
function u(n){"\ufeff"===n.charAt(0)&&(n=n.substring(1));return n}function z(n,g,e){if((n=n[0].next())&&Kc.ud(n.toString()))return n.toString();g=g.fixed.ka.Qa(e,g);if(null===g||"#UNDEFINED"===g)throw new J("No base URI for relative resolution","FODC0002",e);return null===n?null:gb.Ob(n.toString(),g)}return{Vg:z,bf:function(n,g,e){try{return z(n,g,e)}catch(k){if(k instanceof J&&"XPTY0004"===k.code)throw k;throw new J(k.message,"FOUT1170",e);}},eg:function(n){if(""===n)return null;if(!["utf-8","utf-16",
"utf-16be","iso-8859-1"].includes(n.toLowerCase()))throw new J("Unrecognized encoding "+n,"FOUT1190");return n},ah:h,bh:u,Lc:function(n,g){if(0<=n.indexOf("#"))throw new J("Fragments not supported in external document URIs","FODC0002");return a(n,g,"xml",function(){try{var e=gb.readFile(n,!0)}catch(k){if(k instanceof J&&"FOUT1190"===k.code)throw new J(k.message,"FODC0002");throw k;}return e},function(e){if(e instanceof J)return e;e=e.responseText||e;e=u(h(e));e=Kc.Ec(gb.parseXmlFromString(e));e._saxonBaseUri=
n;e._saxonDocUri=n;return e})},Ui:function(n,g){if(0<=n.indexOf("#"))throw new J("Fragments not supported in text document URIs","FOUT1170");return a(n,g,"text",function(){try{var e=gb.readFile(n,!0)}catch(k){if(k instanceof J)throw new J(k.message,"FOUT1170");throw k;}return e},function(e){if(e instanceof J)return e;var k=e.responseText||e;k=u(k);e.responseText?e.responseText=k:e=k;return b(e,"utf-8")})},lg:function(n,g,e,k){if(0<=n.indexOf("#"))throw new J("Fragments not supported in text document URIs",
"FOUT1170");return a(n,g,"text",function(){try{var p=gb.readFile(n,!0)}catch(v){if(v instanceof J&&"FOUT1190"===v.code)throw v;throw new J("Cannot retrieve unparsed-text "+n.toString()+" "+v.toString(),"FOUT1170",e);}return p},function(p){if(p instanceof J)return p;var v=p.responseText||p;v=u(h(v));p.responseText?p.responseText=v:p=v;return b(p,k)})},Sh:function(n){try{return n(),Gc(!0)}catch(g){if(g instanceof J&&"XPTY0004"===g.code)throw g;return Gc(!1)}}}}();
// Input 31
function ye(){}ba=ye.prototype;ba.Ja=function(){};ba.Ca=function(){};ba.oa=function(){};ba.va=function(){};ba.text=function(){};ba.za=function(){};ba.xa=function(){};ba.append=function(){};ba.close=function(){};function ze(){this.b=null}Ka(ze,ye);ba=ze.prototype;ba.Ja=function(a){a=void 0===a?{}:a;this.b.Ja(a)};ba.Ca=function(){this.b.Ca()};ba.oa=function(a,b,h,u){u=void 0===u?{}:u;this.b.oa(a,b,h,u)};ba.va=function(){this.b.va()};ba.text=function(a,b){b=void 0===b?{}:b;this.b.text(a,b)};
ba.za=function(a,b){b=void 0===b?{}:b;this.b.za(a,b)};ba.xa=function(a,b,h){h=void 0===h?{}:h;this.b.xa(a,b,h)};ba.append=function(a,b){b=void 0===b?{}:b;this.b.append(a,b)};ba.close=function(){this.b.close()};function Ae(a,b){a.b=b;return a}function Be(a){this.od=(this.ra=a)?Kc.Qc(a.ja):gb.getPlatform().createDocument();this.Pb=[];this.uc=null;this.g=0;this.l=null}Ka(Be,ye);ba=Be.prototype;
ba.Ja=function(a){a=void 0===a?{}:a;if(0===this.g++){var b=this.uc=this.od.createDocumentFragment();a.BASE_URI&&(b._saxonBaseUri=a.BASE_URI);this.Pb.push(b)}else throw new J("Builder.startDoc() not at top level");};ba.Ca=function(){0===--this.g&&(this.uc=null)};function Ce(a,b){0===a.g?a.Pb.push(b):a.uc.appendChild(b)}
ba.oa=function(a,b,h,u){u=void 0===u?{}:u;var z=this.od.createElementNS(a.uri,a.toString());u.BASE_URI&&(z._saxonBaseUri=u.BASE_URI);u.ORIGINAL_NODE&&(z._saxonOriginal=u.ORIGINAL_NODE);u.CALLBACK&&u.CALLBACK(z);Ce(this,z);this.uc=z;this.g++;this.l=null;b.forEach(function(g,e){z.setAttributeNS(e.uri,e.toString(),g.value)});for(var n in h)h.hasOwnProperty(n)&&(a=h[n],""===n?z.setAttributeNS("http://www.w3.org/2000/xmlns/","xmlns",a):z.setAttributeNS("http://www.w3.org/2000/xmlns/","xmlns:"+n,a))};
ba.va=function(){0===--this.g?this.uc=null:this.uc=this.uc.parentNode;this.l=null};ba.text=function(a){this.l?this.l.appendData(a):(a=this.od.createTextNode(a),Ce(this,a),0<this.g&&(this.l=a))};ba.za=function(a){Ce(this,this.od.createComment(a));this.l=null};ba.xa=function(a,b){Ce(this,this.od.createProcessingInstruction(a,b));this.l=null};ba.append=function(a){if(0!==this.g)throw new J("Builder.append() not at top level");this.Pb.push(a)};ba.result=function(){return this.Pb};ba.close=function(){ye.prototype.close.call(this)};
function De(a){this.b=null;this.done=this.Hg=this.ji=!1;this.b=a}Ka(De,ze);function Ee(a){if(a.done)throw new J("Cannot write to the principal output destination as it has already been closed","XTDE1490");if(a.Hg)throw new J("Cannot write to the principal output destination as it has already been used by xsl:result-document","XTDE1490");a.ji=!0}ba=De.prototype;ba.Ja=function(a){a=void 0===a?{}:a;ze.prototype.Ja.call(this,a)};ba.Ca=function(){ze.prototype.Ca.call(this)};
ba.oa=function(a,b,h,u){u=void 0===u?{}:u;Ee(this);ze.prototype.oa.call(this,a,b,h,u)};ba.va=function(){ze.prototype.va.call(this)};ba.text=function(a,b){b=void 0===b?{}:b;Ee(this);ze.prototype.text.call(this,a,b)};ba.za=function(a,b){b=void 0===b?{}:b;Ee(this);ze.prototype.za.call(this,a,b)};ba.xa=function(a,b,h){h=void 0===h?{}:h;Ee(this);ze.prototype.xa.call(this,a,b,h)};ba.append=function(a,b){b=void 0===b?{}:b;Ee(this);ze.prototype.append.call(this,a,b)};
ba.close=function(){this.done=!0;this.b&&ze.prototype.close.call(this)};function Fe(a){return new Be(a)}function Ge(a){this.mb=a}ba=Ge.prototype;ba.createDocumentFragment=function(){return this.mb.createDocumentFragment()};ba.createElementNS=function(a,b){var h=this.mb.documentElement;""===a&&h&&gb.mc(h)?b=this.mb.createElement(b):"http://www.w3.org/1999/xhtml"===a&&h&&gb.mc(h)?(a=b.indexOf(":"),b=this.mb.createElement(-1===a?b:b.substring(a+1))):b=this.mb.createElementNS(a,b);return b};
ba.createTextNode=function(a){return this.mb.createTextNode(a)};ba.createComment=function(a){return this.mb.createComment(a)};ba.createProcessingInstruction=function(a,b){return this.mb.createProcessingInstruction(a,b)};function He(a){this.b=a;this.g=this.B=this.l=!1}Ka(He,ze);function Ie(a,b){b=void 0===b?{}:b;a.B||(a.g=!1,a.b.Ja(b),a.B=!0,a.l=!0)}ba=He.prototype;ba.Ja=function(a){a=void 0===a?{}:a;Ie(this,a);this.l=!1};ba.Ca=function(){this.g=!1;this.b.Ca()};
ba.oa=function(a,b,h,u){u=void 0===u?{}:u;Ie(this,u);this.b.oa(a,b,h,u)};ba.va=function(){this.b.va();this.g=!1};ba.text=function(a,b){b=void 0===b?{}:b;Ie(this,b);ze.prototype.text.call(this,a,b);this.g=!1};ba.za=function(a,b){b=void 0===b?{}:b;Ie(this,b);ze.prototype.za.call(this,a,b);this.g=!1};ba.xa=function(a,b,h){h=void 0===h?{}:h;Ie(this,h);ze.prototype.xa.call(this,a,b,h);this.g=!1};ba.append=function(a,b){b=void 0===b?{}:b;Ie(this,b);Je(this,a)};ba.close=function(){this.l&&this.Ca();ze.prototype.close.call(this)};
function Je(a,b){if(null!=b)if(Ab.qa(b))a.g&&a.text(" ",{}),a.text(b.toString(),{}),a.g=!0;else if(b instanceof Kd)b.flatten(function(h){return Je(a,h)});else{if(b instanceof Ld.XdmFunction)throw new J("Cannot add a function item to an XDM node tree","SENR0001");if(b instanceof Mc)throw new J("Cannot add a map to an XDM node tree","SENR0001");Ke.Qh(b,a);a.g=!1}}function Le(a,b){this.b=a;this.B=this.g=!1;this.l=!0;this.J=Ab.XS.string.fromString(b)}Ka(Le,ze);
function Me(a,b){b=void 0===b?{}:b;a.B||(a.b.Ja(b),a.B=!0,a.g=!0);a.l||a.b.append(a.J,{});a.l=!1}ba=Le.prototype;ba.Ja=function(a){a=void 0===a?{}:a;Me(this,a);this.g=!1};ba.Ca=function(){this.b.Ca()};ba.oa=function(a,b,h,u){u=void 0===u?{}:u;Me(this,u);this.b.oa(a,b,h,u)};ba.va=function(){this.b.va()};ba.text=function(a,b){b=void 0===b?{}:b;Me(this,b);ze.prototype.text.call(this,a,b)};ba.za=function(a,b){b=void 0===b?{}:b;Me(this,b);ze.prototype.za.call(this,a,b)};
ba.xa=function(a,b,h){h=void 0===h?{}:h;Me(this,h);ze.prototype.xa.call(this,a,b,h)};ba.append=function(a,b){var h=this;b=void 0===b?{}:b;if(Nd.isArray(a))a.flatten(function(u){return h.append(u,b)});else{if(a instanceof Ld.XdmFunction)throw new J("Cannot add a function item to an XDM node tree","SENR0001");if(a instanceof Mc)throw new J("Cannot add a map to an XDM node tree","SENR0001");Ab.qa(a)?(Me(this,b),this.b.append(a,b)):(Me(this,b),Ke.Qh(a,this))}};ba.close=function(){this.g&&this.Ca();ze.prototype.close.call(this)};
function Ne(){this.b=[];this.g=[]}function Oe(a,b){return a.b.findIndex(function(h){return h.equals(b)})}Ne.prototype.get=function(a){a=Oe(this,a);return 0>a?void 0:this.g[a]};Ne.prototype.set=function(a,b){var h=Oe(this,a);0<=h?this.g[h]=b:(this.b.push(a),this.g.push(b))};Ne.prototype.forEach=function(a){for(var b=0;b<this.b.length;b++)a(this.g[b],this.b[b])};
// Input 32
var Ke=function(){function a(f){this.g=f;this.ra=[];this.qb=[];this.rb=null;this.b=0;this.Ia=this.B=null;this.J={};this.ya=null;this.l=this.ba=!1;this.Ba={};this.Yc=null;return this}function b(){}function h(f){if(f){var I=f.PUSH;if(!I)if(I=q[f.N])f.PUSH=I=I(f);else{var d=Nd.nb(f);f.PUSH=I=function(c,l){d(c).forEachItem(function(t){return l.append(t)})}}return I}}function u(f){var I=f.N;f.parentNode&&(I=u(f.parentNode)+"/"+I);return I}function z(f){if(f.C){var I=f.C.map(h);return function(d,c){I.forEach(function(l){return l(d,
c)})}}return function(){}}function n(f,I,d,c,l,t){if(Kc.X(f))switch(f.nodeType){case 9:case 11:d.rb=Kc.baseURI(f);e(f,d,l,t);break;case 1:if(f.hasAttribute("xml:base")){var r=f.getAttribute("xml:base");d.rb=Kc.ud(r)?r:gb.Ob(r.toString(),Nd.Qa(c,I)).toString()}else d.rb=Kc.baseURI(f);e(f,d,l,t);break;case 3:case 4:d.text(f.nodeValue);break;case 7:d.xa(f.target,f.data);break;case 8:d.za(f.textContent);break;default:d.append(f)}else d.append(f)}function g(f,I,d){switch(f.nodeType){case 9:case 11:I.Ja(d?
{BASE_URI:d}:{});f=f.childNodes;for(d=0;d<f.length;++d)g(f[d],I,null);I.Ca();break;case 1:var c={};Kc.Ka.wb(f).forEachItem(function(t){"xml"!==t.prefix&&(c[t.prefix]=t.uri)});var l=new Ne;Kc.Ka.La(f).forEachItem(function(t){l.set(t.Hc,{value:t.value,lb:0})});I.oa(Kc.da(f),l,c,{});f=f.childNodes;for(d=0;d<f.length;++d)g(f[d],I,null);I.va();break;case 3:case 4:I.text(f.nodeValue,0);break;case 7:I.xa(f.target,f.data,0);break;case 8:I.za(f.textContent,0)}}function e(f,I,d,c){c=void 0===c?!1:c;switch(f.nodeType){case 9:case 11:c&&
(I.Yc=f);I.Ja();f=f.childNodes;for(var l=0;l<f.length;++l)e(f[l],I,d,c);I.Ca();break;case 1:c&&(I.Yc=f);I.oa(Kc.da(f));Kc.Ka.La(f).forEachItem(function(t){Kc.da(t);I.La(Kc.da(t),t.value)});d&&Kc.Ka.wb(f).forEachItem(function(t){I.wb(t.prefix,t.uri)});f=f.childNodes;for(l=0;l<f.length;++l)e(f[l],I,d,c);I.va();break;case 3:case 4:c&&(I.Yc=f);I.text(f.nodeValue);break;case 7:c&&(I.Yc=f);I.xa(f.target,f.data);break;case 8:c&&(I.Yc=f),I.za(f.textContent)}}function k(f){return(f=f.next())?f.toString():
""}function p(f,I,d,c,l){I=d&&0<=d.indexOf("c");if(Kc.X(f))switch(f.nodeType){case 9:case 11:l.rb=Kc.baseURI(f);l.Ja();c();l.Ca();break;case 1:d=Kc.da(f);l.rb=Kc.baseURI(f);l.oa(d);I&&Kc.Uc(f).forEach(function(t){l.wb(t.prefix,t.uri)});c();l.Yc=null;l.va();break;case 2:Kc.da(f);l.La(Kc.da(f),f.value);break;case 3:case 4:l.text(f.textContent);break;case 8:l.za(f.textContent);break;case 7:l.xa(f.target,f.data);break;case 13:l.wb(f.prefix,f.uri);break;default:throw new J("Unknown node kind "+f.nodeType,
"SXJS0003");}else l.append(f)}function v(f,I,d,c,l){var t=Ab.XS.string.fromString;if(!d.match(/^[smijxq]*$/))throw new J("Illegal flags for regular expression: "+d,"FORX0001");I=xb.Ke(I,d+"g",f);if(!c&&I.test(""))throw new J("Regular expression matches zero-length string","FORX0003");c=[];for(var r=0,A=0;null!==(d=I.exec(f));)if((d.index>r||d.index===r&&l)&&c.push({Xc:!1,string:t(f.substring(r,d.index))}),c.push({Xc:!0,groups:d,string:t(d[0])}),r=I.lastIndex,0===d[0].length&&I.lastIndex++,A++>10*
f.length)throw new J("analyze-string looping???","SXJS0004");f.length>r&&c.push({Xc:!1,string:t(f.substring(r,f.length))});return c}function B(f){var I="compAtt"===f.N,d=Nd.nb(Nd.ea(f,"name")),c=Nd.nb(Nd.ea(f,"namespace"));return function(l){function t(){throw new J((I?"Attribute":"Element")+" name '"+r+"' is not a lexical QName",I?"XTDE0850":"XTDE0820",f);}var r=xb.trim(d(l).next().toString());if(c){l=c(l).next().value;if(!Nd.ck(l))throw new J((I?"Attribute":"Element")+" namespace '"+l+"' is invalid",
I?"XTDE0865":"XTDE0835",f);var A=Ab.XS.QName;if(""===l&&r.includes(":"))A=A.fromParts("","",r.replace(/^.*:/,""));else if(r.includes(":")){var M=r.split(":");""!==M[0]&&""!==M[1]||t();A=A.fromParts(M[0],l,M[1])}else A=""===l?A.fromParts("","",r):A.fromParts(I?"ns0":"",l,r)}else{if(I&&"xmlns"===r)throw new J("Attribute must not be named xmlns","XTDE0855",f);try{A=Nd.Dg(f,r,!I)}catch(X){if(X instanceof J){if("XTDE0290"===X.code)throw new J((I?"Attribute":"Element")+" name '"+r+"' has an unbound prefix",
I?"XTDE0860":"XTDE0830",f);"FORG0001"===X.code&&t()}throw X;}}if("http://www.w3.org/2000/xmlns/"===A.uri)throw new J("Disallowed namespace "+A.uri,I?"XTDE0865":"XTDE0835",f);return A}}b.prototype.Ja=function(){};b.prototype.Ca=function(){};b.prototype.oa=function(){};b.prototype.La=function(){};b.prototype.wb=function(){};b.prototype.va=function(){};b.prototype.text=function(){};b.prototype.za=function(){};b.prototype.xa=function(){};b.prototype.append=function(){};b.prototype.close=function(){};
Ka(a,b);a.prototype.ta=function(f,I,d){var c=this.J[f];if(c&&c!==I)if(d){if(this.Ba[f])throw new J("Two conflicting bindings for prefix '"+f+"'","XTDE0430");if(this.B.prefix===f){for(c=f+"_9";this.J[c];)c+="9";this.B=Ab.XS.QName.fromParts(c,this.B.uri,this.B.local);this.J[c]=this.B.uri}this.J[f]=I}else return""===f&&(f="NULL"),this.ta(f+"0",I,!1);d&&(this.Ba[f]=!0);for(d=this.ra.length-1;0<=d;d--)if(c=this.ra[d],c[0]===f)return c[1]!==I&&(this.J[f]=I),f;if(""!==f||""!==I)this.J[f]=I;return f};a.prototype.Ja=
function(){0===this.b&&(this.g.Ja({BASE_URI:this.rb,ORIGINAL_NODE:this.Yc}),this.ra=[]);this.U();this.b++;this.l=this.ba=!1};a.prototype.Ca=function(){this.b--;0===this.b&&this.g.Ca();this.l=!1};a.prototype.oa=function(f){this.U();this.b++;this.ba=!0;this.B=f;this.Ia=new Ne;this.J={};this.Ba={};this.ta(f.prefix,f.uri,!1);this.ya=this.Yc;this.l=!1};a.prototype.La=function(f,I){if(0===this.b)this.g.append(new Jc(f,I,null));else{if(!this.ba){if(this.B)throw new J("Cannot write attribute ("+f.$()+"="+
I+") after writing child nodes (level="+this.b+", start tag = "+this.B+")","XTDE0410");throw new J("Cannot attach an attribute ("+f.$()+"="+I+") to a document node","XTDE0420");}if(""!==f.uri){["","xmlns"].includes(f.prefix)&&(f=f.Jc("ns_"));var d=this.ta(f.prefix,f.uri,!1);d!==f.prefix&&(f=f.Jc(d))}this.Ia.set(f,{value:I,lb:0})}};a.prototype.wb=function(f,I){if(0===this.b)this.g.append(new Lc(f,I));else{if(!this.ba)throw new J("Cannot write namespace after writing child nodes","XTDE0410");if(""===
f&&""===this.B.uri)throw new J("Cannot add default namespace declaration to a no-namespace element","XTDE0440");this.ta(f,I,!0)}};a.prototype.va=function(){this.U();this.g.va();this.b--;for(var f=this.qb.pop();0<f--;)this.ra.pop();this.l=!1};a.prototype.text=function(f,I){if(0<f.length||0===this.b)this.U(),this.g.text(f,I||{});this.l=!1};a.prototype.za=function(f){this.U();this.g.za(f);this.l=!1};a.prototype.xa=function(f,I){this.U();this.g.xa(f,I);this.l=!1};a.prototype.append=function(f){var I=
this;if(Kc.X(f)){if(0===this.b)this.g.append(f,{});else switch(f.nodeType){case 2:this.La(Kc.da(f),f.value);break;case 13:this.wb(f.prefix,f.uri);break;case 9:case 11:0===this.b?g(f,this.g,this.rb):Kc.Ka.Ma(f).forEachItem(function(t){return I.append(t)});break;default:this.U(),e(f,this,!0,!1)}this.l=!1}else if(0===this.b)this.g.append(f,{}),this.l=!1;else if(Ab.qa(f))this.U(),this.text((this.l?" ":"")+f.toString(),{}),this.l=!0;else if(Nd.isArray(f)){f=f.value;for(var d=0;d<f.length;d++)for(var c=
f[d],l=0;l<c.length;l++)this.append(c[l])}else{if(Nd.Td(f))throw new J("Cannot attach a "+(Nd.isMap(f)?"map":"function")+" to an XDM tree","XTDE0450");ib.la("Bad item "+ib.showValue(f))}};a.prototype.close=function(){this.g.close()};a.prototype.U=function(){if(this.ba){var f={};this.rb&&(f.BASE_URI=this.rb);this.ya&&(f.ORIGINAL_NODE=this.ya,this.ya=null);this.g.oa(this.B,this.Ia,this.J,f);this.ba=!1;f=0;for(var I=Aa(Object.entries(this.J)),d=I.next();!d.done;d=I.next()){var c=Aa(d.value);d=c.next().value;
c=c.next().value;this.ra.push([d,c]);f++}this.qb.push(f)}};var q={analyzeString:function(f){var I=Nd.nb(Nd.ea(f,"select")),d=Nd.nb(Nd.ea(f,"regex")),c=Nd.nb(Nd.ea(f,"flags")),l=h(Nd.ea(f,"matching")),t=h(Nd.ea(f,"nonMatching"));return function(r,A){try{var M=I(r).next();if(null===M)return wc;M=M.toString();var X=d(r).next().toString(),ka=c(r).next().toString(),qa=v(M,X,ka,!0,!1),Q=r.ca(!1),ja=qa.map(function(D){return D.string});Q.focus=Bc(sc(ja));Q.focus.forEachItem(function(){var D=qa[Q.focus.position-
1];D.Xc?l&&(Q.Zb=D.groups,l(Q,A)):t&&(Q.Zb=[],t(Q,A))})}catch(D){if(D instanceof J&&"FORX0002"===D.code)throw new J(D.message,"XTDE1140");if(D instanceof J&&"FORX0001"===D.code)throw new J(D.message,"XTDE1145");throw D;}}},applyImports:function(f){return function(I,d){var c=I.ib.mode;if(!c)throw new J("applyImports: no current mode","XTDE0560",f);Qd(I,"XTDE0560","xsl:apply-imports",f);var l=I.ca(!0);l.ua=I.ib;Nd.cf(f,I,l);l.focus=I.focus;return c.xi(l,d)}},applyT:function(f){var I=Nd.Va(f),d=Nd.nb(Nd.ea(f,
"separator"));return function(c,l){var t=c.ca(!0);if(Nd.Fb(f,"c")){var r=c.ib.mode;["function","globalVariable","globalParam"].includes(c.ua.actor.N)&&(r=Nd.ue(f,c).hi);t.ua=c.ib}else{var A=Nd.ug(f,c,"mode");r=A.mode;if(r.Lh)throw new J("Mode "+r.$e.name+" only handles typed nodes","XTTE3100",f);t.ua=A}Nd.cf(f,c,t);A=d?d(c).next():null;t.focus=Bc(I(c));t.focus.Zb=c.focus?c.focus.Zb:null;r.Kb(t,l,A)}},att:function(f){var I=f.name,d=I.split(":");var c=f.nsuri?2===d.length?Ab.XS.QName.fromParts(d[0],
f.nsuri,d[1]):Ab.XS.QName.fromParts("",f.nsuri,I):Ab.XS.QName.fromString(I,Nd.be(f,!1));var l=Nd.Va(f);return function(t,r){t=k(l(t));r.La(c,t)}},"break":function(f){var I=z(f);return function(d,c){I(d,c);d.focus.position=-999}},callT:function(f){return function(I,d){var c=Nd.ug(f,I,"template"),l=I.ca(!0);l.Eb=null;l.ua=c;Nd.fg(c.actor,l);Nd.cf(f,I,l);I=c.actor;I.C&&z(I)(l,d)}},choose:function(f){f=f.C;for(var I=[],d=[],c=0;c<f.length;c+=2)I.push(Nd.nb(f[c])),d.push(h(f[c+1]));return function(l,t){for(var r=
0;r<I.length;r++)if(Nd.Od(I[r](l))){d[r](l,t);break}}},comment:function(f){var I=Nd.Va(f);return function(d,c){d=k(I(d)).replace(/--/g,"- -").replace(/-$/,"- ");c.za(d)}},compAtt:function(f){var I=Nd.nb(Nd.ea(f,"select")),d=B(f);return function(c,l){var t=d(c);c=k(I(c));l.La(t,c)}},compElem:function(f){var I=h(Nd.ea(f,"content")),d=B(f);return function(c,l){f.baseUri&&(l.rb=f.baseUri);var t=d(c);l.oa(t);I(c,l);l.va()}},copy:function(f){var I=z(f);return function(d,c){Qd(d,"XTTE0945","xsl:copy",f);
p(Od(d),d,f.flags,function(){return I(d,c)},c)}},copyOf:function(f){var I=Nd.Fb(f,"c"),d=Nd.Fb(f,"m"),c=Nd.Va(f);z(f);return function(l,t){c(l).forEachItem(function(r){return n(r,l,t,f,I,d)})}},doc:function(f){var I=z(f);return function(d,c){c.rb=Nd.Qa(f,d);c.Ja();I(d,c);c.Ca()}},elem:function(f){var I=f.name,d=I.split(":"),c=z(f);var l="undefined"!==typeof f.nsuri?2===d.length?Ab.XS.QName.fromParts(d[0],f.nsuri,d[1]):Ab.XS.QName.fromParts("",f.nsuri,I):Ab.XS.QName.fromString(I,Nd.be(f,!0));return function(t,
r){f.baseUri&&(r.rb=f.baseUri);r.oa(l);if(f.namespaces)for(var A=f.namespaces.toString().split(" "),M=0;M<A.length;M++){var X=A[M],ka=X.indexOf("="),qa=-1===ka?X:X.substring(0,ka);X=-1===ka?"":X.substring(ka+1);"#"===qa&&(qa="");"xml"!==qa&&(X=""!==X?X:Nd.be(f,!0)(qa),r.wb(qa,X))}c(t,r);r.va()}},empty:function(){return function(){}},forEach:function(f){var I=Nd.Va(f),d=h(f.C[1]),c=Nd.nb(Nd.ea(f,"separator"));return function(l,t){var r=l.ca(!1);r.focus=Bc(I(l));r.cb=null;if(c){var A=!0,M=c(l).next();
r.focus.forEachItem(function(){A?A=!1:t.text(M.value,{});d(r,t)})}else r.focus.forEachItem(function(){return d(r,t)})}},forEachGroup:function(f){var I=Nd.nb(Nd.ea(f,"select")),d=Ke.Ji(Nd.ea(f,"content"));return function(c,l){function t(y){var K=[];r.forEachItem(function(U){y(K,U)});return K}var r=Bc(I(c)),A=c.ca(!1);A.focus=r;var M={starting:function(){var y=ue.vd(Nd.ea(f,"match"));return t(function(K,U){1===r.position||ue.matches(y,U,A)?K.push({group:[U]}):K[K.length-1].group.push(U)})},ending:function(){var y=
ue.vd(Nd.ea(f,"match")),K=!1;return t(function(U,O){1===r.position||K?U.push({group:[O]}):U[U.length-1].group.push(O);K=ue.matches(y,O,A)})},adjacent:function(){var y=Nd.ea(f,"collation");y=y?Nd.evaluate(y,c).next().toString():re.Kd;var K=re.qg(y,c),U=Nd.ea(f,"key"),O=null;return t(function(Z,aa){var ca=Nd.evaluate(U,A).expand();1!==r.position&&pe(sc(ca),sc(O),K.equals)?Z[Z.length-1].group.push(aa):(Z.push({group:[aa],key:ca}),O=ca)})},by:function(){var y=Nd.ea(f,"collation");y=y?Nd.evaluate(y,c).next().toString():
re.Kd;y=re.qg(y,c);var K=re.Kf(y,!1),U=Nd.ea(f,"key");return t(function(O,Z){var aa,ca=[];Nd.evaluate(U,A).forEachItem(function(oa){K.containsKey(oa)?(aa=K.get(oa),0>ca.findIndex(function(ea){return ea===aa})&&(O[aa].group.push(Z),ca.push(aa))):(aa=O.length,O.push({group:[Z],key:[oa]}),K.inSituPut(oa,aa),ca.push(aa))})})},by_c:function(){var y=Nd.ea(f,"collation");y=y?Nd.evaluate(y,c).next().toString():re.Kd;y=re.qg(y,c);var K=Nd.ea(f,"key"),U=re.Kf(y,!0);return t(function(O,Z){var aa=Nd.evaluate(K,
A).expand();if(U.containsKey(aa)){var ca=U.get(aa);O[ca].group.push(Z)}else ca=O.length,O.push({group:[Z],key:aa}),U.inSituPut(aa,ca)})}};M.adjacent_c=M.adjacent;var X=f.algorithm+(Nd.Fb(f,"c")?"_c":"");try{var ka=M[X]()}catch(y){if(y instanceof J&&"FOCH0002"===y.code)throw new J("Unknown collation:"+y.message,"XTDE1110");throw y;}var qa=Nd.Db(f,"sortKey");if(0<qa.length){M=Bc(sc(ka));var Q=c.ca(!1);Q.focus=M;var ja=qa.map(function(y){return re.vi(y,c)});M.forEachItem(function(y,K){y.Zh=qa.map(function(U){var O=
Q.focus;O.current=y.group[0];O.position=K+1;Q.kd=y.group;Q.ld=y.key;return Nd.Va(U)(Q).next()})});ka.sort(function(y,K){for(var U=0;U<ja.length;U++){var O=ja[U](y.Zh[U],K.Zh[U]);if(0>O)return-1;if(0<O)return 1}return 0})}M=c.ca(!1);M.focus=Bc(sc(ka));for(X=0;null!==M.focus.next();){var D=ka[X++],C=M.focus;C.current=D.group[0];C.position=X;M.kd=D.group;M.ld=D.key;d(M,l)}}},iterate:function(f){var I=(f.C[1].C||[]).map(Nd.nb),d=Nd.Va(f),c=h(Nd.ea(f,"action")),l=h(Nd.ea(f,"on-completion"));return function(t,
r){I.map(function(X){return X(t)});var A=t.ca(!1);A.focus=Bc(d(t));A.cb=null;for(var M=!1;null!==A.focus.next();)if(c(A,r),-999===A.focus.position){M=!0;break}!M&&l&&(A=t.ca(!1),A.focus=null,l(A,r))}},let:function(f){var I=Nd.Va(f),d=h(f.C[1]),c=parseInt(f.slot,10);return function(l,t){var r=l.sa;l.sa="variable";var A=I(l).expand();l.sa=r;l.pa[c]=A;d(l,t)}},nextIteration:function(f){var I=Nd.Db(f,"withParam").map(function(d){return{select:Nd.Va(d),slot:parseInt(d.slot,10)}});return function(d){I.forEach(function(c){return c.value=
c.select(d).expand()});I.forEach(function(c){return d.pa[c.slot]=c.value})}},nextMatch:function(f){return function(I,d){var c=I.ib.mode;if(!c)throw new J("next-match: no current mode","XTDE0560",f);if(!I.cb)throw new J("next-match: no current template","XTDE0560",f);if(Pe(I))throw new J("next-match: no context item","XTDE0560",f);var l=I.ca(!0);l.ua=I.ib;Nd.cf(f,I,l);return c.Gj(l,d)}},procInst:function(f){var I=Nd.Va(f),d=Nd.fh(f);return function(c,l){var t=I(c).next().toString();if(!xb.Mb(t))throw new J("The name of a processing-instruction must be a valid NCName; supplied value: "+
t.toString(),"XTDE0890",f);if(/^[Xx][Mm][Ll]$/.test(t))throw new J("The name of a processing-instruction must not be 'xml'","XTDE0890",f);c=d(c);c=k(c).replace(/\?>/g,"? >").replace(/^\s+/,"");l.xa(t,c)}},sequence:z,sourceDoc:function(f){var I=Nd.nb(Nd.ea(f,"href")),d=h(f.C[1]);return function(c,l){var t=c.ca(!1),r=null===I?"":I(c).next().toString();r=gb.Ob(r,Nd.Qa(f,c));r=xe.Lc(r,c);c=c.fixed.Ne;null!==c&&(r=c(r));t.focus=Ac(r);t.cb=null;d(t,l)}},useAS:function(f){return function(I,d){var c=Nd.ug(f,
I,"attributeSet");I=I.ca(!0);I.sa="attribute set";I.ua=c;c=c.actor;c.C&&z(c)(I,d)}},valueOf:function(f){var I=Nd.Va(f),d={};Nd.Fb(f,"d")&&(d.DISABLE_ESCAPING=!0);return function(c,l){c=k(I(c));l.text(c,d)}}};return{ag:v,Ji:h,Oc:function(f){return new a(f)},push:function(f,I,d){if("undefined"===typeof d)throw ib.Sa(Error().stack),new J("No output for "+u(f));if(q[f.N]){var c=h(f);try{c(I,d)}catch(r){throw r instanceof J&&!r.xsltLineNr&&hb(r,f),r;}}else{c=Nd.nb(f);try{for(var l=c(I),t;null!=(t=l.next());)d.append(t)}catch(r){throw r instanceof
J&&!r.xsltLineNr&&hb(r,f),r;}}},Qh:g,Ff:n,Xh:p}}();
// Input 33
var Qe=function(){function a(Q,ja,D,C,y){function K(F){return null===r.Za(F)}function U(F){return F.nodeType===Z.nodeType&&(null===aa?!0:r.da(F).equals(aa))}var O=y.fixed.ka;if(ja){var Z=O.evaluate(ja,y).next();if(!Z||!r.X(Z))throw new J("xsl:number/@select must yield a single node","XTTE1000");}else Qd(y,"XTTE0990","xsl:number"),Z=Od(y);var aa=r.da(Z),ca=U;if(null!==D){var oa=ue.vd(D);ca=function(F){return ue.matches(oa,F,y)}}if(null===C)ja=K;else{var ea=ue.vd(C);ja=function(F){return K(F)||ue.matches(ea,
F,y)}}switch(Q){case "simple":return Q=A.Zc(Z).filter(U),Dc(Q.count()+1);case "single":Q=A.$a(Z).filter(ca).next();var na=A.$a(Z).filter(ja).next();Q=null===Q?null:null!==A.$a(Q).filter(function(F){return r.isSameNode(F,na)}).next()?Q:null;if(null===Q)return wc;Q=A.Zc(Q).filter(ca);return Dc(Q.count()+1);case "multi":Q=sc(A.$a(Z).filter(ca).expand().reverse());na=A.$a(Z).filter(ja).next();Q=Q.filter(function(F){return null!==A.$a(F).filter(function(P){return r.isSameNode(P,na)}).next()});var x=M.integer.fromNumber;
return Q.mapOneToOne(function(F){return x(A.Zc(F).filter(ca).count()+1)});case "any":return D=function(F,P){return r.sb(P,F)},Q=new mc(A.zg(Z).filter(ca),A.$a(Z).filter(ca),D),na=(new mc(A.zg(Z).filter(ja),A.$a(Z).filter(ja),D)).next(),null===na&&ib.la("from pattern selected empty"),Q=Q.filter(function(F){return 0<=r.sb(F,na)}),Q=Q.count(),0===Q?wc:Dc(Q);default:return ib.la("unknown xsl:number/@level "+Q),wc}}function b(Q,ja,D,C,y,K,U){function O(aa){""===aa&&(aa="1");if(/^[\x01-\x7f]*$/.test(aa)){aa=
aa.split(/\b/);var ca=function(P){return/\w+/.test(P)}}else{if(aa.length!==tb(aa).length)throw new J("Cannot handle non-BMP characters in format-number picture","SXJS0002");var oa=Ke.ag(aa,"[\\p{Nd}\\p{Nl}\\p{No}\\p{Lu}\\p{Ll}\\p{Lt}\\p{Lm}\\p{Lo}]+","",!0,!1);ca=function(P,V){return oa[V].Xc};aa=oa.map(function(P){return P.string.toString()})}for(var ea={prefix:"",nd:[],ce:""},na=".",x=0;x<aa.length;x++){var F=aa[x];ca(F,x)?(ea.nd.push({$g:F,Uh:na}),na="."):(0===x&&(ea.prefix=F),x===aa.length-1?
ea.ce=F:0!==x&&(na=F))}0===ea.nd.length&&(ea.nd.push({$g:"1",Uh:"."}),ea.ce=ea.prefix);return ea}var Z=U.fixed.ka;if(null!==K)try{M.language.O(K)}catch(aa){throw new J("@lang="+K+": invalid language code","XTDE0030");}return function(aa){var ca=null===ja?"1":Z.evaluate(ja,U).next().toString(),oa=O(ca),ea=oa.prefix;aa.forEach(function(na,x){var F=x>=oa.nd.length?oa.nd[oa.nd.length-1]:oa.nd[x];0!==x&&(ea+=F.Uh);x=F.$g;y&&(x+=";o");na=Qe.ng(na.toNumber(),x);null!==D&&null!==C&&(na=Qe.$f(na,D,C));ea+=
na});ea+=oa.ce;return Cc(ea)}(Q.expand())}function h(Q,ja,D,C,y){var K=y.fixed.ka,U=[1];null!==D&&(U=K.evaluate(D,y).next().toString().split(/\s+/).map(function(O){if(!/^-?[0-9]+$/.test(O))throw new J("xsl:number/@start-at must be a sequence of integers. Offending value: "+O,"XTSE0020");return parseInt(O,10)}));if(ja&&C){Q=Q.next();if(null===Q)return null;try{Q=M.integer.O(Q.round(0))}catch(O){return null}Q=sc([Q])}return Q.mapOneToOne(function(O,Z){O=O.isNumeric()?O.toDouble():M.double.nf(O.toString());
if(0>O.toNumber())throw new J("xsl:number/@value is less than zero ("+O.toString()+")","XTDE0980");try{O=M.integer.O(O.round(0))}catch(aa){throw aa.code="XTDE0980",aa;}Z=Z>=U.length?U[U.length-1]:U[Z];1!==Z&&(O=M.integer.fromNumber(O.toNumber()+(Z-1)));return O})}function u(Q){if(1>Q||4999<Q)return Q.toString();for(var ja="",D="m cm d cd c xc l xl x ix v iv i".split(" "),C=[1E3,900,500,400,100,90,50,40,10,9,5,4,1],y=0;y<D.length;y++)for(;Q>=C[y];)ja+=D[y],Q-=C[y];return ja}function z(Q,ja){function D(y){return(y>
C?D(Math.floor((y-1)/C)):"")+ja.charAt((y-1)%C)}if(1>Q)return Q.toString();var C=ja.length;return D(Q)}function n(Q,ja){return Q<ja.length?ja[Q]:Q.toString()}function g(Q,ja){var D=Math.abs(Q);for(D=999999>D?D.toString():kb(D).toFixed();D.length<ja;)D="0"+D;0>Q&&(D="-"+D);return D}function e(Q,ja){ja=void 0===ja?"FODF1310":ja;var D=tb(Q),C={},y=!0,K=0,U=xb.Ke("\\p{Nd}",null),O=Q.search(U);if(0>O)y=!1;else{K=xb.Jj().Nd;for(var Z=0;Z<K.length;Z++){var aa=parseInt(K[Z][0],16);if(aa<=D[O]&&D[O]<=aa+10)break}for(O=
0;O<D.length;O++)if(128<=D[O])if(K=String.fromCharCode(D[O]),U.test(K)){if(aa>D[O]||D[O]>aa+10)throw new J("Picture string mixes digits from different digit families: "+Q,ja);}else if(xb.Ke("\\p{N}\\p{L}",null).test(K))throw new J("Picture string is invalid: "+Q,ja);var ca=[];K=aa-48;D.forEach(function(oa){aa<=oa&&oa<=aa+10?ca.push(48):ca.push(oa)});Q=wb(ca)}C.pj=y;C.format=Q;C.jg=K;C.Nf=aa;return C}function k(Q,ja){var D=[];tb(Q).forEach(function(C){48<=C&&57>=C?D.push(C+ja):D.push(C)});return wb(D)}
function p(Q,ja,D,C,y){C=void 0===C?"":C;y=void 0===y?!1:y;for(var K=0,U=0;U<Q.length;U++)if(U===ja[K]){var O=y?U+1:Q.length-U;0<O&&(Q=Q.substring(0,O)+(D?D[y?K:D.length-K-1]:C)+Q.substring(O));K++}return Q}function v(Q,ja,D){D=void 0===D?"FODF1310":D;var C=!0,y=0;if(tb(ja).length!==ja.length)throw new J("Cannot handle non-BMP characters in format-integer picture","SXJS0002");/^[\x01-\x7f]*$/.test(ja)||(C=!1);if(C)var K=/[0-9]/.test(ja);else y=e(ja,D),ja=y.format,K=y.pj,y=y.jg;K||(ja="1");var U=ja;
if(!/^(([0-9]|#|[^A-Za-z0-9])+?)$/.test(U))throw new J("The decimal digit pattern in the picture string is invalid: "+U,D);K=U;ja=!0;if(/[^0-9A-Za-z#]/.test(K)){var O=K.match(/[^0-9A-Za-z#]/g);if(1<O.length)for(var Z=1;Z<O.length;Z++)if(O[0]!==O[Z]){ja=!1;break}K=K.replace(/[^0-9A-Za-z#]/g,",")}if(/^,|,,|,$/.test(K))throw new J("Grouping in picture string is invalid: "+U,D);if(/[0-9].*#/.test(K))throw new J("Picture string is invalid: "+U,D);D=K.replace(/[#,]/g,"").length;Q=g(Q,D);if(O){K=tb(K).reverse();
D=[];for(U=0;U<K.length;U++)44===K[U]&&D.push(U);U=!0;K.length-D[D.length-1]-1>D[0]&&(U=!1);if(U)for(K=1;K<D.length;K++)if(0!==(D[K]+1)%(D[0]+1)){U=!1;break}Q=U&&ja?Qe.$f(Q,O[0],D[0]):p(Q,D,O)}O=Q;C||(O=k(O,y));return O}function B(Q){var ja="Zero One Two Three Four Five Six Seven Eight Nine Ten Eleven Twelve Thirteen Fourteen Fifteen Sixteen Seventeen Eighteen Nineteen".split(" "),D="  Twenty Thirty Forty Fifty Sixty Seventy Eighty Ninety".split(" ");return 20>Q?ja[Q]:100>Q?D[Math.floor(Q/10)]+(0===
Q%10?"":" "+ja[Q%10]):1E3>Q?B(Math.floor(Q/100))+" Hundred"+(0===Q%100?"":" and "+B(Q%100)):1E6>Q?B(Math.floor(Q/1E3))+" Thousand"+(0===Q%1E3?"":(100>Q%1E3?" and ":" ")+B(Q%1E3)):1E9>Q?B(Math.floor(Q/1E6))+" Million"+(0===Q%1E3?"":(100>Q%1E3?" and ":" ")+B(Q%1E6)):Q.toString()}function q(Q){var ja="Zeroth First Second Third Fourth Fifth Sixth Seventh Eighth Ninth Tenth Eleventh Twelfth Thirteenth Fourteenth Fifteenth Sixteenth Seventeenth Eighteenth Nineteenth".split(" "),D="  Twent Thirt Fort Fift Sixt Sevent Eight Ninet".split(" ");
return 20>Q?ja[Q]:100>Q?D[Math.floor(Q/10)]+(0===Q%10?"ieth":"y "+ja[Q%10]):1E3>Q?B(Math.floor(Q/100))+" Hundred"+(0===Q%100?"th":" and "+q(Q%100)):1E6>Q?B(Math.floor(Q/1E3))+" Thousand"+(0===Q%1E3?"th":(100>Q%1E3?" and ":" ")+q(Q%1E3)):1E9>Q?B(Math.floor(Q/1E6))+" Million"+(0===Q%1E3?"th":(100>Q%1E3?" and ":" ")+q(Q%1E6)):Q.toString()}function f(Q){return 1===Q%10&&11!==Q%100?"st":2===Q%10&&12!==Q%100?"nd":3===Q%10&&13!==Q%100?"rd":"th"}function I(Q,ja,D){function C(U,O){var Z="";U=U.charCodeAt(0);
for(var aa=0;aa<O;U++,aa++)Z+=String.fromCharCode(U);return Z}D=void 0===D?"FODF1310":D;var y,K=null;if(-1!==(y=ja.lastIndexOf(";"))){K=ja.substring(y+1);if(!/^([co](\(.+\))?)?[at]?$/.test(K))throw new J("Invalid format modifier in picture string: "+K,D);K=K.charAt(0);ja=ja.substring(0,y)}if(""===ja)throw new J("In the picture string, the picture cannot be empty",D);y=0>Q?"-":"";if("o"===K)switch(ja.toString()){case "a":case "A":case "i":case "I":break;case "Ww":return y+q(Math.abs(Q));case "W":return y+
q(Math.abs(Q)).toUpperCase();case "w":return y+q(Math.abs(Q)).toLowerCase();default:return v(Q,ja,D)+f(Math.abs(Q))}switch(ja.toString()){case "a":return z(Q,C("a",26));case "A":return z(Q,C("A",26));case "\u03b1":return z(Q,C("\u03b1",25));case "\u0391":return z(Q,C("\u0391",25));case "i":return u(Q);case "I":return u(Q).toUpperCase();case "Ww":return y+B(Math.abs(Q));case "W":return y+B(Math.abs(Q)).toUpperCase();case "w":return y+B(Math.abs(Q)).toLowerCase();case "\u2460":return n(Q,"\u24ea"+C("\u2460",
20)+C("\u3251",15)+C("\u32b1",15));case "\u2474":return n(Q,"0"+C("\u2474",20));case "\u2488":return 0===Q?"\ud83c\udd00":n(Q,"0"+C("\u2488",20));case "\u2776":return n(Q,"\u24ff"+C("\u2776",10)+C("\u24eb",10));case "\u2780":return 0===Q?"\ud83c\udd0b":n(Q,"0"+C("\u2780",10));case "\u278a":return 0===Q?"\ud83c\udd0c":n(Q,"0"+C("\u278a",10));case "\u24f5":return n(Q,"0"+C("\u24f5",10));case "\u3220":return n(Q,"0"+C("\u3220",10));case "\u3280":return n(Q,"0"+C("\u3280",10));default:return v(Q,ja,D)}}
function d(Q,ja){return String.fromCharCode(parseInt(Q[ja]?Q[ja]:X[ja],10))}function c(Q,ja){function D(xa){[["$gs","grouping separator"],["$es","exponent separator"],["$ds","decimal separator"],["$pc","non-prefix passive character"],["$pp","percent|permille"],["$od","optional digit"],["$md","mandatory digit"],["$ip","integer part"],["$fp","fractional part"]].forEach(function(m){xa=xa.replace(m[0],m[1])});throw new J("Invalid picture: "+xa,"FODF1310");}for(var C={},y=Q.indexOf(ja.jf),K=0<=y,U=ja.Nf.charCodeAt(0),
O=0,Z=0,aa=[],ca=[],oa=[],ea=-1,na=-1,x=!1,F=!1,P=-1,V=0,R=K?y:Q.length,w=0;w<R;w++){var Y=Q.charCodeAt(w);Y>=U&&Y<U+10?(0<na&&D("digit follows $pc"),x?V++:(1>O&&0>ea&&(ea=w),O++,Z++)):Y===ja.eh.charCodeAt(0)?(0<na&&D("digit follows $pc"),x&&D("$od follows $es"),0<O&&D("$od follows $md in $ip"),0>ea&&(ea=w,F=!0),Z++):Y===ja.xe.charCodeAt(0)?(0<oa.length&&D("$gs follows $pc"),x&&D("$gs follows $es"),aa.push(w)):Y===ja.zd.charCodeAt(0)||Y===ja.Ie.charCodeAt(0)?K?D("$pp before $ds"):(0>ea&&D("$pp before any digit"),
0<na&&D("$pp follows $pc"),x&&D("$pp follows $es"),oa.push(Y),0>na&&(na=w),Y===ja.zd.charCodeAt(0)?C.zd=!0:C.Ie=!0):!K&&0<=ea&&Y===ja.Kc.charCodeAt(0)&&0>na?x?(oa.push(Y),0<V?0>na&&(na=w):(oa.push(Y),x=!1,P=-1,0>na&&(na=w-1))):(x=!0,P=w):0<=ea&&K?D("$pc before $ds"):x&&0===V?(x=!1,P=-1,oa.push(ja.Kc.charCodeAt(0)),oa.push(Y),0>na&&(na=w-1)):0<=ea?(oa.push(Y),0>na&&(na=w)):ca.push(Y)}if(0<aa.length){var ia=K?y:x?P:0<na?na:Q.length;aa=aa.map(function(xa){return ia-xa-1}).reverse()}if(0!==aa.length)for(0===
aa[0]&&D("$gs at end of $ip"),P=0;P<aa.length-1;P++)aa[P]+1===aa[P+1]&&D("consecutive $gs in $ip");w=R=0;Y=[];if(K){K=-1;Q=Q.substring(y+1);for(var T=0;T<Q.length;T++){var fa=Q.charCodeAt(T);fa>=U&&fa<U+10?x&&0>na?V++:0<=K||0<=na?D("$md follows $od or $pc in $fp"):(R++,w++):fa===ja.eh.charCodeAt(0)?((0<=na||x)&&D("$od follows $pc or $es"),0>K&&(K=T),w++):fa===ja.xe.charCodeAt(0)?((0<=na||x)&&D("$gs follows $pc or $es"),Y.push(T-y+1)):fa===ja.jf.charCodeAt(0)?D("more than one $ds"):fa===ja.zd.charCodeAt(0)||
fa===ja.Ie.charCodeAt(0)?((0<=na||x)&&D("$pp follows $pc or $es"),oa.push(fa),na=T,fa===ja.zd.charCodeAt(0)?C.zd=!0:C.Ie=!0):fa===ja.Kc.charCodeAt(0)&&0>na?x?(oa.push(fa),0<V?0>na&&(na=T):(oa.push(fa),x=!1,P=-1,0>na&&(na=T-1))):(x=!0,P=T):x&&0===V?(x=!1,P=-1,oa.push(ja.Kc.charCodeAt(0)),oa.push(fa),0>na&&(na=T-1)):(oa.push(fa),0>na&&(na=T))}}0>ea&&0===w&&D("mantissa contains no digits");if(0!==Y.length)for(0===Y[0]&&D("$gs at beginning of $fp"),y=0;y<Y.length-1;y++)Y[y]+1===Y[y+1]&&D("consecutive $gs in $fp");
0===O&&0===w&&(x?w=R=1:O=1);x&&0===O&&F&&(O=1);0===O&&0===R&&(R=1);C.yf=O;C.prefix=ca;C.Xb=aa;C.Ph=F;C.Mj=Z;C.De=R;C.Ce=w;C.og=Y;C.nh=V;C.Kc=ja.Kc?ja.Kc:"e";C.ce=oa;return C}function l(Q){return Math.floor((Q.getTime()-(new Date(Date.UTC(Q.getUTCFullYear(),0,1))).getTime())/864E5)+1}function t(Q,ja){var D=ja?l(Q):Q.getUTCDate();Q=new Date(Date.UTC(Q.getUTCFullYear(),ja?0:Q.getUTCMonth(),1));var C=Q.getUTCDay();0===C&&(C=7);return 4<C&&8>=C+D?t(new Date(Q.getTime()-864E5),ja):Math.floor((D+C-2)/7)+
(5>C?1:0)}var r=Kc,A=r.Ka,M=Ab.XS,X={"decimal-separator":"46","grouping-separator":"44",digit:"35","minus-sign":"45",percent:"37","per-mille":"8240","zero-digit":"48","exponent-separator":"101","pattern-separator":"59",infinity:"Infinity",NaN:"NaN"},ka={Y:"getUTCFullYear",M:"getUTCMonth",D:"getUTCDate",F:"getUTCDay",H:"getUTCHours",m:"getUTCMinutes",s:"getUTCSeconds",f:"getUTCMilliseconds"},qa={d:l,W:function(Q){return t(Q,!0)},w:function(Q){return t(Q,!1)},h:function(Q){return 13>Q.getUTCHours()?
0===Q.getUTCHours()?12:Q.getUTCHours():Q.getUTCHours()-12},P:function(Q){return 12>Q.getUTCHours()?"Am":"Pm"},E:function(Q){return 0>Q.getUTCFullYear()?"BC":"AD"}};return{Hj:function(Q,ja){var D=ja.fixed.ka,C=Q.level,y=D.ea(Q,"select"),K=D.ea(Q,"count");Q=D.ea(Q,"from");return a(C,y,K,Q,ja)},Ij:function(Q,ja){var D=ja.fixed.ka,C=D.ea(Q,"value"),y=D.ea(Q,"format"),K=D.xc(D.ea(Q,"gpSep"),ja).next(),U=D.xc(D.ea(Q,"gpSize"),ja).next(),O=D.xc(D.ea(Q,"ordinal"),ja).next(),Z=D.xc(D.ea(Q,"lang"),ja).next(),
aa=Q.flags||"";Q=D.ea(Q,"startAt");aa=/1/.test(aa);D=D.evaluate(C,ja);D=h(D,aa,Q,!0,ja);O=O?Nd.xj(O.toString()):!1;return null===D?Cc("NaN"):b(D,y,K,U,O,Z,ja)},ng:I,$f:function(Q,ja,D){for(var C="",y=0;y<Q.length;y++)C+=Q.charAt(y),(1===D||1===(Q.length-y)%D)&&y<Q.length-1&&(C+=ja);return C},Wi:function(Q,ja,D){var C=0>Q?!0:0===Q?0>1/Q:!1;D={jf:d(D,"decimal-separator"),xe:d(D,"grouping-separator"),eh:d(D,"digit"),Ej:d(D,"minus-sign"),zd:d(D,"percent"),Ie:d(D,"per-mille"),Nf:d(D,"zero-digit"),Kc:d(D,
"exponent-separator"),Oh:d(D,"pattern-separator"),jj:D.infinity||X.infinity,Fj:D.NaN||X.NaN};if(isNaN(Q))return D.Fj;ja=ja.includes(D.Oh)?ja.split(D.Oh):[ja];if(2<ja.length)throw new J("Invalid picture: multiple pattern separators","FODF1310");var y=c(1<ja.length&&0>Q?ja[1]:ja[0],D);if(isFinite(Q)){if(0<y.nh){var K=y.yf;var U=y.De;var O=y.Ce;var Z=y.Kc,aa=1===K&&y.Ph;aa&&(K=0,0===U&&(U=1,0===O&&(O=1)));var ca=y.nh;Q=kb(Q);var oa=Q.c,ea=0!==K?K+O:O,na=Q.e;oa.length>ea&&(Q=kb(Q.toPrecision(ea)));(ea=
na!==Q.e)&&(K+=1);1<K?Q=Q.Ub(kb(10).pow(K-1)):0===K&&(Q=Q.Ic(kb(10)));U=0!==K?K+U:U;for(oa=Q.c;oa.length<U;)oa.push(0);U="";if(0<oa.length){if(0===K&&y.Ph)U+="0";else for(na=0;na<K;na++)U+=oa[na];!(0<O&&oa.length>K)||aa&&ea||(U+="."+oa.join("").slice(K))}else throw new J("Empty mantissa","SXJS0004");K=U+Z+g(Q.e,ca)}else{O=Math.abs(Q);y.zd&&(O=Math.abs(100*Q));y.Ie&&(O=Math.abs(1E3*Q));"Infinity"===O+""?K=O+"":K=999999>O?O.toString():kb(O).toFixed();O=K.indexOf(".");0>O&&(O=K.length,0<y.De&&(K+="."));
0<=y.Ce&&"."!==K.substring(O)&&O!=K.length&&(0<y.Ce?(K=kb(K).round(y.Ce,1).toString(),0>K.indexOf(".")&&(K+=".0")):K=kb(K).round(0,1).toString());Z=K.length-1-O;if(0<y.De)for(;Z<y.De;)K+="0",Z++;for(;O<y.yf;)K="0"+K,O++;0<Z&&0===y.yf&&"0"===K.substring(0,O)&&(K=K.substring(O));"0"===K&&0===y.yf&&0===y.De&&0<y.Ce&&(K=".0")}O="";for(Z=0;10>Z;Z++)O+=String.fromCharCode(D.Nf.charCodeAt(0)+Z);O+=D.jf;Z="";for(aa=0;aa<K.length;aa++)ca=K.charAt(aa),Q="0123456789.".indexOf(ca),0>Q?Z+=ca:Q<O.length&&(Z+=O.charAt(Q));
O=Z;if(0<y.Xb.length||0<y.og.length){Z=O.indexOf(D.jf);0>Z&&(Z=O.length);K=O.substring(0,Z);O=O.substring(Z);if(0<y.Xb.length){Z=!0;for(aa=1;aa<y.Xb.length;aa++)if(0!==(y.Xb[aa]+1)%(y.Xb[0]+1)){Z=!1;break}Z&&y.Mj+y.Xb.length-y.Xb[y.Xb.length-1]-1>y.Xb[0]&&(Z=!1);K=Z?Qe.$f(K,D.xe,y.Xb[0]):p(K,y.Xb,null,D.xe)}0<y.og.length&&(O=p(O,y.og,null,D.xe,!0));O=K+O}}else O=D.jj;0<y.prefix.length&&(O=wb(y.prefix)+O);0<y.ce.length&&(O+=wb(y.ce));C&&1===ja.length&&(O=D.Ej+O);return O},Rd:function(Q,ja,D,C,y){if(/\[/g.test(D)&&
!/\[(\[|[^\]]*\])|\]\]/g.test(D))throw new J("Date format picture '"+D+"' doesn't match required syntax","FOFD1340");var K="";"undefined"!==typeof C&&null!==C&&"en"!==C.toString()&&(K+="[Language: en]");"undefined"===typeof y||null===y||"AD"===y.toString()||/(Q\{\})?ISO/.test(y.toString())||(K+="[Calendar: AD]");return K+D.replace(/\[(\[|[^\]]*\])|\]\]/g,function(U){if("[["===U)U="[";else if("]]"===U)U="]";else{var O=U.substring(1,U.length-1).replace(/\s+/g,""),Z=O.match(/^([YMDdWwFHhmsfZzPCE])(.*)$/);
if(null===Z)throw new J("Date/time component ["+O+"] not recognised","FOFD1340");U=Z[1];Z=Z[2]||"";if("date"===Q&&/[HhPmsf]/.test(U)||"time"===Q&&/[YMDdFWwCE]/.test(U))throw new J("Date/time component ["+O+"] not available in "+Q,"FOFD1350");if(-1!==(O=Z.lastIndexOf(","))){var aa=Z.substring(O+1).split("-");Z=Z.substring(0,O);var ca=aa[0];aa=aa[1]||"*";if("*"!==ca&&1>ca||"*"!==aa&&1>aa||"*"!==ca&&"*"!==aa&&ca>aa)throw new J("Invalid width modifier in date/time formatting picture","FOFD1340");}var oa=
null;1<Z.length&&/[atco]/.test(Z.charAt(Z.length-1))?(O=Z.substring(0,Z.length-1),oa=Z.charAt(Z.length-1)):O=Z;Z=!0;if(!/^[\x01-\x7f]*$/.test(O)){if(O.length!==tb(O).length)throw new J("Cannot handle non-BMP characters in date/time formatting picture","SXJS0002");Z=!1}if(/[Zz]/.test(U))if(ca="Z"===Ob(ja)?"+00:00":Ob(ja),aa=Z,""===ca)U="Z"===O?"J":"";else if(U="z"===U?"GMT":"","t"===oa&&"+00:00"===ca&&"Z"!==O&&"N"!==O)U="Z";else if(""===O)U+=ca;else{Z=ca.match(/^([+\-])([0-9])([0-9]):([0-9]{2})$/);
if("Z"===O||"N"===O)if("Z"===O){aa=parseInt(Z[1]+Z[2]+Z[3],10);var ea=-12>aa||12<aa||"00"!==Z[4]?ca:"YXWVUTSRQPONZABCDEFGHIKLM".charAt(aa+12)}else{if("N"===O)throw new J("Timezone names not implemented","SXJS0002");}else aa&&!/[0-9]+/.test(O)&&(O="00:00"),ca=0,aa||(ca=e(O,"FOFD1340"),O=ca.format,ca=ca.jg),/[^0-9A-Za-z]/.test(O)?(ea=O.match(/^([0-9]+)([^0-9A-Za-z])([0-9]+)$/),ea=Z[1]+("0"===Z[2]&&1===ea[1].length?Z[3]:Z[2]+Z[3])+ea[2]+Z[4]):(ea=O.match(/^[0-9]+$/),ea=ea[0],ea=Z[1]+(("0"!==Z[2]||1!==
ea.length&&3!==ea.length?Z[2]+Z[3]:Z[3])+(2<ea.length?Z[4]:"00"!==Z[4]?":"+Z[4]:""))),aa||(ea=k(ea,ca));U+=ea}else{ea=ja.proxy();var na=ka[U]?ea[ka[U]]():qa[U]?qa[U](ea):null;if(null===na)throw new J("Component specifier not implemented: ["+U+"]","SXJS0002");"M"===U?na++:"F"===U&&0===na&&(na=7);""===O&&(O=/[FPCE]/.test(U)?"n":/[ms]/.test(U)?"01":"1");if("f"===U){oa=na;ea=O;U=ca;O=aa;aa=Z;ca=0;aa||(ca=e(ea,"FOFD1340"),ea=ca.format,ca=ca.jg);for(Z=oa.toString();3>Z.length;)Z="0"+Z;oa=tb(Z);Z=parseInt(wb(oa.reverse()),
10);if(!U&&!O&&/^[0-9]$/.test(ea))for(;oa.length>ea.length;)ea+="#";if(U&&"*"!==U){na=/^([0-9]*)(#*)$/.exec(ea);ea=na[1]?na[1]:"";for(na=na[2]?na[2]:"";U>ea.length;)ea+="0",na=na.slice(0,-1);ea+=na}if(O)for("*"===O&&(O=oa.length);ea.length<O;)ea+="#";O=wb(tb(ea).reverse());Z=I(Z,O,"FOFD1340");for(Z=wb(tb(Z).reverse());Z.length>ea.length;)Z=Z.slice(0,-1);U&&"*"!==U||0!==parseInt(Z,10)||(Z="0");aa||(Z=k(Z,ca));U=Z}else{if(/[nN]/.test(O))ca="January February March April May June July August September October November December".split(" "),
Z="Monday Tuesday Wednesday Thursday Friday Saturday Sunday".split(" "),oa="M"===U?ca[na-1]:"F"===U?Z[na-1]:na,"F"===U&&aa&&"*"!==aa&&6>aa&&(oa=oa.replace(/(ur)?day$/,""),oa=oa.replace(/nes$/,"s")),"n"===O?oa=oa.toLowerCase():"N"===O&&(oa=oa.toUpperCase()),aa&&"*"!==aa&&(oa=oa.substring(0,aa));else{ea=/;/.test(O)?O+";":O;oa&&(ea+=(";"===ea.charAt(ea.length-1)?"":";")+oa);oa=I(na,ea,"FOFD1340");if("Y"===U&&/[^iIwWnN]/.test(O.charAt(O.length-1))&&(!ca&&2===O.length||2==aa))for(;2<oa.length;)oa=oa.substring(1);
if(ca&&(aa="*"===ca?0:parseInt(ca,10),0<aa&&oa.length<aa))if(/[YMDdWwHhms]/.test(U)&&/[^iIwWnN]/.test(O.charAt(O.length-1)))for(U="0",Z||(U=String.fromCharCode(e(ea,"FOFD1340").Nf));oa.length<aa;)oa=U+oa;else if("Y"===U&&/[iIwWnN]/.test(O.charAt(O.length-1)))for(;oa.length<aa;)oa+=" ";else throw new J("Not implemented min width modification with specifier "+U+" and format "+O,"SXJS0002");}U=oa}}}return U})}}}();
// Input 34
var Re=function(){function a(){this.na=""}function b(x,F){x=l.call(this,x,F)||this;x.g=!1;return x}function h(x,F){this.b=null;this.g=x;this.B=F;this.pending=[];this.l=!1}function u(x,F,P){Be.call(this,P);this.B=x;this.b=F;this.ra=P;this.Aa=x["use-character-maps"]||null}function z(x,F,P){F=n.call(this,x,F,P)||this;F.g=0;F.ta=x["Q{http://saxon.sf.net/}indent-spaces"]||3;F.Ia=" ";return F}function n(x,F,P){Be.call(this,P);this.B=x;this.b=F;this.Aa=x["use-character-maps"]||null}function g(x){this.b=
null;this.l=x;this.g=!1}function e(){ze.apply(this,arguments)}function k(x,F){x=c.call(this,x,F)||this;x.ta="area base br col embed hr img input link meta param source track wbr".split(" ");return x}function p(){ze.apply(this,arguments)}function v(x,F){x=d.call(this,x,F)||this;x.cg="pre script style textarea title xmp".split(" ");x.qe="a abbr acronym applet area audio b basefont bdi bdo big br button canvas cite code data datalist del dfn em embed font i iframe img input ins kbd label map mark math meter noscript object output picture progress q ruby s samp script select small span strike strong sub sup svg template textarea time tt u var video wbr".split(" ");
x.tc=!1;x.ta=!1;x.pe=[];return x}function B(x,F){x=c.call(this,x,F)||this;x.ta=-1E6;x.df="area base br col embed hr img input link meta param source track wbr".split(" ");x.tc={"*":["hidden","autofocus","itemscope"],audio:["autoplay","controls","loop","muted"],button:["disabled","formnovalidate"],details:["open"],dialog:["open"],fieldset:["disabled"],form:["novalidate"],img:["ismap"],input:"checked disabled multiple readonly required formnovalidate".split(" "),iframe:["allowfullscreen","allowpaymentrequest"],
ol:["reversed"],optgroup:["disabled"],option:["selected","disabled"],script:["defer","async","nomodule"],select:["multiple","disabled","required"],textarea:["disabled","readonly","required"],track:["default"],video:["autoplay","controls","loop","muted","playsinline"]};return x}function q(x,F,P){this.b=null;this.ba=x||null;this.encoding=F;this.J=P;this.l=[];this.g="";this.B={};if("fully-normalized"===P)throw new J("Serializer does not support the requested normalization-form: fully-normalized","SESU0011");
}function f(x){this.b=null;this.Aa=x||null}function I(x){this.b=null;this.g=x;if(!String.prototype.normalize)throw new J("normalization-form: not supported on this JavaScript platform","SESU0011");if("fully-normalized"===x)throw new J("Serializer does not support the requested normalization-form: fully-normalized","SESU0011");}function d(x,F){this.b=null;this.B=0;this.fe=x||3;this.Ba="\n               ";this.l=this.J=this.ba=!1;this.Ia=F||null;this.g=-1;this.ya=null;this.qb={}}function c(x,F){F=l.call(this,
x,F)||this;F.qb=!1;F.ra=!1;F.ya=!1;F.cg=!1;F.B=!1;F.l=!1;F.Ia=[];F.J=[];F.pe=0;F.encoding=x.encoding||"UTF-8";return F}function l(x,F){this.b=F||null;this.Oa=x}function t(x,F,P){function V(w,Y){function ia(xa){return function(m){return xa[m]}}var T={"<":"&lt;",">":"&gt;","&":"&amp;",'"':"&#34;","\n":"&#10;","\r":"&#13;","\t":"&#9;"},fa={"<":"&lt;",">":"&gt;","&":"&amp;"};return Y?w.replace(/&|<|>|"|\n|\r|\t/g,ia(T)):w.replace(/&|<|>/g,ia(fa))}if(P){P="";x=x.split("\x00");for(var R=0;R<x.length;R++)P+=
1===R%2?x[R]:V(x[R],F);return P}return V(x,F)}function r(x,F){return"ISO-8859-1"===F.toUpperCase()?255>=x:"US-ASCII"===F.toUpperCase()?127>=x:!0}function A(x,F){return function(P){for(var V=!0,R=0;R<P.length;R++){var w=P.charCodeAt(R);if(w>=x.min&&w<=x.max){V=!1;break}}if(V)return P;V="";R=!1;for(w=0;w<P.length;w++){var Y=P.charAt(w),ia=P.charCodeAt(w),T=55296<=ia&&56320>=ia;T&&(ia=P.codePointAt(w));x.Aa[ia]?(F&&!R&&(V+="\x00",R=!0),V+=x.Aa[ia],T&&w++):(F&&R&&(V+="\x00",R=!1),V+=Y)}return V}}function M(x){return""===
x.uri||"http://www.w3.org/1999/xhtml"===x.uri}function X(x){return["http://www.w3.org/1999/xhtml","http://www.w3.org/2000/svg","http://www.w3.org/1998/Math/MathML"].includes(x)}function ka(x,F){return(F={action:["form"],archive:["object"],background:["body"],cite:["blockquote","del","ins","q"],classid:["object"],codebase:["applet","object"],data:["object"],datasrc:"button div input object select span table textarea".split(" "),"for":["script"],formaction:["button","input"],href:["a","area","base",
"link"],icon:["command"],longdesc:["frame","iframe","img"],manifest:["html"],name:["a"],poster:["video"],profile:["head"],src:"audio embed frame iframe img input script source track video".split(" "),usemap:["img","input","object"]}[F.local])&&F.includes(x.local)}function qa(x,F,P){P=void 0===P?null:P;switch(x.method){case "xml":F=new c(x,F);if(x.version&&"1.0"!==x.version)throw new J("Serializer does not support the requested XML version: "+x.version,"SESU0013");ca(x);if(x["undeclare-prefixes"])throw new J("Cannot undeclare prefixes with XML 1.0 serializer",
"SEPM0010");x.indent&&(F=K(x,F));F=U(x,F);F=O(x,F);F=Z(x,F);return y(x,F);case "html":return oa(x,F);case "text":return ca(x),F=new b(x,F),F=U(x,F),F=O(x,F),y(x,F);case "xhtml":return oa(x,F);case "json":return ca(x),x.indent?new z(x,F,P):new n(x,F,P);case "adaptive":return new u(x,F,P);default:return new h(x,F)}}function Q(x,F,P){function V(fa,xa){xa=xa.trim();if(["yes","true","1"].includes(xa))return!0;if(["no","false","0"].includes(xa))return!1;throw new J("Value of "+fa+" must be yes(=true, 1) or no(=false, 0)",
"SEPM0016");}function R(fa){return function(xa,m){m=m.trim();return m.split(/\s+/).map(function(G){/^Q\{/.test(G)||/:/.test(G)||(G="Q{}"+G);return/^Q\{/.test(G)?G:na.QName.fromString(G,fa).$()})}}function w(fa){return function(xa,m){m=m.trim();if(fa.includes(m))return m;throw new J("Value of "+xa+"("+m+"): must be one of "+fa.join("|"),"SEPM0016");}}function Y(fa){return function(xa,m){if(fa.test(m))return m;throw new J("Invalid value for "+xa+": must match the pattern "+fa,"SEPM0016");}}function ia(fa,
xa){return xa}function T(fa,xa){xa=xa.trim();if(/[0-9]+/.test(xa))return 1*xa;throw new J("Value of "+fa+" must be an integer (Actual value is "+ib.showValue(xa)+")","SEPM0016");}P={"allow-duplicate-names":V,"build-tree":V,"byte-order-mark":V,"cdata-section-elements":R(P),"doctype-public":Y(/[\sa-zA-Z0-9\-'()+,.:=?;!*#@$_%\/]*/),"doctype-system":Y(/[^']*|[^"]*/),encoding:Y(/[\x21-\x7e]+/),"escape-uri-attributes":V,"html-version":function(fa,xa){if(/[0-9]+(\.[0-9]+)?/.test(xa))return 1*xa;throw new J("Value of "+
fa+" must be a decimal number","SEPM0016");},"include-content-type":V,indent:V,"item-separator":ia,"json-node-output-method":w(["xml","xhtml","html","text"]),"media-type":ia,method:function(fa,xa){return function(m,G){G=G.trim();!/^Q\{/.test(G)&&/:/.test(G)&&(G=na.QName.fromString(G,xa).$());if(fa.includes(G))return G;throw new J("Value of "+m+"("+G+"): must be one of "+fa.join("|"),"SEPM0016");}}("xml xhtml html text json adaptive Q{http://saxonica.com/ns/interactiveXSLT}append-content Q{http://saxonica.com/ns/interactiveXSLT}replace-content".split(" "),
P),"normalization-form":w("NFC NFD NFKC NFKD fully-normalized none".split(" ")),"omit-xml-declaration":V,standalone:function(fa,xa){return"omit"===xa.trim()?null:V(fa,xa)},"suppress-indentation":R(P),"undeclare-prefixes":V,"use-character-maps":R(P),version:ia,"Q{http://saxon.sf.net/}indent-spaces":T,"Q{http://saxon.sf.net/}line-length":T,"Q{http://saxon.sf.net/}stylesheet-version":T};/^\{/.test(x)&&(x="Q"+x);if("string"!==typeof F)throw new J("Value of "+x+" must be supplied as a string","SEPM0016");
if(P[x])return P[x](x,F);if(!/^Q\{/.test(x))throw new J("Unknown serialization parameter "+x,"SEPM0017");}function ja(x,F,P){function V(ia,T,fa){if(!Array.isArray(T))throw new J("Value of "+ia+" must be an array","SEPM0016");return(fa||[]).concat(T)}function R(ia,T){if("boolean"!==typeof T)throw new J("Value of "+ia+" must be boolean","SEPM0016");return T}function w(ia,T){if("number"!==typeof T)throw new J("Value of "+ia+" must be an integer (actual value is "+ib.showValue(T)+")","SEPM0016");return T}
var Y={"allow-duplicate-names":R,"build-tree":R,"byte-order-mark":R,"cdata-section-elements":V,"escape-uri-attributes":R,"html-version":function(ia,T){if("number"!==typeof T)throw new J("Value of "+ia+" must be a decimal number","SEPM0016");return T},"include-content-type":R,indent:R,"omit-xml-declaration":R,standalone:function(ia,T){return null===T?null:R(ia,T)},"suppress-indentation":V,"undeclare-prefixes":R,"use-character-maps":function(ia,T,fa){fa&&Array.isArray(fa)&&ib.la("Problem with existing character map supplied to overrideParam");
if("object"!==typeof T)throw new J("Value of "+ia+" must be an object","SEPM0016");if(void 0===T.Aa)throw new J("Value of "+ia+" must be a character map object","SEPM0016");(ia=fa)?D(ia,T):ia=T;return ia},"Q{http://saxon.sf.net/}indent-spaces":w,"Q{http://saxon.sf.net/}line-length":w,"Q{http://saxon.sf.net/}stylesheet-version":w};/^{/.test(x)&&(x="Q"+x);return Y[x]?Y[x](x,F,P):Q(x,F.toString())}function D(x,F){F.min<x.min&&(x.min=F.min);F.max>x.max&&(x.max=F.max);for(var P in F.Aa)F.Aa.hasOwnProperty(P)&&
(x.Aa[P]=F.Aa[P])}function C(x,F){var P={};x.forEach(function(V,R){var w=F.Zg[V];if(!w)throw new J("Unknown character map "+V,"SEPM0016");0===R?P=w:D(P,w)});return P}function y(x,F){return x["item-separator"]&&"#absent"!==x["item-separator"]?new Le(F,x["item-separator"]):new He(F)}function K(x,F){var P=x["Q{http://saxon.sf.net/}indent-spaces"],V=x["suppress-indentation"];return Ae("xml"===x.method?new d(P,V):new v(P,V),F)}function U(x,F){x["normalization-form"]&&"none"!==x["normalization-form"]&&
(F=Ae(new I(x["normalization-form"]),F));return F}function O(x,F){x["use-character-maps"]&&(F=Ae(new f(x["use-character-maps"]),F));return F}function Z(x,F){x["cdata-section-elements"]&&(F=Ae(new q(x["cdata-section-elements"],x.encoding||"UTF-8",x["normalization-form"]&&"none"!==x["normalization-form"]?x["normalization-form"]:null),F));return F}function aa(x,F){return void 0===x[F]||x[F]}function ca(x){if(x.encoding&&!["UTF-8","UTF-16","ISO-646","ISO-8859-1","US-ASCII"].includes(x.encoding.toUpperCase()))throw new J("Serializer does not support the requested encoding: "+
x.encoding,"SESU0007");}function oa(x,F){var P="xhtml"===x.method;F=P?new k(x,F):new B(x,F);if(P&&x.version&&"1.0"!==x.version)throw new J("Serializer does not support the requested XML version: "+x.version,"SESU0013");if((P=P?x["html-version"]:x["html-version"]||x.version)&&5!==1*P)throw new J("Serializer does not support the requested HTML version: "+P,"SESU0013");ca(x);aa(x,"indent")&&(F=K(x,F));F=U(x,F);F=O(x,F);F=Z(x,F);F=Ae(new p,F);aa(x,"escape-uri-attributes")&&(F=Ae(new e,F));aa(x,"include-content-type")&&
(F=Ae(new g(x),F));return y(x,F)}function ea(x,F,P,V){function R(T,fa){switch(T.nodeType){case 9:case 11:fa.Ja(ia);T=T.childNodes;for(var xa=0;xa<T.length;++xa)R(T[xa],fa);fa.Ca();break;case 1:var m={};Kc.Ka.wb(T).forEachItem(function(N){m[N.prefix]=N.uri});var G=new Ne;Kc.Ka.La(T).forEachItem(function(N){G.set(N.Hc,{value:N.value,lb:{}})});fa.oa(Kc.da(T),G,m,ia);T=T.childNodes;for(xa=0;xa<T.length;++xa)R(T[xa],fa);fa.va();break;case 2:if(fa instanceof u)fa.text(Kc.da(T)+'="'+T.value+'"',ia);else throw new J("Cannot serialize free-standing attribute node",
"SENR0001");break;case 13:if(fa instanceof u)xa=T.prefix,fa.text(xa?"xmlns:"+xa:'xmlns="'+T.uri+'"',ia);else throw new J("Cannot serialize free-standing namespace node","SENR0001");break;case 3:case 4:fa.text(T.nodeValue,ia);break;case 7:fa.xa(T.target,T.nodeValue,ia);break;case 8:fa.za(T.nodeValue,ia)}}function w(T,fa){Array.isArray(T)?T.forEach(function(xa){w(xa,fa)}):Kc.X(T)?R(T,fa):fa.append(T,ia)}V=void 0===V?null:V;if(!P){var Y="";P={write:function(T){Y+=T}}}P.write||ib.la("writer supplied to Serialize.serialize() is not a writeable stream");
F||(F={});F=qa(F,P,V);var ia={};x&&w(x,F);F.close();return Y}var na=Ab.XS;Ka(l,ye);l.prototype.close=function(){this.b.end&&!this.b.isTTY&&this.b.end()};Ka(c,l);c.prototype.Ja=function(){this.cg=!0};c.prototype.Ca=function(){};c.prototype.close=function(){this.B||this.U();l.prototype.close.call(this)};c.prototype.oa=function(x,F,P){function V(w){throw new J("When "+w+" is specified, there must only be one top-level element node","SEPM0004");}this.B||(this.U(),this.Ba(x),this.ra&&!this.ya&&!0===this.Oa.indent&&
this.b.write("\n"),this.B=!0);0===this.J.length&&(0<this.pe++&&void 0!==this.Oa.standalone&&null!==this.Oa.standalone&&V("standalone"),1<this.pe&&this.Oa["doctype-system"]&&V("doctype-system"));var R=0===this.J.length?{}:this.J[this.J.length-1];this.Ia.push(x);this.J.push(P);this.l&&this.ba();this.b.write("<"+this.g(x.toString(),!1));this.Ti(P,R);this.qe(x,F);this.l=!0};c.prototype.va=function(){var x=this.Ia.pop();this.J.pop();this.l?(this.b.write("/>"),this.l=!1):this.b.write("</"+x.toString()+
">")};c.prototype.text=function(x,F){F=void 0===F?{}:F;this.B||this.U();this.l&&this.ba();this.b.write(F.DISABLE_ESCAPING?this.g(x,!1):this.g(t(x,!1,!1),!0))};c.prototype.za=function(x){this.B||this.U();this.l&&this.ba();this.b.write("\x3c!--"+this.g(x,!1)+"--\x3e")};c.prototype.xa=function(x,F){this.B||this.U();this.l&&this.ba();this.b.write("<?"+this.g(x,!1)+(0<F.length?" "+this.g(F,!1):"")+"?>")};c.prototype.append=function(x,F){F=void 0===F?{}:F;this.text(x.toString(),F)};c.prototype.U=function(){this.qb||
this.Ii()};c.prototype.ba=function(){this.b.write(">");this.l=!1};c.prototype.Ti=function(x,F){var P=this,V=[],R;for(R in x)if(F[R]!==x[R]){var w="";"xml"!==R&&(w=""===R?' xmlns="'+t(x[R],!0,!1)+'"':" xmlns:"+R+'="'+t(x[R],!0,!1)+'"');""!==w&&V.push(w)}V.sort();V.forEach(function(Y){return P.b.write(Y)})};c.prototype.qe=function(x,F){if(F){var P=this;F.forEach(function(V,R){P.fe(R,V)})}};c.prototype.fe=function(x,F){var P=!0===F.lb.USE_NULL_MARKERS;F=F.lb.DISABLE_ESCAPING?this.g(F.value,!1):this.g(t(F.value,
!0,P),!0);this.b.write(" "+this.g(x.toString(),!1)+'="'+F+'"')};c.prototype.Ii=function(){this.Oa["byte-order-mark"]&&"UTF-8"===this.Oa.encoding&&this.b.write("\ufeff");this.qb=!0;var x=!0===this.Oa["omit-xml-declaration"];this.ra=!x;var F=this.Oa.standalone;if(x&&void 0!==F&&null!==F)throw new J("Serializer values of 'standalone' and 'omit-xml-declaration' conflict","SEPM0009");x||this.b.write('<?xml version="1.0" encoding="'+this.encoding+'"'+(void 0!==F&&null!==F?' standalone="'+(F?"yes":"no")+
'"':"")+"?>")};c.prototype.Ba=function(x){var F=this.Oa["doctype-system"]||null,P=this.Oa["doctype-public"]||null;null!==F&&(this.ra&&this.b.write("\n"),this.b.write("<!DOCTYPE "+this.g(x.toString(),!1)+"\n"),x=F.includes('"')?"'"+F+"'":'"'+F+'"',null===P?this.b.write(" SYSTEM "+this.g(x,!1)+">\n"):this.b.write(' PUBLIC "'+this.g(P,!1)+'" '+this.g(x,!1)+">\n"),this.ya=!0)};c.prototype.g=function(x,F){function P(R){if(F)return"&#"+R+";";throw new J("Character "+String.fromCodePoint(R)+" (decimal "+
R+") is not available in the chosen encoding","SERE0008");}var V=this.encoding;return function(R){var w="",Y=0,ia=R.length;if(0===ia)return"";for(;Y<ia;){for(var T=Y;T<ia;){var fa=R.charCodeAt(T);if(9===fa||10===fa)T++;else if(32>fa)break;else if(127>fa)T++;else if(160>fa||8232===fa||55296<=fa&&56320>=fa||!r(fa,V))break;else T++}if(T>=ia)return 0===Y?R:w+R.substring(Y,T);T>Y&&(w+=R.substring(Y,T));fa=R.charCodeAt(T);Y=R.charAt(T);if(0===fa)throw new J("Null marker","ZZZZ9999");160>fa||8232===fa?w+=
F?P(fa):Y:55296<=fa&&56320>=fa?(fa=R.codePointAt(T++),w+=r(fa,V)?Y+R.charAt(T):P(fa)):r(fa,V)||(w+=P(fa));Y=++T;if(Y>=ia)return w}}(x)};Ka(d,ze);d.prototype.oa=function(x,F,P,V){V=void 0===V?{}:V;this.J||this.l?this.ra():this.U();if(0>this.g){var R=F.get(na.QName.fromParts("","http://www.w3.org/XML/1998/namespace","space"));R&&"preserve"===R.value&&(this.g=this.B)}this.B++;this.Ia&&-1===this.g&&0<=this.Ia.indexOf(x.$())&&(this.g=this.B);this.b.oa(x,F,P,V);this.J=this.ba=!0;this.l=!1};d.prototype.va=
function(){this.B--;this.l&&!this.ba?this.ra():this.J&&!this.l?this.ya=null:this.U();this.b.va();this.ba=!1;this.l=!0;this.J=!1;this.B===this.g-1&&(this.g=-1)};d.prototype.text=function(x,F){F=void 0===F?{}:F;0>this.g&&""===xb.trim(x)?(this.ya=x,this.qb=F):(this.U(),0<=x.indexOf("\n")&&(this.ba=!1),this.b.text(x,F),this.l=this.J=!1)};d.prototype.za=function(x,F){F=void 0===F?{}:F;this.l?this.ra():this.U();this.b.za(x,F);this.l=this.J=!1};d.prototype.xa=function(x,F,P){P=void 0===P?{}:P;this.l?this.ra():
this.U();this.b.xa(x,F,P);this.l=this.J=!1};d.prototype.ra=function(){0<=this.g?this.U():this.df()};d.prototype.df=function(){this.ya=null;var x=this.B*this.fe;if(x+1>this.Ba.length){var F=5*this.fe;x+1>this.Ba.length+F&&(F+=x+1);for(var P=0;P<F;P++)this.Ba+=" "}this.b.text(this.Ba.substring(0,x+1),{DISABLE_ESCAPING:!0});this.ba=!1};d.prototype.U=function(){this.ya&&(this.b.text(this.ya,this.qb),this.ya=null,this.qb={})};Ka(I,ze);I.prototype.oa=function(x,F,P,V){V=void 0===V?{}:V;var R=new Ne,w=this.g;
F.forEach(function(Y,ia){var T="";if(Y.lb.USE_NULL_MARKERS)for(var fa=Y.value.split("\x00"),xa=0;xa<fa.length;xa++)T+=1===xa%2?fa[xa]:fa[xa].normalize(w),T+="\x00";else T=Y.lb.NO_NORMALIZATION?Y.value:Y.value.normalize(w);R.set(ia,{value:T,lb:Y.lb})});this.b.oa(x,R,P,V)};I.prototype.text=function(x,F){F=void 0===F?{}:F;this.b.text(F.NO_NORMALIZATION?x:x.normalize(this.g),F)};Ka(f,ze);f.prototype.oa=function(x,F,P,V){function R(ia){var T=ia.value,fa=ia.lb;if(fa.DISABLE_ESCAPING||fa.NO_CHARACTER_MAP)return{value:T,
lb:fa};ia=w(T);return ia!==T?(T=Object.assign({},fa),T.USE_NULL_MARKERS=!0,{value:ia,lb:T}):{value:ia,lb:fa}}V=void 0===V?{}:V;var w=A(this.Aa,!0),Y=new Ne;F.forEach(function(ia,T){Y.set(T,R(ia))});this.b.oa(x,Y,P,V)};f.prototype.text=function(x,F){F=void 0===F?{}:F;if(F.DISABLE_ESCAPING||F.NO_CHARACTER_MAP)this.b.text(x,F);else{for(var P=this.Aa,V="",R=!1,w=!0,Y=0;Y<x.length;Y++){var ia=x.charCodeAt(Y);if(ia>=P.min&&ia<=P.max){w=!1;break}}if(w)V=x;else for(w=0;w<x.length;w++){Y=x.charAt(w);ia=x.charCodeAt(w);
var T=55296<=ia&&56320>=ia;T&&(ia=x.codePointAt(w));P.Aa[ia]?(R||(""!==V&&(this.b.text(V,F),V=""),R=!0),V+=P.Aa[ia],T&&w++):(R&&(R=Object.assign({},F),R.DISABLE_ESCAPING=!0,R.NO_NORMALIZATION=!0,this.b.text(V,R),V="",R=!1),V+=Y)}R?(x=Object.assign({},F),x.DISABLE_ESCAPING=!0,x.NO_NORMALIZATION=!0,this.b.text(V,x)):this.b.text(V,F)}};Ka(q,ze);q.prototype.oa=function(x,F,P,V){V=void 0===V?{}:V;this.flush();this.l.push(x.$());this.b.oa(x,F,P,V)};q.prototype.va=function(){this.flush();this.l.pop();this.b.va()};
q.prototype.text=function(x,F){F=void 0===F?{}:F;0<=this.ba.indexOf(0===this.l.length?null:this.l[this.l.length-1])?F.DISABLE_ESCAPING?(this.flush(),this.b.text(x,F)):(this.g+=x,this.B=F):this.b.text(x,F)};q.prototype.za=function(x,F){F=void 0===F?{}:F;this.flush();this.b.za(x,F)};q.prototype.xa=function(x,F,P){P=void 0===P?{}:P;this.flush();this.b.xa(x,F,P)};q.prototype.flush=function(){if(""!==this.g){for(var x=this.J?this.g.normalize(this.J):this.g,F=x.length,P=0;P<F;){for(var V=P;V<F;)if(r(x.charCodeAt(V),
this.encoding))V++;else break;V>P&&this.U(x.substring(P,V));V<F&&(P=Object.assign({},this.B),P.DISABLE_ESCAPING=!1,P.NO_CHARACTER_MAP=!0,P.NO_NORMALIZATION=!0,this.b.text(x.charAt(V),P));P=++V}this.g="";this.B={}}};q.prototype.U=function(x){var F=Object.assign({},this.B);F.DISABLE_ESCAPING=!0;F.NO_CHARACTER_MAP=!0;F.NO_NORMALIZATION=!0;this.b.text("<![CDATA[",F);for(var P=0,V=x.length;P<V;){var R=x.substring(P,V).indexOf("]]\x3e");0<=R?(this.b.text(x.substring(P,R+2)+"]]\x3e<![CDATA[",F),P=R+2):(this.b.text(x.substring(P,
V)+"]]\x3e",F),P=V)}};Ka(B,c);B.prototype.oa=function(x,F,P,V){V=void 0===V?{}:V;c.prototype.oa.call(this,x,F,P,V);x.sd("")&&["script","style"].includes(x.local.toLowerCase())&&(this.ta=0);this.ta++};B.prototype.va=function(){var x=this.Ia.pop();this.ta--;0===this.ta&&(this.ta=-1E6);this.J.pop();this.l&&(this.b.write(">"),this.l=!1);this.df.includes(x.local.toLowerCase())&&M(x)||this.b.write("</"+x.toString()+">")};B.prototype.text=function(x,F){F=void 0===F?{}:F;0<this.ta&&!F.DISABLE_ESCAPING?(F=
Object.assign({},F),F.DISABLE_ESCAPING=!0,c.prototype.text.call(this,x,F)):c.prototype.text.call(this,x,F)};B.prototype.xa=function(x,F){this.B||this.U();this.l&&this.ba();if(0<=F.indexOf(">"))throw new J("A processing instruction in HTML must not contain a > character","SERE0015");this.b.write("<?"+this.g(x,!1)+" "+this.g(F,!1)+">")};B.prototype.U=function(){this.qb||(this.Oa["byte-order-mark"]&&"UTF-8"===this.Oa.encoding&&this.b.write("\ufeff"),this.qb=!0)};B.prototype.qe=function(x,F){var P=this;
if(F){var V=this;F.forEach(function(R,w){x.sd("")&&P.Gi(x,w,R.value)?V.b.write(" "+w.toString()):V.fe(w,R)})}};B.prototype.Ba=function(x){var F=this.Oa["doctype-system"]||null,P=this.Oa["doctype-public"]||null;null===F&&null===P?M(x)&&"html"===x.local.toLowerCase()&&(this.ra&&this.b.write("\n"),this.b.write("<!DOCTYPE "+x.toString()+">\n"),this.ya=!0):null===F?(x=M(x)&&"html"===x.local.toLowerCase()?x.toString():"html",this.ra&&this.b.write("\n"),this.b.write("<!DOCTYPE "+x+"\n"),this.b.write('  PUBLIC "'+
P+'">\n'),this.ya=!0):c.prototype.Ba.call(this,"html"===x.local.toLowerCase()?x:na.QName.fromParts("","","html"))};B.prototype.Gi=function(x,F,P){F=F.local.toLowerCase();x=x.local.toLowerCase();return F===P.toLowerCase()&&(this.tc[x]&&this.tc[x].includes(F)||this.tc["*"].includes(F))};Ka(v,d);v.prototype.oa=function(x,F,P,V){V=void 0===V?{}:V;this.tc=this.qe.includes(x.local.toLowerCase())&&M(x);this.J||this.l?this.ra():this.U();this.B++;0>this.g&&this.cg.includes(x.local.toLowerCase())&&M(x)&&(this.g=
this.B);this.Ia&&-1===this.g&&0<=this.Ia.indexOf(x.$())&&(this.g=this.B);this.b.oa(x,F,P,V);this.J=this.ba=!0;this.tc=this.ta=this.l=!1;this.pe.push(x)};v.prototype.va=function(){var x=this.pe.pop();this.B--;this.l&&!this.ba?this.ra():this.U();this.b.va();this.ba=!1;this.l=!0;this.J=!1;this.ta=this.qe.includes(x.local.toLowerCase())&&M(x);this.B===this.g-1&&(this.g=-1)};v.prototype.text=function(x,F){F=void 0===F?{}:F;d.prototype.text.call(this,x,F);0>this.g&&""===xb.trim(x)||(this.ta=!1)};v.prototype.za=
function(x,F){F=void 0===F?{}:F;d.prototype.za.call(this,x,F);this.ta=!1};v.prototype.xa=function(x,F,P){P=void 0===P?{}:P;d.prototype.xa.call(this,x,F,P);this.ta=!1};v.prototype.ra=function(){0<=this.g||this.tc||this.ta?this.U():this.df()};Ka(p,ze);p.prototype.oa=function(x,F,P,V){V=void 0===V?{}:V;for(var R in P)X(P[R])&&delete P[R];X(x.uri)&&(""!==x.prefix&&(x=na.QName.fromParts("",x.uri,x.local)),P[""]=x.uri);F.forEach(function(w,Y){X(Y.uri)&&(P[Y.prefix]=Y.uri)});ze.prototype.oa.call(this,x,
F,P,V)};Ka(k,c);k.prototype.va=function(){this.J.pop();var x=this.Ia.pop();this.l&&this.ta.includes(x.local)&&M(x)?(this.b.write(" />"),this.l=!1):(this.l&&c.prototype.ba.call(this),this.b.write("</"+x.toString()+">"))};k.prototype.text=function(x,F){F=void 0===F?{}:F;(this.B||""!==xb.trim(x))&&c.prototype.text.call(this,x,F)};k.prototype.Ba=function(x){var F=this.Oa["doctype-system"]||null;null===F&&M(x)&&"html"===x.local.toLowerCase()?(this.ra&&this.b.write("\n"),this.b.write("<!DOCTYPE "+x.toString()+
">\n"),this.ya=!0):null!==F&&c.prototype.Ba.call(this,x)};Ka(e,ze);e.prototype.oa=function(x,F,P,V){var R=!1;F.forEach(function(Y,ia){return R=R||ka(x,ia)});if(R){var w=new Ne;F.forEach(function(Y,ia){if(ka(x,ia)){var T=Nd.escape(Y.value.normalize("NFC"),function(fa){return 32>fa||126<fa});Y=Object.assign({},Y.lb);Y.DISABLE_ESCAPING=!1;Y.NO_CHARACTER_MAP=!0;Y.NO_NORMALIZATION=!0;w.set(ia,{value:T,lb:Y})}else w.set(ia,Y)});ze.prototype.oa.call(this,x,w,P,V)}else ze.prototype.oa.call(this,x,F,P,V)};
Ka(g,ze);g.prototype.oa=function(x,F,P,V){V=void 0===V?{}:V;if("meta"===x.local.toLowerCase()&&M(x)){var R=this.g;F.forEach(function(w,Y){"http-equiv"===Y.toString().toLowerCase()&&"content-type"===w.value.trim().toLowerCase()&&(R=!0)});this.g=R}this.g||(ze.prototype.oa.call(this,x,F,P,V),"head"===x.local.toLowerCase()&&M(x)&&(F=new Ne,F.set(na.QName.fromParts("","","http-equiv"),{value:"Content-Type",lb:{}}),F.set(na.QName.fromParts("","","content"),{value:(this.l["media-type"]||"text/html")+"; charset="+
(this.l.encoding||"UTF-8"),lb:{}}),this.b.oa(na.QName.fromParts("",x.uri,"meta"),F,{},{}),this.b.va()))};g.prototype.va=function(){this.g?this.g=!1:this.b.va()};Ka(n,Be);n.prototype.close=function(){this.tc(this.result());this.b.end&&this.b.end();Be.prototype.close.call(this)};n.prototype.Ba=function(x){this.B["normalization-form"]&&"none"!==this.B["normalization-form"]&&(x=x.normalize(this.B["normalization-form"]));for(var F=this.B.encoding||"UTF-8",P="",V=0;V<x.length;V++){var R=x.charCodeAt(V),
w=x.charAt(V);switch(w){case '"':P+='\\"';break;case "\b":P+="\\b";break;case "\f":P+="\\f";break;case "\n":P+="\\n";break;case "\r":P+="\\r";break;case "\t":P+="\\t";break;case "\\":P+="\\\\";break;case "/":P+="\\/";break;default:P=31>=R||127<=R&&159>=R||!r(R,F)?P+("\\u"+("0000"+R.toString(16).toUpperCase()).slice(-4)):P+w}}return P};n.prototype.ya=function(x){var F='"';if(this.Aa){for(var P=this.Aa,V="",R=!1,w=!0,Y=0;Y<x.length;Y++){var ia=x.charCodeAt(Y);if(ia>=P.min&&ia<=P.max){w=!1;break}}if(w)V=
x;else for(w=0;w<x.length;w++){Y=x.charAt(w);ia=x.charCodeAt(w);var T=55296<=ia&&56320>=ia;T&&(ia=x.codePointAt(w));P.Aa[ia]?(R||(""!==V&&(F+=this.Ba(V),V=""),R=!0),V+=P.Aa[ia],T&&w++):(R&&(F+=V,V="",R=!1),V+=Y)}F=R?F+V:F+this.Ba(V)}else F+=this.Ba(x);return F+'"'};n.prototype.tc=function(x){function F(R){switch(R.length){case 0:V.b.write("null");break;case 1:P(R[0]);break;default:throw new J("Cannot output a sequence of length "+R.length+" as JSON","SERE0023");}}function P(R){var w=Ab.XS;if(Ab.qa(R))if(w.boolean.matches(R))V.b.write(R.toString());
else if(w.numeric.matches(R)){R=R.toString();if(["NaN","INF","-INF"].includes(R))throw new J("Cannot output INF or NaN as JSON","SERE0020");V.b.write(R)}else V.b.write(V.ya(R.toString()));else if(Kc.X(R)){w=Object.assign({},V.B);w.method=w["json-node-output-method"]||"xml";w["item-separator"]=null;w["omit-xml-declaration"]=!0;w.encoding="UTF-8";var Y=new a;ea(R,w,Y,V.ra);V.b.write(V.ya(Y.toString()))}else if(Nd.isArray(R))if(R=R.value,0===R.length)V.b.write("[]");else{V.ba("[");F(R[0]);for(w=1;w<
R.length;w++)V.U(),F(R[w]);V.J("]")}else if(R instanceof Mc){var ia=!0;V.ba("{");var T={};R.forAllPairs(function(fa){ia?ia=!1:V.U();var xa=V.ya(fa.k.toString());if(!V.B["allow-duplicate-names"]){if(T[xa])throw new J("Duplicate key '"+xa+"' in serialized JSON map","SERE0022");T[xa]=!0}V.b.write(xa);V.qb();F(fa.v)});V.J("}")}else throw new J("Cannot output "+ib.showValue(R)+" as JSON","SERE0021");}var V=this;F(x)};n.prototype.ba=function(x){this.b.write(x)};n.prototype.J=function(x){this.b.write(x)};
n.prototype.U=function(){this.b.write(",")};n.prototype.qb=function(){this.b.write(":")};Ka(z,n);z.prototype.ba=function(x){n.prototype.ba.call(this,x);this.g++;this.b.write("\n".padEnd(this.g*this.ta+1,this.Ia))};z.prototype.J=function(x){this.g--;this.b.write("\n".padEnd(this.g*this.ta+1,this.Ia));n.prototype.J.call(this,x)};z.prototype.U=function(){n.prototype.U.call(this);this.b.write("\n".padEnd(this.g*this.ta+1,this.Ia))};z.prototype.qb=function(){this.b.write(": ")};Ka(u,Be);u.prototype.close=
function(){function x(fa,xa){for(var m in["fn","math","map","array","xs"])if(Ic.Jc[m]===fa)return m+":"+xa;return"Q{"+fa+"}"+xa}function F(fa){return R.Aa?A(R.Aa,!1)(fa):fa}function P(fa,xa){if(1===fa.length)V(fa[0],xa);else{xa.write("(");for(var m=!0,G=0;G<fa.length;G++)m?m=!1:xa.write(","),V(fa[G],xa);xa.write(")")}}function V(fa,xa){if(Kc.X(fa))switch(fa.nodeType){case 9:case 11:case 1:case 7:case 8:case 3:var m=Object.assign({},R.B);m.method="xml";m["item-separator"]=null;m.encoding="UTF-8";var G=
new a;qa(m,G,R.ra).append(fa);xa.write(G.toString());break;case 2:xa.write(Kc.da(fa)+'="'+fa.value+'"',{});break;case 13:xa.write("*** Namespace Node ****",{})}else if(Ab.qa(fa))m=fa.code,"AB"===m?xa.write(fa.toBoolean()?"true()":"false()"):zb(fa)?xa.write('"'+F(fa.toString().replace(/"/,'""'))+'"'):/^AD/.test(m)?xa.write(fa.toString()):/^A[OF]/.test(m)?xa.write(fa.toString()):xa.write(Ab.wd(m)+' ("'+fa.toString()+'")');else if(fa instanceof Kd){fa=fa.value;xa.write("[");m=!0;for(G=0;G<fa.length;G++)m?
m=!1:xa.write(","),P(fa[G],xa);xa.write("]")}else if(fa instanceof Mc){xa.write("map{");var N=!0;fa.forAllPairs(function(H){N?N=!1:xa.write(",");V(H.k,xa);xa.write(":");P(H.v,xa)});xa.write("}")}else fa instanceof Ld.XdmFunction?fa.local?xa.write(x(fa.uri,fa.local)+"#"+fa.arity):xa.write("(anonymous function)#"+fa.arity):xa.write("JS: "+ib.showValue(fa))}for(var R=this,w=this.result(),Y=!0,ia=0;ia<w.length;ia++){var T=w[ia];Y?Y=!1:this.b.write(this.B["item-separator"]||" ");V(T,this.b)}this.b.end&&
this.b.end();Be.prototype.close.call(this)};Ka(h,ze);h.prototype.Ja=function(x){x=void 0===x?{}:x;this.pending.push(function(F){F.Ja(x)})};h.prototype.oa=function(x,F,P,V){var R=this;V=void 0===V?{}:V;if(!this.l){var w=this.g["Q{http://saxon.sf.net/}stylesheet-version"],Y="xml";"html"===x.local&&"http://www.w3.org/1999/xhtml"===x.uri?Y=10===w?"xml":"xhtml":"html"===x.local.toLowerCase()&&""===x.uri&&(Y="html");this.g=Object.assign({},this.g);this.g.method=Y;this.b=qa(this.g,this.B);this.b instanceof
h&&ib.la("cannot create committed serializer: "+JSON.stringify(this.g));this.pending.forEach(function(ia){return ia(R.b)});this.l=!0;this.pending=[]}ze.prototype.oa.call(this,x,F,P,V)};h.prototype.text=function(x,F){F=void 0===F?{}:F;this.l?ze.prototype.text.call(this,x,F):this.pending.push(function(P){P.text(x,F)})};h.prototype.za=function(x,F){F=void 0===F?{}:F;this.l?ze.prototype.za.call(this,x,F):this.pending.push(function(P){P.za(x,F)})};h.prototype.xa=function(x,F,P){P=void 0===P?{}:P;this.l?
ze.prototype.xa.call(this,x,F,P):this.pending.push(function(V){V.xa(x,F,P)})};h.prototype.append=function(x,F){F=void 0===F?{}:F;this.l?ze.prototype.append.call(this,x,F):this.pending.push(function(P){P.append(x,F)})};h.prototype.Ca=function(){this.l?ze.prototype.Ca.call(this):this.pending.push(function(x){x.Ca()})};h.prototype.close=function(){var x=this;this.l||(this.g.method="xml",this.b=qa(this.g,this.B),this.pending.forEach(function(F){return F(x.b)}));ze.prototype.close.call(this)};Ka(b,l);
b.prototype.text=function(x){this.g||this.l();this.b.write(x)};b.prototype.append=function(x){this.g||this.l();this.b.write(x.toString())};b.prototype.l=function(){this.Oa["byte-order-mark"]&&"UTF-8"===this.Oa.encoding&&this.b.write("\ufeff");this.g=!0};a.prototype.write=function(x){this.na+=x};a.prototype.toString=function(){return this.na};return{serialize:ea,zh:qa,StringWriter:a,Ef:function(x,F,P){function V(ia,T){throw new J("Serialization params: "+ia,void 0===T?"SEPM0017":T);}function R(ia,
T){T=void 0===T?[]:T;var fa=Kc.da(ia);Kc.Ka.La(ia).forEachItem(function(xa){xa=Kc.da(xa);""!==xa.uri||T.includes(xa.local)||V("attribute @"+xa.local+" must not appear on element "+fa.toString())})}function w(ia,T){var fa=Kc.getAttribute(ia,T,null);null===fa&&V("attribute @"+T+" is missing on element "+Kc.da(ia).toString());return fa}P=void 0===P?null:P;"serialization-parameters"!==Kc.da(x).local&&V("root element name must be 'serialization-parameters'",P||"SEPM0017");"http://www.w3.org/2010/xslt-xquery-serialization"!==
Kc.da(x).uri&&V("root element namespace must be http://www.w3.org/2010/xslt-xquery-serialization",P||"SEPM0017");R(x);var Y=[];Kc.Nc(x).forEach(function(ia){if(Kc.sd(ia,"http://www.w3.org/2010/xslt-xquery-serialization")){var T=Kc.da(ia).local;Y.includes(T)&&V("duplicated parameter "+T,"SEPM0019");Y.push(T);if("use-character-maps"===T){R(ia);var fa={min:0,max:0,Aa:{}};Kc.Nc(ia).forEach(function(xa){if(Kc.sd(xa,"http://www.w3.org/2010/xslt-xquery-serialization")&&"character-map"===Kc.da(xa).local){R(xa,
["character","map-string"]);var m=w(xa,"character");1<m.length&&V("keys in a character map must all be one-character strings. Found "+m);var G=m.codePointAt(0);xa=w(xa,"map-string");void 0!==fa.Aa[G]?V("character map contains two entries for the character "+m,"SEPM0019"):(fa.Aa[G]=xa,0===fa.min?fa.min=G:G<fa.min&&(fa.min=G),G>fa.max&&(fa.max=G))}else V("invalid child of use-character-maps: "+Kc.da(xa).toString())});55296<fa.min&&(fa.min=55296)}else R(ia,["value"]),fa=w(ia,"value"),fa=Q(T,fa,function(xa){return ia.lookupNamespaceURI(xa)});
"undefined"!==typeof fa&&(F[T]=ja(T,fa,F[T]))}else Kc.sd(ia,"")&&V("element in no namespace: "+Kc.da(ia).local),T=Kc.da(ia).$(),Y.includes(T)&&V("duplicated parameter "+T,"SEPM0019"),Y.push(T)})},Df:ja,Mf:Q,Lf:function(x,F,P){x=Q(x,F);return C(x,P)},Kj:C,zj:function(x){function F(T,fa){throw new J("Serialization params: "+T,void 0===fa?"SEPM0017":fa);}var P=0,V=0,R={};x=Aa(Object.entries(x));for(var w=x.next();!w.done;w=x.next()){w=Aa(w.value);var Y=w.next().value;w=w.next().value;1<Y.length&&F("keys in a character map must all be one-character strings. Found "+
Y);var ia=Y.codePointAt(0);void 0!==R[ia]&&F("character map contains two entries for the character "+Y,"SEPM0019");R[ia]=w;0===P?P=ia:ia<P&&(P=ia);ia>V&&(V=ia);55296<P&&(P=55296)}return{min:P,max:V,Aa:R}}}}();
// Input 35
var Se=function(){function a(d,c){return d.C?d.C.filter(function(l){return l.N===c}):[]}function b(d,c,l,t,r){r=void 0===r?null:r;c=c.fixed.ka;if(d=d.next()){d=xb.trim(d.toString());if(r&&xb.Mb(d))return"##local"===r&&(r=c.Uc(l)[""]||""),"Q{"+r+"}"+d;try{var A=f.QName.fromString(d,c.be(l,!1));if(null===A)throw new J("Invalid QName "+d,t,l);return"Q{"+A.uri+"}"+A.local}catch(M){if(M instanceof J&&["FORG0001","XTDE0290"].includes(M.code))throw new J(M.message,t);throw M;}}else return null}function h(d,
c){if(2!==c.length&&5!==c.length)throw new J("Wrong number of arguments ("+c.length+") for  "+d,"XPST0017");}function u(d){return(d=d.next())?d.value:""}function z(d){function c(M){return M.replace(/[^A-Za-z]/g,function(X){return"0"+X.codePointAt(0)})}var l=Kc.Ka;d=l.$a(d).expand().reverse();for(var t="",r=0;r<d.length;r++)if(d[r]instanceof Lc)t+="N"+c(d[r].prefix);else if(2===d[r].nodeType)t+="A"+c(Kc.da(d[r]).$());else{var A=l.Zc(d[r]).count()+1;t+=Qe.ng(A,0===r%2?"a":"A")}return"d"+Kc.Hi(d[0])+
t}function n(d,c,l){var t=c;d.forEachItem(function(r){t=l(r,t)});return t}function g(d){function c(t){throw new J(t,"FORG0006");}var l="max"===d?function(t){return 0<t}:function(t){return 0>t};return function(t,r,A){var M=t[0];r.fixed.ka.Fb(A,"i")&&(M=M.filter(function(aa){return!re.Bc(aa)}));t=re.Wb(A,t[1],"compare",r);var X=re.Ci(t),ka=f.untypedAtomic.matches,qa=f.numeric.matches,Q=f.double.matches,ja=f.float.matches,D=f.anyURI.matches,C=f.string.matches;t=f.duration.matches;var y=f.yearMonthDuration.matches,
K=f.dayTimeDuration.matches;r=f.QName.matches;var U=f.double.O,O=f.float.O,Z=f.string.O;M=M.mapOneToOne(function(aa){return ka(aa)?U(aa):aa});A=M.next();if(null===A)return wc;r(A)&&c(d+"() not defined over QName");!t(A)||y(A)||K(A)||c(d+"() not defined over mixed durations");return xc(n(M,A,function(aa,ca){(C(aa)&&qa(ca)||C(ca)&&qa(aa))&&c("Cannot compare string with numeric");(y(aa)&&!y(ca)||K(aa)&&!K(ca))&&c("Cannot compare mixed durations");if(re.Bc(ca))return Q(aa)?U(ca):ca;if(re.Bc(aa))return Q(ca)?
U(aa):aa;Q(ca)&&!C(aa)?aa=U(aa):Q(aa)&&!C(ca)?ca=U(ca):ja(ca)&&!C(aa)?aa=O(aa):ja(aa)&&!C(ca)&&(ca=O(ca));D(ca)&&C(aa)?ca=Z(ca):D(aa)&&C(ca)&&(aa=Z(aa));try{return l(X(aa,ca))?aa:ca}catch(oa){throw oa instanceof J&&"XPTY0004"===oa.code&&(oa.code="FORG0006"),oa;}}))}}function e(d,c){d=d[0].next();if(null===d)return wc;if("timezone"===c)return null===d.Ga?wc:xc(f.dayTimeDuration.qh(d.Ga));if("seconds"===c)return c=d.proxy(),Fc(c.getUTCSeconds()+c.getUTCMilliseconds()/1E3);d=d.proxy()[c]();"getUTCMonth"===
c&&d++;return Dc(d)}function k(d,c){d=d[0].next();return null===d?wc:5!==c?Dc(Tb(d)[c]*Ub(d)):Fc(Tb(d)[c]*Ub(d))}function p(d,c,l){function t(M,X){return xc(Ab.bb[c].fromDate(M,X))}var r=d[0].next();if(!r)return wc;l=l.fixed.Lb.getTimezoneOffset();if(d[1]){var A=d[1].next();d=null!==A?A.Ra/6E4:null;if(null!==d&&(-840>d||840<d))throw new J("timezone out of bounds "+A,"FODT0003");}else d=-l;A=r.Ga;if(null===A&&null===d)return xc(r);if(null===A&&null!==d)return t(Qb(r,-l-d).Pa,d);if(null!==A&&null===
d)return t(Qb(r,l+A).Pa,null);if(null!==A&&null!==d)return t(r.Pa,d)}function v(d,c,l,t){var r=d[1].expand(),A=d[2].expand()[0];A instanceof Ld.XdmFunction||ib.la("fold-"+(t?"left":"right")+"() function argument not instance of XdmFunction");d=d[0].expand();t||(d=d.reverse());d.forEach(function(M){r=A.evaluate(t?[r,[M]]:[[M],r],c,l).expand()});return sc(r)}var B=null,q=null,f=Ab.XS,I=Kc.Ka;return{abs:function(d){d=d[0].next();return null===d?wc:xc(d.abs())},"accumulator-after":function(d,c,l){d=b(d[0],
c,l,"XTDE3340");return sc(ve.ri(l,d,Od(c),c))},"accumulator-before":function(d,c,l){d=b(d[0],c,l,"XTDE3340");return sc(ve.si(l,d,Od(c),c))},"adjust-date-to-timezone":function(d,c){return p(d,"AA",c)},"adjust-dateTime-to-timezone":function(d,c){return p(d,"AM",c)},"adjust-time-to-timezone":function(d,c){return p(d,"AT",c)},"analyze-string":function(d,c){var l=u(d[0]),t=c.ja.createDocumentFragment(),r=Ic.Ea,A=c.ja.createElementNS(r,"analyze-string-result");t.appendChild(A);if(""===l)return xc(A);t=
d[1].next().toString();d=d[2]?d[2].next().toString():"";Ke.ag(l,t,d,!1,!1).forEach(function(M){if(M.Xc){M=M.groups;var X=c.ja.createElementNS(r,"match");var ka=0;for(var qa=1;qa<M.length;qa++)if(M[qa]){var Q=M[0].indexOf(M[qa],ka);Q>ka&&(ka=c.ja.createTextNode(M[0].substring(ka,Q)),X.appendChild(ka));ka=c.ja.createElementNS(r,"group");ka.setAttribute("nr",qa);ka.appendChild(c.ja.createTextNode(M[qa]));X.appendChild(ka);ka=Q+M[qa].length}ka<M[0].length&&(ka=c.ja.createTextNode(M[0].substring(ka,Q)),
X.appendChild(ka))}else X=c.ja.createElementNS(r,"non-match"),ka=c.ja.createTextNode(M.string),X.appendChild(ka);A.appendChild(X)});return xc(A)},apply:function(d,c,l){var t=c.fixed.ka,r=d[0].next();d=d[1].next();var A=d.value;if(r instanceof Ld.XdmFunction)return r.evaluate(A,c,l);c=t.hc(d.value[0][0]).next();if(r instanceof Kd)return c=f.numeric.matches(c)?c.toNumber():f.double.O(c).toNumber(),sc(r.get(c));if(r instanceof Mc)return sc(r.get(c));ib.la("Target of apply() is neither map(*) nor array(*) nor function(*): actual value "+
ib.showValue(r))},"available-environment-variables":function(){return wc},"available-system-properties":function(){var d=[],c={version:"3.0",vendor:"Saxonica","vendor-url":"http://www.saxonica.com/","product-name":"Saxon-JS","product-version":"2.1","is-schema-aware":"no","supports-serialization":"yes","supports-backwards-compatibility":"yes","supports-namespace-axis":"yes","supports-streaming":"no","supports-dynamic-evaluation":"yes","supports-higher-order-functions":"yes","xpath-version":"3.1","xsd-version":"1.1"},
l;for(l in c)c.hasOwnProperty(l)&&d.push(f.QName.fromParts("xsl",Ic.dd,l));return sc(d)},avg:function(d,c){d=d[0].mapOneToOne(c.fixed.ka.ii);c=d.next();if(null===c)return wc;c={Fg:c,count:1};var l=Vb["a+a"];try{var t=n(d,c,function(r,A){return{Fg:l(A.Fg,r),count:A.count+1}});return xc(Vb["a/a"](t.Fg,f.integer.fromNumber(t.count)))}catch(r){throw new J("Input to avg() contains a mix of numeric and non-numeric values ","FORG0006");}},"base-uri":function(d){return d[0].mapOneToOne(function(c){c=Kc.baseURI(c);
return null===c?null:f.anyURI.fromString(c)})},"boolean":function(d,c){return Gc(c.fixed.ka.Od(d[0]))},ceiling:function(d){d=d[0].next();return xc(null===d?null:d.dg())},"codepoint-equal":function(d){var c=d[0].next();d=d[1].next();return null===c||null===d?wc:Gc(c.toString()===d.toString())},"codepoints-to-string":function(d){return Cc(wb(d[0].expand().map(function(c){return c.toNumber()})))},"collation-key":function(d,c,l){var t=d[0].next();d=re.Wb(l,d[1],"collationKey",c);return xc(f.base64Binary.fromUtf8EncodedString(d(t.value)))},
collection:function(d,c,l){var t=c.fixed.ka,r=c.fixed.options.dh;if(r&&"function"===typeof r){var A=null;d[0]&&(d=d[0].next(),null!==d&&(A=gb.Ob(d.toString(),t.Qa(l,c))));r=A?r(A):r();if("undefined"===typeof r)throw new J("No available collection for supplied URI: "+A,"FODC0002",l);r=t.Ta(r);c=c.fixed.Ne;if(null!==c)for(l=0;l<r.length;l++)r[l]=c(r[l]);return Array.isArray(r)?sc(r):xc(r)}if(d[0])throw new J("Unknown collection (no collectionFinder supplied)","FODC0002",l);return wc},compare:function(d,
c,l){var t=d[0].next(),r=d[1].next();if(null===t||null===r)return wc;d=re.Wb(l,d[2],"compare",c);return Dc(d(t.value,r.value))},concat:function(d){return Cc(d.map(function(c){c=c.next();return null===c?"":f.string.O(c)}).join(""))},contains:function(d,c,l){var t=u(d[0]),r=u(d[1]);d=re.Wb(l,d[2],"contains",c);return Gc(d(t,r))},"contains-token":function(d,c,l){function t(M){return A(M,r)}var r=u(d[1]);r=xb.trim(r);if(""===r)return Gc(!1);for(var A=re.Wb(l,d[2],"equals",c);null!==(c=d[0].next());)if(0<=
c.toString().split(/[ \n\r\t]+/).findIndex(t))return Gc(!0);return Gc(!1)},"copy-of":function(d,c,l){var t=Fe(c),r=Ke.Oc(t);d[0].forEachItem(function(A){return Ke.Ff(A,c,r,l,!0,!0)});r.close();return sc(t.Pb)},count:function(d){return Dc(d[0].count())},"current-date":function(d,c){return xc(f.md.fromDate(c.fixed.Lb,-c.fixed.Lb.getTimezoneOffset()))},"current-dateTime":function(d,c){return xc(f.Md.fromDate(c.fixed.Lb,-c.fixed.Lb.getTimezoneOffset()))},"current-group":function(){throw new J("The current-group() function cannot be invoked dynamicaly",
"XTDE1061");},"current-grouping-key":function(){throw new J("The current-grouping-key() function cannot be invoked dynamicaly","XTDE1071");},"current-merge-group":function(d,c){if(null===c.Eb)throw new J("There is no current merge group","XTDE3480");if(d[0]){d=d[0].next().value;if(c=c.Eb.groups[d])return sc(c);throw new J("Unknown merge source "+d,"XTDE3490");}return sc(c.Eb.items)},"current-merge-key":function(d,c){if(null===c.Eb)throw new J("There is no current merge group","XTDE3510");return sc(c.Eb.keys)},
"current-output-uri":function(d,c){d=0===c.xb.length?null:c.xb[c.xb.length-1];return null==d||"function"===c.sa?wc:xc(d)},"current-time":function(d,c){return xc(f.time.fromDate(c.fixed.Lb,-c.fixed.Lb.getTimezoneOffset()))},data:function(d,c){return d[0].mapOneToMany(c.fixed.ka.hc)},dateTime:function(d){var c=d[0].next();d=d[1].next();if(null===c||null===d)return wc;var l="";if(null!==c.Ga)if(null===d.Ga)l=Ob(c);else if(c.Ga!==d.Ga)throw new J("timezones are incompatible ","FORG0008");return xc(f.dateTime.fromString(c.toString().substring(0,
10)+"T"+d.toString()+l))},"day-from-date":function(d){return e(d,"getUTCDate")},"day-from-dateTime":function(d){return e(d,"getUTCDate")},"days-from-duration":function(d){return k(d,2)},"deep-equal":function(d,c,l){c=re.Wb(l,d[2],"equals",c);return Gc(pe(d[0],d[1],c))},"default-collation":function(){return Cc(re.Kd)},"default-language":function(){return xc(f.language.fromString("en"))},"distinct-values":function(d,c,l){c=re.pf(l,d[1],c);var t=re.Kf(c);return d[0].filter(function(r){if(t.containsKey(r))return!1;
t.inSituPut(r,!0);return!0})},doc:function(d,c,l){d=xe.Vg(d,c,l);if(null===d)return xc(null);try{var t=xe.Lc(d,c),r=c.fixed.Ne;r&&(t=r(t));return xc(t)}catch(A){throw A;}},"doc-available":function(d,c,l){var t=xe.Vg(d,c,l);return null===t?Gc(!1):xe.Sh(function(){xe.Lc(t,c)})},document:function(d,c,l){var t=c.fixed.ka,r;if(d[1]){var A=Kc.baseURI(d[1].next());if(!A)throw new J("No base URI available for second argument of document()","XTDE1162");}A||(r=t.Qa(l,c));var M=c.fixed.Ne;return d[0].mapOneToMany(function(X){var ka=
A?A:Kc.X(X)?Kc.baseURI(X):r;return t.hc(X).mapOneToOne(function(qa){if(null===ka)throw new J("No base URI for document('"+qa+"')","XTDE1162");qa=xe.Lc(gb.Ob(qa.toString(),ka),c);null!==M&&(qa=M(qa));return qa})})},"document-uri":function(d){d=d[0].next();return null!==d&&9===d.nodeType&&d._saxonDocUri?xc(f.anyURI.fromString(d._saxonDocUri)):wc},"element-available":function(d,c,l){d=b(d[0],c,l,"XTDE1440","##local").split("}");if(c="http://www.w3.org/1999/XSL/Transform"===d[0].substring(2))null==q&&
(q=gb.Gf("elements")),q[d[1]]||(c=!1);return Gc(c)},"element-with-id":function(d,c){d=u(d[0]);Qd(c,"XPDY0002","element-with-id()");return xc(Kc.zc(Od(c)).getElementById(d))},empty:function(d){return Gc(null===d[0].next())},"encode-for-uri":function(d){d=encodeURIComponent(u(d[0])).replace(/[!'()*]/g,function(c){return"%"+c.charCodeAt(0).toString(16).toUpperCase()});return Cc(d)},"ends-with":function(d,c,l){var t=u(d[0]),r=u(d[1]);d=re.Wb(l,d[2],"endsWith",c);return Gc(d(t,r))},"environment-variable":function(){return wc},
error:function(d){if(void 0===d[0])throw new J("fn:error() invoked","FOER0000");var c=d[0].next();c=c?c.$():"FOER0000";var l=d[1]?u(d[1]):"";c=new J(l,c);d[2]&&(c.errorObject=d[2].expand());throw c;},"escape-html-uri":function(d){return Cc(Nd.escape(u(d[0]),function(c){return 32>c||126<c}))},"exactly-one":function(d){d=d[0].expand();if(1!==d.length)throw new J("Required length 1, actual length "+d.length,"FORG0005");return xc(d[0])},exists:function(d){return Gc(null!==d[0].next())},"false":function(){return Gc(!1)},
filter:function(d,c,l){var t=d[1].expand()[0];t.evaluate||ib.la("filter() argument is not a function");return d[0].mapOneToOne(function(r){var A=t.evaluate([[r]],c,l).expand();if(Array.isArray(A)&&1!==A.length||!f.boolean.matches(A[0]))throw new J("Wrong type for function result in filter(). Expecting xs:boolean, supplied value is "+ib.showValue(A),"XPTY0004",l);if(A[0].toBoolean())return r})},floor:function(d){return d[0].mapOneToOne(function(c){return c.floor()})},"fold-left":function(d,c,l){return v(d,
c,l,!0)},"fold-right":function(d,c,l){return v(d,c,l,!1)},"format-date":function(d){h("format-date",d);var c=d[0].next();return null===c?wc:d[2]?Cc(Qe.Rd("date",c,d[1].next().toString(),d[2].next(),d[3].next(),d[4].next())):Cc(Qe.Rd("date",c,d[1].next().toString()))},"format-dateTime":function(d){h("format-dateTime",d);var c=d[0].next();return null===c?wc:d[2]?Cc(Qe.Rd("dateTime",c,d[1].next().toString(),d[2].next(),d[3].next(),d[4].next())):Cc(Qe.Rd("dateTime",c,d[1].next().toString()))},"format-integer":function(d){var c=
d[0].next();return null===c?Cc(""):Cc(Qe.ng(c,d[1].next().toString()))},"format-number":function(d,c,l){var t=c.fixed.ka,r=d[2]?b(d[2],c,l,"FODF1280"):"Q{}";l=t.ue(l,c).jb[null!==r?r:"Q{}"];if(!l)throw new J("Unknown decimal format "+r,"FODF1280");r=d[0].next();null===r?r=Number.NaN:r=r.toDouble().value;return Cc(Qe.Wi(r,d[1].next().toString(),l,c))},"format-time":function(d){h("format-time",d);var c=d[0].next();return null===c?wc:d[2]?Cc(Qe.Rd("time",c,d[1].next().toString(),d[2].next(),d[3].next(),
d[4].next())):Cc(Qe.Rd("time",c,d[1].next().toString()))},"for-each":function(d,c,l){var t=c.fixed.ka,r=d[1].expand()[0];if(r instanceof Ld.XdmFunction)return d[0].mapOneToMany(function(A){return r.evaluate([[A]],c,l)});if(r instanceof Mc)return d[0].mapOneToMany(function(A){return sc(r.get(t.hc(A).next()))});if(r instanceof Kd)return d[0].mapOneToMany(function(A){A=t.hc(A).next();A=f.numeric.matches(A)?A.toNumber():f.double.O(A).toNumber();return sc(r.get(A))});ib.la("for-each() function object not instance of XdmFunction | XdmArray | XdmMap")},
"for-each-pair":function(d,c,l){var t=d[2].expand();if(1!==t.length)throw new J("for-each-pair() must have exactly one function item as the thIrd argument. Supplied length "+t.length,"XPTY0004",l);t=t[0];t instanceof Ld.XdmFunction||ib.la("Function object not instance of XdmFunction");var r=[],A=d[0];d=d[1];for(var M=A.next(),X=d.next();M&&X;)r=r.concat(t.evaluate([[M],[X]],c,l).expand()),M=A.next(),X=d.next();return sc(r)},"function-arity":function(d){d=d[0].next();return xc(f.integer.fromNumber(d instanceof
Mc||d instanceof Kd?1:d.arity))},"function-available":function(d,c,l){var t=!1,r=b(d[0],c,l,"XTDE1400",Ic.Ea),A=d[1]?d[1].next().toNumber():-1,M=c.fixed.Na;Object.keys(M).forEach(function(X){X=M[X];X.name===r&&X.actor&&"function"===X.actor.N&&(0>A?t=!0:(X=a(X.actor,"arg").length,A===X&&(t=!0)))});t||(d=f.QName.fromEQName(r),null==B&&(B=gb.Gf("functions")),t=(c=B[d.uri])&&c[d.local]?"concat"===d.local&&d.uri===Ic.Ea&&(-1===A||2<=A)?!0:0<=A?0<=c[d.local].indexOf(A):!0:!1);return Gc(t)},"function-lookup":function(d,
c,l){var t=d[0].next();d=d[1].next().toNumber();var r=te.find("SaxonJS").XPath,A=c.ca(!0);A.eb=!0;r=r.getFnSignature(t,d,A);if(!r)return wc;c=r.isUserFunction?new Ld.Tf(r,c):t.uri===Ic.qc?new Ld.Qf(r,t,l):new Ld.Se(r,t,d,l,c);return xc(c)},"function-name":function(d){d=d[0].next();if(!(d instanceof Ld.XdmFunction))throw new J("Input to function-name() is not an XdmFunction ","XXXX9999");return d.kc()&&d.kc().uri!==Ic.Ig?xc(d.kc()):wc},"generate-id":function(d){d=d[0].next();return Cc(d?z(d):"")},
"has-children":function(d){return Gc(null!==I.Ma(d[0].next()).next())},head:function(d){return xc(d[0].next())},"hours-from-dateTime":function(d){return e(d,"getUTCHours")},"hours-from-duration":function(d){return k(d,3)},"hours-from-time":function(d){return e(d,"getUTCHours")},id:function(d,c){var l="";d[0].forEachItem(function(M){l+=M.value.trim()+" "});c=d[1]?d[1].next():Kc.zc(Od(c));if(!Kc.X(c))throw new J("Context item is not a node for fn:id()","XPTY0004");9!==c.nodeType&&(c=Kc.zc(c));var t;
d=[];var r=l.trim().split(/\s+/);if(9===c.nodeType){r=Aa(r);for(var A=r.next();!A.done;A=r.next())(t=c.getElementById(A.value))&&d.push(t);return Kc.Sd(sc(d))}if(11===c.nodeType){for(c=I.mf(c);null!==(t=c.next());)1===t.nodeType&&(r.includes(t.getAttributeNS(Ic.xml,"id"))||r.includes(t.getAttribute("id")))&&d.push(t);return Kc.Sd(sc(d))}return wc},idref:function(){return wc},"implicit-timezone":function(d,c){return xc(f.dayTimeDuration.qh(-c.fixed.Lb.getTimezoneOffset()))},"index-of":function(d,c,
l){var t=Bc(d[0]),r=d[1].next();d=re.Wb(l,d[2],"equals",c);var A=re.Li(d);return t.mapOneToOne(function(M){try{return A(M,r)?f.integer.fromNumber(t.position):null}catch(X){return null}})},innermost:function(d,c,l){function t(){var A,M,X;return Xa(t,function(ka){1==ka.b&&(A=null);if(null==(M=r.next()))return A?ka=Pa(ka,A,0):(ka.b=0,ka=void 0),ka;if(A)if(I.gd(M).some(function(qa){return qa===A}))A=M,ka.b=2;else return X=A,A=M,Pa(ka,X,2);else A=M,ka.b=2})}var r=c.fixed.ka.Fb(l,"p")?d[0]:Kc.Sd(d[0]);
ib.Sa("innermost of "+ib.showValue(r));return new uc(t())},"in-scope-prefixes":function(d){d=d[0].next();return sc(Kc.Uc(d).map(function(c){return f.string.fromString(c.prefix)}))},"insert-before":function(d){var c=d[1].next().value,l=d[2],t=Bc(d[0]);return 1>c?zc([l,t]):c>t.last()?zc([t,l]):t.mapOneToMany(function(r){return t.position===c?zc([d[2],xc(r)]):xc(r)})},"iri-to-uri":function(d){return Cc(Nd.escape(u(d[0]),function(c,l){return 32>=c||126<c||/[<>"{}|\\^`]/.test(l)}))},"json-doc":function(d,
c,l){l=xe.bf(d,c,l);if(!l)return wc;l=xe.Ui(l,c);d=d[1]?d[1].next():null;return xc(we.He(l,d,c))},"json-to-xml":function(d,c,l){var t=c.fixed.ka,r=d[0].next();if(null===r)return wc;d=d[1]?d[1].next():null;r=we.Lj(r.toString(),d,c);d=c.ja.createDocumentFragment();d.appendChild(r);d._saxonBaseUri=t.Qa(l,c);return xc(d)},key:function(d,c,l){function t(V){V=K?f.string.O(V):V;return y.containsKey(V)?(V=sc(y.get(V)),ja===D?V:V.filter(function(R){return null!==I.$a(R).filter(function(w){return w===D}).next()})):
wc}function r(V){return{visibility:"PRIVATE",Ua:V,Nh:X,actor:V,ef:V.binds.split(" ").map(function(R){return parseInt(R,10)})}}var A=c.fixed.ka,M=b(d[0],c,l,"XTDE1260"),X=A.ue(l,c),ka=X.keys[M];if(!ka)throw new J("Unknown key "+M,"XTDE1260");var qa=ka.Gg,Q=A.Fb(ka.Wd[0],"c"),ja;if(d[2]){var D=d[2].next();Kc.X(D)&&(ja=Kc.zc(D))}else{var C=Od(c);Kc.X(C)&&(ja=D=Kc.zc(C))}if(!ja||9!==ja.nodeType&&11!==ja.nodeType)throw new J("No containing document for key()","XTDE1270",l);ja._saxonIndexes||(ja._saxonIndexes=
{},ja._saxonIndexesBC={});l=ja._saxonIndexes;var y=l[qa];if("under construction"===y)throw new J("Definition of key "+M+" is circular","XTDE0640");var K=!1;if(y)K=ja._saxonIndexesBC[qa];else{M=re.pf(ka.Wd[0],null,c);y=re.Kf(M,Q);l[qa]="under construction";M=function(V){return zc([I.self(V),I.wb(V),I.La(V)])};C=function(V){return zc([I.self(V),I.La(V)])};for(var U=function(V){return zc([I.self(V),I.wb(V)])},O={},Z=0;Z<ka.Wd.length;O={ed:O.ed,Of:O.Of},Z++){var aa=ka.Wd[Z],ca=/b/.test(aa.flags);K=K||
ca;var oa=void 0;oa=aa.C[0];O.ed=c.ca(!0);O.ed.ua=r(aa);O.ed.focus=Ac(ja);if("p.nodeSet"===oa.N)oa=A.evaluate(oa.C[0],O.ed);else{O.Of=ue.vd(oa);var ea=function(V){return function(R){V.ed.focus=Ac(ja);return V.Of(R,V.ed)}}(O);oa=I.ve(ja);var na=A.Fb(aa,"a"),x=A.Fb(aa,"n");if(na||x)oa=oa.mapOneToMany(na&&x?M:na?C:U);oa=oa.filter(ea)}ea=aa.C[1];na=c.ca(!0);na.ua=r(aa);na.sa="key";for(aa=void 0;null!==(aa=oa.next());){na.focus=Ac(aa);x=A.evaluate(ea,na);var F=function(V,R){y.containsKey(V)?0>y.get(V).indexOf(R)&&
(y.get(V).push(R),1<ka.Wd.length&&y.get(V).sort(Kc.sb)):y.inSituPut(V,[R])};if(Q)F(x.expand(),aa);else for(var P=void 0;null!==(P=x.next());)re.Bc(P)||(ca&&(P=f.string.O(P)),F(P,aa))}}l[qa]=y;ja._saxonIndexesBC[qa]=K}d=d[1];return Q?t(d.expand()):d.mapOneToMany(t)},lang:function(d,c){c=d[1]?d[1].next():Od(c);if(!c)throw new J("No context item for lang()","XPDY0002");if(!Kc.X(c))throw new J("Context item is not a node in lang()","XPTY0004");d=u(d[0]).toLowerCase();c=I.$a(c).filter(function(l){return 1===
l.nodeType&&l.hasAttribute("xml:lang")}).next();if(!c)return Gc(!1);c=c.getAttribute("xml:lang").toLowerCase();return Gc(d===c||c.length>d.length+1&&c.substring(0,d.length)===d&&"-"===c.charAt(d.length))},last:function(d,c){Qd(c,"XPDY0002","last()");return Dc(c.focus.last())},"load-xquery-module":function(){throw new J("No XQuery processor available","FOQM0006");},"local-name":function(d){d=Kc.da(d[0].next());return Cc(d?d.local:"")},"local-name-from-QName":function(d){d=d[0].next();return null===
d?wc:xc(Ab.type.NCName.fromString(d.local))},"lower-case":function(d){return Cc(u(d[0]).toLowerCase())},matches:function(d){var c=u(d[0]),l=d[1].next().toString();d=d[2]?d[2].next().toString():"";if(!d.match(/^[smijxq]*$/))throw new J("Illegal flags for regular expression: "+d,"FORX0001");l=xb.Ke(l,d,c);return Gc(0<=c.search(l))},max:g("max"),min:g("min"),"minutes-from-dateTime":function(d){return e(d,"getUTCMinutes")},"minutes-from-duration":function(d){return k(d,4)},"minutes-from-time":function(d){return e(d,
"getUTCMinutes")},"month-from-date":function(d){return e(d,"getUTCMonth")},"month-from-dateTime":function(d){return e(d,"getUTCMonth")},"months-from-duration":function(d){return k(d,1)},name:function(d){d=Kc.da(d[0].next());return Cc(d?d.toString():"")},"namespace-uri":function(d){d=Kc.da(d[0].next());return xc(f.anyURI.fromString(d?d.uri:""))},"namespace-uri-for-prefix":function(d){var c=u(d[0]);d=d[1].next();c="xml"===c?Ic.xml:d.lookupNamespaceURI(""===c?null:c);return null!==c?xc(f.anyURI.fromString(c)):
wc},"namespace-uri-from-QName":function(d){d=d[0].next();return null===d?wc:xc(f.anyURI.fromString(d.uri))},nilled:function(d){return(d=d[0].next())&&1===d.nodeType?Gc(!1):wc},"node-name":function(d){return xc(Kc.da(d[0].next()))},"normalize-space":function(d){d=u(d[0]);return Cc(mb(d))},"normalize-unicode":function(d){if(String.prototype.normalize){var c=u(d[0]);d=d[1]?d[1].next().toString().toUpperCase().trim():"NFC";if(["NFC","NFD","NFKC","NFKD"].includes(d))return Cc(c.normalize(d));if(""===d)return Cc(c)}throw new J("normalize-unicode(): not supported on this Javascript platform",
"FOCH0003");},not:function(d,c){return Gc(!c.fixed.ka.Od(d[0]))},number:function(d){return xc(Ab.XS.numeric.Fe(d[0].next()))},"one-or-more":function(d){d=nc(d[0]);if(null===d.wa())throw new J("Required length >= 1, actual length zero","FORG0004");return d},outermost:function(d,c,l){c=c.fixed.ka.Fb(l,"p");d=d[0];c||(d=Kc.Sd(d));var t=null;return d.filter(function(r){if(null===t)return t=r,!0;if(I.gd(r).some(function(A){return A===t}))return!1;t=r;return!0})},"parse-ietf-date":function(d){return d[0].mapOneToOne(function(c){function l(ja){return 1===
ja.length?"0"+ja:ja}function t(){throw new J("Invalid IETF date/time "+c.toString(),"FORG0010");}var r=c.toString().trim().toLowerCase();r=r.replace(/^(monday|tuesday|wednesday|thursday|friday|saturday|sunday|mon|tue|wed|thu|fri|sat|sun),?\s+/,"");if(/^[a-z]/.test(r)){var A=/^([a-z]{3})(?:\s*-\s*|\s+)(\d\d\d?\d?)\s+([\d:.]+)\s*([a-z]+|[+\-]\d\d?:?(?:\d\d)?(?:\s*\(\s*[a-z]+\s*\))?)?\s+(\d+)$/;r=A.exec(r);if(null!==r){var M=r[1];var X=r[2];var ka=r[3];var qa=r[4];var Q=r[5]}else t()}else A=/^(\d+)(?:\s*-\s*|\s+)([a-z]{3})(?:\s*-\s*|\s+)(\d\d\d?\d?)\s+([\d:.]+)\s*([a-z]+|[+\-]\d\d?:?(?:\d\d)?(?:\s*\(\s*[a-z]+\s*\))?)?$/,
r=A.exec(r),null!==r?(X=r[1],M=r[2],Q=r[3],ka=r[4],qa=r[5]):t();2===Q.length&&(Q="19"+Q);M="jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec".indexOf(M||"*");0>M&&t();r={ut:"Z",utc:"Z",gmt:"Z",est:"-05:00",edt:"-04:00",cst:"-06:00",cdt:"-05:00",mst:"-07:00",mdt:"-06:00",pst:"-08:00",pdt:"-07:00"};/^[0-9]:/.test(ka)&&(ka="0"+ka);5===ka.length&&(ka+=":00");qa&&(qa=/([a-z]+)|([+\-]\d\d?:?(?:\d\d)?)(?:\s*\(\s*([a-z]+)\s*\))?$/.exec(qa),(!qa[3]||qa[3]in r)&&(!qa[1]||qa[1]in r)||t(),qa=qa[1]?qa[1]:qa[2],
/^[+\-][0-9]((:|$)|[0-9]{2}$)/.test(qa)&&(qa=qa.charAt(0)+"0"+qa.substring(1)),qa in r?qa=r[qa]:3===qa.length?qa+=":00":4===qa.length?qa+="00":5===qa.length&&(qa=qa.substring(0,3)+":"+qa.substring(3)));X=Q+"-"+l((M/4+1).toString())+"-"+l(X)+"T"+ka+(qa||"Z");try{return f.dateTime.fromString(X)}catch(ja){throw new J("''"+c.toString()+"'' is not a valid IETF date","FORG0010");}})},"parse-json":function(d,c){var l=d[0].next();if(null===l)return wc;d=d[1]?d[1].next():null;return xc(we.He(l.toString(),
d,c))},"parse-xml":function(d,c,l){d=d[0].next();if(null===d)return wc;var t=c.fixed.ka;try{var r=gb.parseXmlFromString(d.toString());r._saxonBaseUri=t.Qa(l,c);return xc(r)}catch(A){throw new J(A.message,"FODC0006");}},"parse-xml-fragment":function(d,c,l){var t=c.fixed.ka;return d[0].mapOneToOne(function(r){var A=/^<\?xml(?:\s+version\s*=\s*(['"])1.[0-9]+['"])?\s+encoding\s*=\s*(['"][A-Za-z](?:[A-Za-z0-9._\-])*)['"]\s*\?>/;r=r.toString();if(/^<\?xml/.test(r)&&!A.test(r))throw new J("Invalid XML header for a text declaration",
"FODC0006");r=r.replace(A,"");try{var M=gb.parseXmlFromString("<z>"+r+"</z>")}catch(ka){throw new J(ka.message,"FODC0006");}var X=c.ja.createDocumentFragment();I.Ma(M.documentElement).forEachItem(function(ka){X.appendChild(ka)});X._saxonBaseUri=t.Qa(l,c);return X})},path:function(d){return d[0].mapOneToOne(function(c){function l(t){return I.Zc(t).filter(function(r){return t.nodeType===r.nodeType})}return f.string.fromString(function(t){if([9,11].includes(t.nodeType))return"/";var r="",A=t;for(t={};A;){var M=
Kc.Za(A);if(!M)return[9,11].includes(A.nodeType)?r:"Q{http://www.w3.org/2005/xpath-functions}root()"+r;switch(A.nodeType){case 9:case 11:return r;case 1:t.Re=Kc.da(A);r="/"+t.Re.$()+"["+(1+l(A).filter(function(X){return function(ka){return Kc.da(ka).equals(X.Re)}}(t)).count())+"]"+r;break;case 3:r="/text()["+(1+l(A).count())+"]";break;case 8:r="/comment()["+(1+l(A).count())+"]";break;case 7:r="/processing-instruction("+A.target+")["+(1+l(A).filter(function(X){return A.target===X.target}).count())+
"]";break;case 2:r="/@"+(0>A.name.indexOf(":")?A.name:Kc.da(A).$());break;default:r="/namespace::"+(""!==A.prefix?A.prefix:"*[Q{"+Ic.Ea+'}local-name()=""]')}A=M;t={Re:t.Re}}}(c))})},"prefix-from-QName":function(d){return d[0].mapOneToOne(function(c){return""===c.prefix?null:f.Kg.fromString(c.prefix)})},position:function(d,c){Qd(c,"XPDY0002","position()");return Dc(c.focus.position)},QName:function(d){var c=d[1].next().value,l=c.split(":"),t=2===l.length?l[0]:"";d=u(d[0]);if(""!==t&&""===d)throw new J("Prefix but no URI for QName()",
"FOCA0002");if(2===l.length&&""===t||""!==t&&!xb.Mb(t))throw new J(c+" not valid for QName()","FOCA0002");c=2===l.length?l[1]:l[0];if(!xb.Mb(c))throw new J(c+" not valid local name for QName()","FOCA0002");return xc(f.QName.fromParts(t,d,c))},"random-number-generator":function(d,c){d=new Mc;d.inSituPut(f.string.fromString("number"),[f.double.fromNumber(Math.random())]);var l=f.QName.fromParts("fn",Ic.Ea,"random-number-generator"),t=te.find("SaxonJS").XPath,r=t.getFnSignature(l,0,c);l=new Ld.Se(r,
l,0,{},c);d.inSituPut(f.string.fromString("next"),[l]);l=f.QName.fromParts("saxon",Ic.zb,"permute");r=c.ca();r.eb=!0;t=t.getFnSignature(l,1,r);c=new Ld.Se(t,l,1,{},c);d.inSituPut(f.string.fromString("permute"),[c]);return xc(d)},"regex-group":function(d,c){d=d[0].next().toNumber();try{return Cc(c.Zb[d]||"")}catch(l){return Cc("")}},remove:function(d){var c=d[1].next().toNumber(),l=Bc(d[0]);return l.filter(function(){return l.position!==c})},replace:function(d){var c=u(d[0]),l=d[3]?d[3].next().toString():
"";if(!l.match(/^[smijxq]*$/))throw new J("Illegal flags for regular expression: "+l,"FORX0001");var t=0,r=xb.Ke(d[1].next().toString(),l+"g",null,function(A){t=A});if(r.test(""))throw new J("Regular expression matches zero-length string","FORX0003");d=xb.Fi(d[2].next().toString(),l,t);return Cc(c.replace(r,d))},"resolve-QName":function(d){var c=d[1].next();return d[0].mapOneToOne(function(l){try{return Kc.Rj(c,l.toString(),!0)}catch(t){throw t instanceof J&&"FORG0001"===t.code&&(t.code=xb.Eh(l)?
"FONS0004":"FOCA0002"),t;}})},"resolve-uri":function(d,c,l){var t=c.fixed.ka;c=d[1]?d[1].next().toString():t.Qa(l,c);d=d[0].next();if(null===d)return wc;c=c.replace(/^file:\/(?!\/)/,"file:///");d=d.toString();l=Kc.ud(d);if(!l&&!Kc.ud(c))throw new J("resolve-uri: invalid URI (base="+c+", relative="+d+")","FORG0002");if(!gb.td()&&/#/.test(c))throw new J("Base URI "+c+" contains a fragment identifier","FORG0002");d=l?d:gb.Ob(d,c).toString();return xc(f.anyURI.fromString(d))},reverse:function(d){return sc(d[0].expand().slice().reverse())},
root:function(d){return d[0].mapOneToMany(function(c){return I.$a(c)}).filter(function(c){return!Kc.Za(c)})},round:function(d){return d[0].mapOneToOne(function(c){return c.round(d[1]?Math.min(d[1].next().value,1E4):0)})},"round-half-to-even":function(d){return d[0].mapOneToOne(function(c){return c.Hf(d[1]?Math.min(d[1].next().value,1E4):0)})},"seconds-from-dateTime":function(d){return e(d,"seconds")},"seconds-from-duration":function(d){return k(d,5)},"seconds-from-time":function(d){return e(d,"seconds")},
serialize:function(d,c){function l(X){X.method="xml";X.indent=!1;X["omit-xml-declaration"]=!0}var t=new Re.StringWriter,r={};if(d[1]){var A=d[1].next();if(null===A)l(r);else if(Kc.X(A)){if(!Kc.Ch(A))throw new J("Second argument supplied to serialize() is wrong kind of node","XPTY0004");Re.Ef(A,r,"XPTY0004")}else{if(!(A instanceof Mc))throw new J("Second argument supplied to serialize() does not match required type","XPTY0004");var M=function(X,ka,qa,Q,ja){ja=void 0===ja?!1:ja;var D=Ab.XS.string.fromString(X);
if(A.containsKey(D)){var C=A.get(D);if(0<C.length&&Nd.isArray(C[0]))if(qa){var y=[];C[0].flatten(function(K){return y.push(K)});C=y}else throw new J("fn:serialize(): supplied value of "+D+" is invalid","XPTY0004");if(1!==C.length&&!qa&&!ja||!C.every(function(K){return"AZ"===K.code||ka.matches(K)}))throw new J("fn:serialize(): supplied value of "+D+" is invalid","XPTY0004");r[X]=ja&&0===C.length?null:Q(qa?C:"AZ"===C[0].code?ka.O(C[0]):C[0])}};"doctype-public doctype-system encoding item-separator json-node-output-method media-type method normalization-form version".split(" ").forEach(function(X){return M(X,
Ab.XS.string,!1,function(ka){return ka.toString()})});"allow-duplicate-names byte-order-mark escape-uri-attributes include-content-type indent omit-xml-declaration undeclare-prefixes".split(" ").forEach(function(X){return M(X,Ab.XS.boolean,!1,function(ka){return ka.toBoolean()})});M("standalone",Ab.XS.boolean,!1,function(X){return X.toBoolean()},!0);M("html-version",Ab.XS.ub,!1,function(X){return X.toNumber()});["cdata-section-elements","suppress-indentation"].forEach(function(X){return M(X,Ab.XS.QName,
!0,function(ka){for(var qa="",Q=0;Q<ka.length;Q++)qa+=ka[Q].$()+" ";return qa})});(function(X){var ka=Ab.XS.string.fromString(X);if(A.containsKey(ka)){var qa=A.get(ka);if(1!==qa.length||!qa.every(function(D){return D instanceof Mc}))throw new J("fn:serialize(): supplied value of "+ka+" is invalid","XPTY0004");qa=qa[0];var Q=function(D){return f.string.matches(D)};if(!qa.conforms(Q,Q,function(D){return 1===D}))throw new J("fn:serialize(): supplied value of "+ka+" is invalid","XPTY0004");ka=Nd.tb(qa,
!0);qa={min:0,max:0,Aa:{}};for(var ja in ka)if(ka.hasOwnProperty(ja)){if(1<ja.length)throw new J("fn:serialize(): Keys in a character map must all be one-character strings. Found "+ja,"SEPM0016");Q=ja.codePointAt(0);0===qa.min?qa.min=Q:Q<qa.min&&(qa.min=Q);Q>qa.max&&(qa.max=Q);qa.Aa[Q]=ka[ja]}r[X]=qa}})("use-character-maps")}}else l(r);Re.serialize(d[0].expand(),r,t,c);return Cc(t.toString())},snapshot:function(d,c,l){return d[0].mapOneToOne(function(t){function r(ja){if(0<ja.length){var D=ja.pop();
Ke.Xh(D,c,"cm",function(){I.La(D).forEachItem(function(C){return M.append(C)});0<ja.length?r(ja):(Ke.Ff(t,c,M,l,!0,!0),Q=A.uc.lastChild)},M)}}var A=Fe(c),M=Ke.Oc(A);if(!Kc.Za(t))return Ke.Ff(t,c,M,l,!0,!0),M.close(),sc(A.Pb).next();var X=t.nodeType,ka=Kc.da(t);[2,13].includes(X)&&(t=Kc.Za(t));M.Ja();var qa=I.gd(t).expand();if(0<qa.length)r(qa);else{Ke.Ff(t,c,M,l,!0,!0);var Q=A.uc.lastChild}M.Ca();Q||ib.la("snapshot: failed to find marked node");switch(X){case 2:return Kc.Ka.La(Q).filter(function(ja){return Kc.da(ja).equals(ka)}).next();
case 13:return Kc.Ka.wb(Q).filter(function(ja){return Kc.da(ja).equals(ka)}).next();default:return Q}})},sort:function(d,c,l){var t=re.pf(l,d[1],c),r=Nd.hc;if(d[2]){var A=d[2].next();r=function(M){return A.evaluate([[M]],c,l)}}return sc(d[0].expand().slice().sort(function(M,X){return d[1]?se(r(M),r(X),t):se(r(M),r(X))}))},"starts-with":function(d,c,l){var t=u(d[0]),r=u(d[1]);d=re.Wb(l,d[2],"startsWith",c);return Gc(d(t,r))},"static-base-uri":function(d,c,l){return(d=c.fixed.ka.Qa(l,c))?xc(f.anyURI.fromString(d)):
wc},"stream-available":function(){return Gc(!1)},string:function(d,c){c=c.fixed.ka;d=d[0].next();if(!d)return Cc("");if(c.isArray(d)||c.isMap(d)||c.Td(d))throw new J("Input to string() is a function item ","FOTY0014");return c.hc(d).mapOneToOne(function(l){return f.string.O(l)})},"string-join":function(d){for(var c="",l=d[1]?d[1].next().value:"",t,r=!0;null!==(t=d[0].next());)r||(c+=l),c+=t.toString(),r=!1;return Cc(c)},"string-length":function(d){return Dc(tb(u(d[0])).length)},"string-to-codepoints":function(d){return sc(tb(u(d[0])).map(f.integer.fromNumber))},
subsequence:function(d){var c=Math.round(d[1].next().toNumber()),l=d[2]?c+Math.round(d[2].next().toNumber()):Number.POSITIVE_INFINITY;return new vc(d[0],c,l)},substring:function(d){var c=Math.round(d[1].next().toNumber()),l=d[2]?c+Math.round(d[2].next().toNumber()):Number.POSITIVE_INFINITY;d=new uc(u(d[0])[Symbol.iterator]());c=new vc(d,c,l);return Cc(c.expand().join(""))},"substring-after":function(d,c,l){var t="",r=u(d[0]),A=u(d[1]),M;-1!==(M=re.Wb(l,d[2],"indexOf",c)(r,A))&&(t=r.substring(M+A.length));
return Cc(t)},"substring-before":function(d,c,l){var t="",r=u(d[0]),A=u(d[1]),M;-1!==(M=re.Wb(l,d[2],"indexOf",c)(r,A))&&(t=r.substring(0,M));return Cc(t)},sum:function(d,c){c=d[0].mapOneToOne(c.fixed.ka.ii);var l=c.next();if(null===l)return 1===d.length?Dc(0):d[1];try{return Vb["a+a"](l,l),xc(n(c,l,Vb["a+a"]))}catch(t){throw t instanceof J&&"XPTY0004"===t.code&&(t.code="FORG0006"),t;}},"system-property":function(d,c,l){d=f.QName.fromEQName(b(d[0],c,l,"XTDE1390"));c="";d.uri===Ic.dd&&(c={version:"3.0",
vendor:"Saxonica","vendor-url":"http://www.saxonica.com/","product-name":"Saxon-JS","product-version":"2.1","is-schema-aware":"no","supports-serialization":"yes","supports-backwards-compatibility":"yes","supports-namespace-axis":"yes","supports-streaming":"no","supports-dynamic-evaluation":"yes","supports-higher-order-functions":"yes","xpath-version":"3.1","xsd-version":"1.1"}[d.local]);d.uri===Ic.zb&&(c=gb.Si()[d.local]);c||(c="");return Cc(c)},tail:function(d){var c=Bc(d[0]);return c.filter(function(){return 1!==
c.position})},"timezone-from-date":function(d){return e(d,"timezone")},"timezone-from-dateTime":function(d){return e(d,"timezone")},"timezone-from-time":function(d){return e(d,"timezone")},tokenize:function(d){var c=u(d[0]);d[1]||(c=mb(c));if(""===c)return wc;var l=d[1]?d[1].next().toString():"[ \\n\\r\\t]+";l=Ke.ag(c,l,d[2]?d[2].next().toString():"",!1,!0);c=d[1]&&l[0].Xc;var t=l[l.length-1];d=d[1]&&t.Xc;l=l.filter(function(r){return!r.Xc}).map(function(r){return r.string});c&&l.unshift(f.string.fromString(""));
d&&l.push(f.string.fromString(""));return sc(l)},trace:function(d){var c=d[0].expand();ib.Sa("fn:trace: "+(2===d.length?d[1].next().toString()+": ":"")+ib.showValue(c),1);return sc(c)},transform:function(d,c,l){function t(T){T=f.string.fromString(T);return y.get(T)}function r(T,fa){throw new J(T,void 0===fa?"FOXT0002":fa);}function A(T,fa,xa){xa=void 0===xa?function(m){return m}:xa;(T=t(T))&&(T=sc(T).wa())&&(K[fa]=xa(T))}function M(T){return function(fa){fa instanceof Mc||r("The value of the option "+
T+" in fn:transform() must be of type map(xs:QName,item()*)");if(fa.conforms(function(xa){f.QName.matches(xa)||r("The keys of the "+T+" option in fn:transform() must be of type xs:QName. Supplied value:"+xa.toString());return!0},function(){return!0},function(){return!0}))return fa}}function X(T,fa){A(T,fa,M(T))}function ka(T){T.C&&T.C.forEach(function(fa){fa.parentNode=T;ka(fa)})}function qa(T,fa,xa){if(!D.N)throw new J("No XSLT processor is available to support fn:transform(). If using SaxonJS2.rt.js, it does not contain an XSLT compiler ",
"FOXT0001",l);var m={stylesheetParams:new Mc,tunnelParams:{},functionParams:{},deliverResultDocument:{},isDynamicStylesheet:!0,destination:"application",initialMode:"compile-complete",templateParams:{"Q{}options":{noXPath:!1}}},G;for(G in fa)"compiler.options"===G?m.tunnelParams["Q{}compiler.options"]=fa[G]:m.templateParams["Q{}options"][G]=fa[G];m.stylesheetParams.inSituPut(Ab.XS.QName.fromParts("","","staticParameters"),[xa]);m.stylesheetFileName="file:///compiler.sef";m.outputProperties={};ka(D);
fa=Q.checkOptions(m);Q.internalTransform(D,T,fa);return fa.principalResult}var Q=te.find("SaxonJS"),ja=c.fixed.ka,D=gb.Gf("compiler"),C=!1,y=d[0].next(),K={stylesheetParams:new Mc,templateParams:new Mc,tunnelParams:new Mc,functionParams:{},outputProperties:{},isDynamicStylesheet:!0,language:"XSLT",messages:[],resultDocuments:{},resultForm:"xdm"};if((d=t("requested-properties"))&&d[0]){var U=function(T,fa){r("No processor with property "+T.$()+"="+fa+"() available for fn:transform()","FOXT0001")};
!(1<d.length)&&d[0]instanceof Mc||r("The value of the 'requested-properties' option for fn:transform() must be of type map(*)");d=d[0];d.forAllPairs(function(T){var fa=T.v[0];switch(T.k.local){case "supports-streaming":(fa=fa.toBoolean())&&U(T.k,fa);break;case "supports-namespace-axis":fa=fa.toBoolean();U(T.k,fa);break;case "supports-backwards-compatibility":(fa=fa.toBoolean())||U(T.k,fa);break;case "product-name":fa=fa.toString();"SaxonJS"!==fa&&U(T.k,fa);break;case "vendor":fa=fa.toString(),"Saxonica"!==
fa&&U(T.k,fa)}})}var O=t("vendor-options");O&&O[0]?(!(1<O.length)&&O[0]instanceof Mc||r("The value of the 'vendor-options' option for fn:transform() must be of type map(*)"),O=O[0]):O=null;A("stylesheet-location","stylesheetLocation");A("stylesheet-node","stylesheetNode");A("stylesheet-text","stylesheetText");A("package-location","packageLocation");A("package-node","packageNode");A("package-text","packageText");var Z=0;"stylesheetLocation stylesheetNode stylesheetText packageLocation packageNode packageText".split(" ").forEach(function(T){K[T]&&
Z++});1!==Z&&r("A stylesheet must be supplied as an option to fn:transform(), by exactly one of 'stylesheet-location', 'stylesheet-node', 'stylesheet-text', 'package-location', 'package-node' or 'package-text'");A("source-node","sourceNode");A("global-context-item","globalContextItem");(d=t("source-node")[0])&&!t("global-context-item")[0]&&Kc.Za(d)&&(K.globalContextItem=d.ownerDocument);(d=t("initial-match-selection"))&&0<d.length&&(K.initialSelection=d);A("initial-template","initialTemplate",function(T){return T.$()});
A("initial-mode","initialMode",function(T){return["#unnamed","#default"].includes(T.toString())?T.toString():T.$()});A("initial-function","initialFunction",function(T){return T.$()});A("static-params","staticParams");A("base-output-uri","baseOutputURI");A("stylesheet-base-uri","stylesheetBaseURI",function(T){return T.toString()});A("delivery-format","destination",function(T){return T.toString()});var aa=K.destination;aa&&!["raw","document","serialized"].includes(aa)&&r("The value of the delivery-format option to fn:transform() must be one of 'raw', 'document'. 'serialized'. Supplied value is "+
aa);aa||(aa="document");d=(d=t("enable-messages"))&&1===d.length?d[0].toBoolean():!1;var ca=t("enable-assertions");ca=ca&&1===ca.length?ca[0].toBoolean():!1;var oa=null,ea=null;if(K.stylesheetNode){var na=K.stylesheetNode._saxonBaseUri;ea=gb.createDocument();var x=K.stylesheetNode;if(Kc.Ae(x)){x=x.childNodes;for(var F=0;F<x.length;F++)ea.appendChild(x[F].cloneNode(!0))}else ea=x.cloneNode(!0),ea.appendChild(x);ea._saxonBaseUri=na;ea._saxonDocUri=na}if(K.stylesheetLocation){na=ja.Qa(l,c);na=gb.Ob(K.stylesheetLocation.toString(),
na);K.stylesheetLocation=na;try{ea=xe.Lc(na,c)}catch(T){r("Error fetching stylesheet in fn:transform():"+T.code+" "+T.message)}ea._saxonBaseUri=na;ea._saxonDocUri=na}K.stylesheetText&&(ea=gb.parseXmlFromString(K.stylesheetText.toString(),!0),ea._saxonBaseUri="NoStylesheetBaseURI",ea._saxonDocUri="NoStylesheetBaseURI");ea&&K.stylesheetBaseURI&&(na=K.stylesheetBaseURI,ea._saxonBaseUri=na,ea._saxonDocUri=na);K.packageNode&&(na=K.packageNode,Kc.Ae(na)?oa=Q.XPath.sefToJSON(na.firstChild):r("The value of the 'package-node' option of fn:transform() must be a single document or element node"));
K.packageText&&(oa=JSON.parse(K.packageText.toString()));ea&&(oa=K.staticParams?K.staticParams:new Mc,na=new Mc,x=!1,F=f.QName.fromParts("saxon",Ic.zb,"compiler.options"),O&&O.containsKey(F)&&(na=O.get(F)[0]),F=f.QName.fromParts("saxon",Ic.zb,"allowSyntaxExtensions"),O&&O.containsKey(F)&&(x=O.get(F)[0]),oa=qa(ea,{"enable-assertions":ca,"compiler.options":na,allowSyntaxExtensions:x},oa),oa=Q.XPath.sefToJSON(oa.firstChild));K.sourceNode&&K.initialSelection&&r("Only one of 'source-node' or 'initial-match-selection' must be supplied to fn:transform() for apply-templates invocation");
if(K.sourceNode){O=K.sourceNode;var P=gb.createDocument();if(Kc.Ae(O))for(ca=O.childNodes,na=0;na<ca.length;na++)P.appendChild(ca[na].cloneNode(!0));else P=x=O.cloneNode(!0);P._saxonBaseUri=O._saxonBaseUri;P._saxonDocUri=O._saxonDocUri}K.baseOutputURI||(K.baseOutputURI="",C=!0);X("stylesheet-params","stylesheetParams");X("template-params","templateParams");X("tunnel-params","tunnelParams");A("function-params","functionParams",function(T){ib.assert(T instanceof Kd);return T.value});"serialized"===
aa&&A("serialization-params","outputProperties",function(T){return ja.tb(T,!0)});A("output","output");K.deliverResultDocument=function(){var T=this;return{destination:aa,save:function(fa,xa){T.resultDocuments[fa.toString()]="serialized"===aa?[Ab.XS.string.fromString(xa)]:Kc.X(xa)?[xa]:xa}}};K.deliverMessage=function(T){gb.xh()(T);this.Jh.push([T])};var V={};O=sc(t("collections")).wa();null!==O&&(V=ja.tb(O,!0));K.collectionFinder=function(T){return"undefined"===typeof T?null:V[T]};O=!["raw","serialized"].includes(aa);
if(null!==ea)for(ca=Aa(ea.documentElement.childNodes),na=ca.next();!na.done;na=ca.next()){if(na=na.value,"output"===na.nodeName&&!na.name){ca=Aa(na.childNodes);for(na=ca.next();!na.done;na=ca.next())switch(na=na.value,x=na.getAttribute("value"),na.getAttribute("name")){case "build-tree":O="true"===x}break}}else if(null!==oa)for(ca=Aa(a(oa,"output")),na=ca.next();!na.done;na=ca.next())for(na=Aa(a(na.value,"property")),x=na.next();!x.done;x=na.next());try{null===oa?oa=Q.XPath.sefToJSON(ea.documentElement):
ka(oa);var R=Q.checkOptions(K);Q.internalTransform(oa,P,R);var w=R.principalResult}catch(T){if(T instanceof J&&T.Ze)throw new J(T.message,T.code.toString());throw T;}if(w&&O){ib.assert(Array.isArray(w));c=Fe(c);var Y=Ke.Oc(c);Y.Ja();w.forEach(function(T){return Y.append(T)});Y.Ca();Y.close();w=c.Pb}Array.isArray(w)&&1===w.length&&Array.isArray(w[0])&&(w=w[0]);c=R.resultDocuments[""];if(void 0!==c){if(Array.isArray(w)?0<w.length:null!=w)throw new J("A result document on the base output URI has already been created",
"XTDE1490");w=c}c=new Mc;w&&c.inSituPut(C?f.string.fromString("output"):R.ic,Array.isArray(w)?w:[w]);d&&c.inSituPut(f.string.fromString("messages"),[new Kd(R.Jh)]);for(var ia in R.resultDocuments)ib.Sa("xsl:result-document href="+ia,2),c.inSituPut(f.anyURI.fromString(ia),R.resultDocuments[ia]);return xc(c)},translate:function(d){var c=tb(u(d[0])),l=tb(u(d[1])),t=tb(u(d[2])),r=[];c.forEach(function(A){var M;-1!==(M=l.indexOf(A))?M<t.length&&r.push(t[M]):r.push(A)});return Cc(wb(r))},"true":function(){return Gc(!0)},
"type-available":function(d,c,l){d=b(d[0],c,l,"XTDE1428").split("}");return Gc("Q{http://www.w3.org/2001/XMLSchema"===d[0]&&(d[1]in Ab.type||["untyped","anyType","anySimpleType"].includes(d[1])))},unordered:function(d){return d[0]},"unparsed-entity-public-id":function(){return wc},"unparsed-entity-uri":function(){return wc},"unparsed-text":function(d,c,l){var t=xe.bf(d,c,l);if(!t)return wc;d=xe.eg(d[1]?u(d[1]):"");return Cc(xe.lg(t,c,l,d))},"unparsed-text-available":function(d,c,l){return xe.Sh(function(){var t=
xe.bf(d,c,l),r=xe.eg(d[1]?u(d[1]):"");xe.lg(t,c,l,r)})},"unparsed-text-lines":function(d,c,l){var t=xe.bf(d,c,l);if(!t)return wc;d=xe.eg(d[1]?u(d[1]):"");c=xe.lg(t,c,l,d).split(/\r?\n|\r/);0<c.length&&""===c[c.length-1]&&--c.length;return sc(c).mapOneToOne(f.string.fromString)},"upper-case":function(d){return Cc(u(d[0]).toUpperCase())},"uri-collection":function(){return wc},"xml-to-json":function(d){var c=d[1]?d[1].next():new Mc;return d[0].mapOneToOne(function(l){return f.string.fromString(we.dk(l,
c))})},"year-from-date":function(d){return e(d,"getUTCFullYear")},"year-from-dateTime":function(d){return e(d,"getUTCFullYear")},"years-from-duration":function(d){return k(d,0)},"zero-or-one":function(d){d=d[0].expand();if(1<d.length)throw new J("Required length 0-1, actual length "+d.length,"FORG0003");return xc(d[0]?d[0]:null)}}}();
// Input 36
var Te=function(){function a(n,g,e){var k=e.fixed.ka,p=g.name||"#unnamed";g=k.Db(g,"sortKey");k=g.map(k.Va);var v=re.bj(g,e);g=[];var B=e.ca(!1);for(B.focus=Bc(n);null!==(n=B.focus.next());)e=k.map(function(q,f){var I=B.ca(!1);I.focus=Ac(B.focus.current);q=q(I);if((I=q.next())&&q.next())throw new J("Multiple values in merge key","XTTE1020");return v[f](I)}),g.push({item:n,keys:e,Kh:p});return g}function b(n,g,e){function k(q){var f=p[p.length-1];if(f&&0<e(f,q))throw new J("Input to xsl:merge is not correctly sorted",
"XTDE2220");p.push(q)}for(var p=[],v=0,B=0;;)if(v>=n.length){for(;B<g.length;)k(g[B++]);break}else if(B>=g.length){for(;v<n.length;)k(n[v++]);break}else 0>=e(n[v],g[B])?k(n[v++]):k(g[B++]);return p}function h(n,g){return g.fixed.ka.Db(n,"sortKey").map(function(e){return re.Yj(e,g)})}function u(n){return function(g,e){for(var k=0;k<n.length;k++){var p=n[k](g.keys[k],e.keys[k]);if(0!==p)return p}return 0}}function z(n,g,e){for(var k=null,p=[],v={},B=0;B<g.length;B++){var q=g[B];k=null===k?-1:e(k,q);
if(0>k){v={items:[q.item],keys:q.keys,groups:{}};for(k=0;k<n.length;k++)v.groups[n[k].name||"#unnamed"]=[];v.groups[q.Kh]=[q.item];p.push(v)}else if(0===k)v.items.push(q.item),v.groups[q.Kh].push(q.item);else throw new J("Group Input to xsl:merge is not correctly sorted","XTDE2220");k=q}return p}return{Cj:function(n,g){var e=g.fixed.ka,k=e.Db(n,"mergeSrc"),p=g.ca();p.sa="merge-key";for(var v=k.map(function(X){return h(X,p)}),B=v[0],q=1;q<v.length;q++)for(var f=0;f<B.length;f++){var I=B[f],d=v[q][f],
c=I.lang===d.lang||null!==I.lang&&I.lang.equals(d.lang);if(I.order!==d.order||!c||I.gf!==d.gf||I.dataType!==d.dataType)throw new J("All merge sources must have compatible merge keys","XTDE2210",n);}v=B.map(function(X){return re.Ei(X,p)});v=u(v);B=null;for(q=0;q<k.length;q++){f=k[q];I=e.ea(f,"forEachItem");d=e.ea(f,"selectRows");try{if(I){var l=g.ca(!1);for(l.focus=Bc(e.evaluate(I,g));null!==l.focus.next();){var t=e.evaluate(d,l),r=a(t,f,l);B=null===B?r:b(B,r,v)}}else{var A=e.evaluate(d,g),M=a(A,f,
g);B=null===B?M:b(B,M,v)}}catch(X){if(X instanceof J&&"XPTY0004"===X.code)throw new J(X.message,"XTTE2230",n);throw X;}}if(null===B)return wc;k=z(k,B,v);n=e.ea(n,"action");l=k.map(function(X){return X.items[0]});g=g.ca(!1);g.focus=Bc(sc(l));l=[];for(t=0;t<k.length;t++)for(g.focus.next(),g.Eb=k[t],r=e.evaluate(n,g),A=void 0;null!==(A=r.next());)l.push(A);return sc(l)}}}();
// Input 37
var Ue=function(){function a(h,u){for(var z in h)if(z==u)return{k:u,v:h[u]};return null}function b(){}b.prototype={containsKey:function(h){return null!==a(this,h)},forAllPairs:function(h){for(var u in this)this.__proto__.hasOwnProperty(u)||h({k:u,v:this[u]})},get:function(h){var u=a(this,h);if(null===u)throw new J("'"+h+"' is not a permitted key for the given tuple type","XTTE9999");return u.v},keys:function(){var h=[];this.forAllPairs(function(u){h.push(Ab.XS.string.fromString(u.k))});return h},
put:function(h,u){var z=new b,n=!1,g;for(g in this)z[g]=this[g],g==h&&(n=!0,z[h]=u);if(!n)throw new J("'"+h+"' is not a permitted key for the given tuple type","XTTE9999");return z},size:function(h){var u=0;h[0].next().forAllPairs(function(){u++});return Dc(u)}};return b}();
// Input 38
var Nd=function(){function a(){}function b(m){var G=(m.C||[]).map(A);return function(N){function H(){var E,da,S,ta,wa;return Xa(H,function(va){1==va.b&&(E=Aa(G),da=E.next());if(5!=va.b){if(da.done){va.b=0;return}S=da.value;ta=S(N);wa=void 0}if(null===(wa=ta.next()))da=E.next(),va.b=2;else return Pa(va,wa,5)})}return new uc(H())}}function h(m){function G(S){switch(S){case 1:return"first";case 2:return"second";case 3:return"third";default:return S+"th"}}m=m.split("|");var N=m[0],H=m[1],E=""===m[2]?
"XPTY0004":m[2],da=m[3];return{required:function(){switch(parseInt(N,10)){case 0:return G(+H+1)+" argument of "+(""===da?"anonymous function":da+"()");case 1:return G(+H+1)+" operand of '"+da+"'";case 9:return"operand of '-'";case 2:return"value in '"+da+"' expression";case 3:return"saxon:context-item"===da?"context item":"value of variable $"+da;case 4:var S=da.split("/");return"@"+S[1]+" attribute of "+("LRE"===S[0]?"a literal result element":S[0]);case 5:return"result of call to "+(""===da?"anonymous function":
"function "+da);case 7:return"result of template "+da;case 6:return G(+H+1)+" sort key";case 8:return"value of parameter $"+da;case 11:return"value of the grouping key";case 12:return"result of xsl:evaluate";case 13:return"the context item";case 14:return"the context item for the "+this.$k(H)+" axis";case 15:return"the value of the "+da+" option";case 17:return"document-order sorter";default:return""}}(),code:E}}function u(m){m=m.trim();if(["true","yes","1"].includes(m))return!0;if(["false","no",
"0"].includes(m))return!1;throw new J("Value must be yes|true|1 or no|false|0","XTDE0030");}function z(m){if(Y.X(m))return xc(n(m));if(ca(m))return zc(m.value.map(function(G){return sc(G)})).mapOneToMany(z);if(ea(m))throw new J("Cannot atomize a "+(oa(m)?"map":"function"),"FOTY0013");return xc(m)}function n(m){var G=T.string.fromString,N=T.untypedAtomic.fromString;switch(m.nodeType){case 9:return N(m.documentElement.textContent);case 11:case 1:case 3:case 4:return N(m.textContent||"");case 2:return N(m.value);
case 8:return G(m.textContent);case 7:return G(m.textContent);case 13:return G(m.uri);default:throw new J("Unknown node type "+m.nodeType,"SXJS0003");}}function g(m){return sc(m).mapOneToMany(z).expand()}function e(m){function G(E){throw new J("Effective boolean value is not defined for "+E,"FORG0006");}function N(){if(null!==m.next())G("an atomic sequence of length > 1");else return!0}var H=m.next();if(null===H)return!1;if(Y.X(H))return!0;if(Ab.qa(H)){if("AB"===H.code)return N()&&H.toBoolean();if(H.isNumeric())return H=
H.toNumber(),N()&&!isNaN(H)&&0!==H;if(zb(H))return N()&&0!==H.toString().length;G("type "+Ab.wd(H.code))}else oa(H)?G("maps"):ca(H)?G("arrays"):ea(H)?G("functions"):G("value "+ib.showValue(H))}function k(m,G){var N=Vb["i+i"],H=T.integer.fromNumber(1);return new kc(m,function(E){E=N(E,H);return 0<E.compareTo(G)?null:E})}function p(m,G){return m.C?m.C.filter(function(N){return N.N===G}):[]}function v(m,G,N){m=p(m,"withParam");var H=[new Mc,new Mc];G.Qb.forAllPairs(function(da){return H[1].inSituPut(da.k,
da.v)});var E=G.sa;G.sa="template parameter";m.forEach(function(da){var S=da.name,ta=X(da,"t")?1:0;H[ta].inSituPut(Ab.XS.QName.fromEQName(S),t(da)(G).expand())});G.sa=E;N.Wc=H[0];N.Qb=H[1]}function B(m){return!/[ \t\r\n]/.test(m)}function q(m,G,N){if(m){m=m.split(/\r?\n/);for(var H=0;H<m.length;H++)if(m[H]){var E=m[H].split("="),da=E[0];E=E[1];"item-separator"===da&&(E=E.replace(/%n/g,"\n").replace(/%%/g,"%"));E="parameter-document"===da?void 0:"use-character-maps"===da?Re.Lf(da,E,N):Re.Mf(da,E);
"undefined"!==typeof E&&(G[da]=Re.Df(da,E,G[da]))}}}function f(m,G){var N=t(m),H=r(m);return function(E){try{var da=N(E).next(),S=H(E).next();return null===da||null===S?null:G(da,S)}catch(ta){Z(ta,m)}}}function I(m,G){if(G.fixed.staticBaseURI)return G.fixed.staticBaseURI;for(;;){if(m.baseUri)return m.baseUri;m=m.parentNode;if(!m)return null}}function d(m,G,N){N=void 0===N?!1:N;var H=Y.Qc(G.ja).createDocumentFragment();H=Y.Ec(H);H._saxonBaseUri=I(m,G);var E=G.ca(!1);N&&(E.ja=G.masterDocument);Md.Aj(H,
A(m)(E),E);return H}function c(m){var G=t(m),N=r(m),H=m.op;return function(E){var da=G(E),S=N(E).expand();E=re.re(m.comp,E);return re.gc(da,S,H,E,m)}}function l(m,G){throw new J("The "+m+" construct is not implemented in Saxon-JS","SXJS0002",G);}function t(m){return A(m.C[0])}function r(m){return A(m.C[1])}function A(m){if(m){var G=m.ELAB;G||((G=xa[m.N])||ib.la("Unknown expr: "+m.N+" parent="+m.p),G=m.ELAB=G(m));return G}}function M(m,G){m=m.C||[];for(var N=0;N<m.length;N++){var H=m[N];if(H.role===
G)return H}return null}function X(m,G){return(m=m.flags)?0<=m.indexOf(G):!1}function ka(m){var G={};m=qa(m,"ns");if(null!==m){m=m.split(" ");for(var N=0;N<m.length;N++){var H=m[N].split("=");G[H[0]]="~"===H[1]?Ic.Jc[H[0]]:H[1]}}return G}function qa(m,G){for(;m;){if(m[G])return m[G];m=m.parentNode}return null}function Q(m,G){var N=ka(m);return function(H){if(""===H&&!G)return"";var E=N[H];if(E)return E;if(""===H)return"";throw new J("Undeclared prefix: "+H,"XTDE0290",m);}}function ja(m,G,N){return T.QName.fromString(G,
Q(m,N))}function D(m,G,N){if(null!==m&&0<N){var H=Y.getAttribute(m,G);return null!==H&&""!==H?H:D(m.parentNode,G,N-1)}}function C(m,G,N){var H=m.bSlot;if(H){H=parseInt(H,10);m=G.ua.ef[H];var E=G.fixed.Na[m]}else(m=m.coId)?E=G.fixed.Na[parseInt(m,10)]:ib.la("No binding slot or component Id for component");E||ib.la("Target component not found (slot="+H+" id="+m+")");if("ABSTRACT"===E.visibility)throw new J("Abstract "+E.actor.N+" "+E.actor.name+" has no implementation","XTDE3052");N&&E.actor.N!==N&&
ib.la("Expected "+N+", found "+E.actor.N);return E}function y(m,G,N,H){m=M(G.actor,"body");m=A(m);H=H.ca(!0);H.ua=G;Rd(H);H.sa="function";H.ib={};H.cb=null;H.focus=null;H.Zb=[];H.pa=N;return m(H)}function K(m,G){for(m=m.parentNode;"package"!==m.N;)m=m.parentNode;return G.fixed.yd[m.name]}function U(m,G){return A(m)(G)}function O(m){return function(G){var N=Fe(G),H=Ke.Oc(N);Ke.push(m,G,H);H.close();return sc(N.Pb)}}function Z(m,G){m instanceof J&&!m.xsltLineNr&&hb(m,G);throw m;}function aa(m,G){function N(E){return"Context item for template "+
m.name+" must be "+E}if(Pe(G)&&-1===m.flags.indexOf("o"))throw new J(N("supplied"),"XTTE3090",m);Pe(G)||-1!==m.flags.indexOf("s")||(G.focus={});var H=m.cxt;if(H&&!Pe(G)&&!Xc(H).ia()(Od(G)))throw new J(N("instance of "+Xc(H).toString()),"XTTE0590",m);}function ca(m){return m instanceof Kd}function oa(m){return m instanceof Mc||m instanceof x}function ea(m){return m instanceof Ld.XdmFunction||ca(m)||oa(m)}function na(m,G,N){ib.assert(Array.isArray(m));ib.assert(G instanceof Oc);var H=G.V(),E=H.p;if(H.o&&
!oe(H.o)(m.length))throw new J("Supplied value "+ib.showValue(m)+" does not match required cardinality "+H.o+(N?" in "+N():""),"XPTY0004");if(0<m.length){if(G.T.qa()){m=Nd.zi(m);var da=Ab.XS.untypedAtomic;if(Vd(G.T))m=m.map(function(va){return va.isNumeric()?va:da.matches(va)?Ab.XS.double.O(va):va instanceof x?Ab.XS.double.fromString(va.value.toString()):va});else{var S=Ab.bb[E],ta=S.O;"A"!==E&&(m=m.map(function(va){return da.matches(va)?ta(va):va instanceof x?S.fromString(va.value.toString()):va}));
["AO","AF"].includes(E)&&(m=m.map(function(va){return va.isNumeric()?S.O(va):va}));"AS"===E&&(m=m.map(function(va){return Ab.XS.anyURI.matches(va)?S.O(va):va}))}}else if(G.T.Td())if(["FM","FA"].includes(E))m=V(m);else if(G.T instanceof Pd){var wa=G.T.ia();m.every(function(va){return wa(va)})||(m=m.map(function(va){if(wa(va))return va;if(va instanceof Ld.XdmFunction){var Na=G.T,Ua=Na.g.length;Na={minArgs:Ua,maxArgs:Ua,sig:Na.ha(),alphaTree:Na.V()};return new Ld.Jg(Na,va)}return va}))}if(!G.matches(m))throw new J("Supplied value "+
ib.showValue(m)+" does not match required type "+G.toString()+(N?" in "+N():""),"XPTY0004");}return m}function x(m){this.value=m}function F(m){this.value=m}function P(m){function G(H){switch(typeof H){case "undefined":return[];case "string":return T.untypedAtomic.fromString(H);case "number":return T.double.fromNumber(H);case "boolean":return T.boolean.fromBoolean(H);case "function":return new x(H);case "object":if(T.bg.matches(H)||Y.X(H))return H;if(H instanceof Kd)return H;if(H instanceof Ld.XdmFunction||
H instanceof Mc)return H;if(H instanceof x)return H;if(H instanceof Date)return T.dateTime.fromString(H.toISOString());if(Array.isArray(H)){var E=[];H.forEach(function(da){E.push(P(da))});return new Kd(E)}return null===H?[]:H instanceof F?H.value:new x(H);default:return new x(H)}}function N(H){var E=[];H.forEach(function(da){da=G(da);Array.isArray(da)&&0===da.length||E.push(da)});return E}if(Array.isArray(m))return N(m);m=G(m);return Array.isArray(m)&&0===m.length?[]:[m]}function V(m){function G(H){if(H instanceof
Kd){var E=[];H.value.forEach(function(va){return E.push(N(va))});return new Kd(E)}if(H instanceof x){var da=H.value;if("object"===typeof da){H=new Mc;for(var S in da)if(da.hasOwnProperty(S)){var ta=P(S)[0],wa=P(da[S]);H.inSituPut(ta,V(wa))}return H}}return H}function N(H){var E=[];H.forEach(function(da){da=G(da);Array.isArray(da)&&0===da.length||E.push(da)});return E}ib.assert(Array.isArray(m));return N(m)}function R(m,G){function N(E){if(Array.isArray(E))throw new J("convertItem() called for an array",
"SXJS0004");if(null===E)return null;if(T.string.matches(E)||T.anyURI.matches(E)||T.duration.matches(E)||T.hexBinary.matches(E)||T.base64Binary.matches(E)||T.untypedAtomic.matches(E))return E.toString();if(T.numeric.matches(E))return E.toNumber();if(T.boolean.matches(E))return E.toBoolean();if(T.dateTime.matches(E)||T.md.matches(E)||T.Md.matches(E)||T.time.matches(E)||T.wh.matches(E)||T.vh.matches(E)||T.uh.matches(E)||T.th.matches(E)||T.sh.matches(E))return E.toString();if(T.QName.matches(E))return E.$();
if(Y.X(E))return E;if(E instanceof Kd){var da=[];0!==E.value.length&&E.value.forEach(function(ta){da.push(R(ta,G))});return da}if(E instanceof Mc){if(G){var S={};E.forAllPairs(function(ta){S[ta.k.toString()]=R(ta.v,G)});return S}return new F(E)}return E instanceof F?E:E instanceof x?E.value:new F(E)}function H(E){var da=[];if(0===E.length)return null;if(1===E.length)return N(E[0]);E.forEach(function(S){da.push(N(S))});return da}G=void 0===G?!1:G;return Array.isArray(m)?H(m):N(m)}function w(m){return m.mapOneToMany(function(G){return ca(G)?
zc(G.value.map(function(N){return w(sc(N))})):xc(G)})}var Y=Kc,ia=Y.Ka,T=Ab.XS,fa,xa={acFnRef:function(m){var G=T.QName.fromEQName(m.name),N=te.find("SaxonJS").XPath;return function(H){H=H.ca(!0);H.eb=!0;if(H=N.getFnSignature(G,1,H))return H=new Ld.Qf(H,G,m),xc(H);throw new J("Reference to an unknown constructor function "+G+"#1","XPST0017",m);}},among:function(m){var G=t(m),N=r(m);return function(H){var E=G(H).next();return N(H).filter(function(da){return da===E})}},analyzeString:O,and:function(m){var G=
t(m),N=r(m);return function(H){return Gc(e(G(H))&&e(N(H)))}},applyImports:O,applyT:O,arith:function(m){var G=Vb[m.calc];G||l("arithmetic ("+m.calc+")",m);var N=f(m,G);return function(H){return xc(N(H))}},arith10:function(m){function G(da){da=da.next();return null===da?T.double.fromNumber(NaN):zb(da)?T.double.nf(da.toString()):T.double.O(da)}var N=t(m),H=r(m),E=Vb[m.calc];return function(da){return xc(E(G(N(da)),G(H(da))))}},array:function(m){var G=(m.C||[]).map(A);return function(N){var H=G.map(function(E){return E(N).expand()});
return xc(new Kd(H))}},arrayBlock:function(m){var G=(m.C||[]).map(A);return function(N){var H=G.map(function(E){return E(N).expand()});return xc(new Kd(H))}},atomic:function(m){var G=Ab.bb[m.type].fromString(m.val);return function(){return xc(G)}},atomSing:function(m){var G=t(m);return m.card?function(N){return G(N).mapOneToMany(z)}:function(N){N=G(N).mapOneToMany(z);N=nc(N);if(null===N.wa())throw N=h(m.diag),new J("The "+N.required+" is empty",N.code,m);return N}},att:O,attVal:function(m){var G=
T.QName.fromEQName(m.name);return function(N){Qd(N,"XPDY0002","@"+G,m);N=Od(N);if(!Y.X(N))throw new J("Context item for 'attVal' must be a node","XPTY0020",m);if(1!==N.nodeType)return wc;N=""===G.uri?N.hasAttribute(G.local)?N.getAttribute(G.local):null:G.uri===Ic.xml?N.hasAttribute("xml:"+G.local)?N.getAttribute("xml:"+G.local):null:N.hasAttributeNS(G.uri,G.local)?N.getAttributeNS(G.uri,G.local):null;return null===N?wc:xc(T.untypedAtomic.fromString(N))}},axis:function(m){var G=Xc(m.nodeTest||"").ia(),
N=m.name;return function(H){Qd(H,"XPDY0002",N+" axis",m);H=Od(H);if(!Y.X(H))throw new J("Context item for "+N+" axis is not a node - supplied:"+ib.showValue(H),"XPTY0020",m);return Y.Xg[N](H).filter(G)}},"break":O,callT:function(m){return function(G){var N=C(m,G,"template"),H=G.ca(!0);H.Eb=null;H.ua=N;aa(N.actor,H);v(m,G,H);return t(N.actor)(H)}},cast:function(m){function G(va){va=N(va).next();if(null===va){if(H)return null;throw new J("Empty sequence casting to "+Xc(m.as).toString(),"XPTY0004",m);
}return va}var N=t(m),H=X(m,"e");if(X(m,"a")){var E=Yb(m.as),da="AQ"===E.p?Q(m,!0):null,S=Ab.bb[E.p].O;return function(va){va=G(va);if(null===va)return wc;try{var Na=S(va,da);return xc(Na)}catch(Ua){if("FORG0001"===Ua.code&&m.diag)throw Na=h(m.diag),new J("Required item type of "+Na.required+" is "+Xc(m.as).toString()+"; supplied value is "+ib.Yh(va),Na.code,m);if(null!==Ua.code)throw Ua;throw new J("Failed to cast "+ib.Yh(va)+" to type "+Xc(m.as).toString(),"XPTY0004",m);}}}if(X(m,"l")){var ta=Ab.XS.QName.fromEQName(m.as),
wa=Id[ta.local].fromString;ib.assert(ta.uri===Ic.qc);ib.assert(wa);return function(va){va=G(va);if(null===va)return wc;va=xb.collapse(va.toString()).split(" ").map(wa);if(0===va.length)throw new J("xs:"+ta.local+" value must contain at least one token","FORG0001");return sc(va)}}if(X(m,"u"))return function(va){va=G(va);return null===va?wc:Ab.XS.double.matches(va)||Ab.XS.ub.matches(va)||Ab.XS.float.matches(va)?xc(va):xc(Ab.XS.double.O(va))};throw new J("Unknown cast variety "+m.flags);},castable:function(m){var G=
t(m),N=X(m,"e");if(X(m,"a")){var H=Yb(m.as),E="AQ"===H.p?Q(m,!0):null,da=Ab.bb[H.p].O;var S=function(wa){da(wa,E);return!0}}else if(X(m,"l")){m=Ab.XS.QName.fromEQName(m.as);var ta=Id[m.local].fromString;S=function(wa){return 0<xb.collapse(wa.toString()).split(" ").map(ta).length}}else if(X(m,"u"))S=function(wa){Ab.XS.double.O(wa);return!0};else throw new J("Unknown castable variety "+m.flags);return function(wa){wa=G(wa);var va=wa.next();if(null===va)return Gc(N);if(null!==wa.next())return Gc(!1);
wa=z(va).next();try{return Gc(S(wa))}catch(Na){return Gc(!1)}}},cfn:function(m){var G=m.name,N=t(m);if("_format-number_1"===G){var H=Se["format-number"];return function(E){var da=[N(E),Cc(m.pic)];m.hasAttribute("format")&&da.push(Cc(m.format));return H(da,E,m)}}if("_translate_1"===G)return H=Se.translate,function(E){var da=[N(E),Cc(m.a1),Cc(m.a2)];return H(da,E)};ib.la("Unimplemented: cfn "+G,m)},check:function(m){function G(da){var S=h(m.diag);throw new J("Required cardinality of "+S.required+" is "+
H[N]+"; supplied value "+da,S.code,m);}var N=m.card,H={"*":"zero or more","+":"one or more","?":"zero or one",0:"zero",1:"exactly one"},E=t(m);return function(da){da=E(da);if("*"===N)return da;var S=nc(da);da=S.wa();if(null===da){if("?"===N||"0"===N)return wc;G("is empty")}else"0"===N&&G("is not empty (first item "+ib.showValue(da)+")");if("+"===N)return S;S.next();var ta=S.next();null!==ta&&(S=S.count()+2,G("contains "+S+" items ("+ib.showValue(da)+", "+ib.showValue(ta)+(2<S?",... )":")")));return xc(da)}},
choose:function(m){var G=m.C.map(A);return function(N){for(var H=0;H<G.length;H+=2)if(e(G[H](N)))return G[H+1](N);return wc}},coercedFn:function(m){var G=t(m);return function(N){return G(N)}},comment:O,compareToInt:function(m){var G=m.op,N=T.integer.fromString(m.val),H=t(m);return function(E){E=H(E);var da=E.next();if(null===da)return wc;if(re.Bc(da))return Gc("ne"===G);if(null!==E.next())throw new J("Value comparison only applicable to single values","XPTY0004",m);return Gc(re.Jf(G,da.compareTo(N)))}},
compareToString:function(m){var G=m.op,N=t(m),H=T.string.fromString(m.val);return function(E){var da=N(E),S=da.next();if(null===S)return wc;if(null!==da.next())throw new J("Value comparison only applicable to single values","XPTY0004",m);return m.comp?(E=re.re(m.comp,E),["=","eq","!=","ne"].includes(G)?Gc(re.ei(G,E.equals(S,H))):Gc(re.Jf(G,E.compare(S,H)))):Gc(re.Jf(G,S.compareTo(H)))}},compAtt:O,compElem:O,condCont:function(m){var G=t(m);return function(N){return G(N).filter(function(H){if(Y.X(H))switch(H.nodeType){case 9:case 11:case 1:return H.hasChildNodes();
default:return""!==n(H).toString()}else return oa(H)?0<H.keys().length:""!==T.string.O(H).toString()})}},condSeq:function(m){function G(H){if(Y.X(H)){if([9,11].includes(H.nodeType))return!H.hasChildNodes();if(3===H.nodeType)return""===H.nodeValue}else{if(Ab.qa(H))return""===T.string.O(H).toString();if(ca(H))return!w(xc(H)).some(function(E){return!G(E)})}return!1}var N=m.C.map(function(H){return{name:H.N,eval:A(H)}});return function(H){var E=[],da=[],S=!0;N.map(function(ta){if("onEmpty"===ta.name)S&&
(E=ta.eval(H).expand());else if("onNonEmpty"===ta.name){var wa=E.length;da.unshift(function(){E.splice.apply(E,[wa,0].concat(ta.eval(H).expand()))})}else ta.eval(H).forEachItem(function(va){G(va)||(S=!1);E.push(va)})});S||da.forEach(function(ta){ta()});return sc(E)}},conditionalSort:function(m){var G=t(m),N=r(m),H=t(m.C[1]);return function(E){return e(G(E))?N(E):H(E)}},convert:function(m){var G=m.to,N=Ab.bb[G];N||ib.la("Unknown to-type "+m.to);var H=t(m);return X(m,"p")?function(E){return H(E).mapOneToOne(function(da){return Ab.Ag(da,
G)})}:function(E){return H(E).mapOneToOne(N.O)}},copy:O,copyOf:O,currentGroup:function(m){return function(G){if(G&&G.kd)return sc(G.kd);throw new J("There is no current group","XTDE1061",m);}},currentGroupingKey:function(m){return function(G){if(G&&G.ld)return sc(G.ld);throw new J("There is no current grouping key","XTDE1071",m);}},cvUntyped:function(m){var G=t(m),N=Ab.bb[m.to],H=N.O,E="string"===N.name;return function(da){return G(da).mapOneToOne(function(S){if(T.double.matches(S)&&"float"===N.name)throw new J("xs:double may not be promoted to xs:float",
"XTTE0570",m);if(T.untypedAtomic.matches(S)||E&&T.anyURI.matches(S))try{return H(S)}catch(ta){if("FODT0001"===ta.code||!m.diag)throw ta;S=h(m.diag);throw new J(ta.message,S.code,m);}else return S})}},data:function(m){var G=t(m);return function(N){return G(N).mapOneToMany(z)}},dbl:function(m){var G=T.double.fromString(m.val);return function(){return xc(G)}},dec:function(m){var G=kb(m.val);return function(){return Fc(G)}},doc:O,docOrder:function(m){var G=t(m);return function(N){return Y.Sd(G(N))}},
dot:function(m){return function(G){Qd(G,"XPDY0002",". (dot)",m);return xc(Od(G))}},elem:O,empty:function(){return function(){return wc}},emptyTextNodeRemover:function(m){var G=t(m);return function(N){return G(N).filter(function(H){return!(Y.X(H)&&3===H.nodeType&&""===H.textContent)})}},error:function(m){return function(){throw new J(m.message,m.code,m);}},evaluate:function(m){var G={language:"XSLT",contextType:{o:"1",p:"",code:"1"}},N=A(M(m,"xpath")),H=A(M(m,"cxt")),E=A(M(m,"sa")),da=A(M(m,"nsCxt")),
S=p(m,"withParam").map(function(wa){return{name:T.string.fromString(wa.name),eval:t(wa)}}),ta=A(M(m,"wp"));return function(wa){var va=N(wa).next().toString();M(m,"cxt");var Na=H?H(wa):wc,Ua=new Mc;S.forEach(function(la){Ua.inSituPut(la.name,la.eval(wa).expand())});ta&&ta(wa).next().forAllPairs(function(la){if(!T.QName.matches(la.k))throw new J("Parameter names in xsl:evaluate/@with-params map must be QNames:"+la.k.toString(),"XTTE3165",m);Ua.inSituPut(la.k,la.v)});Ua=xc(Ua);if(E){var Qa=E(wa).next().toString();
if(u(Qa))throw new J("Saxon-JS does not support schema-aware evaluation","XTDE1665",m);}(Qa=m.as)&&(G.requiredType=Qa.toString());(Qa=m.dxns)&&"http://www.w3.org/1999/xhtml"!==Qa.toString()&&(G.xpathDefaultNamespace=Qa.toString());var jb={};if(da){Qa=da(wa);var ub=Qa.next();if(null===ub||null!==Qa.next()||!Y.X(ub))throw new J("xsl:evaluate/@namespace-context must evaluate to a single node","XTTE3170",m);jb[""]="";Kc.Uc(ub).forEach(function(la){return jb[la.prefix]=la.uri});G.xpathDefaultNamespace=
jb[""]}else jb=ka(m);var ob={};ub=Qa=!1;var L={},W=M(m,"options");if(W){W=U(W,wa).next();var ha=T.string.fromString("var-slots");W.containsKey(ha)&&W.get(ha)[0].forAllPairs(function(la){ob[la.k.$()]=R(la.v)});ha=T.string.fromString("allow-empty");W.containsKey(ha)&&(Qa=W.get(ha)[0].toBoolean());ha=T.string.fromString("isStatic");W.containsKey(ha)&&(ub=W.get(ha)[0].toBoolean());ha=T.string.fromString("staticContext");W.containsKey(ha)&&W.get(ha)[0].forAllPairs(function(la){L[la.k.toString()]=R(la.v,
!0)})}G.params=Ua;G.namespaces=jb;G.slotMap=ob;G.allowEmpty=Qa;G.staticTyping=!0;G.isStatic=ub;G.staticContext=L;G.dynamicEval=!0;(Qa=M(m,"baseUri"))&&(G.staticBaseURI=U(Qa,wa).next().toString());(Qa=qa(m,"defaultCollation"))&&(G.defaultCollation=Qa);return te.find("SaxonJS").XPath.evaluateXDM(va,Na,wa,G)}},every:function(m){var G=parseInt(m.slot,10),N=t(m),H=r(m);return function(E){var da=N(E);return Gc(!da.some(function(S){E.pa[G]=[S];return!e(H(E))}))}},except:function(m){var G=t(m),N=r(m);return function(H){return new pc(G(H),
N(H),Y.sb)}},"false":function(){return function(){return Gc(!1)}},filter:function(m){var G=t(m),N=r(m);return X(m,"i")?function(H){var E=G(H);H=N(H).expand();if(1===H.length&&T.numeric.matches(H[0])){var da=H[0].toNumber();if(E instanceof sc)return xc(E.expand()[da-1]||null);var S=Bc(E);return S.filter(function(){return S.position===da})}return e(sc(H))?E:wc}:function(H){var E=G(H),da=H.ca(!1);da.focus=Bc(E);H=X(m,"p")?function(){var S=N(da).expand();return 1===S.length&&T.numeric.matches(S[0])?da.focus.position===
S[0].toNumber():e(sc(S))}:function(){return e(N(da))};return da.focus.filter(H)}},first:function(m){var G=t(m);return function(N){return xc(G(N).next())}},flt:function(m){var G=T.float.fromString(m.val);return function(){return xc(G)}},fn:function(m){var G=Se[m.name];G||l(m.name+"()",m);var N=(m.C||[]).map(A);return function(H){var E=N.map(function(da){return da(H)});return G(E,H,m)}},fnCoercer:function(m){var G=t(m),N=Wc(m.to),H=N.T,E={alphaTree:N.V()};return function(da){return G(da).mapOneToOne(function(S){if(!(S instanceof
Ld.XdmFunction||S instanceof Kd||S instanceof Mc)){var ta=h(m.diag);throw new J("Required item type of "+ta.required+" is "+N.toString()+"; supplied value is "+ib.showValue(S),ta.code,m);}if((H instanceof Pd?H.g:[]).length!==(S instanceof Ld.XdmFunction?S.arity:1))throw ta=h(m.diag),new J("Required item type of "+ta.required+" is "+N.toString()+"; supplied value is "+ib.showValue(S),ta.code,m);return new Ld.Jg(E,S)})}},fnRef:function(m){var G=m.name;G=/^Q{/.test(G)?T.QName.fromEQName(G):T.QName.fromParts("",
Ic.Ea,G);var N=parseInt(m.arity,10),H=te.find("SaxonJS").XPath;return function(E){var da=E.ca(!0);da.eb=!0;if(da=H.getFnSignature(G,N,da))return E=da.isUserFunction?new Ld.Tf(da,E):G.uri===Ic.qc?new Ld.Qf(da,G,m):new Ld.Se(da,G,N,m,E),xc(E);throw new J("Reference to an unknown function and/or arity "+G.$()+"#"+N,"XPST0017",m);}},follows:function(m){var G=f(m,function(N,H){return T.boolean.fromBoolean(0<Y.sb(N,H))});return function(N){return xc(G(N))}},"for":function(m){var G=parseInt(m.slot,10),N=
t(m),H=r(m);return function(E){return N(E).mapOneToMany(function(da){E.pa[G]=[da];return H(E)})}},forEach:function(m){var G=t(m),N=r(m);return M(m,"separator")?O(m):function(H){var E=H.ca(!1);E.focus=Bc(G(H));E.cb=null;return E.focus.mapOneToMany(function(){return N(E)})}},forEachGroup:O,fork:b,functionCall:function(m){l(m.name+"()",m)},gc:c,gc10:function(m){var G=t(m),N=r(m);return function(H){var E=G(H).expand(),da=N(H).expand();1===E.length&&T.boolean.matches(E[0])?da=[T.boolean.fromBoolean(e(sc(da)))]:
1===da.length&&T.boolean.matches(da[0])?E=[T.boolean.fromBoolean(e(sc(E)))]:(E=g(E),da=g(da));H=re.re(m.comp,H);var S=m.op;"="!==S&&"!="!==S?(E=sc(E).mapOneToOne(Ab.XS.numeric.Fe).expand(),da=sc(da).mapOneToOne(Ab.XS.numeric.Fe).expand()):H=re.$i(H);return re.gc(sc(E),da,S,H,m)}},gcEE:c,gVarRef:function(m){return function(G){var N=C(m,G),H=N.actor,E=H.name;if(N.value===a)throw G=new J("Circularity in global variable "+E,"XTDE0640"),G.Ze=!0,G;if(null===N.value||"undefined"===typeof N.value){if("globalParam"===
H.N){var da=G.fixed.options.di,S=Ab.XS.QName.fromEQName(E);if(da.containsKey(S))da=da.get(S),ib.assert(Array.isArray(da)),S=Wc(H.as||"*"),da=na(da,S,function(){return"stylesheet parameter "+E}),ib.assert(Array.isArray(da)),N.value=da;else{if(X(H,"r"))throw new J("Parameter "+E+" is required","XTDE0050",H);if(X(H,"i"))throw new J("Parameter "+E+" cannot be empty","XTDE0700",H);H.C||(N.value=[])}}if(null===N.value||"undefined"===typeof N.value){da=!H.parentNode.parentNode.parentNode;S=G.ca(!0);S.sa=
"variable";S.xb.push(null);S.ib={};S.cb=null;S.ua=N;S.focus=da?Ac(G.fixed.Rc):null;try{var ta=t(H);N.value=a;N.value=ta(S).expand()}catch(wa){throw wa instanceof J&&(N.value=[],T.QName.fromEQName(E).uri!==Ic.zb+"generated-variable"&&(wa.Ze=!0)),wa;}S.xb.pop()}}return sc(N.value)}},homCheck:function(m){var G=t(m);return function(N){N=nc(G(N));var H=N.wa();if(null===H)return wc;var E=Y.X(H);N=N.filter(function(da){if(Y.X(da)!==E)throw new J("Cannot mix nodes and atomic values in the result of a path expression",
"XPTY0018",m);return!0});return E?Y.Sd(N):N}},ifCall:function(m){var G=T.QName.fromEQName(m.name);if("schedule-action"===G.local){var N=t(m),H=r(m),E=A(m.C[3]);return function(S){function ta(){L(Qa).expand()}var wa=4===m.C.length;if(4<m.C.length)throw new J("Only attributes @wait, @document and @http-request are available on ixsl:schedule-action in this version of Saxon-JS","SXJS0002",m);var va=N(S).next().toNumber(),Na=H(S).next(),Ua=wa?E(S).next():null;wa=null;var Qa=S.ca(!0);Rd(Qa);var jb=m.C[2],
ub=C(jb,S,"template"),ob=ub.actor;Pe(S)||(Qa.focus=Bc(xc(Od(S))),Qa.focus.next());Qa.Eb=null;Qa.ua=ub;Qa.sa=!1;null===Ua&&aa(ob,Qa);v(jb,S,Qa);var L=t(ob);if(null!==Ua)wa=gb.Bj(Ua,function(W){Qa.focus=Bc(xc(W[0]));Qa.focus.next();aa(ob,Qa);ta()},va);else if(null!==Na)Na=Na.toString().split(" "),Ua=Na.map(function(W){return gb.Ob(W,Nd.Qa(m,S))}),gb.td()?(wa=gb.ui(Ua,S.fixed.Cd,ta,va),wa=1===Na.length?wa[Ua[0]]:wa):(va=Ua.map(function(W){return te.find("SaxonJS").getResource({location:W,type:"text"}).then(function(ha){try{return S.fixed.Cd[W]=
ha,ta(),Promise.resolve(null)}catch(la){return ha=Promise.reject(la),S.fixed.Qj.push(ha),ha}})}),va=Promise.all(va),S.fixed.ee.push(va));else if(0!==va)if(gb.td())setTimeout(ta,va);else throw new J("ixsl:schedule-action/@wait is not supported on Node.js","SXJS0002");return wa?xc(wa):wc}}var da=(m.C||[]).map(A);return function(S){var ta=da.map(function(wa){return wa(S)});return fa(G.uri,G.local,ta,S,m)}},indexedFilter2:function(m){var G=A(M(m,"base")),N=A(M(m,"use")),H=A(M(m,"search"));return function(E){var da=
re.re(m.comp,E),S=E.ca(!1);S.focus=Bc(G(E));var ta=H(E).expand();return S.focus.filter(function(){var wa=N(S);return re.gc(wa,ta,"=",da,m).next().toBoolean()})}},inlineFn:function(m){var G={sig:m.sType,alphaTree:Yb(m.sType)};return function(N){return xc(new Ld.li(G,m,N))}},instance:function(m){var G=t(m),N=Wc(m.of);return function(H){H=G(H).expand();return Gc(N.matches(H))}},"int":function(m){var G=T.integer.fromString(m.val);return function(){return xc(G)}},intersect:function(m){var G=t(m),N=r(m);
return function(H){return new oc(G(H),N(H),Y.sb)}},intRangeTest:function(m){var G=t(m);m=m.C;var N=A(m[1]),H=A(m[2]);return function(E){var da=N(E).next(),S=H(E).next();if(!da||!S)return Gc(!1);var ta=da.toNumber(),wa=S.toNumber();E=G(E).some(function(va){va=va.value;return va>=ta&&va<=wa});return Gc(E)}},is:function(m){var G=f(m,function(N,H){return T.boolean.fromBoolean(Y.isSameNode(N,H))});return function(N){return xc(G(N))}},isLast:function(m){var G="1"===m.test;return function(N){return Gc(N.focus.position===
N.focus.last()===G)}},iterate:O,javaCall:function(m){var G=T.QName.fromEQName(m.name),N=(m.C||[]).map(A);return function(H){var E=N.map(function(da){return da(H)});return fa(G.uri,G.local,E,H,m)}},lastOf:function(m){var G=t(m);return function(N){var H=nc(G(N));return H.filter(function(){return null===H.wa()})}},let:function(m){var G=t(m),N=r(m),H=parseInt(m.slot,10);return function(E){var da=E.sa;E.sa="variable";var S=G(E).expand();E.sa=da;E.pa[H]=S;return N(E)}},literal:b,lookup:function(m){var G=
t(m),N=r(m);return function(H){function E(ub){wa=wa.concat(va[ub.toString()])}function da(ub){wa=wa.concat(va.get(ub))}function S(ub){return function(ob){if(!T.integer.matches(ob))throw new J("Array lookup key must be of type xs:integer; supplied value is "+ib.showValue(ob),"XPTY0004",m);ob=ob.toNumber();if(1>ob||ob>ub.length)throw new J("Array index ("+ob+") out of bounds (1 to "+ub.length+")","FOAY0001",m);wa=wa.concat(ub[ob-1])}}var ta=G(H),wa=[],va;H=N(H).expand();var Na=m.tuple;if(null!=Na)for(var Ua=
Na.split(/\s+/),Qa=Aa(H),jb=Qa.next();!jb.done;jb=Qa.next())if(jb=jb.value,-1===Ua.indexOf(jb.toString()))throw new J("Tuple lookup key must be one of  "+Na+". Supplied key is "+jb.toString(),"XPTY0004",m);for(;null!==(va=ta.next());)if(Nd.isArray(va))Ua=S(va.value),H.forEach(Ua);else if(Nd.isMap(va))H.forEach(da);else if(null!=Na&&Nd.Fh(va))H.forEach(E);else throw new J("The source for a lookup operator (?) must be of type array() or map(); supplied value is "+ib.showValue(va),"XPTY0004",m);return sc(wa)}},
lookupAll:function(m){var G=t(m);return function(N){function H(ta){da=da.concat(ta)}function E(ta){da=da.concat(S.get(ta))}N=G(N);for(var da=[],S;null!==(S=N.next());)if(Nd.isArray(S))S.value.forEach(H);else if(Nd.isMap(S))S.keys().forEach(E);else throw new J("The source for a lookup operator ('?') must be of type array() or map(); supplied value is "+ib.showValue(S),"XPTY0004",m);return sc(da)}},lookupTuple:function(m){var G=t(m),N=m.key;return function(H){H=G(H);for(var E=[],da;null!==(da=H.next());)if(null!=
N&&Nd.Fh(da))E=E.concat(da[N]);else throw new J("The source for a lookupTuple operator (?) must be of type tuple(); supplied value is "+ib.showValue(da),"XPTY0004",m);return sc(E)}},map:function(m){var G=(m.C||[]).map(A);return function(N){var H=new Mc,E=null;G.forEach(function(da){if(null===E)E=da(N).next();else{if(H.containsKey(E))throw new J("Duplicate map constructor entries for key:"+ib.showValue(E),"XQDY0137",m);da=da(N).expand();H.inSituPut(E,da);E=null}});return xc(H)}},merge:function(m){return function(G){return Te.Cj(m,
G)}},mergeAdj:function(m){var G=t(m);return function(N){for(var H=[],E=G(N),da=!1,S;null!==(S=E.next());){var ta=Y.X(S)&&3===S.nodeType;ta&&""===S.nodeValue||(da&&ta?(ta=Y.Qc(N.ja),H.push(ta.createTextNode(H.pop().nodeValue+S.nodeValue))):(da=ta,H.push(S)))}return sc(H)}},message:function(m){var G=A(M(m,"terminate")),N=A(M(m,"error"));return function(H){var E=H.sa;H.sa="variable";var da=G(H).next(),S=N(H).next().toString();try{S=ja(m,S,!0).$()}catch(va){S="Q{http://www.w3.org/2005/xqt-errors}XTMM9000"}try{var ta=
u(da.toString())}catch(va){throw new J("The terminate attribute of xsl:message must be yes|no|true|false|1|0","XTDE0030",m);}da=Fe(H);var wa=new He(da);try{Ke.push(m.C[0],H,Ke.Oc(wa))}catch(va){wa.append(Ab.XS.string.fromString("xsl:message evaluation at "+ib.location(m)+" failed: "+va))}da=sc(da.Pb).next();H.fixed.options.ig||(H.fixed.options.ig=gb.xh());try{wa=S,H.Dc[wa]||(H.Dc[wa]=0),H.Dc[wa]++,H.fixed.options.ig(da,S)}catch(va){}if(ta)throw ib.Sa(H.getStackTrace()),new J("Terminated with "+da,
S,m);H.sa=E;return wc}},minus:function(m){var G=t(m);return function(N){return G(N).mapOneToOne(function(H){return H.Af()})}},namespace:function(m){var G=t(m),N=r(m);return function(H){var E=G(H).next().toString();H=N(H).next().toString();if(""!==E&&!xb.Mb(E))throw new J("Prefix "+E+" is not an NCName","XTDE0920",m);if(""===H)throw new J("Namespace is zero-length","XTDE0930",m);if("xmlns"===E)throw new J("Disallowed prefix xmlns","XTDE0920",m);if("http://www.w3.org/2000/xmlns/"===H)throw new J("Disallowed namespace "+
H,"XTDE0905",m);if(!B(H))throw new J("Invalid namespace "+H,"XTDE0905",m);if("xml"===E!==(H===Ic.xml))throw new J("The xml namespace prefix and uri can only be used together","XTDE0925",m);return xc(new Lc(E,H))}},nextIteration:O,nextMatch:O,nodeNum:function(m){return function(G){return Qe.Hj(m,G)}},"null":function(m){return function(){return xc(m)}},numSeqFmt:function(m){return function(G){return Qe.Ij(m,G)}},onEmpty:b,onNonEmpty:b,or:function(m){var G=t(m),N=r(m);return function(H){return Gc(e(G(H))||
e(N(H)))}},origFC:function(m){m.C||(m.C=[]);var G=m.C.map(A);return function(N){if(!m._target){var H=m.pack,E=ja(m,m.name,!1).$(),da=m.C.length,S=N.fixed.Na;Object.keys(S).forEach(function(va){va=S[va];va.Nh.name===H&&va.name===E&&va.actor&&"function"===va.actor.N&&p(va.actor,"arg").length===da&&(m._target=va.id)})}var ta=N.fixed.Na[m._target],wa=G.map(function(va){return va(N).expand()});return y(m,ta,wa,N)}},param:function(m){var G=m.name,N=parseInt(m.slot,10),H=X(m,"t"),E=A(M(m,"select")),da=A(M(m,
"conversion"));return function(S){var ta=H?S.Qb:S.Wc,wa=Ab.XS.QName.fromEQName(G);if(ta.containsKey(wa))ta=ta.get(wa),""!==Wc(m.as||"*").V().p&&(ta=V(ta));else if(E&&!X(m,"r"))wa=S.sa,S.sa="xsl:param",ta=E(S).expand(),S.sa=wa;else throw new J("Required parameter $"+G+" not supplied","XTDE0700",m);ib.assert(Array.isArray(ta));S.pa[N]=ta;if(da)try{S.pa[N]=da(S).expand()}catch(va){throw new J("Supplied value for parameter $"+G+" could not be converted to required type","XTTE0590",m);}return wc}},partialApply:function(m){for(var G=
t(m),N=[],H=[],E=0,da=m.C.length-1,S=1;S<m.C.length;S++){var ta=m.C[S];"null"===ta.N?(E++,N.push(Yb(ta.sT||"*"))):H[S-1]=A(ta)}return function(wa){for(var va=G(wa).next(),Na=[],Ua=0;Ua<da;Ua++)H[Ua]&&(Na[Ua]=H[Ua](wa).expand());wa={o:"1",p:"F",r:va.Ab.alphaTree.r,a:N};return xc(new Ld.ki({minArgs:E,maxArgs:E,sig:Zb(wa),alphaTree:wa},va,Na))}},precedes:function(m){var G=f(m,function(N,H){return T.boolean.fromBoolean(0>Y.sb(N,H))});return function(N){return xc(G(N))}},procInst:O,qName:function(m){var G=
T.QName.fromParts(m.pre,m.uri,m.loc);return function(){return xc(G)}},range:function(m){var G=T.integer.fromString(m.from),N=T.integer.fromString(m.to);return function(){return k(G,N)}},resultDoc:function(m){return function(G){try{if(G.sa)throw new J("Cannot call xsl:result-document while evaluating "+G.sa,"XTDE1480",m);var N=K(m,G),H={},E=function(){},da=M(m,"format");if(da){var S=U(da,G).next().toString();S=ja(m,S,!0).$();var ta=N.xg[S];if(!ta)throw new J("Unknown xsl:output format "+S,"XTDE1460");
for(var wa,va,Na=Aa(ta.C),Ua=Na.next();!Ua.done;Ua=Na.next()){var Qa=Ua.value,jb=Qa.name;"parameter-document"===jb?wa=Qa.value:"Q{http://saxon.sf.net/}parameter-document-base-uri"===jb?va=Qa.value:H[jb]="use-character-maps"===jb?Re.Lf(jb,Qa.value,N):Re.Mf(jb,Qa.value)}if(wa){var ub=gb.Ob(wa,va),ob=xe.Lc(ub,G);Re.Ef(ob.documentElement,H)}}q(m.global,H,N);q(m.local,H,N);var L=Aa(m.C);for(Ua=L.next();!Ua.done;Ua=L.next()){var W=Ua.value,ha=W.role.replace(/^Q{\}/,"");if(!["href","format","content","parameter-document"].includes(ha)){"output-version"===
ha&&(ha="version");var la=U(W,G).next().toString();la="use-character-maps"===ha?Re.Lf(ha,la,N):Re.Mf(ha,la,Q(m,!0));"undefined"!==typeof la&&(H[ha]=Re.Df(ha,la,H[ha]))}}var za=M(m,"Q{}parameter-document");if(null!==za){var Fa=U(za,G).next().toString(),Ja=gb.Ob(Fa,Nd.Qa(m,G)),Za=xe.Lc(Ja,G);Re.Ef(Za.documentElement,H)}var $a=G.fixed.ic,pb=M(m,"href"),fb=null===pb?"":U(pb,G).next().toString();var vb=$a?gb.newURL(fb,$a):Y.ud(fb)?gb.newURL(fb):fb;var yc=vb.toString();if(G.fixed.ic&&yc===G.fixed.ic.toString()&&
1<G.xb.length)throw new J("Cannot revert to the original output destination while writing a secondary result","XTDE1490");var rb;rb||(rb=!gb.td()&&!G.masterDocument||G.fixed.vf?"file":"html-page");if("html-page"!==rb){if(G.fixed.options.lf){var dc=G.fixed.options.lf(vb);if(!dc)throw new J("deliverResultDocument() result has no destination");rb=dc.destination}else throw new J("No deliverResultDocument function supplied");if("undefined"!==typeof G.fixed.vb[yc])throw new J("A result document with URI "+
yc+" is attempting to write to a document already read","XTDE1500",m);if(G.fixed.Th[yc])throw new J("A result document with URI "+yc+" has already been created","XTDE1490",m);G.fixed.Th[yc]=!0}if(["file","serialized"].includes(rb)){ib.Sa("Writing to "+vb,2);var ih=new Re.StringWriter;var hg=Re.zh(H,ih,G);E=function(){dc.save(vb,ih.toString(),H.encoding||"utf-8")}}else if("document"===rb){var ig=Fe(G);hg=new He(ig);E=function(){var Rc=sc(ig.Pb).next();Rc&&(Rc._saxonBaseUri=vb.toString());dc.save(vb,
ig.result())}}else if("html-page"===rb){N=!1;var jh=H.method||"appendContent";"Q{http://saxonica.com/ns/interactiveXSLT}replace-content"===jh&&(jh="replaceContent",N=!0);var kh="#"===fb.charAt(0)||"?"===fb.charAt(0),li=Fe(G);hg=new He(li);G.xb.push(fb);var mi=d(M(m,"content"),G,kh),pi=function(Rc,ni){ia.Ma(Rc).forEachItem(function(oi){ni.appendChild(oi)})};if(kh){if(!G.masterDocument)return ib.Sa("Result document href="+fb+": no master document available",1),wc;if("?."===fb)var ec=Od(G);else if("?"===
fb.charAt(0)){var qi=Od(G),ri=fb.substring(1),si={language:"XSLT",contextType:{o:"1",p:"",code:"1"},namespaces:ka(m),dynamicEval:!0};try{ec=te.find("SaxonJS").XPath.evaluateXDM(ri,xc(qi),G,si)}catch(Rc){throw new J("Error in xsl:result-document/@href ?xpath:"+Rc.message,"SXJS0099",m);}ec=ec.expand();if(1<ec.length)throw new J("xsl:result-document/@href ?xpath yields more than one node","SXJS0099",m);ec=ec[0]}else ec=G.masterDocument.getElementById(fb.substring(1));if(!ec)return ib.Sa("Result document href="+
fb+": no such node",1),wc;if("?."===fb&&ec!==G.masterDocument&&ec.ownerDocument!==Y.Qc(G.masterDocument))return ib.Sa("Result document href="+fb+": current node is not in master document",1),wc;N&&(ec.innerHTML="");E=function(){pi(mi,ec);G.masterDocument.Zk={}}}else throw new J("xsl:result-document/@href value in browser must be '?.' or '#frag'");}else{if("raw"===rb)throw new J("Unsupported result-document destination "+rb,"FOXT0001");throw new J("Unsupported result-document destination "+rb,"XXXX9999");
}if("html-page"!==rb&&(""===fb||vb.toString()===$a)){var kf=G.fixed.Zi;if(kf){if(kf.ji)throw new J("Cannot use xsl:result-document to write to a destination already used for the principal output","XTDE1490");if(kf.Hg)throw new J("Cannot write more than one xsl:result-document to the principal output destination","XTDE1490");kf.Hg=!0}}if("html-page"!==rb){G.xb.push(vb.toString());var ti=M(m,"content"),lh=Ke.Oc(hg);Ke.push(ti,G,lh);lh.close()}E();G.xb.pop();return wc}catch(Rc){Z(Rc,m)}}},root:function(m){return function(G){Qd(G,
"XPDY0002","/",m);G=Od(G);if(!Y.X(G))throw new J("Context item for '/' must be a node","XPTY0020",m);var N=Y.zc(G);if(9!==N.nodeType&&11!==N.nodeType)throw new J("Root node for '/' must be a document node","XPDY0050",m);return xc(Y.zc(G))}},sequence:b,slash:function(m){var G=t(m),N=r(m);return function(H){var E=H.ca(!1);E.focus=Bc(G(H));return E.focus.mapOneToMany(function(){return N(E)})}},some:function(m){var G=parseInt(m.slot,10),N=t(m),H=r(m);return function(E){var da=N(E);return Gc(da.some(function(S){E.pa[G]=
[S];return e(H(E))}))}},sort:function(m){var G=re.Zj(m);return function(N){try{return G(N)}catch(H){if(H instanceof J&&"FOCH0002"===H.code)throw new J(H.message,"XTDE1035",m);if(H instanceof J&&"XPTY0004"===H.code)throw new J(H.message,"XTDE1030",m);throw H;}}},sourceDoc:O,str:function(m){var G=m.val;return function(){return Cc(G)}},subscript:function(m){var G=t(m),N=r(m);return function(H){var E=G(H);H=N(H).next();if("ADI"===H.code)H=H.value;else if(H.equals(H.round(0)))H=Ab.XS.integer.O(H).value;
else return wc;return(E=E.wf(H))?xc(E):wc}},supplied:function(m){var G=parseInt(m.slot,10);return function(N){return sc(N.pa[G])}},tail:function(m){var G=parseInt(m.start,10)-1,N=t(m);return function(H){var E=G;return N(H).filter(function(){return 0>=E--})}},tailCallLoop:b,to:function(m){var G=t(m),N=r(m);return function(H){var E=G(H).next();H=N(H).next();return null===E||null===H||0<E.compareTo(H)?wc:k(E,H)}},treat:function(m){var G=Xc(m.as),N=G.ia(),H=t(m);return function(E){return H(E).mapOneToOne(function(da){if(N(da))return da;
var S=h(m.diag);throw new J("Required item type of "+S.required+" is "+G.toString()+"; supplied value is "+ib.showValue(da),S.code,m);})}},"true":function(){return function(){return Gc(!0)}},"try":function(m){var G=t(m),N=p(m,"catch");N.forEach(function(H){var E=H.errors.split(" ").map(function(da){if("*"===da)return function(){return!0};if(/^\*:/.test(da)){var S=da.substring(2);return function(va){return va.local===S}}if(/}\*$/.test(da)){var ta=da.substring(2,da.length-2);return function(va){return va.uri===
ta}}var wa=T.QName.fromEQName(da);return function(va){return va.equals(wa)}});H.test=1===E.length?E[0]:function(da){return E.some(function(S){return S(da)})}});return function(H){try{return sc(G(H).expand())}catch(ta){if(ta instanceof J&&!ta.Ze){H.hf=ta;ta.xsltLineNr||hb(ta,m.C[0]);var E=ta.code.match("^Q{")?T.QName.fromEQName(ta.code):T.QName.fromParts("","http://www.w3.org/2005/xqt-errors",ta.code);for(var da=0;da<N.length;da++){var S=N[da];if(S.test(E))return t(S)(H)}throw ta;}if(ta instanceof
J)throw ta;Error.b&&Error.b();throw ta;}}},ufCall:function(m){var G=(m.C||[]).map(A);return function(N){var H=C(m,N,"function"),E=G.map(function(da){return da(N).expand()});return y(m,H,E,N)}},ufRef:function(m){var G=m.name;G=/^[a-z]+:/.test(G)?ja(m,G,!0):/^Q\{/.test(G)?T.QName.fromEQName(G):T.QName.fromParts("",Ic.Ea,G);var N=parseInt(m.arity,10),H=te.find("SaxonJS").XPath;return function(E){var da=H.getFnSignature(G,N,E);if(da&&da.isUserFunction)return E=new Ld.Tf(da,E),xc(E);ib.la("Named user function "+
G+"#"+N+" not found")}},union:function(m){var G=t(m),N=r(m);return function(H){return new mc(G(H),N(H),Y.sb)}},useAS:O,valueOf:O,varRef:function(m){var G=parseInt(m.slot,10);return function(N){return sc(N.pa[G])}},vc:function(m){function G(S){return null===S?null:T.untypedAtomic.matches(S)?T.string.O(S):S}var N=m.op,H=m.onEmpty,E=t(m),da=r(m);return function(S){var ta=E(S),wa=da(S),va=G(ta.next()),Na=G(wa.next());if(null===va||null===Na)return H?Gc("1"===H):wc;if(null!==ta.next()||null!==wa.next())throw new J("Value comparison only applicable to single values",
"XPTY0004",m);if(va.isNumeric()&&isNaN(va.value)||Na.isNumeric()&&isNaN(Na.value))return Gc("ne"===N);S=re.re(m.comp,S);return["=","eq","!=","ne"].includes(N)?Gc(re.ei(N,S.equals(va,Na))):Gc(re.Jf(N,S.compare(va,Na)))}}};x.prototype={value:null,containsKey:function(m){return"undefined"!==typeof this.value[m]},get:function(m){return P(this.value[m])},inSituPut:function(){throw new J("inSituPut() not allowed for JSValue","SXJS0005");},put:function(){throw new J("put() not allowed for JSValue","SXJS0005");
},remove:function(){throw new J("remove() not allowed for JSValue","SXJS0005");},keys:function(){throw new J("keys() not allowed for JSValue","SXJS0005");},forAllPairs:function(){throw new J("forEach() not allowed for JSValue","SXJS0005");},conforms:function(){throw new J("conforms() not allowed for JSValue","SXJS0005");},showValue:function(){return"JSValue "+ib.showValue(this.value)}};F.prototype={value:null,showValue:function(){return"XDMValue "+ib.showValue(this.value)}};return{ea:M,cf:v,hc:z,
zi:g,al:function(){return"ancestor ancestor-or-self attribute child descendant descendant-or-self following following-sibling namespace parent preceding preceding-sibling self preceding-or-ancestor".split(" ")},fg:aa,Db:p,gg:na,tb:R,ue:K,ug:C,Od:e,Va:t,fh:r,nb:A,escape:function(m,G){var N="";tb(m).forEach(function(H){var E=wb([H]);N+=G(H,E)?encodeURIComponent(E):E});return N},evaluate:U,xc:function(m,G){return null===m?wc:U(m,G)},Ni:function(m,G,N,H){var E=Se[m.local];E||l(m.toString()+"()",H);return E(G.map(function(da){return sc(da)}),
N,H)},Oi:function(m,G,N,H){return fa(m.uri,m.local,G.map(function(E){return sc(E)}),N,H)},flatten:w,tg:qa,jl:function(m){if("undefined"!==typeof m)return D(m,"module",20)+"#"+D(m,"line",10)},Fb:X,Uc:ka,isArray:ca,isMap:oa,Td:ea,Fh:function(m){return m instanceof Ue},xj:u,Sf:x,yl:l,Tj:h,Pj:function(m){fa=m},Hl:Z,Qa:I,Fd:V,Dg:ja,be:Q,ii:function(m){return"AZ"===m.code?T.double.O(m):m},ck:B,Ta:P,Yk:F}}();
// Input 39
abstractNode=Node;
HostPlatform=function(){function a(){Math.trunc=Math.trunc||function(z){return 0>z?Math.ceil(z):Math.floor(z)};Array.prototype.findIndex||Object.defineProperty(Array.prototype,"findIndex",{value:function(z,n){if(null===this||"undefined"==typeof this)throw new TypeError("Array.prototype.findIndex called on null or undefined");if("function"!==typeof z)throw new TypeError("predicate must be a function");for(var g=Object(this),e=g.length>>>0,k,p=0;p<e;p++)if(k=g[p],z.call(n,k,p,g))return p;return-1},
enumerable:!1,configurable:!1,writable:!1});Array.prototype.includes||Object.defineProperty(Array.prototype,"includes",{value:function(z){if(null===this||"undefined"==typeof this)throw new TypeError("Array.prototype.includes called on null or undefined");return this.findIndex(function(n){return n===z})}});String.prototype.includes||(String.prototype.includes=function(z,n){"number"!==typeof n&&(n=0);return n+z.length>this.length?!1:-1!==this.indexOf(z,n)})}var b=Resources,h,u={name:"Browser",init:function(){a()},
inBrowser:function(){return!0},expose:function(z,n){window[n]=z},getPromise:function(z){return new Promise(function(n,g){var e=new XMLHttpRequest;e.open("GET",z);e.onload=function(){200<=this.status&&300>this.status?n({responseText:e.responseText,contentType:e.getResponseHeader("Content-Type")}):g(new J(e.statusText+": "+z,"SXJS0006"))};e.onerror=function(){g(new J("Document fetch failed: "+z,"SXJS0006"))};e.send(null)})},asyncGet:function(z,n,g){var e=new XMLHttpRequest;e.open("GET",z,!0);0!==g&&
(e.timeout=g);e.onload=function(){4===e.readyState&&(200===e.status?n({responseText:e.responseText,contentType:e.getResponseHeader("Content-Type")}):n(new J(e.statusText+": "+z,"SXJS0006")))};e.ontimeout=function(){n(new J("Document fetch timeout: "+z,"SXJS0008"))};e.onabort=function(){n(new J("Document fetch aborted: "+z,"SXJS0008"))};e.onerror=function(){n(new J("Document fetch failed: "+z,"SXJS0006"))};e.send(null);return e},addDoc:function(z,n,g,e){function k(p){ib.Sa("Add to response pool "+
z,2);g[z]=p;n(g)}if(!g[z]||g[z]instanceof J)return u.asyncGet(z,k,e);ib.Sa("Doc already in pool "+z,2);n(g);return null},addMultipleDocs:function(z,n,g,e){function k(q){0>=--p&&g(q)}for(var p=z.length,v={},B=0;B<z.length;B++)z[B]?v[z[B]]=u.addDoc(z[B],k,n,e):p--;return v},makeHttpRequest:function(z,n,g){function e(f){if(f instanceof DocumentFragment){var I=document.implementation.createDocument("","",null);f=I.importNode(f,!0);I.appendChild(f);f=I}return f}function k(f,I){return function(d){q.status=
0;q.message=f+": "+p.href;q.error=I;q.type=d.type;n(Nd.Fd(Nd.Ta(q)))}}var p=Nd.tb(z,!0);(function(f){function I(c){throw new J(c+" in HTTP request to: "+f.href,"SXJS0006");}if(!f.href)throw new J("'href' must be specified in HTTP request","SXJS0006");f.method||I("'method' must be specified");f.method=f.method.toUpperCase();"status-only"in f&&"boolean"!==typeof f["status-only"]&&I("'status-only' parameter must be of type xs:boolean");f.wi="GET"!=f.method&&"HEAD"!=f.method;f.tf="body"in f||"multipart-bodies"in
f;("multipart-bodies"in f||"multipart-headers"in f)&&I("Multipart requests not yet implemented");!f.wi&&f.tf&&I("Body and multipart body content must be null for "+f.method+" request");"headers"in f||(f.headers={});var d=f["media-type"]||f.headers["content-type"]||"";f.tf&&""===d&&I("No content type specified");f.tf&&"media-type"in f&&(f.headers["content-type"]=d);"multipart-bodies"in f&&!/$multipart\//.test(d)&&I("Content type must be multipart media type for multipart request");("username"in f&&
!("password"in f)||!("username"in f)&&"password"in f)&&I("Must set both (or neither) username and password");f.body instanceof DocumentFragment&&(f.body=e(f.body,d));!f.tf||"text/xml"!=d&&"application/xml"!=d&&"text/xml-external-parsed-entity"!=d&&"application/xml-external-parsed-entity"!=d&&!/\+xml$/.test(d)&&"text/html"!=d||f.body instanceof Document||I("HTTP request body must be a document node, supplied item is: "+ib.showValue(f.body));ib.Sa("HTTP request prepared",2);return f})(p);"undefined"==
typeof XMLHttpRequest&&(XMLHttpRequest=function(){return new ActiveXObject("Msxml2.XMLHTTP.6.0")});var v=new XMLHttpRequest;"override-media-type"in p&&v.overrideMimeType(p["override-media-type"]);"username"in p?v.open(p.method,p.href,!0,p.username,p.password):v.open(p.method,p.href,!0);"timeout"in p&&0===g&&(g=p.timeout);0!==g&&(v.timeout=g);for(var B in p.headers)p.headers.hasOwnProperty(B)&&v.setRequestHeader(B,p.headers[B]);var q={};v.ontimeout=k("HTTP request timeout","SXJS0008");v.onabort=k("HTTP request aborted",
"SXJS0008");v.onerror=k("HTTP request failed","SXJS0009");v.onload=function(f){ib.Sa("Saxon-JS processing httpRequest response",2);q.headers={};q.status=v.status;q.message=v.statusText;q.type=f.type;if(!p["status-only"]&&"HEAD"!==p.method){f=(v.getResponseHeader("Content-Type")||"").split(/;/)[0];f=p["override-media-type"]||f;q["media-type"]=f;var I=u.parseXmlFromString;switch(f){case "text/xml":case "application/xml":case "text/xml-external-parsed-entity":case "application/xml-external-parsed-entity":q.body=
v.responseXML||I(v.responseText);break;case "text/html":q.body=v.responseXML||I(v.responseText,!1,f);break;default:q.body=/\+xml$/.test(f)?v.responseXML||I(v.responseText):v.responseText}}(function(d,c){c=c.getAllResponseHeaders();""!==c&&c.split(/\r\n/).forEach(function(l){if(""!==l.trim()){var t=l.indexOf(":");0<=t?d.headers[l.substring(0,t).toLowerCase()]=l.substring(t+1).trim():ib.Sa("No ':' separator found in HTTP response header "+l,2)}})})(q,v);n(Nd.Fd(Nd.Ta(q)))};v.send(p.body);return v},
parseXmlFromString:function(z,n,g){z=z.replace(/\r\n|\r(?!\n)/g,"\n");g||(g="application/xml");try{if("application/xml"===g&&/^.+<\?xml/i.test(z))throw Error();var e=(new DOMParser).parseFromString(z,g);if(0===e.getElementsByTagName("parsererror").length)var k=!1;else{if(!h)try{h=(new DOMParser).parseFromString("<","text/xml").getElementsByTagName("parsererror")[0].namespaceURI}catch(p){}k=0<e.getElementsByTagNameNS(h,"parsererror").length}if(k)throw Error(e.getElementsByTagNameNS(h,"parsererror")[0].textContent);
return e}catch(p){throw new J("Misplaced or malformed markup"+(p.message?": "+p.message:""),"FODC0002");}},resource:function(z){return b[z]},readFile:function(z,n){n=void 0===n?!1:n;var g=new XMLHttpRequest,e;try{g.open("GET",z,!1),g.send(null)}catch(k){throw new J("Get failure "+z,"FODC0002");}if(200===g.status)n?e={responseText:g.responseText,contentType:g.getResponseHeader("Content-Type")}:e=g.responseText;else throw new J("Failed to read file at "+z,"FODC0002");return e},resourcePromise:function(z){var n=
"file";if(z.file)var g=Promise.reject(new J("File access not supported in browser ("+z.file+")","SXJS0006"));else if(z.location){var e=z=z.location;z instanceof URL?z=z.toString():e=new URL(z);n=z;"file:"===e.protocol?g=Promise.reject(new J("File access not supported in browser ("+e+")","SXJS0006")):g=u.getPromise(z).then(function(k){return k.responseText})}return g.catch(function(k){return Promise.reject(new J("Failed to read "+n+"("+k.message+")","SXJS0006"))}).then(function(k){65279===k.codePointAt(0)&&
(k=k.substring(1));return Promise.resolve(k)})},resolveUri:function(z,n){try{return(n&&null!==n&&""!==n?z&&""!==z?new URL(z,n):new URL(n):new URL(z)).toString()}catch(g){throw new J("Failed URI resolution: href="+z+" base="+n+" -- "+g,"FODC0005");}},getDefaultDeliverMessage:function(){return function(z){var n="";Nd.hc(z).expand().forEach(function(g){n+=(""===n?"":" ")+g.toString()});console.log("xsl:message: "+n)}},isHTMLElement:function(z){return"http://www.w3.org/1999/xhtml"===z.namespaceURI},saxonPrint:function(z){console.log(z)},
createDocument:function(){return document.implementation.createDocument("","",null)},setDocURI:function(z,n){z._saxonBaseUri=n;return z},newURL:function(z,n){n=void 0===n?null:n;return null!==n?new URL(z,n):new URL(z)},extraProperties:{platform:"Browser"},toUTF8:function(z){return(new TextEncoder).encode(z)},fromUTF8:function(z){return(new TextDecoder).decode(z)}};return u}();
// Input 40
// Input 41
// Input 42
var Ve={fl:function(){return null}},We;for(We in Ic)Ve[We.toUpperCase()]=Ic[We];Ve.Ik=Ic.qc;Ve.ni=Ic.dd;function Xe(a){a=a.charCodeAt(0);return 65<=a&&90>=a||97<=a&&122>=a}var Ye=xb.Mb;function Ze(a){var b=a.charCodeAt(0);return Xe(a)||48<=b&&57>=b}function $e(a,b){return new J(a,void 0===b?"XPST0003":b)}function af(a){return new J(void 0===a?"Illegal Argument":a,"XPST0003")};
// Input 43
var bf="array attribute comment document-node element empty-sequence function if item map namespace-node node processing-instruction schema-attribute schema-element switch text typeswitch".split(" ");function cf(a,b){if(""===a.prefix&&bf.includes(a.local))throw new $e("The unprefixed function name '"+a.local+"' is reserved","XPST0003");this.name=a;this.arity=b}cf.prototype.$=function(){return this.name.$()};cf.prototype.toString=function(){return this.name.$()+"#"+this.arity};
// Input 44
var df={},ef={};df[0]="<eof>";df[1]="|";df[2]="/";df[3]="@";df[48]="~";df[4]="[";df[5]="(";df[6]="=";df[7]=",";df[8]="//";df[9]="or";df[10]="and";df[11]=">";df[12]="<";df[13]=">=";df[14]="<=";df[15]="+";df[16]="-";df[299]="-";df[17]="*";df[18]="div";df[19]="mod";df[20]="is";df[21]="$";df[22]="!=";df[40]="!";df[30]="||";df[23]="intersect";df[24]="except";df[25]="return";df[26]="then";df[27]="else";df[29]="to";df[31]="in";df[32]="some";df[33]="every";df[34]="satisfies";df[35]="<function>(";df[36]="<axis>";
df[37]="if(";df[38]="<<";df[39]=">>";df[41]="::";df[42]=":*";df[44]="#";df[45]="instance of";df[46]="cast as";df[47]="treat as";df[50]="eq";df[51]="ne";df[52]="gt";df[54]="ge";df[53]="lt";df[55]="le";df[56]="idiv";df[57]="castable as";df[58]=":=";df[71]="as";df[75]=":";df[76]="=>";df[79]="otherwise";df[80]="andAlso";df[81]="orElse";df[201]="<name>";df[202]="<string-literal>";df[203]="]";df[204]=")";df[205]=".";df[206]="..";df[207]="*";df[208]="<prefix:*>";df[209]="<numeric-literal>";df[69]="<node-type>()";
df[211]="for";df[70]="<*:local-name>";df[213]="?";df[59]="{";df[60]="<keyword> {";df[215]="}";df[216]="let";df[299]="-";function ff(a,b){ef[a]=b;df[b]=a}ff("instance of",45);ff("cast as",46);ff("treat as",47);ff("castable as",57);
// Input 45
function gf(a,b,h,u){this.kind=a;this.g=b;this.b=h;this.errorCode=void 0===u?"XPTY0004":u}gf.prototype={we:function(){return this.errorCode},save:function(){var a=this.kind+"|"+this.b+"|";a+=("XPTY0004"===this.errorCode?"":this.errorCode)+"|";return a+=this.g}};
// Input 46
function hf(){this.j=Nc.aa}ba=hf.prototype;ba.start=function(a,b){b=void 0===b?{}:b;a.start(this.K,b);this.nc&&a.Bd("role",this.nc);this.mg&&a.Bd("sType",this.j.ha())};ba.Yb=function(){return!Uc(this.j.fa)};ba.fb=function(a,b){b=void 0===b?{}:b;this.start(a,b);a.end()};ba.G=function(a){this.fb(a)};ba.K="Expression";ba.kind=null;ba.nc=null;ba.mg=null;ba.Fa=function(a,b){return new gf(this.kind,this.K,a,void 0===b?"XPTY0004":b)};
ba.Ha=function(){throw new $e(ib.Xj(this)+" is not a valid pattern","XTSE0340");};ba.Qd=function(){return this};function jf(a,b,h){this.j=Nc.aa;this.Xa=b;this.ga=a;this.ma=h;if(this.ga.Ya||this.ma.Ya)this.Ya=!0;this.ga.lc&&this.ma.lc&&(this.lc=!0)}Ka(jf,hf);jf.prototype.G=function(a){hf.prototype.start.call(this,a,this.Xa?{op:df[this.Xa]}:{});this.ga.G(a);this.ma.G(a);a.end()};jf.prototype.kind=1;function lf(a){this.j=Nc.aa;this.b=a;this.b.Ya&&(this.Ya=!0);this.b.lc&&(this.lc=!0)}Ka(lf,hf);
lf.prototype.G=function(a){hf.prototype.start.call(this,a);this.b.G(a);a.end()};lf.prototype.kind=9;
// Input 47
var mf=[!1,!1,!0,!0,!0,!0,!0,!0,!0,!0,!1,!1,!0,!1];function nf(a){return"ancestor ancestor-or-self attribute child descendant descendant-or-self following following-sibling namespace parent preceding preceding-sibling self preceding-or-ancestor".split(" ")[a]}var of=[1,1,2,1,1,1,1,1,13,1,1,1,1,1],pf=[4,5,9,9,0,1,10,11,9,3,6,7,12];
function qf(a){switch(a){case "self":return 12;case "child":return 3;case "parent":return 9;case "ancestor":return 0;case "attribute":return 2;case "following":return 6;case "namespace":return 8;case "preceding":return 10;case "descendant":return 4;case "ancestor-or-self":return 1;case "following-sibling":return 7;case "preceding-sibling":return 11;case "descendant-or-self":return 5}throw new $e("Unknown axis name: "+a);};
// Input 48
function rf(){this.j=Nc.rc}Ka(rf,hf);rf.prototype.Ha=function(){return this};rf.prototype.G=function(a){hf.prototype.fb.call(this,a)};function sf(){rf.apply(this,arguments)}Ka(sf,rf);sf.prototype.K="p.any";function tf(a,b){b=void 0===b?"match":b;rf.call(this);this.message=a;this.nc=b;this.j=Nc.rc}Ka(tf,rf);tf.prototype.G=function(a){rf.prototype.fb.call(this,a,{message:this.message})};tf.prototype.K="p.warning";function uf(a){rf.call(this);this.b=a;this.j=Nc.rc}Ka(uf,rf);
uf.prototype.G=function(a){rf.prototype.start.call(this,a);this.b.G(a);a.end()};uf.prototype.K="p.booleanExp";function vf(a,b){rf.call(this);ib.assert(b instanceof bd);this.test=b;this.j=new Oc(b,2)}Ka(vf,rf);vf.prototype.G=function(a){rf.prototype.fb.call(this,a,{test:this.test.ha()})};vf.prototype.K="p.nodeTest";function wf(a,b){rf.call(this);this.pattern=a;this.b=b;this.j=a.j;a.code&&(this.code=a.code)}Ka(wf,rf);
wf.prototype.G=function(a){rf.prototype.start.call(this,a);this.code&&a.Bd("code",this.code);this.pattern.G(a);this.b.G(a);a.end()};wf.prototype.K="p.withPredicate";function xf(a){rf.call(this);this.b=a;this.j=Qc(a.j,2)}Ka(xf,rf);xf.prototype.G=function(a){rf.prototype.start.call(this,a);this.b.G(a);a.end()};xf.prototype.K="p.nodeSet";function yf(a){rf.call(this);this.b=a;this.j=Qc(a.j,2)}Ka(yf,rf);yf.prototype.G=function(a){rf.prototype.start.call(this,a);this.b.G(a);a.end()};yf.prototype.K="p.genNode";
function zf(a,b){rf.call(this);this.b=b;this.code=a.j.ha();this.j=Qc(a.j,2)}Ka(zf,rf);zf.prototype.G=function(a){rf.prototype.start.call(this,a,{test:this.code});this.b.G(a);a.end()};zf.prototype.K="p.genPos";function Af(a,b){rf.call(this);this.code=a.ha();this.Yd=b;this.j=Qc(a,2)}Ka(Af,rf);Af.prototype.G=function(a){rf.prototype.fb.call(this,a,{test:this.code,pos:this.Yd})};Af.prototype.K="p.simPos";function Bf(a,b,h){rf.call(this);this.axis=pf[a];this.mb=b;this.b=h;this.j=b.j}Ka(Bf,rf);
Bf.prototype.G=function(a){rf.prototype.start.call(this,a,{axis:nf(this.axis)});this.mb.G(a);this.b.G(a);a.end()};Bf.prototype.K="p.withUpper";function Cf(a,b,h,u){rf.call(this);this.Xa=b;this.ga=a;this.ma=h;this.j=Qc(u,2)}Ka(Cf,rf);Cf.prototype.G=function(a){rf.prototype.start.call(this,a,{op:1===this.Xa?"union":df[this.Xa]});this.ga.G(a);this.ma.G(a);a.end()};Cf.prototype.K="p.venn";function Df(a,b,h){Cf.call(this,a,1,b,h)}Ka(Df,Cf);
function Ef(a){var b=[];a.ga instanceof Df?b=Ef(a.ga):b.push(a.ga);a.ma instanceof Df?b=b.concat(Ef(a.ma)):b.push(a.ma);return b}function Ff(a){rf.call(this);this.b=a;a=Aa(this.b);for(var b=a.next();!b.done;b=a.next())b.value.mg=!0}Ka(Ff,rf);Ff.prototype.G=function(a){rf.prototype.start.call(this,a);for(var b=Aa(this.b),h=b.next();!h.done;h=b.next())h.value.G(a);a.end()};Ff.prototype.K="UnionExprP";
// Input 49
function Gf(a,b){this.j=Nc.aa;Array.isArray(a)||ib.la("Literal value must be array");b instanceof Oc&&b.T.ha||ib.la("Literal type must be a SequenceType");a&&(this.value=a,b&&(this.j=b));this.nc=null}Ka(Gf,hf);Gf.prototype.toString=function(){return this.value.toString()};
Gf.prototype.G=function(a){function b(z,n){var g=z.code,e={};switch(g){case "AB":break;case "AQ":e.pre=z.prefix;e.uri=z.uri;e.loc=z.local;break;default:e.val=z.toString()}n&&(e.role=n);switch(g){case "AS":a.start("str",e);break;case "AO":a.start("dbl",e);break;case "AB":a.start(z.value?"true":"false",e);break;case "AD":a.start("dec",e);break;case "ADI":a.start("int",e);break;case "AQ":a.start("qName",e);break;default:e.type=g,a.start("atomic",e)}a.end()}if(0===this.value.length)a.start("empty",this.nc?
{role:this.nc}:{}),a.end();else if(1===this.value.length)b(this.value[0],this.nc);else{var h={count:this.value.length};this.nc&&(h.role=this.nc);a.start("atomic",h);h=Aa(this.value);for(var u=h.next();!u.done;u=h.next())b(u.value);a.end()}};function Hf(a){return a instanceof Gf&&1===a.value.length&&kd.ia()(a.value[0])}function If(a,b){return new Gf(a,b)}function Jf(){return new Gf([],Nc.Te)}Gf.prototype.K="literal";Gf.prototype.lc=!0;
function Kf(a){Gf.call(this,[Ab.XS.string.fromString(a)],Nc.dc);this.j=Nc.dc}Ka(Kf,Gf);
// Input 50
function Lf(a,b){this.j=Nc.aa;ib.assert(!b||b instanceof bd);this.axis=a;this.K="axis";b=b||gd;2===a&&b instanceof hd?b=wd:3===a&&b instanceof hd&&(b=id);this.b=b;9===a&&b instanceof hd?this.j=Nc.me:b?this.j=new Oc(b,7):this.j=Nc.ke}Ka(Lf,hf);Lf.prototype.pd=function(){return this.axis};Lf.prototype.Yb=function(){return[2,3,4,5,6,7,9,12].includes(this.axis)};Lf.prototype.Ha=function(){return new vf(this.axis,this.b)};Lf.prototype.G=function(a){hf.prototype.fb.call(this,a,{name:nf(this.axis),nodeTest:this.j.ha()})};
function Mf(a,b){jf.call(this,a,2,b);this.ga=a=Nf(a,Nc.ke,this.Fa(0,"XPTY0019"));this.j=Sc(b.j,a.j.fa)}Ka(Mf,jf);Mf.prototype.Yb=function(){return!0};Mf.prototype.Ha=function(){if(this.ma instanceof Of||this.ma instanceof Gf)throw new $e("Not an axis step for a pattern","XTSE0340");return this.Ya?new yf(this):new Bf(this.ma.pd(),this.ma.Ha(),this.ga.Ha())};Mf.prototype.G=function(a){jf.prototype.start.call(this,a,this.Xa?{op:df[this.Xa]}:{});this.ga.G(a);this.ma.G(a);a.end()};Mf.prototype.K="slash";
function Pf(){this.j=Nc.me}Ka(Pf,hf);Pf.prototype.Ha=function(){return new vf(12,td)};Pf.prototype.K="root";
// Input 51
var Qf={ADI:"i",ADIL:"c",ADILI:"i",ADILIS:"i",ADILISB:"i",ADIN:"c",ADINN:"c",ADIP:"c",ADIPP:"c",ADIPL:"c",ADIPLI:"i",ADIPLIS:"i",ADIPLISB:"i",AD:"c",AO:"d",AF:"f",A:"a",AZ:"a",AT:"t",AA:"t",AM:"t",AMP:"t",AH:"t",AI:"t",AJ:"t",AG:"t",AK:"t",ARD:"u",ARY:"u",E:"a"},Rf={19:"%",18:"/",56:"~",15:"+",16:"-",299:"-",17:"*"};function Sf(a,b){b=new Tf(a,"AO",b.save());b.j=2===a.j.fa?Nc.oe:Nc.Mg;return b}
function Uf(a,b,h,u){function z(l){return l instanceof dd&&l.cd.mb?new dd(Ab.Nj(l.cd)):l}function n(){throw new $e("Cannot evaluate "+k.toString()+" "+df[b]+" "+p.toString(),"XPTY0004");}jf.call(this,a,b,h);var g=u.Be(),e=a.Ya||h.Ya;g&&(a=Vf(a),h=Vf(h));a=Nf(a,Nc.cc,this.Fa(0));h=Nf(h,Nc.cc,this.Fa(1));var k=z(a.j.T),p=z(h.j.T),v=k.V().p,B=p.V().p;g||!/^AS/.test(v)&&!/^AS/.test(B)||n();"AZ"!==v||g||(a=Sf(a,this.Fa(0,"FORG0001")),k=Bd,v="AO");"AZ"!==B||g||(h=Sf(h,this.Fa(1,"FORG0001")),p=Bd,B="AO");
var q=Qf[v],f=Qf[B],I=k.isNumeric(),d=p.isNumeric();B=Hd;v=Tc(a.j.fa)||Tc(a.j.fa);q||(q="a");f||(f="a");var c;g?(q=f="d",B=Bd):I&&d&&("d"===q||"d"===f?(q=f="d",B=c=Bd):"f"===q||"f"===f?(q=f="f",B=c=Cd):"c"===q||"c"===f?(q=f="c",B=c=Ed):"i"===q&&"i"===f&&18===b?(q=f="c",B=Ed):"i"!==q||"i"!==f||15!==b&&16!==b&&17!==b&&19!==b?"i"===q&&"i"===f&&18===b&&(B=Ed):B=Fd);56===b&&(B=Fd);B.isNumeric()&&c&&(c=new Oc(c,3),a=Nf(a instanceof Wf?a.b:a,c,this.Fa(0)),h=Nf(h instanceof Wf?h.b:h,c,this.Fa(1)));[q,f].includes("a")&&
(q=f="a");(k.qa("ARY")&&p.qa("AT")||p.qa("ARY")&&k.qa("AT"))&&n();"t"===q&&"t"===f&&(16===b?B=Gd:n());"u"===q&&"t"===f&&(15===b?B=p:n());"u"===q&&"u"===f&&(17===b&&n(),18===b?B=Ed:B=k);I&&"u"===f&&18===b&&n();"t"!==q||"u"!==f||16!==b&&15!==b?"i"===q&&"u"===f&&17===b?(q="n",B=p):"u"!==q||"d"!==f&&"i"!==f&&"c"!==f?"u"!==f||"d"!==q&&"i"!==q&&"c"!==q||(q="n",B=p):(B=k,f="n"):B=k;q=q+Rf[b]+f;if(!u.Be()&&"AF"!==B.V().p&&!u.Vc&&(u.Bi?0===u.Bi:1)&&Hf(a)&&Hf(h)&&(u=a.value[0],c=h.value[0],18!==b||0!==c.toNumber()))return a=
Vb[q](u,c),If([a],new Oc(new dd(Ab.bb[a.code]),2));this.ga=a;this.ma=h;a.j.equals(h.j)&&(this.j=a.j);B&&(this.j=new Oc(B,v?3:2));this.b=q;e&&(this.Ya=!0);g&&(this.K="arith10");this.j||(this.j=Nc.Xe)}Ka(Uf,jf);Uf.prototype.Ha=function(){throw new $e("The arithmetic expression  "+df[this.Xa]+" is not a patternXTSE0340");};Uf.prototype.G=function(a){jf.prototype.start.call(this,a,this.Xa?{op:df[this.Xa],calc:this.b}:{});this.ga.G(a);this.ma.G(a);a.end()};Uf.prototype.K="arith";
// Input 52
var Xf={6:50,22:51,11:52,12:53,13:54,14:55};function Yf(a,b){a=a.V().p;b.V();if("AZ"===a&&b.isNumeric())return!1;if("A"===a&&b.qa())return!0;""===a&&b.qa();return!0}function Zf(a){return/^A[BAMT2XQ]/.test(a)}function $f(a,b,h,u){jf.call(this,a,b,h);a=u.cj();b="GAC";switch(a){case re.Bh:b="CAC"}a.startsWith(re.ak)&&(b="CAC");h.j===Nc.Rg&&(b="EQC");this.Ld=b+"|"+a;this.j=Nc.gb}Ka($f,jf);
$f.prototype.G=function(a){jf.prototype.start.call(this,a,{op:df[this.Xa],comp:this.Ld});this.ga.G(a);this.ma.G(a);a.end()};function ag(a,b,h,u){$f.call(this,a,b,h,u)}Ka(ag,$f);ag.prototype.G=function(a){$f.prototype.start.call(this,a,{op:df[this.Xa],comp:this.Ld,card:"1:1"});this.ga.G(a);this.ma.G(a);a.end()};
function bg(a,b,h,u){$f.call(this,a,b,h,u);a=Nf(a,Nc.Hd,this.Fa(0));h=Nf(h,Nc.Hd,this.Fa(1));b=a.j.V();u=h.j.V();var z=b.p;var n=u.p;z===n||[z,n].includes("AZ")||[z,n].includes("A")?z=!0:(/^A[ODF]/.test(z)&&(z="AO"),/^A[ODF]/.test(n)&&(n="AO"),/^A[SU]/.test(z)&&(z="AS"),/^A[SU]/.test(n)&&(n="AS"),z="E"===z||"E"===n?!0:z===n);if(!z)if(z="Cannot compare items of types "+a.j.T.toString()+" and "+h.j.T.toString(),/[*?]/.test(b.o)||/[*?]/.test(u.o))ib.Sa("Cannot compare items of types "+a.j.T.toString()+
" and "+h.j.T.toString()+": the comparison can succeed only if one of the values is an empty sequence",2);else throw new $e(z,"XPTY0004");this.ga=a;this.ma=h}Ka(bg,ag);bg.prototype.K="gc";function cg(a,b,h,u){$f.call(this,a,b,h,u)}Ka(cg,ag);cg.prototype.K="gc10";
function dg(a,b,h,u,z){z=void 0===z?!0:z;$f.call(this,a,b,h,u);var n=a.j.T,g=h.j.T,e=n.V().p,k=g.V().p;if(!("A"===e&&g.qa()||"A"===k&&n.qa()||""===e||""===k||n.V().p.startsWith("AM")&&g.V().p.startsWith("AM")||(Zf(e)||Zf(k)?e===k:Yf(n,g)||Yf(g,n))))throw new $e("Cannot compare "+a.j.toString()+" "+df[b]+" "+h.j.toString(),"XPTY0004");a=Nf(a,Nc.cc,this.Fa(0));h=Nf(h,Nc.cc,this.Fa(1));if(51!==b&&50!==b&&/^A[QGHIJK]/.test(a.j.T.V().p))throw new $e("Cannot order-compare "+a.j.toString()+" "+df[b]+" "+
h.j.toString(),"XPTY0004");if(z){if(Hf(h)&&Ab.XS.integer.matches(h.value[0])&&"ADI"===a.j.V().p)return new eg(a,b,h,u);if(h instanceof Kf)return new fg(a,b,h,u)}this.ga=a;this.ma=h}Ka(dg,$f);dg.prototype.K="vc";function gg(a,b,h,u){return dg.call(this,a,b,h,u,!1)||this}Ka(gg,dg);gg.prototype.G=function(a){dg.prototype.start.call(this,a,{op:df[this.Xa],val:this.ma.value[0]});this.ga.G(a);a.end()};function eg(a,b,h,u){return gg.call(this,a,b,h,u)||this}Ka(eg,gg);eg.prototype.K="compareToInt";
function fg(a,b,h,u){return gg.call(this,a,b,h,u)||this}Ka(fg,gg);fg.prototype.G=function(a){gg.prototype.start.call(this,a,{op:df[this.Xa],val:this.ma.value[0].toString(),comp:this.Ld});this.ga.G(a);a.end()};fg.prototype.K="compareToString";function jg(a,b,h,u){$f.call(this,a,b,h,u);a=Nf(a,Nc.me,this.Fa(0));h=Nf(h,Nc.me,this.Fa(0));this.ga=a;this.ma=h;this.K=38===b?"precedes":39===b?"follows":"is"}Ka(jg,$f);jg.prototype.G=function(a){$f.prototype.start.call(this,a);this.ga.G(a);this.ma.G(a);a.end()};
// Input 53
function kg(a,b){return a instanceof Of&&a.name.name.uri===Ic.Ea&&a.name.name.local===b}function lg(a,b){return a instanceof dg&&a.Xa===b&&kg(a.ga,"position")&&kg(a.ma,"last")}
function mg(a,b){this.j=Nc.aa;if(b){var h=kg(b,"last")||lg(b,50);if(h)return new ng(a);h=h||lg(b,53);var u=b.Ya||b instanceof Uf,z=b.lc;if(!u&&z&&b.j.equals(Nc.Id))return new og(a,b);u||(u=b.j.T.V().p,u="AB"===u||u.startsWith("N")?!1:"A"===u||/^A[ODF]/.test(u));this.b=a;this.j=a.j;b instanceof pg?this.j=Qc(b.type,this.j.fa):b instanceof Gf&&1===b.value.length&&kd.ia()(b.value[0])&&(this.j=Qc(this.j,3));u&&(this.Ya=!0);z&&(this.lc=!0);this.g=h?new qg(50===b.Xa?"1":"0"):b}}Ka(mg,hf);ba=mg.prototype;
ba.pd=function(){return this.b.pd()};ba.Yb=function(){return hf.prototype.Yb.call(this)||this.b.Yb()};ba.Ha=function(){if(!Ud(this.j.T)){var a=rg("exists",this);return new uf(a)}return this.Ya&&this.b instanceof Lf&&!(this.g instanceof qg)?new zf(this.b,this.g):this.Ya?new yf(this.Qd()):new wf(this.b.Ha(),this.g)};ba.Qd=function(){if(this.b instanceof sg)return this.g;this.b instanceof Lf?this.b=tg(new sg,this.b):this.b=this.b.Qd();return this};
ba.G=function(a){hf.prototype.start.call(this,a);var b="";this.lc&&(b+="i");this.Ya&&(b+="p");""!==b&&a.Bd("flags",b);this.b.G(a);this.g.G(a);a.end()};ba.K="filter";function og(a,b){var h=mg.call(this,a,null)||this;if(Hf(b)&&Ab.XS.integer.matches(b.value[0])&&1===b.value[0].value)return new ug(a);h.b=a;h.j=Qc(a.j,3);h.g=b;h.Ya=!0;return h}Ka(og,mg);
og.prototype.Ha=function(){return this.b instanceof sg?new tf("Numeric predicates other than 1 in PredicatePatterns never match. Value provided:"+this.g.value.value):this.b instanceof Lf?new Af(this.b.j,this.g.value[0].value):new yf(this.Qd())};og.prototype.K="subscript";function ug(a){var b=og.call(this,a,null)||this;b.b=a;b.Ya=!0;b.j=Qc(a.j,3);return b}Ka(ug,og);ug.prototype.Ha=function(){return Ud(this.j.T)?this.b instanceof Lf?new yf(new ug(tg(new sg,this.b))):new yf(this.Qd()):new uf(this)};
ug.prototype.G=function(a){og.prototype.start.call(this,a);this.b.G(a);a.end()};ug.prototype.K="first";function ng(a){var b=og.call(this,a,null)||this;b.b=a;b.Ya=!0;b.j=Qc(a.j,3);return b}Ka(ng,og);ng.prototype.Ha=function(){if(!(this.b instanceof Lf))return new yf(this.Qd());if(3===this.b.axis){var a=new Lf(7,this.b.b);return new wf(this.b.Ha(),rg("empty",[a]))}return new Af(this.b.j,1)};ng.prototype.G=function(a){og.prototype.start.call(this,a);this.b.G(a);a.end()};ng.prototype.K="lastOf";
function qg(a){this.j=Nc.aa;this.test=void 0===a?"1":a;this.j=Nc.gb}Ka(qg,hf);qg.prototype.G=function(a){this.fb(a,{test:this.test})};qg.prototype.K="isLast";
// Input 54
function vg(a,b){if(a instanceof Pf&&b instanceof Lf&&9===b.pd())return Jf();if(a instanceof Mf&&a.ga instanceof Pf&&5===a.ma.axis&&b instanceof Lf&&3===b.axis)return vg(a.ga,new Lf(4,b.b));if(b instanceof Mf||b instanceof wg&&b.b instanceof Mf){var h=xg(b);yg(h.ga)instanceof Lf&&yg(h.ma)instanceof Lf&&(a=tg(a,h.ga),b=h.ma)}a=new Mf(a,b);return zg(a)?a:Ud(a.ma.j.T)?(a.ga=Ag(a.ga),a.ma=Ag(a.ma),new wg(a)):a}
function zg(a){if(!Uc(a.ga.j.fa))return a.ma.Yb();var b=yg(a.ga);return b instanceof Lf&&3===b.axis&&(a=yg(a.ma),a instanceof wg&&(a=a.b),a instanceof Lf&&[3,2,4,5,8].includes(a.axis)||a instanceof Mf&&zg(a))?!0:!1}function xg(a){return a instanceof wg?a.b:a}function yg(a){return a instanceof mg?yg(a.b):a instanceof wg?yg(a.b):a}var tg=vg;function Bg(a){return a.Yb()?a:new wg(a)}var Ag=xg;
// Input 55
function Cg(a,b,h,u,z){z=void 0===z?{}:z;ib.assert(b);ib.assert(b instanceof Oc);ib.assert(a.j);ib.assert(a.j instanceof Oc);var n=b.V(),g=a,e,k=b.T,p=b.fa,v=Uc(p),B=null,q=-1,f=7===p;f||(q=a.j.fa,f=(p|q)===p);n=""===n.p;1===p&&(n=!0);n||(B=a.j.T,q=a.j.fa,n=ce.Nb(k,B),n===ce.bc&&(n=B===ed?ce.sc:k===ed?ce.Rb:n),n=n===ce.Bb||n===ce.Rb);if(n&&f)return g;if(z.Sb&&!v&&(Uc(q)&&(g=e=new ug(g),q=3,f=(p|q)===p),!n)){if(k.qa("AS")){try{e=Cg(g,Nc.Vf,h,u)}catch(I){throw I;}g=rg("string",e);B=Dd;q=2;g.j=Nc.dc;
f=(p|q)===p;n=!0}if(Vd(k)||k.qa("AO")){try{e=Cg(g,Nc.Vf,h,u)}catch(I){throw I;}g=rg("number",e);B=Bd;q=2;g.j=Nc.oe;f=(p|q)===p;n=!0}}if(!n)if(k.qa()){if(!B.qa()&&1!==q){if(!B.oj)throw b=new J("An atomic value is required for the "+h.getMessage()+", but the supplied value (type "+B.toString()+") cannot be atomized","error()"===B.name?"FOER0000":"FOTY0013",a),b;B=!Uc(q);q=!Uc(p);e=B?g instanceof Lf&&2===g.axis&&g.j.T instanceof Yd?new Dg(g.j):new Eg(g,u,Tc(p)):g instanceof Lf&&2===g.axis&&g.j.T instanceof
Yd?new Dg(g.j):new Fg(g,u);q&&!B&&(e=Gg(e,p,u));g=e;B=g.j.T;q=g.j.fa;f=(p|q)===p}B.qa("AZ")&&(Vd(k)||!k.qa("AZ")&&"A"!==k.V().p)&&(g=e=Hg(g,k,u),n=!0,B=k);"A"!==B.ha()||["A","AZ"].includes(k.ha())||(g=e=Hg(g,k,u),B=g.j.T);k.qa()&&(k.qa("AO")&&ce.Nb(B,kd)!==ce.bc?(g=e=new Ig(g,Nc.oe,u),B=Bd,q=-1):k.qa("AF")&&ce.Nb(B,kd)!==ce.bc&&!ce.vj(B,Bd)&&(g=e=new Ig(g,Nc.Qg,u),B=Cd,q=-1),k.qa("AS")&&B.qa("AU")&&(n=!0,g=e=new Ig(g,Nc.dc,u),B=Dd))}else k.Td()&&"F"===k.V().p&&(B.Td()||(g=new Jg(g,md,u),B=md),k instanceof
nd||(e=B.V(),("FM"===e.p||"FA"===e.p)&&1===k.g.length)||(g=new Kg(g,ad(k),u)),n=!0);if(n&&f)return g;-1===q&&(q=g.j.fa,f||(f=(p|q)===p));if(f&&1===q)return g;if(1===q&&!Tc(p))throw b=new J("An empty sequence is not allowed as the "+h.getMessage(),h.we()),b;n=n?ce.sc:ce.Nb(B,k);n===ce.bc&&(n=B===ed?ce.Rb:k===ed?ce.sc:n);1===p&&(n=ce.Bb);if(n===ce.bc)if(Tc(q)&&Tc(p))1!==q&&(e=h.hg(k,B),ib.Sa(e+". The expression can succeed only if the supplied value is an empty sequence.",1));else throw e=h.hg(k,B),
b=new J(e,"error()"===B.name?"FOER0000":h.we()),b;if(n!==ce.Bb&&n!==ce.sc){if(g.rj){u=Ab.XS.string.fromString(g.getAttribute("val"));if(k.qa()&&b.matches(u))return g;e=h.hg(k,B);b=new J(e,h.we());throw b;}g=e=Lg(g,new Oc(k,p),u);f=!0}if(!f){if(g.rj)throw b=new J("Required cardinality of "+h.getMessage()+" is "+Pc(p)+"; supplied value has cardinality "+Pc(q),h.we()),b;g=e=Gg(g,p,u)}return g}function Hg(a,b,h){b=Vd(b)?"AO":b.V().p;return new Tf(a,b,h)};
// Input 56
function Mg(){this.I=this.Ba=0;this.R=null;this.g=this.te=0;this.J=null;this.U=0;this.l=null;this.B=this.b=0;this.Ia=this.ya=1;this.ra=null;this.ba=-1;this.ta=""}function Ng(a,b){a.Ba=b;0===b?(a.ba=-1,a.ta="",a.I=-1):b===Og&&(a.ba=204,a.ta=")",a.I=204)}function Pg(a,b){a.Ba=0;a.g=0;a.J=null;a.U=0;a.b=0;a.l=b;a.ya=0;a.Ia=0;a.B=b.length;Qg(a);a.next()}
Mg.prototype.next=function(){this.ba=this.I;this.ta=this.R;this.I=this.g;this.R=this.J;null===this.R&&(this.R="");this.te=this.U;this.ya=this.Ia;switch(this.I){case 201:var a=Rg(this.R);-1===a||150>=this.ba||213===this.ba||(this.I=a);break;case 207:150>=this.ba||213===this.ba||(this.I=17)}if(215!==this.I&&(a=this.ba,Qg(this),201===this.I&&this.Ba!==Sg&&21!==a))switch(this.g){case 5:var b=Rg(this.R);-1===b||150>=a?(this.I=this.sg(this.R),Qg(this)):this.I=b;break;case 59:this.Ba!==Tg&&(this.I=60,Qg(this));
break;case 41:Qg(this);this.I=36;break;case 44:Qg(this);this.I=43;break;case 42:Qg(this);this.I=208;break;case 21:"for"===this.R?this.I=211:"some"===this.R?this.I=32:"every"===this.R?this.I=33:"let"===this.R&&(this.I=216);break;case 201:a=this.R+" "+this.J,b=ef[a],null!=b&&(this.I=b,this.R=a,Qg(this))}};
function Qg(a){a.ba=a.g;a.ta=a.J;a.J=null;for(a.U=a.b;;){if(a.b>=a.B){a.g=0;break}var b=a.l.charAt(a.b++);switch(b){case "/":if(a.b<a.B&&"/"===a.l.charAt(a.b)){a.b++;a.g=8;return}a.g=2;return;case ":":if(a.b<a.B){":"===a.l.charAt(a.b)?(a.b++,a.g=41):"="===a.l.charAt(a.b)?(a.g=58,a.b++):a.g=75;return}throw new $e("Unexpected colon at start of token");case "@":a.g=3;return;case "~":a.g=48;return;case "?":a.g=213;return;case "[":a.g=4;return;case "]":a.g=203;return;case "{":a.g=59;return;case "}":a.g=
215;return;case "(":if(a.b<a.B&&":"===a.l.charAt(a.b)){a.b++;for(b=1;0<b&&a.b<a.B-1;)"\n"===a.l.charAt(a.b)?Ug(a):":"===a.l.charAt(a.b)&&")"===a.l.charAt(a.b+1)?(b--,a.b++):"("===a.l.charAt(a.b)&&":"===a.l.charAt(a.b+1)&&(b++,a.b++),a.b++;if(0<b)throw new $e("Unclosed XPath comment");Qg(a)}else a.g=5;return;case ")":a.g=204;return;case "+":a.g=15;return;case "-":a.g=16;return;case "=":if(a.b<a.B&&">"===a.l.charAt(a.b)){a.b++;a.g=76;return}a.g=6;return;case "!":if(a.b<a.B&&"="===a.l.charAt(a.b)){a.b++;
a.g=22;return}a.g=40;return;case "*":(b=a.b<a.B&&":"===a.l.charAt(a.b)&&a.b+1<a.B)&&!(b=127<a.l.charAt(a.b+1))&&(b=a.l.charAt(a.b+1),b=Xe(b)||"_"===b);if(b){a.b++;a.g=70;return}a.g=207;return;case ",":a.g=7;return;case "$":a.g=21;return;case "|":if(a.b<a.B&&"|"===a.l.charAt(a.b)){a.b++;a.g=30;return}a.g=1;return;case "#":a.g=44;return;case "<":if(a.b<a.B&&"="===a.l.charAt(a.b)){a.b++;a.g=14;return}if(a.b<a.B&&"<"===a.l.charAt(a.b)){a.b++;a.g=38;return}a.g=12;return;case ">":if(a.b<a.B&&"="===a.l.charAt(a.b)){a.b++;
a.g=13;return}if(a.b<a.B&&">"===a.l.charAt(a.b)){a.b++;a.g=39;return}a.g=11;return;case ".":if(a.b<a.B){var h=a.l.charAt(a.b);if("."===h){a.b++;a.g=206;return}if("{"===h){a.b++;a.J=".";a.g=60;return}}if(a.b===a.B||"0">a.l.charAt(a.b)||"9"<a.l.charAt(a.b)){a.g=205;return}case "0":case "1":case "2":case "3":case "4":case "5":case "6":case "7":case "8":case "9":h=!0;var u=!1,z=!0;a:for(;;){switch(b){case "0":case "1":case "2":case "3":case "4":case "5":case "6":case "7":case "8":case "9":u=!1;break;
case ".":if(z)u=z=!1;else{a.b--;break a}break;case "E":case "e":if(h)u=!0,h=!1;else{a.b--;break a}break;case "+":case "-":if(u)u=!1;else{a.b--;break a}break;default:if("a"<=b&&"z">=b||127<b)throw h=a.b,b=(h=void 0===h?null:h)?h:a.b,u=h,u=(u=void 0===u?null:u)?Vg(a,u)>>16:a.ya,h=void 0===h?null:h,new $e("Separator needed after numeric literal at line:"+(u+" col:"+(Vg(a,h?h:a.te)&32767)+" near '"+a.l.slice(5>b?0:b-5,b+5)+"'"));a.b--;break a}if(a.b>=a.B)break;b=a.l.charAt(a.b++)}a.J=a.l.substring(a.U,
a.b);a.g=209;return;case '"':case "'":for(a.J="";;){a.b=a.l.indexOf(b,a.b);if(0>a.b)throw a.b=a.U+1,new $e("Unmatched quote in expression");a.J+=a.l.substring(a.U+1,a.b++);if(a.b<a.B)if(a.l.charAt(a.b)===b)a.J+=b,a.U=a.b,a.b++;else break;else break}if(0<=a.J.indexOf("\n"))for(b=0;b<a.J.length;b++)"\n"===a.J.charAt(b)&&Ug(a,a.U+b+1);a.g=202;return;case "\n":Ug(a,a.b);case " ":case "\t":case "\r":a.U=a.b;break;case "\u00b6":case "Q":if(a.b<a.B&&"{"===a.l.charAt(a.b)){h=a.l.indexOf("}",a.b++);if(h<a.b)throw new $e("Missing closing brace in EQName");
b=a.l.substring(a.b,h);b=xb.collapse(b);if(b.includes("{"))throw new $e("EQName must not contain opening brace");a.b=h+1;h=a.b;for(u=!1;a.b<a.B;)if(z=a.l.charAt(a.b),128<z||Ze(z)||"_"===z||"."===z||"-"===z)a.b++;else{"*"===z&&h===a.b&&(a.b++,u=!0);break}a.J="Q{"+b+"}"+a.l.substring(h,a.b);a.g=u?208:201;return}default:if(128>b.charCodeAt(0)&&!Xe(b))throw new $e("Invalid character '"+b+"' in expression");case "_":h=!1;a:for(;a.b<a.B;a.b++)switch(b=a.l.charAt(a.b),b){case ":":if(h)break a;else{if(213===
a.ba||70===a.ba){a.J=a.l.substring(a.U,a.b);a.g=201;return}if(a.b+1<a.B){b=a.l.charAt(a.b+1);if(":"===b){a.J=a.l.substring(a.U,a.b);a.g=36;a.b+=2;return}if("*"===b){a.J=a.l.substring(a.U,a.b);a.g=208;a.b+=2;return}if(!("_"===b||127<b||Xe(b))){a.J=a.l.substring(a.U,a.b);a.g=201;return}}h=!0}break;case ".":case "-":if(150<a.ba&&213!==a.ba&&70!==a.ba&&-1!==Rg(a.l.substring(a.U,a.b))&&(201!==a.ba||-1===Rg(a.ta))){a.g=Rg(a.l.substring(a.U,a.b));return}case "_":break;default:if(128>b.charCodeAt(0)&&!Ze(b))break a}a.J=
a.l.substring(a.U,a.b);a.g=201;return}}}function Rg(a){a={or:9,is:20,to:29,"in":31,eq:50,ne:51,le:55,lt:53,gt:52,ge:54,as:71,and:10,div:18,mod:19,idiv:56,then:26,"else":27,"case":67,where:28,union:1,except:24,"return":25,otherwise:79,orElse:81,"default":212,andAlso:80,intersect:23,satisfies:34}[a];return[81,80].includes(a)?-1:a||-1}
Mg.prototype.sg=function(a){var b={"if":37,array:69,attribute:69,comment:69,"document-node":69,element:69,"empty-sequence":69,"function":69,map:69,item:69,"namespace-node":69,node:69,"processing-instruction":69,"schema-attribute":69,"schema-element":69,"switch":69,otherwise:79,text:69,tuple:69,typeswitch:69,union:69}[a];return b?["tuple","union"].includes(a)?35:b:35};function Ug(a,b){b=void 0===b?-1:b;a.ya++;a.Ia++;null===a.ra&&(a.ra=[]);a.ra.push(-1===b?a.b-1:b)}
function Wg(a){a.b>a.B&&(a.b=a.B);return 34>a.b?a.l.substring(0,a.b):xb.collapse("..."+a.l.substring(a.b-30,a.b)).toString()}function Vg(a,b){if(null===a.ra)return b;for(var h=a.ra.length-1;0<=h;h--){var u=a.ra[h];if(b>u)return h+1<<16|b-u}return b}var Sg=1,Tg=2,Og=3;
// Input 57
function Xg(a,b){this.j=Nc.aa;this.b=a;this.actions=b;this.Ya=b.some(function(h){return h.Ya});a=ce.Xd(b.map(function(h){return h.j.T}));b=me(b.map(function(h){return h.j.fa}));this.j=new Oc(a,b)}Ka(Xg,hf);Xg.prototype.G=function(a){hf.prototype.start.call(this,a);for(var b=0,h=Aa(this.b),u=h.next();!u.done;u=h.next())u.value.G(a),this.actions[b++].G(a);a.end()};function Yg(a,b,h){a=[a,If([Ab.XS.boolean.fd],Nc.gb)];return new Xg(a,[b,h])}Xg.prototype.K="choose";
// Input 58
function Zg(){this.j=Nc.aa;this.action=this.value=this.ac=this.ab=null;this.slot=0}Ka(Zg,hf);Zg.prototype.rd=function(){return this.ab};Zg.prototype.b=function(a){this.action=a;this.j=a.j};Zg.prototype.g=function(a){this.value=a;this.ac=a.j};Zg.prototype.G=function(a){hf.prototype.start.call(this,a,{name:this.ab.$(),slot:this.slot});this.value.G(a);this.action.G(a);a.end()};function $g(){Zg.call(this)}Ka($g,Zg);
$g.prototype.g=function(a){this.value=a;this.ac=Qc(a.j,2);this.action&&(this.j=Sc(this.action.j,a.j.fa))};$g.prototype.b=function(a){this.action=a;this.value&&(this.j=Sc(a.j,this.value.j.fa))};$g.prototype.K="for";function ah(){Zg.call(this)}Ka(ah,Zg);ah.prototype.K="let";
// Input 59
function bh(a,b){jf.call(this,a,null,b);this.j=Nc.gb}Ka(bh,jf);function ch(a,b){bh.call(this,a,b)}Ka(ch,bh);ch.prototype.K="and";function dh(a,b){bh.call(this,a,b)}Ka(dh,bh);dh.prototype.K="or";
// Input 60
function eh(a){return Hf(a)&&Ab.XS.integer.matches(a.value[0])&&1E7>a.value[0].value}function fh(a,b,h){h=void 0===h?!1:h;jf.call(this,a,null,b);h&&(a=Vf(a),b=Vf(b));this.ga=Nf(a,Nc.Uf,this.Fa(0));this.ma=Nf(b,Nc.Uf,this.Fa(1));this.j=Nc.Ve}Ka(fh,jf);fh.prototype.K="to";function gh(a,b){this.b=a;this.g=b;this.j=Nc.Ve}Ka(gh,hf);gh.prototype.G=function(a){hf.prototype.fb.call(this,a,{from:this.b.value,to:this.g.value})};gh.prototype.K="range";
function hh(a,b){this.j=Nc.aa;this.b=a;this.next=b;this.j=Sc(b.j,a.j.fa)}Ka(hh,hf);hh.prototype.G=function(a){hf.prototype.start.call(this,a);this.b.G(a);this.next.G(a);a.end()};hh.prototype.K="forEach";
// Input 61
function mh(){this.depth=0;this.masterDocument=this.g=this.ja=this.ua=this.focus=null;this.sa=!1;this.xb=[];this.fixed={};this.Wc=new Mc;this.Qb=new Mc;this.cb=null;this.ib={};this.Eb=this.ld=this.kd=this.se=this.hf=null;this.Zb=[];this.Qe=null;this.pa=[];this.Dc={};this.b=null}mh.prototype.toString=function(){var a="{";this.Wc.forAllPairs(function(b){return a+=b.k+":("+b.v+"),"});a+="}";return"context: "+Od(this)+" localParams: "+a};
mh.prototype.ca=function(a){var b=new mh;b.depth=this.depth+1;b.focus=this.focus;b.ua=this.ua;b.ja=this.ja;b.masterDocument=this.masterDocument;b.ib=this.ib;b.cb=this.cb;b.hf=this.hf;b.kd=this.kd;b.Zb=this.Zb;b.ld=this.ld;b.fixed=this.fixed;b.sa=this.sa;b.xb=this.xb;b.se=this.se;b.Qe=this.Qe;b.Dc=this.Dc;b.b=this;a?(b.Wc=new Mc,b.Qb=new Mc,b.pa=[],b.Eb=null):(b.Wc=this.Wc,b.Qb=this.Qb,b.pa=this.pa,b.Eb=this.Eb);return b};function Rd(a){a.kd=null;a.ld=null;a.Eb=null}
mh.prototype.createElement=function(a,b){var h=Kc.Qc(this.ja),u=h.documentElement;""===a&&u&&gb.mc(u)?b=h.createElement(b):"http://www.w3.org/1999/xhtml"===a&&u&&gb.mc(u)?(a=b.indexOf(":"),b=h.createElement(-1===a?b:b.substring(a+1))):b=h.createElementNS(a,b);return b};function Pe(a){return!a.focus||!a.focus.current}function Qd(a,b,h,u){if(Pe(a))throw new J("Focus for "+h+" is absent",b,void 0===u?null:u);}function Od(a){return Pe(a)?null:a.focus.current}
mh.prototype.getStackTrace=function(){var a="";Pe(this)||(a="    Processing "+ib.showValue(this.focus.current)+"\n");try{var b=this.ua.actor;if("mode"===b.N){var h="template rule";b=this.cb.action}else h=b.N+" "+b.name;a+="    at "+h+" in "+Nd.tg(b,"module")+"#"+Nd.tg(b,"line")}catch(u){a=""}this.b&&(a+="\n"+this.b.getStackTrace());return a};
// Input 62
var nh={array:"http://www.w3.org/2005/xpath-functions/array",fn:"http://www.w3.org/2005/xpath-functions",map:"http://www.w3.org/2005/xpath-functions/map",math:"http://www.w3.org/2005/xpath-functions/math",saxon:"http://saxon.sf.net/",xml:"http://www.w3.org/XML/1998/namespace",xs:"http://www.w3.org/2001/XMLSchema",xsl:"http://www.w3.org/1999/XSL/Transform"},oh={},ph;for(ph in nh)oh[nh[ph]]=ph;
// Input 63
var qh={Pl:{QuantifiedExpr:"1AB",OrExpr:"1AB",AndExpr:"1AB",ComparisonExpr:"1AB",StringConcatExpr:"1AS",RangeExpr:"*ADI",AdditiveExpr:"?A",MultiplicativeExpr:"?A",IntersectExceptExpr:"*N",InstanceOfExpr:"1AB",CastableExpr:"1AB",UnaryExpr:"?A m[UO,UD,UF]",StringLiteral:"1AS",IntegerLiteral:"1ADI",DecimalLiteral:"1AD",DoubleLiteral:"1AO",NamedFunctionRef:"1F",InlineFunctionExpr:"1F",MapExpr:"1FM",Root:"1ND"},ek:{"http://www.w3.org/2005/xpath-functions":{QName:{sig:"1F r[1AQ] a[?AS,1AS]"},abs:{sig:"1F r[?A m[AO,AD,AF]] a[?A m[AO,AD,AF]]"},
"accumulator-after":{XSLTonly:!0,sig:"1F a[1AS] r[*]"},"accumulator-before":{XSLTonly:!0,sig:"1F a[1AS] r[*]"},"adjust-date-to-timezone":{arity:[1,2],sig:"1F r[?AA] a[?AA,?ARD]"},"adjust-dateTime-to-timezone":{arity:[1,2],sig:"1F r[?AM] a[?AM,?ARD]"},"adjust-time-to-timezone":{arity:[1,2],sig:"1F r[?AT] a[?AT,?ARD]"},"analyze-string":{arity:[2,3],sig:"1F r[1NE nQ{http://www.w3.org/2005/xpath-functions}analyze-string-result] a[?AS,1AS,1AS]"},apply:{sig:"1F a[1F,1FA] r[*]"},"available-environment-variables":{sig:"1F r[*AS] a[]"},
"available-system-properties":{XSLTonly:!0,sig:"1F r[*AQ] a[]"},avg:{sig:"1F r[?A] a[*A]"},"base-uri":{defaultToDot:!0,arity:[0,1],sig:"1F r[?AU] a[?N]"},"boolean":{sig:"1F r[1AB] a[*]"},ceiling:{sig:"1F r[?A m[AO,AD,AF]] a[?A m[AO,AD,AF]]"},"codepoint-equal":{sig:"1F r[?AB] a[?AS,?AS]"},"codepoints-to-string":{sig:"1F r[1AS] a[*ADI]"},"collation-key":{arity:[1,2],collation:1,sig:"1F r[1A2] a[1AS,1AS]"},collection:{arity:[0,1],sig:"1F a[?AS] r[*]"},compare:{arity:[2,3],collation:2,sig:"1F r[?ADI] a[?AS,?AS,1AS]"},
concat:{arity:[2,Infinity],sig:"1F r[1AS] a[?A,?A]",variadic:!0},contains:{arity:[2,3],collation:2,sig:"1F r[1AB] a[?AS,?AS,1AS]"},"contains-token":{arity:[2,3],collation:2,sig:"1F r[1AB] a[*AS,1AS,1AS]"},"copy-of":{XSLTonly:!0,defaultToDot:!0,arity:[0,1],sig:"1F a[*] r[*]"},count:{sig:"1F r[1ADI] a[*]"},current:{XSLTonly:!0,sig:"1F r[1] a[]"},"current-date":{sig:"1F r[1AA] a[]"},"current-dateTime":{sig:"1F r[1AMP] a[]"},"current-group":{XSLTonly:!0,sig:"1F a[] r[*]"},"current-grouping-key":{XSLTonly:!0,
sig:"1F r[*A] a[]"},"current-merge-group":{XSLTonly:!0,arity:[0,1],sig:"1F a[1AS] r[*]"},"current-merge-key":{XSLTonly:!0,sig:"1F r[*A] a[]"},"current-output-uri":{XSLTonly:!0,sig:"1F r[?AU] a[]"},"current-time":{sig:"1F r[1AT] a[]"},data:{defaultToDot:!0,arity:[0,1],sig:"1F r[*A] a[*]"},dateTime:{sig:"1F r[?AM] a[?AA,?AT]"},"day-from-date":{sig:"1F r[?ADI] a[?AA]"},"day-from-dateTime":{sig:"1F r[?ADI] a[?AM]"},"days-from-duration":{sig:"1F r[?ADI] a[?AR]"},"deep-equal":{arity:[2,3],collation:2,sig:"1F r[1AB] a[*,*,1AS]"},
"default-collation":{sig:"1F r[1AS] a[]"},"default-language":{sig:"1F r[1ASNTL] a[]"},"distinct-values":{arity:[1,2],collation:1,sig:"1F r[*A] a[*A,1AS]"},doc:{sig:"1F r[?ND] a[?AS]"},"doc-available":{sig:"1F r[1AB] a[?AS]"},document:{XSLTonly:!0,arity:[1,2],sig:"1F r[*N] a[*,1N]"},"document-uri":{defaultToDot:!0,arity:[0,1],sig:"1F r[?AU] a[?N]"},"element-available":{XSLTonly:!0,sig:"1F r[1AB] a[1AS]"},"element-with-id":{defaultToDot:!0,arity:[1,2],sig:"1F r[*NE] a[*AS,1N]"},empty:{sig:"1F r[1AB] a[*]"},
"encode-for-uri":{sig:"1F r[1AS] a[?AS]"},"ends-with":{arity:[2,3],collation:2,sig:"1F r[1AB] a[?AS,?AS,1AS]"},"environment-variable":{sig:"1F r[?AS] a[1AS]"},error:{arity:[0,1,2,3],sig:"1F r[0E] a[?AQ,1AS,*]"},"escape-html-uri":{sig:"1F r[1AS] a[?AS]"},"exactly-one":{sig:"1F r[1] a[*]"},exists:{sig:"1F r[1AB] a[*]"},"false":{sig:"1F r[1AB] a[]"},filter:{sig:"1F r[*] a[*,1F r[1AB] a[1]]"},floor:{sig:"1F r[?A m[AO,AD,AF]] a[?A m[AO,AD,AF]]"},"fold-left":{sig:"1F a[*,*,1F a[*,1] r[*]] r[*]"},"fold-right":{sig:"1F a[*,*,1F a[1,*] r[*]] r[*]"},
"for-each":{sig:"1F a[*,1F a[1] r[*]] r[*]"},"for-each-pair":{sig:"1F a[*,*,1F a[1,1] r[*]] r[*]"},"format-date":{arity:[2,5],sig:"1F r[?AS] a[?AA,1AS,?AS,?AS,?AS]"},"format-dateTime":{arity:[2,5],sig:"1F r[?AS] a[?AM,1AS,?AS,?AS,?AS]"},"format-integer":{arity:[2,3],sig:"1F r[1AS] a[?ADI,1AS,?AS]"},"format-number":{arity:[2,3],sig:"1F r[1AS] a[?A m[AO,AD,AF],1AS,?AS]"},"format-time":{arity:[2,5],sig:"1F r[?AS] a[?AT,1AS,?AS,?AS,?AS]"},"function-arity":{sig:"1F r[1ADI] a[1F]"},"function-available":{XSLTonly:!0,
arity:[1,2],sig:"1F r[1AB] a[1AS,1ADI]"},"function-lookup":{sig:"1F r[?F] a[1AQ,1ADI]"},"function-name":{sig:"1F r[?AQ] a[1F]"},"generate-id":{defaultToDot:!0,arity:[0,1],sig:"1F r[1AS] a[?N]"},"has-children":{defaultToDot:!0,arity:[0,1],sig:"1F r[1AB] a[?N]"},head:{sig:"1F r[?] a[*]"},"hours-from-dateTime":{sig:"1F r[?ADI] a[?AM]"},"hours-from-duration":{sig:"1F r[?ADI] a[?AR]"},"hours-from-time":{sig:"1F r[?ADI] a[?AT]"},id:{defaultToDot:!0,arity:[1,2],sig:"1F r[*NE] a[*AS,1N]"},idref:{defaultToDot:!0,
arity:[1,2],sig:"1F r[*N] a[*AS,1N]"},"implicit-timezone":{sig:"1F r[1ARD] a[]"},"in-scope-prefixes":{sig:"1F r[*AS] a[1NE]"},"index-of":{arity:[2,3],collation:2,sig:"1F r[*ADI] a[*A,1A,1AS]"},innermost:{sig:"1F r[*N] a[*N]"},"insert-before":{sig:"1F a[*,1ADI,*] r[*]"},"iri-to-uri":{sig:"1F r[1AS] a[?AS]"},"json-doc":{arity:[1,2],sig:"1F r[?] a[?AS,1FM]"},"json-to-xml":{arity:[1,2],sig:"1F r[?ND] a[?AS,1FM]"},key:{XSLTonly:!0,arity:[2,3],sig:"1F r[*N] a[1AS,*A,1N]"},lang:{defaultToDot:!0,arity:[1,
2],sig:"1F r[1AB] a[?AS,1N]"},last:{sig:"1F r[1ADI] a[]"},"load-xquery-module":{arity:[1,2],sig:"1F r[1FM] a[1AS,1FM]"},"local-name":{defaultToDot:!0,arity:[0,1],sig:"1F r[1AS] a[?N]"},"local-name-from-QName":{sig:"1F r[?ASNTNC] a[?AQ]"},"lower-case":{sig:"1F r[1AS] a[?AS]"},matches:{arity:[2,3],sig:"1F r[1AB] a[?AS,1AS,1AS]"},max:{arity:[1,2],collation:1,sig:"1F r[?A] a[*A,1AS]"},min:{arity:[1,2],collation:1,sig:"1F r[?A] a[*A,1AS]"},"minutes-from-dateTime":{sig:"1F r[?ADI] a[?AM]"},"minutes-from-duration":{sig:"1F r[?ADI] a[?AR]"},
"minutes-from-time":{sig:"1F r[?ADI] a[?AT]"},"month-from-date":{sig:"1F r[?ADI] a[?AA]"},"month-from-dateTime":{sig:"1F r[?ADI] a[?AM]"},"months-from-duration":{sig:"1F r[?ADI] a[?AR]"},name:{defaultToDot:!0,arity:[0,1],sig:"1F r[1AS] a[?N]"},"namespace-uri":{defaultToDot:!0,arity:[0,1],sig:"1F r[1AU] a[?N]"},"namespace-uri-for-prefix":{sig:"1F r[?AU] a[?AS,1NE]"},"namespace-uri-from-QName":{sig:"1F r[?AU] a[?AQ]"},nilled:{defaultToDot:!0,arity:[0,1],sig:"1F r[?AB] a[?N]"},"node-name":{defaultToDot:!0,
arity:[0,1],sig:"1F r[?AQ] a[?N]"},"normalize-space":{arity:[0,1],sig:"1F r[1AS] a[?AS]"},"normalize-unicode":{arity:[1,2],sig:"1F r[1AS] a[?AS,1AS]"},not:{sig:"1F r[1AB] a[*]"},number:{defaultToDot:!0,arity:[0,1],sig:"1F r[1AO] a[?A]"},"one-or-more":{sig:"1F r[+] a[*]"},outermost:{sig:"1F r[*N] a[*N]"},"parse-ietf-date":{sig:"1F r[?AM] a[?AS]"},"parse-json":{arity:[1,2],sig:"1F r[?] a[?AS,1FM]"},"parse-xml":{sig:"1F r[?ND e[NE]] a[?AS]"},"parse-xml-fragment":{sig:"1F r[?ND] a[?AS]"},path:{defaultToDot:!0,
arity:[0,1],sig:"1F r[?AS] a[?N]"},position:{sig:"1F r[1ADI] a[]"},"prefix-from-QName":{sig:"1F r[?ASNTNC] a[?AQ]"},"random-number-generator":{arity:[0,1],sig:"1F r[1FM k[AS] v[1]] a[?A]"},"regex-group":{XSLTonly:!0,sig:"1F r[1AS] a[1ADI]"},remove:{sig:"1F r[*] a[*,1ADI]"},replace:{arity:[3,4],sig:"1F r[1AS] a[?AS,1AS,1AS,1AS]"},"resolve-QName":{sig:"1F r[?AQ] a[?AS,1NE]"},"resolve-uri":{arity:[1,2],sig:"1F r[?AU] a[?AS,1AS]"},reverse:{sig:"1F a[*] r[*]"},root:{defaultToDot:!0,arity:[0,1],sig:"1F r[?N] a[?N]"},
round:{arity:[1,2],sig:"1F r[?A m[AO,AD,AF]] a[?A m[AO,AD,AF],1ADI]"},"round-half-to-even":{arity:[1,2],sig:"1F r[?A m[AO,AD,AF]] a[?A m[AO,AD,AF],1ADI]"},"seconds-from-dateTime":{sig:"1F r[?AD] a[?AM]"},"seconds-from-duration":{sig:"1F r[?AD] a[?AR]"},"seconds-from-time":{sig:"1F r[?AD] a[?AT]"},serialize:{arity:[1,2],sig:"1F r[1AS] a[*,?]"},snapshot:{XSLTonly:!0,defaultToDot:!0,arity:[0,1],sig:"1F a[*] r[*]"},sort:{arity:[1,2,3],collation:1,sig:"1F r[*] a[*,?AS,1F r[*A] a[1]]"},"starts-with":{arity:[2,
3],collation:2,sig:"1F r[1AB] a[?AS,?AS,1AS]"},"static-base-uri":{sig:"1F r[?AU] a[]"},"stream-available":{XSLTonly:!0,sig:"1F r[1AB] a[?AS]"},string:{defaultToDot:!0,arity:[0,1],sig:"1F r[1AS] a[?]"},"string-join":{arity:[1,2],sig:"1F r[1AS] a[*A,1AS]"},"string-length":{arity:[0,1],sig:"1F r[1ADI] a[?AS]"},"string-to-codepoints":{sig:"1F r[*ADI] a[?AS]"},subsequence:{arity:[2,3],sig:"1F a[*,1AO,1AO] r[*]"},substring:{arity:[2,3],sig:"1F r[1AS] a[?AS,1AO,1AO]"},"substring-after":{arity:[2,3],collation:2,
sig:"1F r[1AS] a[?AS,?AS,1AS]"},"substring-before":{arity:[2,3],collation:2,sig:"1F r[1AS] a[?AS,?AS,1AS]"},sum:{arity:[1,2],sig:"1F r[?A] a[*A,?A]"},"system-property":{XSLTonly:!0,sig:"1F r[1AS] a[1AS]"},tail:{sig:"1F a[*] r[*]"},"timezone-from-date":{sig:"1F r[?ARD] a[?AA]"},"timezone-from-dateTime":{sig:"1F r[?ARD] a[?AM]"},"timezone-from-time":{sig:"1F r[?ARD] a[?AT]"},tokenize:{arity:[1,2,3],sig:"1F r[*AS] a[?AS,1AS,1AS]"},trace:{arity:[1,2],sig:"1F a[*,1AS] r[*]"},transform:{sig:"1F r[1FM] a[1FM]"},
translate:{sig:"1F r[1AS] a[?AS,1AS,1AS]"},"true":{sig:"1F r[1AB] a[]"},"type-available":{XSLTonly:!0,sig:"1F r[1AB] a[1AS]"},unordered:{sig:"1F r[*] a[*]"},"unparsed-entity-public-id":{XSLTonly:!0,arity:[1,2],sig:"1F r[1AS] a[1AS,1N]"},"unparsed-entity-uri":{XSLTonly:!0,arity:[1,2],sig:"1F r[1AU] a[1AS,1N]"},"unparsed-text":{arity:[1,2],sig:"1F r[?AS] a[?AS,1AS]"},"unparsed-text-available":{arity:[1,2],sig:"1F r[1AB] a[?AS,1AS]"},"unparsed-text-lines":{arity:[1,2],sig:"1F r[*AS] a[?AS,1AS]"},"upper-case":{sig:"1F r[1AS] a[?AS]"},
"uri-collection":{arity:[0,1],sig:"1F r[*AU] a[?AS]"},"xml-to-json":{arity:[1,2],sig:"1F r[?AS] a[?N,1FM]"},"year-from-date":{sig:"1F r[?ADI] a[?AA]"},"year-from-dateTime":{sig:"1F r[?ADI] a[?AM]"},"years-from-duration":{sig:"1F r[?ADI] a[?AR]"},"zero-or-one":{sig:"1F r[?] a[*]"}},"http://www.w3.org/2005/xpath-functions/array":{append:{sig:"1F r[1FA] a[1FA,*]"},filter:{sig:"1F r[1FA] a[1FA,1F r[1AB] a[*]]"},flatten:{sig:"1F a[*] r[*]"},"fold-left":{sig:"1F a[1FA,*,1F a[*,*]] r[*]"},"fold-right":{sig:"1F a[1FA,*,1F a[*,*]] r[*]"},
"for-each":{sig:"1F r[1FA] a[1FA,1F a[*]]"},"for-each-pair":{sig:"1F r[1FA] a[1FA,1FA,1F a[*,*]]"},get:{sig:"1F a[1FA,1ADI] r[*]"},head:{sig:"1F a[1FA] r[*]"},"insert-before":{sig:"1F r[1FA] a[1FA,1ADI,*]"},join:{sig:"1F r[1FA] a[*FA]"},put:{sig:"1F r[1FA] a[1FA,1ADI,*]"},remove:{sig:"1F r[1FA] a[1FA,*ADI]"},reverse:{sig:"1F r[1FA] a[1FA]"},size:{sig:"1F r[1ADI] a[1FA]"},sort:{arity:[1,2,3],collation:1,sig:"1F r[1FA] a[1FA,?AS,1F r[*A] a[*]]"},subarray:{arity:[2,3],sig:"1F r[1FA] a[1FA,1ADI,1ADI]"},
tail:{sig:"1F r[1FA] a[1FA]"}},"http://www.w3.org/2005/xpath-functions/map":{contains:{sig:"1F r[1AB] a[1FM,1A]"},entry:{sig:"1F r[1FM] a[1A,*]"},find:{sig:"1F r[1FA] a[*,1A]"},"for-each":{sig:"1F r[*] a[1FM,1F a[1A,*]]"},get:{sig:"1F a[1FM,1A] r[*]"},keys:{sig:"1F r[*A] a[1FM]"},merge:{arity:[1,2],sig:"1F r[1FM] a[*FM,1FM]"},put:{sig:"1F r[1FM] a[1FM,1A,*]"},remove:{sig:"1F r[1FM] a[1FM,*A]"},size:{sig:"1F r[1ADI] a[1FM]"}},"http://www.w3.org/2005/xpath-functions/math":{acos:{sig:"1F r[?AO] a[?AO]"},
asin:{sig:"1F r[?AO] a[?AO]"},atan:{sig:"1F r[?AO] a[?AO]"},atan2:{sig:"1F r[1AO] a[1AO,1AO]"},cos:{sig:"1F r[?AO] a[?AO]"},exp:{sig:"1F r[?AO] a[?AO]"},exp10:{sig:"1F r[?AO] a[?AO]"},log:{sig:"1F r[?AO] a[?AO]"},log10:{sig:"1F r[?AO] a[?AO]"},pi:{sig:"1F r[1AO] a[]"},pow:{sig:"1F r[?AO] a[?AO,1A m[AO,AD,AF]]"},sin:{sig:"1F r[?AO] a[?AO]"},sqrt:{sig:"1F r[?AO] a[?AO]"},tan:{sig:"1F r[?AO] a[?AO]"}}}};
// Input 64
var rh=function(){var a={};a[Ic.zb]={apply:{sig:"1F r[*] a[1F,1FA]"},"column-number":{arity:[0,1],sig:"1F r[1ADI] a[1N]"},"compile-XPath":{arity:[1,2,3],sig:"1F r[1ND] a[1AS,?,?]"},"compile-GlobalContext":{sig:"1F r[1] a[1FM,1FM,1FM]"},"discard-document":{sig:"1F r[1ND] a[1ND]"},"has-uniform-namespaces":{sig:"1F r[1AB] a[1N]"},"line-number":{arity:[0,1],sig:"1F r[1ADI] a[1N]"},"marshal-type":{sig:"1F r[1FM] a[1AS]"},"message-count":{sig:"1F r[1ADI] a[?AQ]"},"parse-sequence-type":{arity:[2,3],sig:"1F r[1FM] a[1AS,1NE,?AS]"},
"parse-XPath":{arity:[1,2,3],sig:"1F r[1ND] a[1AS,?AS,?]"},permute:{sig:"1F r[*] a[*]"},"system-id":{sig:"1F r[*AU] a[]"},timestamp:{sig:"1F r[1AMP] a[]"}};a[Ic.Vd]={apply:{sig:"1F r[*] a[1F,1FA]"},call:{sig:"1F r[*] a[1,1AS,1FA]"},contains:{sig:"1F r[1AB] a[1,1AS]"},eval:{sig:"1F r[*] a[1AS]"},event:{sig:"1F r[?] a[]"},get:{sig:"1F r[*] a[?,1AS]"},location:{sig:"1F r[1AS] a[]"},page:{sig:"1F r[1N] a[]"},"query-params":{sig:"1F r[1FM] a[]"},"set-property":{sig:"1F r[0E] a[1AS,*,1NE]"},source:{sig:"1F r[?N] a[]"},
style:{sig:"1F r[1FM] a[?N]"},window:{sig:"1F r[1] a[]"}};a["http://exslt.org/common"]={"node-set":{sig:"1F r[?N] a[?N]",isIdempotent:!0}};return{fj:function(b){return a[b]},Wg:function(b){var h=b.namespace;b=b.signatures;var u=a[h];u||(u={},a[h]=u);Object.entries(b).forEach(function(z){u[z[0]]=z[1]})}}}();
// Input 65
var th=function(){function a(c){var l;var t={};for(l in c){var r=c[l];t[l]=r}return t}function b(){}function h(c){var l={};if(c){c=c.split(" ");for(var t=0;t<c.length;t++){var r=c[t],A=r.indexOf("="),M=-1===A?r:r.substring(0,A);r=-1===A?"":r.substring(A+1);l[M]="~"===r?Ic.Jc[M]:r}}return l}function u(c){var l=[];(c.C||[]).forEach(function(t){"arg"===t.N&&l.push(t)});return l}function z(c,l,t){if(l.globalContext)var r=l.globalContext.value.ca();else{r=new b;r.g();var A=l.globals;A&&(r.Tc=A);if(A=l.accumulators)r.Jb=
A}A=l.language;d&&d.language&&(A=d.language);var M=l.staticContext;M&&M.language&&(A=M.language);M=l.html5Mode;r.ye=M?M:!1;r.eb=A?"XSLT"===A:!1;r.Vc=l.inTry;r.uf=l.inCatch;r.Sb=l.backwardCompatibility;var X,ka;if(A=l.slotMap){M=-1;for(X in A){var qa=A[X];r.$b[X]=qa;M=qa>M?qa:M}r.xd=M+1}r.Vj(c);if(c=l.slotTypes)for(X in c)r.Dd[X]=Zc(c[X]);for(ka in t)r.namespaces[ka]=t[ka];r.namespaces[""]="";if(t=l.staticTyping)r.If=t;if(t=l.xpathDefaultNamespace)r.xpathDefaultNamespace=t;if(t=l.defaultCollation)r.Tb=
t;if(t=l.staticBaseURI)r.staticBaseURI=t;(t=l.contextType)?r.type=new Oc($c(t),2):r.type=r.eb?null:Nc.rc;r.Ge=r.type;if(l=l.requiredType)r.Le=Wc(l);return r}function n(c){var l=new b;l.type=Nc.rc;l.U(c.params,c.inputConvert);["xs","map","array","math","saxon"].forEach(function(M){return l.namespaces[M]=nh[M]});for(var t in c.namespaceContext)l.namespaces[t]=c.namespaceContext[t];c.xpathDefaultNamespace&&(l.xpathDefaultNamespace=c.xpathDefaultNamespace);var r;if(c=c.slotMap){t=-1;for(r in c){var A=
c[r];l.$b[r]=A;t=A>t?A:t}l.xd=t+1}return l}function g(c,l,t){c=sh.process(c,"pattern"===l.type,l.splitPattern,t,"XSLT"===l.language,l.allowEmpty,l.freeVariables);return xc(c)}function e(c,l){l=void 0===l?!1:l;var t={"function":{},globalVariable:{},globalParam:{}},r;for(r in c)if("PRIVATE"!==c[r].visibility){var A=c[r].actor,M=Kc.X(A)?A.nodeName:A.N,X=Kc.X(A)?A.getAttribute("name"):A.name;switch(M){case "function":if(l&&"PUBLIC"!==c[r].visibility&&"FINAL"!==c[r].visibility)break;A=(Kc.X(A)?Kc.Nc(A,
"arg"):u(A)).length;t[M][X+"#"+A]=c[r];break;case "globalVariable":case "globalParam":t[M][X]=c[r]}}return t}function k(c,l){l=void 0===l?!0:l;var t={N:c.nodeName},r=c.attributes,A;for(A=0;A<r.length;A++){var M=r[A].name;if("xmlns"!==M.substring(0,5)||5!==M.length&&":"!==M.charAt(5))t[M]=r[A].value}c=c.childNodes||[];if(0!==c.length){r=[];for(A=0;A<c.length;A++)M=k(c[A],l),l&&(M.parentNode=t),r.push(M);t.C=r}return t}function p(c){c.C&&c.C.forEach(function(l){l.parentNode=c;p(l)})}function v(c,l,
t){c={N:"co",id:c,binds:t,ef:t.split(/\s+/).map(function(r){return parseInt(r,10)}),actor:l,C:[l]};return l.parentNode=c}function B(c){if(!Kc.X(c))return[];var l=[];c.childNodes.forEach(function(t){return l=l.concat(B(t))});return l}function q(c,l){"ufRef"===c.nodeName?c.setAttribute("bSlot",l[c.getAttribute("name")]):c.hasChildNodes()&&c.childNodes.forEach(function(t){return q(t,l)})}function f(c,l,t){var r=te.find("SaxonJS"),A=r.XError,M=r.convertResult,X=r.getPlatform(),ka=X.inBrowser();t||(t=
{});t.params||(t.params={});if(c.freeV){var qa=t.params,Q={};c.freeV.forEach(function(O){var Z=O.startsWith("Q{}")?qa[O.substring(3)]:qa[O];if(!Z)throw new A("A value for parameter "+O+" is required","SXJS9999");Q[O]=Z});t.params=Q}r=t.inputConvert||"strong";var ja=n(t),D=c.C[0];D.parentNode=c;var C={name:"evaluate",Ua:c,jb:{}};c=new mh;c.ja=X.createDocument();c.fixed={vb:{},pc:{},Cd:{},Zf:0,Lb:new Date,jb:{"Q{}":c.ja.createElement("decimal-format")},yd:{evaluate:C},staticBaseURI:ka?window.location.href:
null,ka:Nd};t.staticBaseURI&&(c.fixed.staticBaseURI=t.staticBaseURI);var y;if(t.namedDecimalFormats)for(var K in t.namedDecimalFormats){X=t.namedDecimalFormats[K];ka=c.ja.createElement("decimal-format");for(y in X){var U=X[y];ka.setAttribute(y,["infinity","NaN"].includes(y)?U:tb(U)[0])}C.jb[/^Q\{/.test(K)?K:"Q{}"+K]=k(ka)}if(t.defaultDecimalFormat){X=t.defaultDecimalFormat;ka=c.ja.createElement("decimal-format");for(y in X)U=X[y],ka.setAttribute(y,["infinity","NaN"].includes(y)?U:tb(U)[0]);C.jb["Q{}"]=
k(ka)}c.pa=ja.Fc;if(l)if(l instanceof bc)c.focus=l;else{if(Array.isArray(l)&&1<l.length)throw new A("Context item is a sequence of more than one item","XTTE3210");l=Nd.Ta(l);"strong"===r&&(l=Nd.Fd(l));c.focus=null!=l?Ac(l[0]):Bc(wc)}else c.focus=Bc(wc);l=Nd.evaluate(D,c);return M(l,t.resultForm||"default")}var I=re.Kd,d=null;b.prototype={type:void 0,Le:void 0,Ge:void 0,B:[],J:void 0,xd:0,l:1,$b:{},Dd:{},b:{},Fc:[],Tc:{},Jb:{},If:!1,eb:!1,Vc:!1,uf:!1,yg:!1,Sb:!1,namespaces:{},xpathDefaultNamespace:"",
Tb:I,g:function(){this.l=this.xd=0;this.Fc=[];this.$b={};this.Dd={};this.If=!1;this.b={};this.Tc={};this.namespaces={"":"",xml:"http://www.w3.org/XML/1998/namespace",fn:"http://www.w3.org/2005/xpath-functions"};this.Tb=I},toString:function(){var c=0,l;for(l in this.Tc)c++;return"CompileContext:\n\t"+(c+" global variables/params")},Vj:function(c){if(void 0!==c&&(c=c.next(),null!=c))for(var l=sc(c.keys()),t;null!=(t=l.next());){var r=Ab.XS.QName.matches(t)?t.$():t.toString();/^Q{/.test(r)||(r="Q{}"+
r);var A=this.$b.hasOwnProperty(r)?this.$b[r]:this.xd++;this.$b[r]=A;t=c.get(t);this.Fc[A]=t;this.Dd[r]=Nc.aa}},U:function(c,l){this.g();if(void 0!==c)for(var t in c){var r=t.toString();/^Q{/.test(r)||(r="Q{}"+r);var A=this.xd++;this.$b[r]=A;var M=Nd.Ta(c[t]);"strong"===l&&(M=Nd.Fd(M));if(this.Fc[A]=M)A=M.code,this.Dd[r]=A?new Oc(Ab.bb[A],2):Nc.aa}},ca:function(){var c=new b;c.type=this.type;c.Le=this.Le;c.Ge=this.Ge;c.B=this.B;c.J=this.J;c.xd=this.xd;c.l=this.l;c.$b=a(this.$b);c.Dd=a(this.Dd);c.If=
this.If;c.eb=this.eb;c.Vc=this.Vc;c.uf=this.uf;c.Sb=this.Sb;c.b=a(this.b);c.Fc=a(this.Fc);c.Tc=this.Tc;c.Jb=this.Jb;c.namespaces=a(this.namespaces);c.xpathDefaultNamespace=this.xpathDefaultNamespace;c.Tb=this.Tb;c.hb=this.hb;return c}};return{compile:function(c,l,t,r,A){r=h(l.nsAttribute);t=z(t,l,r);if(!l.globalContext&&(t.hb=e(A.fixed.Na),A=l.globalFunctions)){r={};var M=0,X;for(X in A){var ka=A[X],qa=Kc.X(ka);ka=qa?ka.parentNode:ka.parent;var Q=qa?ka.parentNode:ka.parent;r[M++]={actor:ka,Ua:Q,id:parseInt(qa?
Q.getAttribute("id"):Q.id,10)}}t.hb=e(r)}return g(c,l,t)},compileOnly:function(c,l,t){t=void 0===t?!1:t;te.find("SaxonJS").getPlatform();l||(l={});l.params||(l.params={});l.freeVariables=!0;var r=n(l),A=g(c,l,r).next();if(r=A.Yi){var M=Object.assign({},l);M.freeVariables=!1;M.params={};r.forEach(function(ka){M.params[ka]="dummy"});l=n(M);A=g(c,M,l).next()}var X={N:"package",name:"evaluate",C:[A]};r&&(X.freeV=r);return t?X:function(ka,qa){qa=void 0===qa?{}:qa;return f(X,ka,{params:qa})}},evaluateOnly:f,
compileGlobalContext:function(c,l,t){var r=new b;r.g();r.Tc=c;r.ba=l;r.Jb=t;c={};t=0;for(var A in l){var M=l[A],X=Kc.X(M);M=X?M.parentNode:M.parent;var ka=X?M.parentNode:M.parent;c[t++]={actor:M,Ua:ka,id:parseInt(X?ka.getAttribute("id"):ka.id,10)}}r.hb=e(c);return xc(r)},evaluateXDM:function(c,l,t,r){function A(F){return x[void 0===F?"":F]}var M,X=te.find("SaxonJS").getPlatform(),ka=z(r.params,r,r.namespaces);ka.Na=t.fixed.Na;ka.staticBaseURI||(ka.staticBaseURI=t.fixed.staticBaseURI);var qa=t.fixed.Na;
ka.hb=e(t.fixed.Na,r.dynamicEval);c=g(c,r,ka).next();var Q={N:"package",name:"evaluate"},ja={name:"evaluate",Ua:Q,jb:{}};c.parentNode=Q;var D=!1;var C=B(c);if(0<C.length){D=!0;var y=[];for(var K=0;K<C.length;K++)y.push(1001+K);var U=1001,O=0,Z=[],aa={};C.forEach(function(F){var P=v(U,k(F),"");F=F.getAttribute("name")+"#"+F.getAttribute("arity");P.parentNode=Q;ka.hb["function"][F]={actor:P,Ua:null,id:U};aa[F]=O++;qa[U]=P;U++;Z.push(P)});q(c,aa);c=k(c);y=v(1E3,c,y.join(" "));y.parentNode=Q;qa[1E3]=
y;Z.unshift(y);Q.C=Z}else Kc.X(c)?c=k(c):p(c),Q.C=[v(1E3,c,"")],c.parentNode=Q;K=Nd.ue(t.ua.actor,t);for(M in K.jb)ja.jb[M]=K.jb[M];C=new mh;C.fixed={vb:{},Cd:{},pc:{},Zf:0,Lb:new Date,jb:{},Na:t.fixed.Na,ka:Nd,options:t.fixed.options,ic:t.fixed.ic,staticBaseURI:t.fixed.staticBaseURI,Ne:null,yd:{evaluate:ja}};C.fixed.yd[K.name]=K;r.staticBaseURI&&(C.fixed.staticBaseURI=r.staticBaseURI);if(K=r.staticContext){var ca=K.decimalFormats;if(ca)for(M in ca)ja.jb[M]=k(ca[M]);if(ja=K.resources)for(M in ja)if(ca=
ja[M],ca.hasOwnProperty("err")){var oa=ca.err;oa=oa.replace(/Q{http:\/\/www\.w3\.org\/2005\/xqt-errors\}/,"");C.fixed.pc[M]=new J(oa+" "+ca.description,oa)}else C.fixed.pc[M]=ca;if(ja=K.sources)for(M in ja)if("."!==M)try{var ea=ja[M];ca=void 0;if("string"==typeof ea){var na=X.readFile(ea);na=xe.bh(xe.ah(na));ca=Kc.Ec(X.parseXmlFromString(na));ca._saxonBaseUri=ea;ca._saxonDocUri=ea}else ca=ea;C.fixed.vb[M]=ca}catch(F){C.fixed.vb[M]=F}if(M=K.collectionFinder){var x=M;C.fixed.options.dh=A}M=K.staticBaseURI;
"undefined"!=typeof M&&(C.fixed.staticBaseURI="#UNDEFINED"===M?null:M)}r.staticBaseURI&&(C.fixed.staticBaseURI=r.staticBaseURI);C.masterDocument=t.masterDocument;C.ja=X.createDocument();if(null!=l){r=l.next();if(null!=l.next())throw new J("Context item is a sequence of more than one item","XTTE3210");C.pa=[];for(l=0;l<ka.Fc.length;l++)C.pa[l]=ka.Fc[l];C.ua=D?y:t.ua;null!=r?(r=Ac(r),C.focus=r):C.focus=Bc(wc);return Nd.evaluate(c,C)}return wc},evaluate:function(c,l,t){var r=te.find("SaxonJS"),A=r.XError,
M=r.convertResult,X=r.getPlatform(),ka=X.inBrowser();t||(t={});t.params||(t.params={});r=t.inputConvert||"strong";var qa=n(t);c=g(c,t,qa).next();var Q={N:"package",name:"evaluate",C:[c]};c.parentNode=Q;var ja={name:"evaluate",Ua:Q,jb:{}};Q=new mh;Q.ja=X.createDocument();Q.fixed={vb:{},pc:{},Cd:{},Zf:0,Lb:new Date,jb:{"Q{}":Q.ja.createElement("decimal-format")},yd:{evaluate:ja},staticBaseURI:ka?window.location.href:null,ka:Nd};t.staticBaseURI&&(Q.fixed.staticBaseURI=t.staticBaseURI);var D;if(t.namedDecimalFormats)for(var C in t.namedDecimalFormats){X=
t.namedDecimalFormats[C];ka=Q.ja.createElement("decimal-format");for(D in X){var y=X[D];ka.setAttribute(D,["infinity","NaN"].includes(D)?y:tb(y)[0])}ja.jb[/^Q\{/.test(C)?C:"Q{}"+C]=k(ka)}if(t.defaultDecimalFormat){X=t.defaultDecimalFormat;ka=Q.ja.createElement("decimal-format");for(D in X)y=X[D],ka.setAttribute(D,["infinity","NaN"].includes(D)?y:tb(y)[0]);ja.jb["Q{}"]=k(ka)}Q.pa=qa.Fc;if(l)if(l instanceof bc)Q.focus=l;else{if(Array.isArray(l)&&1<l.length)throw new A("Context item is a sequence of more than one item",
"XTTE3210");l=Nd.Ta(l);"strong"===r&&(l=Nd.Fd(l));Q.focus=null!=l?Ac(l[0]):Bc(wc)}else Q.focus=Bc(wc);l=Nd.evaluate(c,Q);return M(l,t.resultForm||"default")},forceBaseURI:function(c,l){c._saxonBaseUri=l;c._saxonDocUri=l;return c},getFnSignature:function(c,l,t){function r(qa){qa.sig||(qa.sig="F a["+Nd.Db(qa,"arg").map(function(Q){return Q.as}).join(",")+"] r["+qa.as+"]");return qa.sig}var A=null,M=qh.ek[c.uri];M&&(A=M[c.local]);if(A&&!t.eb&&A.XSLTonly)return null;A||c.uri!==Ic.qc||(Ab.type[c.local]?
A={sig:"1F a[?A] r[?"+Ab.type[c.local].code+"]"}:le[c.local]&&(A={sig:"1F a[1AS] r[*"+le[c.local]+"]"}));if(!A&&t.hb&&t.hb["function"]){var X=c.$();if(t=t.hb["function"][X+"#"+l])c=X,A=t.actor,Kc.X(A)?(Kc.Nc(A,"arg"),A={sig:A.getAttribute("sig")}):(u(A),A={sig:r(A)}),A.isUserFunction=!0,A.componentBinding=t.id}else if(!A&&t.fixed&&t.fixed.Na)for(X in M=c.$(),t.fixed.Na){var ka=t.fixed.Na[X].actor;if("function"===ka.N&&ka.name===M&&u(ka).length===l){A={sig:r(ka),isUserFunction:!0,componentBinding:X};
break}}if(!A&&((t=rh.fj(c.uri))&&(A=t[c.local]),c.uri===Ic.Hh)){c=0<l?"*":"";for(A=1;A<l;A++)c+=",*";A={sig:"1F a["+c+"] r[*]",variadic:!0}}A&&!A.alphaTree&&(A.alphaTree=Yb(A.sig));return A&&(c=A.alphaTree.a.length,null!=l&&l!==c&&(A.variadic?l<c:!(A.arity||[]).includes(l)))?null:A},parseNsAttribute:h,sefToJSON:k,setStaticContext:function(c){d=Nd.tb(c.value,!0);c={};var l=d.resources,t;for(t in l){var r=l[t];if(r.hasOwnProperty("err")){var A=r.err;A=A.replace(/Q{http:\/\/www\.w3\.org\/2005\/xqt-errors\}/,
"");c[t]=new J(A+" "+r.description,A)}else c[t]=r}d.resources=c;return null},resetStaticContext:function(){return d=null},resetNameBase:function(){}}}();
// Input 66
var uh={evaluateXDM:th.evaluateXDM,evaluate:th.evaluate,compileToXML:th.compile,compile:th.compileOnly,evaluateOnly:th.evaluateOnly,compileGlobalContext:th.compileGlobalContext,forceBaseURI:th.forceBaseURI,getFnSignature:th.getFnSignature,sefToJSON:th.sefToJSON,setStaticContext:th.setStaticContext,resetStaticContext:th.resetStaticContext,resetNameBase:th.resetNameBase};
// Input 67
var vh={FUNCTION:0,ik:1,Qk:2,Xk:3,xk:4,rk:5,Ek:6,Pk:7,Hk:8,Uk:9,Vk:10,wk:11,EVALUATE_RESULT:12,kk:13,gk:14,Dk:15,jk:16,lk:17},wh=["doc","id","element-with-id","key","root"];function xh(a,b,h,u){return vh[a]+"|"+b+"|"+h+"|"+u}function yh(a,b,h,u){return{getMessage:function(){return b},hg:u,we:function(){return a},save:function(){return h}}}function zh(a,b){var h=Nd.Tj(b).required;return yh(a,h,b,function(u,z){return"Required type of "+h+" is "+u.toString()+": actual type is "+z.toString()})}
function Ah(a,b){var h=null;this.name=a;this.arity=a.arity;if(b){this.Ab=b;h=b.alphaTree;var u=[],z;b.variadic&&(z=Zc(h.a[0]));for(b=0;b<a.arity;b++){var n=z?z:Zc(h.a[b]);u.push(n)}h=h.r?Zc(h.r):Nc.aa;this.j=ad(new Pd(u,h))}h?this.resultType=h:this.resultType=Nc.aa;this.j||(this.j=Nc.Ye)}Ah.prototype.resultType=Nc.aa;
var sh=function(){function a(){E=new Mg;wa=[];ta=[];Qa=0}function b(L){var W=ob[L];W||z("No namespace binding for prefix '"+L+"'","XPST0081");return W}function h(L,W,ha){W=void 0===W?this.yb:W;ha=void 0===ha?function(){return""}:ha;return Ab.XS.QName.fromString(L,function(la){return""===la?ha():W(la)})}function u(){var L=df[E.I];return 201===E.I?'name "'+E.R+'"':-1===E.I?"(unknown token)":E.R&&E.R!==df[E.I]?L+' ("'+E.R+'")':/^[a-z]+$/.test(L)?'"'+L+'"':L}function z(L,W){W=void 0===W?"XPST0003":W;
Wg(E);throw new $e(L,W);}function n(L){var W=Wg(E);console.log((L.startsWith("...")?"near":"in")+" "+W+":\n    "+L)}function g(){try{E.next()}catch(L){z(L.message)}}function e(L){E.I!==L&&z('expected "'+df[L]+'", found '+u())}function k(L){L=p(void 0===L?!1:L);for(var W=null;7===E.I;){null==W&&(W=[],W.push(L));g();var ha=p();W.push(ha)}null!=W&&(L=new Bh(W));return L}function p(L){L=void 0===L?!1:L;switch(E.I){case 0:if(L)return Jf();z("Expected an expression, but reached the end of the input");break;
case 211:case 216:L=0;var W=E.I,ha=null,la=null;do{g();e(21);g();e(201);var za=E.R,Fa=void 0;211===W?Fa=new $g:Fa=new ah;L++;za=m(za,"");Fa.ab=za;g();e(216===W?58:31);g();ta.push(null);Fa.g(p());ta.pop();T(Fa);null==la?ha=Fa:la.b(Fa);la=Fa}while(7===E.I);e(25);g();la.b(p());for(W=0;W<L;W++)ta.pop();return ha;case 32:case 33:L=0;la=E.I;W=ha=null;do g(),e(21),g(),e(201),Fa=E.R,L++,Fa=new Ch(la,m(Fa,"")),g(),e(31),g(),za=p(),Fa.value=za,Fa.ac=Qc(za.j,2),T(Fa),null!=W?W.action=Fa:ha=Fa,W=Fa;while(7===
E.I);e(34);g();la=p();W.action=la;for(W=0;W<L;W++)ta.pop();return ha;case 37:return g(),L=k(),e(204),g(),e(26),g(),ha=p(),e(27),g(),W=p(),Yg(L,ha,W);default:return q(I(),4)}}function v(){return B(E.I)}function B(L){switch(L){case 9:case 81:return 4;case 10:case 80:return 5;case 50:case 51:case 55:case 53:case 54:case 52:case 6:case 22:case 14:case 12:case 13:case 11:case 20:case 38:case 39:return 6;case 30:return 7;case 29:return 8;case 15:case 16:return 9;case 17:case 18:case 56:case 19:return 10;
case 79:return 11;case 1:return 12;case 23:case 24:return 13;case 45:return 14;case 47:return 15;case 57:return 16;case 46:return 17;case 76:return 18;case 40:return 20;default:return-1}}function q(L,W){for(;v()>=W;){var ha=E.I,la=v();switch(ha){case 45:case 47:g();var za=M();a:switch(ha){case 45:L=new pg(L,za);break a;case 47:L=Lg(L,za);break a;default:throw new af(df[ha]);}v()>=la&&z("Left operand of '"+df[E.I]+"' needs parentheses");break;case 46:case 57:g();var Fa=void 0;if(69===E.I&&"union"===
E.R)throw new J("Saxon union types not supported");e(201);if(E.R)a:{try{var Ja=h(E.R,S.yb,S.jc);break a}catch(pb){z(pb.message,pb.code)}Ja=void 0}else Ja=z("Parser tokenization error NAME");za=Ja;za.uri!==Ic.qc&&z("Unknown simple type "+E.R+" in cast (Saxon-JS is not schema-aware)");g();var Za=213===E.I;Za&&(E.I=204,g());var $a={numeric:"u",ENTITIES:"l",NMTOKENS:"l",IDREFS:"l"}[za.local]||"a";if("a"===$a){["anyType","anyAtomicType","NOTATION"].includes(za.local)&&z("Casting to xs:"+za.local+" is not possible (it's an abstract type)",
"XPST0080");Fa=Ab.type[za.local];if(!Fa)throw new J("Cannot find type "+za.$());Fa=new dd(Fa)}L=(57===ha?Dh:Eh)(L,$a,za,Fa,Za);v()>=la&&z("Left operand of '"+df[E.I]+"' needs parentheses");break;case 76:L=Q(L);break;default:Za=40===ha;g();Za&&wa.push(Qc(L.j,2));za=I();for(Za&&wa.pop();v()>la;)za=q(za,v());if(!(la=v()!==la))a:switch(E.I){case 50:case 51:case 55:case 53:case 54:case 52:case 6:case 22:case 14:case 12:case 13:case 11:case 20:case 38:case 39:case 29:la=!1;break a;default:la=!0}la||z("Left operand of '"+
df[E.I]+"' needs parentheses");L=f(L,ha,za)}}return L}function f(L,W,ha){switch(W){case 9:return new dh(L,ha);case 10:return new ch(L,ha);case 50:case 51:case 55:case 53:case 54:case 52:return new dg(L,W,ha,S);case 6:case 22:case 14:case 12:case 13:case 11:if(S.Be())W=new cg(L,W,ha,S);else{var la=S;ha=new bg(L,W,ha,la);if(L=2===ha.ga.j.fa&&2===ha.ma.j.fa&&Xf[W]){L=ha.ga.j.T;var za=ha.ma.j.T,Fa=L.V().p,Ja=za.V().p;L=Zf(Fa)||Zf(Ja)?Fa===Ja:L.isNumeric()&&za.isNumeric()?!0:/^A[SZU]/.test(Fa)&&/^A[SZU]/.test(Ja)}W=
L?new dg(ha.ga,Xf[W],ha.ma,la):ha}return W;case 20:case 38:case 39:return new jg(L,W,ha,S);case 29:return ha=new fh(L,ha,S.Be()),eh(ha.ga)&&eh(ha.ma)?(W=ha.ga.value[0],ha=ha.ma.value[0],W=W.value>ha.value?Jf():W.value===ha.value?new Gf([W],Nc.Ve):new gh(W,ha)):W=ha,W;case 30:W=[];if(L instanceof Of&&"concat"===L.name.name.local&&L.name.name.uri===Ic.Ea)for(la=Aa(L.b),L=la.next();!L.done;L=la.next())W.push(L.value);else la=xh("BINARY_OP",0,"","concat"),za=zh("FOTY0013",la),L=Cg(L,Nc.cc,za,la,S),W.push(L);
la=xh("BINARY_OP",1,"","concat");za=zh("FOTY0013",la);ha=Cg(ha,Nc.cc,za,la,S);W.push(ha);W=rg("concat",W);W.j=Nc.dc;return W;case 15:case 16:case 17:case 18:case 56:case 19:return new Uf(L,W,ha,S);case 79:return la=L,W=ha,L=Ab.XS.QName.fromParts("vv",Ic.Ig,"otherwise"),ha=new ah,ha.ab=L,ha.g(la),T(ha),la=Fh(ha),L=[rg("exists",[la]),If([Ab.XS.boolean.fd],Nc.gb)],ha.b(new Xg(L,[la,W])),ha;case 1:case 23:case 24:return new Gh(L,W,ha,1===Na);case 81:return ha=rg("boolean",ha),Yg(L,If([Ab.XS.boolean.fd],
Nc.gb),ha);case 80:return ha=rg("boolean",ha),Yg(L,ha,If([Ab.XS.boolean.je],Nc.gb));default:throw new af(df[W]);}}function I(){switch(E.I){case 16:g();var L=I();L=d(299,L);break;case 15:g();L=I();L=d(15,L);break;default:for(L=l();40===E.I;){g();wa.push(Qc(L.j,2));var W=l();wa.pop();L=new hh(L,W)}}return L}function d(L,W){if(Hf(W)){var ha=W.value[0];return 299===L?If([ha.Af()],W.j):W}return new Uf(If([Ab.XS.integer.fromNumber(0)],Nc.Id),L,W,S)}function c(){switch(E.I){case 36:case 3:case 201:case 208:case 70:case 207:case 69:case 205:case 206:case 35:case 202:case 209:case 5:case 21:case 43:return!0;
case 60:return["ordered","unordered"].includes(E.R);default:return!1}}function l(){var L=E.te;switch(E.I){case 2:return g(),L=new Pf,c()?t(L):L;case 8:g();L=new Pf;var W=new Lf(5,null);L=tg(L,W);return t(L);default:201===E.I&&["true","false"].includes(E.R)&&n("The expression is looking for a child element named '"+E.R+"' - perhaps "+E.R+"() was intended? To avoid this warning, use child::"+E.R+" or ./"+E.R+".");201===E.I&&-1!==Rg(E.R)&&1!==Na&&(0<L||0!==E.g)&&(L=E.R,n("The keyword '"+L+"' in this context means 'child::"+
L+"'. If this was intended, use 'child::"+L+"' or './"+L+"' to avoid this warning."));for(L=r(1===Na&&0===Qa);[2,8].includes(E.I);){wa.push(Qc(L.j,2));var ha=E.I;g();W=r(!1);2===ha?L=tg(L,W):W instanceof Lf&&3===W.axis?(W=new Lf(4,W.b),L=tg(L,W)):(ha=new Lf(5,null),L=tg(L,ha),L=tg(L,W));L=Hh(L);wa.pop()}return L}}function t(L){var W=L,ha=2;for(wa.push(Qc(L.j,2));;){L=r(!1);wa.pop();wa.push(Qc(L.j,2));2===ha?(W=tg(W,L),W=Hh(W)):8===ha?(ha=new Lf(5,null),ha=tg(ha,L),W=tg(W,ha),W=Hh(W)):W=new hh(W,L);
ha=E.I;if(2!==ha&&8!==ha&&40!==ha)break;g()}wa.pop();return W}function r(L){L=ja(L);for(var W=L instanceof Lf&&!mf[L.pd()];;)if(4===E.I)wa.push(Qc(L.j,2)),L=A(L),wa.pop();else if(5===E.I)L=O(L,null);else if(213===E.I)L=Z(L);else break;W&&(L=rg("reverse",L),L.xl=!0);return L}function A(L){L=void 0===L?null:L;if(!L)return k(null);g();Qa++;var W=A();Qa--;e(203);g();return L=new mg(L,W)}function M(){var L="empty-sequence"===E.R,W=X();if(L)return Nc.Te;switch(E.I){case 207:case 17:L=7;E.I=204;g();break;
case 15:L=6;E.I=204;g();break;case 213:L=3;E.I=204;g();break;default:L=2}return new Oc(W,L)}function X(){if(5===E.I){g();var L=X();e(204);g()}else if(201===E.I){L=null;try{L=h(E.R,S.yb,S.jc)}catch(za){z(za.message,za.code)}var W=L,ha=W.uri;""===ha&&(ha=S.jc());var la=W.local;L=W.toString();ha===Ic.qc?"numeric"===la?L=kd:((W=Ab.type[la])||z("Unknown atomic type "+L,"XPST0051"),L=new dd(W)):(z("Unknown atomic type "+W.$()+" (Saxon-JS is not schema-aware)","XPST0051"),L=void 0);g()}else if(69===E.I)switch(E.R){case "item":g();
e(204);g();L=ed;break;case "function":L=ka();break;case "map":L=qa();break;case "array":L=E;g();[207,17].includes(L.I)?(g(),e(204),g(),L=qd):(L=M(),e(204),g(),L=new ee(L));break;case "empty-sequence":g();e(204);g();L=sd;break;case "switch":case "typeswitch":z(E.R+"() is reserved in XPath; it is available only in XQuery");break;default:L=na()}else{if(4===Na&&208===E.I)return L=E.R,g(),G(1,L);if(4===Na&&70===E.I)return g(),e(201),L=E.R,g(),N(1,L);if(4===Na&&3===E.I){g();if(208===E.I)return L=E.R,g(),
G(2,L);if(70===E.I)return g(),e(201),L=E.R,g(),N(2,L);z("Expected NodeTest after '@'");return Hd}z("Expected type name in SequenceType, found "+df[E.I]);return Hd}return L}function ka(){g();var L=[];if([207,17].includes(E.I))return g(),e(204),g(),md;for(;204!==E.I;){var W=M();L.push(W);if(204===E.I)break;else 7===E.I?g():z("Expected ',' or ')' after function argument type, found '"+df[E.I]+"'")}g();if(71===E.I)return g(),W=M(),new Pd(L,W);0<L.length?z("Result type must be given if an argument type is given: expected 'as (type)'"):
z("function() is not allowed for a general function type: must be function(*)");return null}function qa(){var L=E;g();if([207,17].includes(L.I))return g(),e(204),g(),od;L=X();e(7);g();var W=M();e(204);g();return L instanceof dd?new de(L,W):(z("Key type of a map must be atomic"),null)}function Q(L){g();var W=E.I;if([201,35].includes(W))return V(L);if(21===W)return W=U(),e(5),O(W,L);if(5===W)return W=D(),e(5),O(W,L);z("Unexpected "+df[W]+" after '=>'");return null}function ja(L){function W(Fa){return Fa instanceof
sg||Fa instanceof mg&&W(Fa.b)}switch(E.I){case 21:return U();case 5:var ha=D();L&&W(ha)&&z("Predicate pattern cannot be parenthesized","XTSE0340");return ha;case 4:return F();case 202:return K(!0);case 209:return C(!0);case 35:return ha=E.R,0===Qa&&1===Na&&(S.yg=!0,L?wh.includes(ha)||ha.includes(":")||z("Function "+ha+"() is not permitted at first position in pattern","XTSE0340"):z("Function call not at first position in pattern","XTSE0340")),ha=V(null),S.yg=!1,ha;case 213:return Z(new sg);case 205:return g(),
new sg(wa[wa.length-1]);case 206:return g(),new Lf(9,null);case 69:if("function"===E.R)return ca();case 201:case 208:case 70:case 207:return ha=3,69===E.I&&["attribute","schema-attribute"].includes(E.R)?ha=2:69===E.I&&"namespace-node"===E.R?ha=8:L&&69===E.I&&"document-node"===E.R&&(ha=12),L=ea(1),new Lf(ha,L);case 3:g();switch(E.I){case 201:case 208:case 70:case 207:case 69:return ha=ea(2),new Lf(2,ha);default:z("@ must be followed by a NodeTest")}break;case 36:try{ha=qf(E.R)}catch(Fa){z(Fa.message),
ha=3}L=of[ha];g();switch(E.I){case 201:case 208:case 70:case 207:case 69:L=ea(L);var la=null;if(2===ha){var za=ce.Nb(L,new ud(2));za===ce.bc?la="attribute axis can never yield nodes of type "+L:za===ce.Rb&&(L=new ud(2))}3===ha&&9===L.kind&&(la=L.name+" nodes can never be children");2!==L.kind||[2,12].includes(ha)||(la="axis "+nf(ha)+" can never yield attribute nodes");if(la){if(1===Na)return new tf(la);n(la);return Jf()}return new Lf(ha,L);default:z("Unexpected token "+u()+" after axis name")}break;
case 60:switch(E.R){case "map":return x();case "array":return P();case ".":return oa();case "_":z("Underscore functions not yet implemented")}break;case 43:return ia();default:z("Unexpected token "+u()+" at start of expression")}}function D(){g();if(204===E.I)return g(),Jf();var L=k();e(204);g();return L}function C(){var L=E.R?y(E.R):z("Parser tokenization error NUMBER");isNaN(L)&&z("Invalid numeric literal ("+E.R+")");g();return If([L],"AO"===L.code?Nc.oe:"AD"===L.code?Nc.Pg:Nc.Id)}function y(L){try{return 0<=
L.indexOf("e")||0<=L.indexOf("E")?Ab.XS.double.fromString(L):0<=L.indexOf(".")?Ab.XS.ub.fromString(L):Ab.XS.integer.fromString(L)}catch(W){return NaN}}function K(){var L=new Kf(E.R);g();return L}function U(){g();e(201);var L=E.R;g();var W=m(L,"");L=fa(W);if(null!=L)var ha=Fh(L);else if(W.uri===Ic.Mi&&0<Ua){L=W.local;if("code description value module line-number column-number".split(" ").includes(L))return ha=new cf(m("dynamic-error-info",Ic.zb),1),ha=new Ih(ha,[If([Ab.XS.string.fromString(L)],Nc.dc)],
Nc.aa);z("Unknown error variable "+W.$(),"XPST0008")}else S.pg?(ha={ab:W,rd:function(){return W}},T(ha),ha=Fh(ha)):z("No binding for variable "+W.$(),"XPST0008");return ha}function O(L,W){var ha=[];null!=W&&ha.push(W);W=null;g();if(204!==E.I){for(;;){var la=Y();if(null==la||la instanceof Jh)null==W&&(W=[]),W.push(ha.length);ha.push(la);if(7===E.I)g();else break}e(204)}g();if(null==W){var za=ha;L instanceof Kh?(W=xh("FUNCTION",1,"XPTY0004","array:get"),la=zh("XPTY0004",W),za=Cg(za[0],Nc.Id,la,W,S),
ha=Lh(Mh,"get",2),L=Nh(ha,[L,za])):(za=new Kh(za),ha=Lh(Oh,"apply",2),W=xh("FUNCTION",0,"XPTY0004","apply"),la=zh("XPTY0004",W),L=Cg(L,Nc.Ye,la,W,S),L=Nh(ha,[L,za]))}else L=new Ph(L,ha,W);return L}function Z(L){var W=E;Ng(W,Sg);W.I=5;g();var ha=W.I;Ng(W,Og);if(201===ha)W=W.R,Ye(W)||z("The name following '?' must be a valid NCName"),g(),L=new Qh(L,new Kf(W));else if(209===ha)W=W.R?y(W.R):z("Parser tokenization error NUMBER"),(isNaN(W)||"ADI"!==W.code)&&z("Number following '?' must be an integer"),
g(),L=aa(this,L,If([W],Nc.Id));else if([17,207].includes(ha))g(),L=new Rh(L);else if(5===ha)L=aa(this,L,D());else return z("Unexpected "+df[ha]+" after '?'"),null;return L}function aa(L,W,ha){if(Uc(W.j.fa)){L=new $g;L.ac=Qc(W.j,2);var la=m("zibble",""),za=Fh(L);L.ab=la;L.g(W);T(L);L.b(new Qh(za,ha));ta.pop();return L}return new Qh(W,ha)}function ca(){var L=E,W=new Sh;W.b=[];for(var ha=Aa(ta),la=ha.next();!la.done;la=ha.next())W.b.push(la.value);W.l=[];W.g=[];jb.push(W);g();la=[];ha=[];for(var za,
Fa=0;204!==L.I;){e(21);g();e(201);for(var Ja=m(L.R,""),Za=Aa(la),$a=Za.next();!$a.done;$a=Za.next())$a.value.equals(Ja)&&z("Duplicate parameter name $"+Ja.toString(),"XQST0039");la.push(Ja);$a=Nc.aa;g();71===L.I?(g(),$a=M()):va[Fa]&&($a=va[Fa]);Za=new Th;Za.j=$a;Za.ac=$a;Za.name=Ja;Za.ab=Ja;Ja=Fa++;Za.slot=Ja;ha.push(Za);T(Za);if(204===L.I)break;else 7===L.I?g():z("Expected ',' or ')' after function argument, found '"+df[L.I]+"'")}Ng(L,Sg);g();71===L.I&&(Ng(L,Tg),g(),za=M());e(59);Ng(L,0);g();215===
L.I?(Qg(L),g(),Fa=Jf()):(Fa=k(),e(215),Qg(L),g(),za||(za=Fa.j));za||(za=Nc.aa);L=la.length;for(la=0;la<L;la++)ta.pop();za=new Uh(W,ha,za,Fa);ta=W.b;jb.pop();return za}function oa(){var L=E,W=new Sh;W.b=[];for(var ha=Aa(ta),la=ha.next();!la.done;la=ha.next())W.b.push(la.value);W.l=[];W.g=[];jb.push(W);g();ha=[];la=Ab.XS.QName.fromParts("saxon",Ic.zb,"dot");var za=new Th,Fa=Nc.rc;za.j=Fa;za.ac=Fa;za.name=la;za.ab=la;za.slot=0;ha.push(za);la=new hh(Fh(za),k());e(215);Qg(L);g();L=new Uh(W,ha,la.j,la);
ta=W.b;jb.pop();return L}function ea(L){var W=E.R;switch(E.I){case 201:g();var ha=W;W=m(ha,1===L?S.jc():"");1===L&&""===W.uri&&S.ye?(ha=m(ha,"http://www.w3.org/1999/xhtml"),L=new he(new Yd(L,W),new Yd(L,ha))):L=new Yd(L,W);return L;case 208:return g(),G(L,W);case 70:return g(),W=E.R,e(201),g(),N(L,W);case 207:return g(),Xd(L);case 69:return na();default:throw z("Unrecognized node test"),new $e("");}}function na(){function L(){ha||z("No arguments are allowed in "+W+"()","XPST0003")}var W=E.R,ha=!1;
g();204===E.I&&(ha=!0,g());switch(W){case "item":case "switch":case "typeswitch":return z(W+"() is not allowed in a path expression"),null;case "node":return L(),gd;case "text":return L(),xd;case "comment":return L(),yd;case "namespace-node":if(ha)return Ad;if(4===Na&&201===E.I)return g(),e(204),g(),new Zd(13,"");z("No arguments are allowed in namespace-node()");return null;case "document-node":if(ha)return td;if("element"!==E.R)return z("Argument to document-node() must be an element type"),null;
var la=na();e(204);g();return new ae(ad(la));case "processing-instruction":if(ha)return zd;202===E.I?la=E.R?xb.trim(unescape(E.R)):z("Parser tokenization error STRING_LIITERAL"):201===E.I?la=E.R:z("Processing instruction name must be an NCName or a string literal");Ye(la)||z("Processing instruction name must be a valid NCName: '"+la+"' is not");/^[Xx][Mm][Ll]$/.test(la)&&z("Processing instruction name must not be 'xml'");g();e(204);g();return new Yd(7,Ab.XS.QName.fromParts("","",la));case "attribute":case "element":la=
"element"===W?1:2;var za="",Fa=!1;if(ha)return Xd(la);[207,17].includes(E.I)?za="*":201===E.I?za=E.R:70===E.I?(g(),Fa=!0,za=E.R):(z("Unexpected "+df[E.I]+" after '(' in SequenceType"),za="*");g();if(204===E.I)return g(),"*"===za?Xd(la):Fa?new Zd(la,za):new Yd(la,h(za,S.yb,1===la?S.jc:function(){return""}));if(7===E.I){g();e(201);Fa=!1;var Ja=h(E.R,S.yb,S.jc);Ja.uri===Ic.qc?1===la?["untyped","anyType"].includes(Ja.local)||(["untypedAtomic","anyAtomicType"].includes(Ja.local)?Fa=!0:z("Unsupported type annotation "+
E.R+" (Saxon-JS is not schema-aware, so all nodes are untyped)")):["untypedAtomic","anyAtomicType"].includes(Ja.local)||z("Unsupported type annotation "+E.R+" (Saxon-JS is not schema-aware, so all nodes are untyped)"):z("Unsupported type annotation "+E.R+" (Saxon-JS is not schema-aware, so only built-in types are recognized)");g();213===E.I&&g();e(204);g();if(Fa)return sd;if("*"===za)return Xd(la);za=h(za,S.yb,1===la?S.jc:function(){return""});return new Yd(la,za)}z("Expected ')' or ',' in SequenceType");
return null;case "schema-attribute":case "schema-element":return z("Type "+W+"() not allowed: Saxon-JS is not schema-aware"),null;default:return z("Unknown node kind "+W),null}}function x(){var L=[];g();var W=[],ha=!0;if(215!==E.I)for(;;){var la=p();e(75);g();var za=p(),Fa=xh("FUNCTION",0,"","map:entry"),Ja=zh("XPTY0004",Fa);la=Cg(la,Nc.Xe,Ja,Fa,S);!ha||la instanceof Gf?-1===W.indexOf(la)?W.push(la):ha=!1:ha=!1;la=new Vh(la,za);L.push(la);if(215===E.I)break;else e(7),g()}Qg(E);g();if(0===L.length)return Nh(Lh(Wh,
"_new",1),[]);if(1===L.length)return L[0];if(ha){var Za=[];L.forEach(function($a){Za.push([$a.key,$a.value])});return new Xh(Za)}L=new Bh(L);W=[];W=new Xh([[new Kf("duplicates"),new Kf("reject")],[new Kf("duplicates-error-code"),new Kf("XQDY0137")]]);return Nh(Lh(Wh,"merge",2),[L,W])}function F(){var L=[];g();if(203===E.I)return g(),new Kh(L);for(;;){var W=p();L.push(W);if(7===E.I)g();else{if(203===E.I){g();break}z("Expected ',' or ']', found "+df[E.I])}}return new Kh(L)}function P(){g();var L=Lh(Mh,
"_from-sequence",1);if(215===E.I)return Qg(E),g(),Nh(L,[Jf()]);var W=k();e(215);Qg(E);g();return Nh(L,[W])}function V(L){function W(){if(209===E.I)return C(!0);if(202===E.I)return K(E.R);if(21===E.I)return U();z("The arguments to a function call in a pattern must be literals or variable references","XTSE0340")}var ha=E.R,la=E.te,za=[];null!=L&&za.push(L);L=w(ha);ha=null;va=[];var Fa="fold-left"===L.local,Ja="fold-right"===L.local,Za="for-each"===L.local|"filter"===L.local;Ja=[Ic.Ea,Ic.hd].includes(L.uri)&&
(Fa||Ja||Za);1===Na&&L.uri===Ic.Ea&&["current-group","current-grouping-key","current-merge-group","current-merge-key"].includes(L.local)&&z(L.local+"() is not permitted in a pattern","XTSE1060");var $a=0;g();if(204!==E.I){for(;;){var pb=S.yg?W():Y();if(Ja){var fb=void 0;switch($a){case 0:switch(L.uri){case Ic.hd:fb=pb.j.T.valueType;break;case Ic.Ea:fb=Qc(pb.j,2)}va[Fa?1:0]=fb;break;case 1:if(!Za){switch(L.uri){case Ic.hd:fb=1===pb.j.fa?Nc.aa:Qc(pb.j,7);break;case Ic.Ea:fb=Nc.aa}va[Fa?0:1]=fb}}}if(null==
pb||pb instanceof Jh)null==ha&&(ha=[]),ha.push(za.length);za.push(pb);if(7===E.I)g();else break;$a++}e(204)}g();va=[];Fa=new cf(L,za.length);if(null!=ha)return la=S.qf().rg(Fa,S),null==la&&z("No function "+L.toString()+"#"+za.length+" is known for partial application","XPST0017"),new Ph(la,za,ha);ha=[];Fa=S.qf().bind(Fa,za,S,ha);return null==Fa?R(S,la,L,za,ha):Fa}function R(L,W,ha,la,za){var Fa="Cannot find a "+la.length+"-argument function named "+ha.$()+"()";za=Aa(za);for(var Ja=za.next();!Ja.done;Ja=
za.next())Fa+=". "+Ja.value;za=!1;for(Ja=0;Ja<la.length+5;Ja++)if(Ja!==la.length){var Za=new cf(ha,Ja);if(L.qf().rg(Za,L)){za=!0;break}}za?Fa+=". The namespace URI and local name are recognized, but the number of arguments is wrong":(la=ha.uri,ha=la===Ic.dd?"original"===ha.local?"Function name xsl:original is only available within an overriding function":"There are no functions defined in the XSLT namespace":la===Ic.zb?"Most Saxon extension functions are not available under Saxon-JS":la.startsWith("java:")?
"Calls to Java methods are not available under Saxon-JS":la.startsWith("clitype:")?"Calls to .NET methods are not available under Saxon-JS":null,null!=ha&&(Fa+=ha));if(L.Be())return rg("error",[rg("QName",[new Kf(""),new Kf("XTDE1425")]),new Kf(Fa)]);z(Fa,"XPST0017",W)}function w(L){var W=null;try{W=h(L,S.yb,S.yh)}catch(ha){z(ha.message,ha.code)}return W}function Y(){return 213===E.I&&[7,204].includes(E.g)?(g(),new Jh):p()}function ia(){var L=E,W=L.R,ha=L.te,la=S;g();e(209);L=L.R?y(L.R):z("Parser tokenization error NUMBER");
isNaN(L)||"ADI"!==L.code?z("Number following '#' is invalid"):(0>L.value||L.value>Number.MAX_SAFE_INTEGER)&&z("Number following '#' is out of range","FOAR0002");L=L.value;g();var za=null;try{za=h(W,la.yb,la.yh)}catch(Ja){z(Ja.message,Ja.code)}W=la.qf();var Fa=new cf(za,L);la=W.rg(Fa,la);null==la&&z("Function "+za.$()+"#"+L+" not found","XPST0017",ha);return la.Ab.Gh&&!za.sd(Ve.ni)?new Yh(la):new Zh(la)}function T(L){L.slot=ta.length;ta.push(L)}function fa(L){for(var W=ta.length-1;0<=W;W--){var ha=
ta[W];if(ha&&ha.rd().equals(L))return ha}}function xa(L,W){return h(L,S.yb,function(){return W})}function m(L,W){try{return xa(L,W)}catch(ha){z(ha.message,ha.code)}}function G(L,W){if(W.startsWith("Q{"))return new $d(L,W.substring(2,W.length-2));try{var ha=h(W+":dummy",S.yb,function(){return""});return new $d(L,ha.uri)}catch(la){return z(la.message,la.code),null}}function N(L,W){Ye(W)||z("Local name ["+W+"] contains invalid characters");return new Zd(L,W)}function H(L,W,ha,la){this.global=void 0===
la?!1:la;this.ab=this.name=L;this.ac=W;this.slot=ha}var E,da,S={},ta=[],wa=[],va=[],Na=0,Ua=0,Qa=0,jb=[],ub={rg:function(L,W){W=W.getContext();return(W=uh.getFnSignature(L.name,L.arity,W))?new Ah(L,W):null},bind:function(L,W,ha){var la=ha.getContext(),za=uh.getFnSignature(L.name,L.arity,la);if(za){if(L.name.uri===Ic.Ea)switch(L.name.local){case "false":return If([Ab.XS.boolean.je],Nc.gb);case "true":return If([Ab.XS.boolean.fd],Nc.gb);case "normalize-unicode":1===W.length&&W.push(new Kf("NFC"));break;
case "copy-of":return new $h(W[0]?W[0]:new sg(wa[wa.length-1]));case "current-group":return new ai;case "current-grouping-key":return new bi;default:za.defaultToDot&&0===L.arity&&za.arity.includes(0)&&(W.push(new sg(wa[wa.length-1])),L.arity++)}else if(za.isIdempotent&&1===W.length)return W[0];la=[];var Fa,Ja=za.alphaTree;Ja&&"F"===Ja.p&&!Ja.r&&(Ja.r={p:"",o:"*"});Ja&&"F"===Ja.p&&Ja.a&&Ja.r||ib.la("Bad function signature for "+L.name.local);za.variadic&&(Fa=Ja.a[0]);for(var Za=za.collation||-1,$a=
0;$a<W.length;$a++){var pb=Fa?Fa:Ja.a[$a],fb=xh("FUNCTION",$a,"",L.name.toString()),vb=zh("XPTY0004",fb);$a===Za?(pb=Cg(W[$a],"?"===pb.o?Nc.Ng:Nc.dc,vb,fb,ha),pb=rg("resolve-uri",[pb,rg("static-base-uri",[])]),pb.j=Nc.Lg,la.push(pb)):la.push(Cg(W[$a],Zc(pb),vb,fb,ha))}Za>W.length-1&&la.push(new Kf(ha.Tb));W=Zc(Ja.r);if(za.isUserFunction)return new ci(L,la,W,za.componentBinding);switch(L.name.uri){case Ic.Ea:case Ic.qc:switch(L.name.local){case "current":wa[0]&&(W=wa[0]);break;case "accumulator-before":case "accumulator-after":if(la[0]instanceof
Kf){za=h(la[0].value[0].value,ha.yb,function(){return""}).$();ha=ha.Jb[za];if(!ha)throw new J("Unknown accumulator "+za,"XTDE3340");W=Wc(ha);la[0]=new Kf(za)}}return new Of(L,la,W);case Ic.map:return new di(L,la,W);case Ic.hd:return new ei(L,la,W);case Ic.Ih:return new fi(L,la,W);case Ic.zb:return new Ih(L,la,W);case Ic.Vd:return new gi(L,la,W);case Ic.Hh:return new hi(L,la,W);default:return za.Gh?new ci(L,la,W,za.bl):new Ih(L,la,W)}}else{if(za=uh.getFnSignature(L.name,null,la))if(za&&!za.alphaTree&&
(za.alphaTree=Yb(za.sig)),la=W.length,Fa=za.alphaTree.a.length,la!==Fa&&(za.variadic?W.length<Fa:!(za.arity||[]).includes(la)))throw new J("Wrong argument arity "+W.length+" for function "+L.name.local+"()","XPST0017");if(ha.Sb)return rg("error",[rg("QName",[new Kf(""),new Kf("XTDE1425")]),new Kf("Unknown function "+L.toString())]);throw new J("Unknown function "+L.$()+"()","XPST0017");}}};S={jc:function(){return""},Tb:"http://www.w3.org/2005/xpath-functions/collation/codepoint",ye:!1,hb:{},Jb:{},
eb:!1,Vc:!1,pg:!1,Sb:!1,Uj:function(){S.jc=function(){return""};S.Tb="http://www.w3.org/2005/xpath-functions/collation/codepoint";S.hb={};S.Jb={};S.eb=!1;S.Vc=!1;S.pg=!1;S.Sb=!1},getContext:function(){return{eb:S.eb,nl:1===Na,hb:S.hb}},cj:function(){return S.Tb},yh:function(){return Ic.Ea},qf:function(){return ub},il:function(){return null},ml:function(){return!1},Be:function(){return S.Sb},ul:function(){return null}};var ob={};H.prototype.rd=function(){return this.name};return{process:function(L,
W,ha,la,za,Fa,Ja){za=void 0===za?!1:za;Fa=void 0===Fa?!1:Fa;Ja=void 0===Ja?!1:Ja;a();da=L;Na=W?1:0;L=[];ob={};S.Uj();S.yb=b;W&&(S.eb=!0);Ja&&(S.pg=!0);if(la){Ua=la.uf?1:0;la.Vc&&(S.Vc=!0);for(var Za in la.namespaces)ob[Za]=la.namespaces[Za];for(var $a in la.Tc){Za=h($a,S.yb,function(){return""});var pb=la.Tc[$a],fb=Wc(pb.getAttribute("sType"));L.push(new H(Za,fb,pb,!0))}for(var vb in la.$b)$a=h(vb,S.yb,function(){return""}),L.push(new H($a,la.Dd[vb],la.$b[vb]));ta=L;S.Jb=la.Jb;la.ye&&(S.ye=la.ye);
la.Tb&&(S.Tb=la.Tb);la.hb&&(S.hb=la.hb);la.eb&&(S.eb=la.eb);la.Sb&&(S.Sb=la.Sb);la.Ge&&(wa=[Qc(la.Ge,2)]);la.xpathDefaultNamespace&&(S.jc=function(){return la.xpathDefaultNamespace});S.Qa=la.Qa?la.Qa:null}vb="";for(var yc in ob)L=ob[yc],L=L===Ic.Jc[yc]?"~":L,vb+=yc+"="+L+" ";Pg(E,da);try{var rb=k(Fa)}catch(dc){throw dc instanceof J&&la&&la.$j&&(dc.code=la.$j,dc.message="Invalid XPath expression supplied to xsl:evaluate {"+da+"}: "+dc.message),dc;}0!==E.I&&z("Found "+u()+" beyond end of expression");
W?(rb=rb.Ha(),rb instanceof Df&&ha&&(rb=new Ff(Ef(rb)))):la&&la.Le&&(W=xh("EVALUATE_RESULT",0,"",null),ha=zh("XPTY0004",W),rb=Cg(rb,la.Le,ha,W));W=za?new ii:new ji;rb.G(W);W=W.current;za?(W=W.documentElement,rb.j&&W.setAttribute("sType",rb.j.ha()),""!==vb&&W.setAttribute("ns",vb),la&&la.Qa&&W.setAttribute("baseUri",la.Qa)):(W=W.C[0],""!==vb&&(W.zl=vb),la&&la.Qa&&(W.rb=la.Qa),Ja&&0<ta.length&&(W.Yi=ta.map(function(dc){return dc.ab.$()})));return W},parseSequenceType:function(L,W,ha){a();S.yb=W;S.jc=
ha;Na=0;ob={};Pg(E,L);L=M();0!==E.I&&z("Unexpected "+u()+" after SequenceType");return L},getTokenizer:function(){return E}}}();function ji(){this.b=[];this.current={C:[]}}ji.prototype={start:function(a,b){b=void 0===b?{}:b;a={N:a,C:[]};for(var h in b)a[h]=b[h];this.current.C.push(a);this.b.push(this.current);this.current=a},Bd:function(a,b){this.current[a]=b},end:function(){this.current=this.b.pop()}};
function ii(){this.g=te.find("SaxonJS").getPlatform().createDocument();this.b=[];this.current=this.g}ii.prototype={start:function(a,b){b=void 0===b?{}:b;a=this.g.createElement(a);for(var h in b)a.setAttribute(h,b[h]);this.current.appendChild(a);this.b.push(this.current);this.current=a},Bd:function(a,b){this.current.setAttribute(a,b)},end:function(){this.current=this.b.pop()}};
// Input 68
function rg(a,b){a=new Ab.XS.QName.fromParts("fn",Ic.Ea,a);b=Array.isArray(b)?b:[b];a=new cf(a,b.length);return new Of(a,b,Nc.aa)}function Nf(a,b,h){var u=void 0===u?null:u;u=zh(u?u:h.errorCode?h.errorCode:"XPTY0004",h.save());return Cg(a,b,u,h.save())}function Vf(a){return Uc(a.j.fa)?new ug(a):a}function Bh(a){this.j=Nc.aa;this.value=a;0===a.length?this.j=Nc.Te:this.j=new Oc(ce.Xd(a.map(function(h){return h.j.T})),7);var b=!0;a.forEach(function(h){b=b&&h instanceof Gf});b&&(this.b=!0)}Ka(Bh,hf);
Bh.prototype.G=function(a){hf.prototype.start.call(this,a);this.b&&a.Bd("literal",!0);for(var b=Aa(this.value),h=b.next();!h.done;h=b.next())h.value.G(a);a.end()};Bh.prototype.K="sequence";function Kh(a){this.j=Nc.aa;this.value=a;if(0===a.length)this.j=ad(qd);else{var b=ce.Xd(a.map(function(u){return u.j.T})),h=0;a.forEach(function(u){return h|=u.j.fa});this.j=ad(new ee(new Oc(b,h)))}}Ka(Kh,hf);
Kh.prototype.G=function(a){hf.prototype.start.call(this,a);for(var b=Aa(this.value),h=b.next();!h.done;h=b.next())h.value.G(a);a.end()};Kh.prototype.K="arrayBlock";function Ch(a,b){this.j=Nc.aa;this.Xa=a;this.K=df[a];this.ab=b;this.action=this.Pb=void 0;this.slot=0;this.ac=this.value=null;this.j=Nc.gb}Ka(Ch,hf);Ch.prototype.rd=function(){return this.ab};Ch.prototype.G=function(a){hf.prototype.start.call(this,a,{"var":this.ab.$(),slot:this.slot});this.value.G(a);this.action.G(a);a.end()};
function Gh(a,b,h,u){u=void 0===u?!1:u;jf.call(this,a,b,h);this.K=1===b?"union":df[b];u=u?"XTSE0340":"XPTY0004";a=Nf(a,Nc.ke,this.Fa(0,u));h=Nf(h,Nc.ke,this.Fa(1,u));a=Bg(a);h=Bg(h);this.ga=a;this.ma=h;ib.assert(a.j);ib.assert(h.j);switch(b){case 24:this.j=a.j;break;case 23:b=a.j.fa&h.j.fa|3;this.j=new Oc(ce.hj([a,h].map(function(z){return z.j.T})),b);break;case 1:this.j=new Oc(ce.Xd([a,h].map(function(z){return z.j.T})),ne(a.j.fa,h.j.fa))}}Ka(Gh,jf);Gh.prototype.Yb=function(){return!0};
Gh.prototype.pd=function(){return this.ga.pd()};Gh.prototype.Ha=function(){return 1===this.Xa?new Df(this.ga.Ha(),this.ma.Ha(),this.j):new Cf(this.ga.Ha(),this.Xa,this.ma.Ha(),this.j)};function sg(a){this.j=a=void 0===a?Nc.rc:a}Ka(sg,hf);sg.prototype.G=function(a){this.fb(a)};sg.prototype.Ha=function(){return new sf};sg.prototype.K="dot";function ki(a){lf.call(this,a);this.j=a.j}Ka(ki,lf);function Hh(a){return""===a.j.V().p?new ki(a):a}ki.prototype.K="homCheck";
function ui(){this.b=null;this.j=Nc.aa}Ka(ui,hf);function Fh(a){return a.global?new vi(a):new wi(a)}ui.prototype.K="varRef";ui.prototype.lc=!0;function wi(a){ui.call(this);this.b=a;this.j=a.ac||Nc.aa}Ka(wi,ui);wi.prototype.rd=function(){return this.b.ab};wi.prototype.G=function(a){this.fb(a,{name:this.b.ab.$(),slot:this.b.slot})};wi.prototype.K="varRef";function vi(a){ui.call(this);this.b=a;this.j=a.ac}Ka(vi,ui);vi.prototype.rd=function(){return this.b.ab};vi.prototype.Ha=function(){return new xf(this)};
vi.prototype.G=function(a){this.fb(a,{name:this.b.ab.$(),bSlot:"?"})};vi.prototype.K="gVarRef";function Qh(a,b){jf.call(this,a,213,b);this.ga=a=Nf(a,Nc.Rf,this.Fa(0));a.j&&a.j.T&&a.j.T.resultType&&(this.j=a.j.T.resultType)}Ka(Qh,jf);Qh.prototype.K="lookup";function Rh(a){this.j=Nc.aa;this.ga=a=Nf(a,Nc.Rf,this.Fa(0))}Ka(Rh,hf);Rh.prototype.G=function(a){hf.prototype.start.call(this,a);this.ga.G(a);a.end()};Rh.prototype.K="lookupAll";function ai(){this.j=Nc.aa}Ka(ai,hf);ai.prototype.K="currentGroup";
function bi(){this.j=Nc.Hd}Ka(bi,hf);bi.prototype.K="currentGroupingKey";function wg(a){lf.call(this,a);this.j=a.j}Ka(wg,lf);wg.prototype.Yb=function(){return!0};wg.prototype.Ha=function(){return this.b.Ha()};wg.prototype.K="docOrder";function $h(a){lf.call(this,a);this.j=a.j}Ka($h,lf);$h.prototype.G=function(a){lf.prototype.start.call(this,a,{flags:this.flags});this.b.G(a);a.end()};$h.prototype.K="copyOf";$h.prototype.flags="cm";
function pg(a,b){this.j=Nc.aa;ib.assert(b instanceof Oc);this.ga=a;this.type=b;this.j=Nc.gb}Ka(pg,hf);pg.prototype.G=function(a){hf.prototype.start.call(this,a,{of:this.type.ha()});this.ga.G(a);a.end()};pg.prototype.K="instance";function Lg(a,b,h){h=void 0===h?null:h;h||(h=new gf(2,"treat as",0),h.errorCode="XPDY0050",h=h.save());return new Jg(Gg(a,b.fa,h),b.T,h)}function Wf(a,b,h){lf.call(this,a);this.j=new Oc(a.j.T,b);this.g=b;this.l=h}Ka(Wf,lf);
Wf.prototype.G=function(a){lf.prototype.start.call(this,a,{card:2===this.g?"1":Pc(this.g),diag:this.l});this.b.G(a);a.end()};Wf.prototype.K="check";function Gg(a,b,h){return 7===b?a:a instanceof Gf&&(b|2)===b?a:new Wf(a,b,h)}function Jg(a,b,h){lf.call(this,a);this.j=a.j;this.l=b;this.g=h}Ka(Jg,lf);Jg.prototype.G=function(a){lf.prototype.start.call(this,a,{as:this.l.ha(),diag:this.g});this.b.G(a);a.end()};Jg.prototype.K="treat";
// Input 69
function xi(a){lf.call(this,a);this.K="cast";this.kind=2;this.Ad={}}Ka(xi,lf);xi.prototype.G=function(a){lf.prototype.start.call(this,a,this.Ad);this.b.G(a);a.end()};
function Eh(a,b,h,u,z){var n=Nf(a,Nc.cc,a.Fa(0,"XPTY0004"));n=new xi(n);if(/[lu]/.test(b))n.Ad={flags:b+(z?"e":""),as:h.$()};else{ib.assert("a"===b);try{if(a instanceof Gf&&1===a.value.length&&Ab.qa(a.value[0])&&"AQ"!==u.V().p){var g=u.cd.O(a.value[0]);return new Gf([g],new Oc(u,2))}}catch(e){}n.Ad={flags:z?"ae":"a",as:u.ha()}}return n}function yi(a){lf.call(this,a);this.j=Nc.gb;this.K="castable";this.kind=2;this.Ad={}}Ka(yi,lf);
yi.prototype.G=function(a){lf.prototype.start.call(this,a,this.Ad);this.b.G(a);a.end()};function Dh(a,b,h,u,z){var n=new yi(a);n=Nf(n,Nc.cc,n.Fa(0,"XPTY0004"));if(/[lu]/.test(b))n.Ad={flags:b+(z?"e":""),as:h.$()};else{ib.assert("a"===b);if(a instanceof Gf&&1===a.value.length&&Ab.qa(a.value[0])&&"AQ"!==u.V().p)try{return u.cd.O(a.value[0]),new Gf([Ab.XS.boolean.fd],Nc.gb)}catch(g){return new Gf([Ab.XS.boolean.je],Nc.gb)}n.Ad={flags:z?"ae":"a",as:u.ha()}}return n}
function Fg(a,b){lf.call(this,a);this.g=b;this.j=Ud(a.j.T)?Nc.Tg:Nc.Hd}Ka(Fg,lf);Fg.prototype.G=function(a){lf.prototype.start.call(this,a,{diag:this.g});this.b.G(a);a.end()};Fg.prototype.K="data";function Eg(a,b,h){Fg.call(this,a,b);this.l=h;Ud(a.j.T)?this.j=h?Nc.Wf:Nc.Sg:this.j=h?Nc.cc:Nc.Xe}Ka(Eg,Fg);Eg.prototype.G=function(a){Fg.prototype.start.call(this,a,{diag:this.g});this.l&&a.Bd("card","?");this.b.G(a);a.end()};Eg.prototype.K="atomSing";
function Dg(a){this.j=Nc.aa;this.name=a.T.name.$();this.j=Nc.Wf}Ka(Dg,hf);Dg.prototype.G=function(a){hf.prototype.fb.call(this,a,{name:this.name})};Dg.prototype.K="attVal";function Tf(a,b,h){lf.call(this,a);this.code=b;this.g=h;this.j=Nc.Hd}Ka(Tf,lf);Tf.prototype.G=function(a){lf.prototype.start.call(this,a,{to:this.code,diag:this.g});this.b.G(a);a.end()};Tf.prototype.K="cvUntyped";function Ig(a,b){lf.call(this,a);this.type=b;this.flags="";this.j=new Oc(b.T,a.j.fa)}Ka(Ig,lf);
Ig.prototype.G=function(a){lf.prototype.start.call(this,a,{to:this.type.ha().replace(/^[01?*+]/,""),flags:this.flags});this.b.G(a);a.end()};Ig.prototype.K="convert";function Kg(a,b,h){lf.call(this,a);this.type=b;this.resultType=a.resultType;this.g=h;this.j=b}Ka(Kg,lf);Kg.prototype.G=function(a){lf.prototype.start.call(this,a,{to:this.type.ha().replace(/^[01?*+]/,""),diag:this.g});this.b.G(a);a.end()};Kg.prototype.K="fnCoercer";
// Input 70
function Of(a,b,h){this.j=Nc.aa;var u=a.name.local;if(a.name.uri===Ic.qc){if("NOTATION"===u)throw new J("No constructor function exists for xs:NOTATION","XPST0017");var z=b[0].j;if(2===z.fa&&z.T.qa()&&z.T.cd&&u===z.T.cd.name)a=b[0];else if(["NMTOKENS","ENTITIES","IDREFS"].includes(u))a=Eh(b[0],"l",a.name,null,!0);else{var n;"numeric"===u?n=Eh(b[0],"u",a.name,null,!0):n=Eh(b[0],"a",null,h.T,!0);a=n}return a}this.name=a;this.b=b;this.j=h;switch(u){case "exactly-one":this.j=Qc(b[0].j,2);break;case "head":this.j=
Qc(b[0].j,Tc(b[0].j.fa)?3:2);break;case "tail":case "subsequence":this.j=Qc(b[0].j,7);break;case "ceiling":case "floor":case "reverse":case "snapshot":this.j=b[0].j;break;case "last":case "position":this.Ya=!0;break;case "string-length":case "normalize-space":0===b.length&&this.b.push(rg("string",new sg));break;case "fold-left":case "fold-right":this.j=b[2].resultType;break;case "for-each":this.j=Qc(b[1].j.T.rf(),7);break;case "filter":this.j=Qc(b[0].j,7);break;case "current":this.lc=!0;break;case "boolean":if(b[0].j.equals(Nc.gb))return b[0]}}
Ka(Of,hf);Of.prototype.Ha=function(){return new xf(this)};Of.prototype.Yb=function(){if(this.name.uri===Ic.Ea)return"doc document element-with-id id innermost key outermost".split(" ").includes(this.name.local)?!0:"filter one-or-more subsequence remove tail trace unordered zero-or-more".split(" ").includes(this.name.local)?this.b[0].Yb():!1};Of.prototype.G=function(a){hf.prototype.start.call(this,a,{name:this.name.name.local});for(var b=Aa(this.b),h=b.next();!h.done;h=b.next())h.value.G(a);a.end()};
Of.prototype.K="fn";function zi(a,b,h){this.j=Nc.aa;this.name=a;this.b=b;this.j=h}Ka(zi,hf);zi.prototype.G=function(a){hf.prototype.start.call(this,a,{name:this.name.$()});for(var b=Aa(this.b),h=b.next();!h.done;h=b.next())h.value.G(a);a.end()};zi.prototype.K="ifCall";function di(a,b,h){this.j=Nc.aa;this.name=a;this.b=b;this.j=h;if(a="merge"===a.name.local)a=b[0],a=!(a instanceof Gf&&0===a.value.length);a&&(this.j=Qc(b[0].j,2))}Ka(di,hf);
di.prototype.G=function(a){hf.prototype.start.call(this,a,{name:this.name.$()});for(var b=Aa(this.b),h=b.next();!h.done;h=b.next())h.value.G(a);a.end()};di.prototype.K="ifCall";function ei(a,b,h){this.j=Nc.aa;this.name=a;this.b=b;this.j=h}Ka(ei,hf);ei.prototype.G=function(a){hf.prototype.start.call(this,a,{name:this.name.$()});for(var b=Aa(this.b),h=b.next();!h.done;h=b.next())h.value.G(a);a.end()};ei.prototype.K="ifCall";function fi(a,b,h){zi.call(this,a,b,h)}Ka(fi,zi);
function Ih(a,b,h){this.j=Nc.aa;this.name=a;this.b=b;this.j=h}Ka(Ih,hf);Ih.prototype.G=function(a){hf.prototype.start.call(this,a,{name:this.name.$()});for(var b=Aa(this.b),h=b.next();!h.done;h=b.next())h.value.G(a);a.end()};Ih.prototype.K="ifCall";function gi(a,b,h){this.j=Nc.aa;this.name=a;this.b=b;this.j=h}Ka(gi,hf);gi.prototype.G=function(a){hf.prototype.start.call(this,a,{name:this.name.$()});for(var b=Aa(this.b),h=b.next();!h.done;h=b.next())h.value.G(a);a.end()};gi.prototype.K="ifCall";
function hi(a,b,h){gi.call(this,a,b,h);var u=new cf(Ab.XS.QName.fromParts("ixsl",Ic.Vd,"call"),3),z=new cf(Ab.XS.QName.fromParts("ixsl",Ic.Vd,"window"),0);this.name=u;this.b=[new gi(z,[],Nc.rc),new Kf(a.name.local),new Kh(b)];this.j=h}Ka(hi,gi);function ci(a,b,h,u){this.j=Nc.aa;this.name=a;this.b=b;this.j=h;this.g=u}Ka(ci,hf);ci.prototype.G=function(a){hf.prototype.start.call(this,a,{name:this.name.$(),coId:this.g});for(var b=Aa(this.b),h=b.next();!h.done;h=b.next())h.value.G(a);a.end()};
ci.prototype.K="ufCall";function Zh(a){this.j=Nc.aa;this.name=a.name.name;this.Ab=a.Ab;this.arity=a.name.arity;this.j=a.j;this.resultType=a.resultType}Ka(Zh,hf);Zh.prototype.G=function(a){this.fb(a,{name:this.name.$(),arity:this.arity,resultType:this.resultType.ha()})};Zh.prototype.K="fnRef";function Yh(a){Zh.call(this,a);this.b=0}Ka(Yh,Zh);Yh.prototype.G=function(a){this.fb(a,{name:this.name.$(),arity:this.arity,resultType:this.resultType.ha(),bSlot:this.b})};Yh.prototype.K="ufRef";
function Jh(){this.j=Nc.aa}Ka(Jh,hf);Jh.prototype.G=function(a){this.fb(a,{asCode:"*"})};Jh.prototype.K="null";function Sh(){this.j=Nc.aa}Ka(Sh,hf);Sh.prototype.b={};Sh.prototype.l={};Sh.prototype.g={};function Th(){this.j=Nc.aa;this.slot=this.ab=this.name=this.ac=null}Ka(Th,hf);Th.prototype.rd=function(){return this.name};Th.prototype.toString=function(){return"$"+this.name+" as "+this.j.toString()};Th.prototype.G=function(a){this.fb(a,{name:this.name,asCode:this.j.ha(),slot:this.slot})};
Th.prototype.K="arg";function Uh(a,b,h,u){this.j=Nc.aa;this.params=b;this.resultType=h;var z=[];b.forEach(function(n){z.push(n.j)});u=Nf(u,h,this.Fa("body"));this.j=ad(new Pd(z,h));this.arity=b.length;this.body=u;u.nc="body"}Ka(Uh,hf);ba=Uh.prototype;ba.G=function(a){hf.prototype.start.call(this,a,{arity:this.arity,name:this.name,as:this.resultType.ha()});for(var b=Aa(this.params),h=b.next();!h.done;h=b.next())h.value.G(a);this.body.G(a);a.end()};ba.K="inlineFn";ba.kind=5;
ba.Fa=function(a,b){b=void 0===b?"XPTY0004":b;return new gf(this.kind,"("+this.params.map(function(h){return h.toString()}).join(",")+")"+(this.resultType?" as "+this.resultType.toString():""),a,b)};ba.name="inline";ba.mg=!0;
function Ph(a,b,h){this.j=Nc.aa;var u=this;if(a.j&&a.j.T instanceof Pd&&a.j.V().a.length!==b.length)throw new J("Partially-applied function requires "+a.arity+" arguments, but "+(b.length<a.arity?"only ":"")+b.length+" were supplied","XPTY0004");this.Ea=a;if(a instanceof Ah){var z=a.resultType;a.Ab.Gh?this.g=new Yh({name:{name:a.name,arity:a.arity},Ab:a.Ab,j:a.j,resultType:z}):this.g=new Zh({name:{name:a.name,arity:a.arity},Ab:a.Ab,j:a.j,resultType:z})}else this.g=a;this.b=[];var n=0,g;if(a.Ab){var e=
a.Ab.alphaTree,k=a.Ab.variadic;b.forEach(function(p){var v=e.a[n];v=g?g:Zc(v);p instanceof Jh?p.j=v:p=Nf(p,v,u.Fa(n));u.b.push(p);k&&(g=v);n++})}else b.forEach(function(p){u.b.push(p)});a.j.T instanceof Pd&&(this.j=ad(ge(a.j.T,h)))}Ka(Ph,hf);Ph.prototype.G=function(a){hf.prototype.start.call(this,a);this.g.G(a);this.b.forEach(function(b){b.G(a)});a.end()};Ph.prototype.K="partialApply";Ph.prototype.kind=5;function Vh(a,b){this.j=Nc.aa;this.key=a;this.value=b;this.j=Wc("1FM")}Ka(Vh,hf);
Vh.prototype.toString=function(){return"map{"+this.key.toString+" : "+this.value.toString()+"}"};Vh.prototype.G=function(a){hf.prototype.start.call(this,a);this.key.G(a);this.value.G(a);a.end()};Vh.prototype.K="map";
function Xh(a){this.j=Nc.aa;this.entries=a;var b=ce.Xd(a.map(function(z){return z[0].j.T})),h=ce.Xd(a.map(function(z){return z[1].j.T}));a=a.map(function(z){return z[1].j.fa});var u=2;a.some(function(z){return Tc(z)})&&(u+=1);a.some(function(z){return Uc(z)})&&(u+=4);this.j=ad(new de(b,new Oc(h,u)))}Ka(Xh,hf);Xh.prototype.toString=function(){var a="map{";this.entries.forEach(function(b){a+=b[0].toString+" : "+b[1].toString()});return a+"}"};
Xh.prototype.G=function(a){hf.prototype.start.call(this,a);this.entries.forEach(function(b){b[0].G(a);b[1].G(a)});a.end()};Xh.prototype.K="map";
// Input 71
function Ai(a,b,h,u){this.j=Nc.aa;this.name=Ab.XS.QName.fromParts(oh[h],h,a);this.arity=b;this.uri=h;this.j=Nc.Ye;this.resultType=u}Ka(Ai,hf);
function Nh(a,b){var h=new cf(a.name,a.arity),u=Nc.aa;switch(a.uri){case Ic.map:u=Nc.Xf;switch(a.name.local){case "get":u=b[0].j.valueType;break;case "merge":u=Nc.Xf;break;case "entry":u=ad(new de(b[0].j,b[1].j))}return new di(h,b,u);case Ic.hd:switch(a.name.local){case "_from-sequence":u=ad(new ee(b[0].j))}return new ei(h,b,u);case Ic.zb:switch(a.name.local){case "apply":a=b[0].j,a=a.T?a.T:a,u=a.resultType?a.resultType:Nc.aa}return new Ih(h,b,u)}}Ai.prototype.K="function";function Bi(){}
Bi.prototype.uri=Ic.Ea;function Lh(a,b,h){return new Ai(b,h,a.uri,Nc.aa)}function Ci(){}Ka(Ci,Bi);Ci.prototype.uri=Ic.map;var Wh=new Ci;function Di(){}Ka(Di,Bi);Di.prototype.uri=Ic.hd;var Mh=new Di;function Ei(){}Ka(Ei,Bi);Ei.prototype.uri=Ic.zb;function Fi(){}Ka(Fi,Ei);var Oh=new Fi;
// Input 72
// Input 73
// Input 74
// Input 75
// Input 76
function Lc(a,b,h){h=void 0===h?null:h;this.prefix=null===a?"":a;this.uri=null===b?"":b;h&&(this.parentNode=h)}Lc.prototype={prefix:null,uri:null,parentNode:null,nodeType:13};function Jc(a,b,h){this.Hc=a;this.value=b;this.parent=h;this.name=this.Hc.toString();this.namespaceURI=this.Hc.uri;this.localName=this.Hc.local;this.prefix=this.Hc.prefix}Jc.prototype={Hc:null,value:null,parent:null,nodeType:2};
// Input 77
var Gi=function(){function a(e,k,p,v,B){switch(e){case Ic.Ea+"/math":return z(k,p,v,B);case Ic.Ea+"/map":return u(k,p,v,B);case Ic.Ea+"/array":return h(k,p,v,B);case Ic.Vd:return b(k,p,v,B);default:var q=(g[e]||{})[k];if(!q)throw new J("Unknown function "+(g[e]?"local name "+k:"namespace "+e),"SXJS0003",B);return q(p,v,B)}}function b(e,k,p,v){function B(c,l){for(var t=0;t<l.length;t++){try{c=c[l[t]]}catch(r){if(null!==r.code)throw r;throw new J("Failed to get property "+l[t],"SXJS0007",v);}if("undefined"===
typeof c)return}return c}function q(c,l,t){try{var r=c.apply(t,l)}catch(A){if(null!==A.code)throw A;throw new J("Failed to call method "+c,"SXJS0007",v);}return null===r||"undefined"===typeof r?wc:sc(Nd.Ta(r))}function f(c){return c instanceof Nd.Sf?c.value:c}function I(c,l){ib.Sa("Warning "+c+": "+l,1)}var d={apply:function(c){var l=f(c[0].next());if("function"!==typeof l)throw new J("ixsl:apply: first argument is not a function","SXJS0007",v);c=Nd.tb(c[1].next());return q(l,c,null)},call:function(c){for(var l=
f(c[0].next()),t=c[1].next().toString(),r=t.split("."),A=l,M=0;l&&M<r.length;M++)try{A=l,l=l[r[M]]}catch(X){if(null!==X.code)throw X;throw new J("Failed to get property "+r[M],"SXJS0007",v);}if("undefined"===typeof l)throw new J("ixsl:call: object method '"+t+"' not found","SXJS0007",v);if("function"!==typeof l)throw new J("ixsl:call: property '"+t+"' is not a function","SXJS0007",v);c=Nd.tb(c[2].next());return q(l,c,A)},contains:function(c){var l=f(c[0].next()),t=!0;null===l&&(t=!1);c=c[1].next().toString().split(".");
"undefined"==typeof B(l,c)&&(t=!1);return sc(Nd.Ta(t))},eval:function(c){c=c[0].next().toString();c=new Function("return "+c);return sc(Nd.Ta(c()))},event:function(c,l){return null===l.se?wc:xc(Nd.Ta(l.se)[0])},get:function(c){var l=f(c[0].next());if(null===l)return wc;c=c[1].next().toString();l=B(l,c.split("."));return"undefined"==typeof l?(I("ixsl:get","object property '"+c+"' not found"),wc):sc(Nd.Ta(l))},location:function(){return xc(Nd.Ta(window.location.toString())[0])},page:function(){return xc(Nd.Ta(p.masterDocument)[0])},
"query-params":function(){function c(ka){return n.string.fromString(decodeURIComponent(ka.replace(t," ")))}for(var l,t=/\+/g,r=/([^&=]+)=?([^&]*)/g,A=window.location.search.substring(1),M=new Mc;null!==(l=r.exec(A));){var X=M.get(c(l[1]));X.push(c(l[2]));M.inSituPut(c(l[1]),X)}return xc(M)},source:function(c,l){return null===l.fixed.Rc?wc:xc(Nd.Ta(l.fixed.Rc)[0])},style:function(c){c=c[0].next();c=Kc.Ch(c)?window.getComputedStyle(c):{};for(var l=new Mc,t=0;t<c.length;t++){var r=c.item(t),A=c.getPropertyValue(r);
"undefined"===typeof A||null===A?l.inSituPut(n.string.fromString(r),[]):l.inSituPut(n.string.fromString(r),[n.string.fromString(A.toString())])}return xc(l)},window:function(){return xc(Nd.Ta(window)[0])},"remove-attribute":function(c,l,t){var r=c[0].next().toString();c=c[1].next();if(1!==c.nodeType||c.ownerDocument!==l.masterDocument)throw new J("Context item for ixsl:remove-attribute must be an element in the master document","SXJS0007",t);l=Nd.Dg(t,r,!1);""!==l.uri?c.removeAttributeNS(l.uri,l.local):
c.removeAttribute(l.local);return wc},"remove-property":function(c){var l=c[0].next().toString(),t=f(c[1].next()),r=l.split(".");c=r.pop();t=B(t,r);if("undefined"==typeof t||"undefined"==typeof t[c])return I("ixsl:remove-property","'"+l+"' not found for supplied object"),wc;r=t.hasOwnProperty(c);delete t[c]&&r||I("ixsl:remove-property","'"+l+"' could not be removed for supplied object");return wc},"schedule-action":function(){throw new J("ixsl:schedule-action - shouldn't be here","SXJS0007",v);},
"set-attribute":function(c,l,t){var r=c[0].next().toString(),A=c[1].next().toString();c[2]||Qd(l,"SXJS0007","ixsl:set-attribute",t);c=c[2]?c[2].next():Od(l);l=Kc.Qc(l.masterDocument);if(1!==c.nodeType||Kc.Qc(c)!==l)throw new J("Context item for ixsl:set-attribute must be an element in the master document","SXJS0007",t);var M=Nd.Dg(t,r,!1);""!==M.uri?(""===M.prefix&&Kc.Uc(c).forEach(function(X){X.uri===M.uri&&(M.prefix=X.prefix)}),c.setAttributeNS(M.uri,M.toString(),A)):c.setAttribute(M.local,A);return wc},
"set-property":function(c){var l=c[0].next().toString(),t=Nd.tb(c[1].next()),r=f(c[2].next()),A=l.split(".");c=A.pop();r=B(r,A);if("undefined"==typeof r)return I("ixsl:set-property","'"+l+"' not found for supplied object"),wc;r[c]=t;return wc},"set-style":function(c){var l=c[0].next().toString(),t=Nd.tb(c[1].next());c=c[2].next();if(!(c instanceof Element&&"style"in c))return I("ixsl:set-style","style '"+l+"' cannot be set for supplied object"),wc;c.style[l]=t;return wc}};if(!d[e])throw new J("Unknown ixsl function: "+
e,"SXJS0003",v);return d[e](k,p,v)}function h(e,k,p,v){function B(c,l){if(1>c||c>l)throw new J("Array index ("+c+") out of bounds (1 to "+l+")","FOAY0001",v);}function q(c){return c[0].next().value}function f(c){return xc(new Kd(c))}function I(c,l,t,r){var A=l.fixed.ka,M=c[1].expand(),X=c[2].expand()[0],ka=Object.keys(l.pa).length;if(X instanceof Ld.XdmFunction)c=q(c),r||(c=c.reverse()),c.forEach(function(Q){M=X.evaluate(r?[M,Q]:[Q,M],l,t).expand()});else{var qa;"function"!==X.N&&(qa=X);c[0].forEachItem(function(Q){var ja=
l.ca(!0);qa&&(ja.ua=qa);Rd(ja);ja.sa="function";ja.ib={};ja.cb=null;ja.focus=null;r?(ja.pa[ka]=M,ja.pa[ka+1]=[Q]):(ja.pa[ka]=[Q],ja.pa[ka+1]=M);M=A.evaluate(void 0,ja).expand()})}return sc(M)}var d={append:function(c){return f(q(c).concat([c[1].expand()]))},filter:function(c,l,t){var r=c[1].expand()[0];Object.keys(l.pa);c=q(c);if(r.evaluate){var A=[];c.forEach(function(M){var X=r.evaluate([M],l,t).expand();if(Array.isArray(X)&&1!==X.length||!n.boolean.matches(X[0]))throw new J("Wrong type for function result in filter(). Expecting xs:boolean, supplied value is "+
ib.showValue(X),"XPTY0004",t);X[0].toBoolean()&&A.push(M)});return f(A)}throw new J("Argument supplied to array:filter is not a function","XPTY0004",t);},flatten:function(c){return Nd.flatten(c[0])},"fold-left":function(c,l,t){return I(c,l,t,!0)},"fold-right":function(c,l,t){return I(c,l,t,!1)},"for-each":function(c,l,t){var r=c[1].expand()[0];c=q(c);Object.keys(l.pa);if(r.evaluate){var A=[];c.forEach(function(M){A.push(r.evaluate([M],l,t).expand())});return f(A)}},"for-each-pair":function(c,l,t){var r=
c[2].expand();if(1!==r.length)throw new J("for-each-pair() must have exactly one function item as the third argument. Supplied length "+r.length,"XPTY0004",t);r=r[0];Object.keys(l.pa);var A=[],M=c[0].next().value[Symbol.iterator]();c=c[1].next().value[Symbol.iterator]();var X=M.next().value,ka=c.next().value;if(r instanceof Ld.XdmFunction)for(;X&&ka;)A.push(r.evaluate([X,ka],l,t).expand()),X=M.next().value,ka=c.next().value;return f(A)},"_from-sequence":function(c){var l=[];c[0].forEachItem(function(t){l.push([t])});
return f(l)},get:function(c){var l=q(c);c=c[1].next().toNumber();B(c,l.length);return sc(l[c-1])},head:function(c){c=q(c);B(1,c.length);return sc(c[0])},"insert-before":function(c){var l=q(c),t=c[1].next().toNumber();B(t,l.length+1);c=c[2].expand();return f(l.slice(0,t-1).concat([c]).concat(l.slice(t-1)))},join:function(c){var l=[];c[0].forEachItem(function(t){t.value.forEach(function(r){l.push(r)})});return f(l)},put:function(c){var l=q(c),t=c[1].next().toNumber();B(t,l.length);c=c[2].expand();return f(l.slice(0,
t-1).concat([c]).concat(l.slice(t)))},remove:function(c){var l=q(c),t=[];c[1].forEachItem(function(r){r=r.toNumber();B(r,l.length);t.push(r-1)});return f(l.filter(function(r,A){return 0>t.indexOf(A)}))},reverse:function(c){return f(q(c).slice().reverse())},size:function(c){return Dc(c[0].next().size())},sort:function(c,l,t){var r=re.pf(t,c[1],l),A=Nd.hc;if(c[2]){var M=c[2].next();A=function(X){return M.evaluate([[X]],l,t)}}return f(q(c).slice().sort(function(X,ka){return c[1]?se(sc(X).mapOneToMany(A),
sc(ka).mapOneToMany(A),r):se(sc(X).mapOneToMany(A),sc(ka).mapOneToMany(A))}))},subarray:function(c){var l=q(c),t=c[1].next().toNumber();B(t,l.length+1);c=c[2]?c[2].next().toNumber():l.length-t+1;if(0>c)throw new J("Length is negative","FOAY0002");B(t+c,l.length+1);return f(l.slice(t-1,t+c-1))},tail:function(c){c=q(c);B(1,c.length);return f(c.slice(1))},"_to-sequence":function(c){var l=[];q(c).forEach(function(t){t.forEach(function(r){l.push(r)})});return sc(l)}};if(!d[e])throw new J("Unknown array function: "+
e,"SXJS0003",v);return d[e](k,p,v)}function u(e,k,p,v){var B={contains:function(q){var f=q[0].next();q=q[1].next();return Gc(f.containsKey(q))},create:function(q){var f=new Mc;q[0].forEachItem(function(I){I.forAllPairs(function(d){f.inSituPut(d.k,d.v)})});return xc(f)},entry:function(q){var f=new Mc;f.inSituPut(q[0].next(),q[1].expand());return xc(f)},find:function(q){function f(l){Nd.isArray(l)?l.value.forEach(I):Nd.isMap(l)&&(l.containsKey(c)&&d.push(l.get(c)),l.keys().forEach(function(t){I(l.get(t))}))}
function I(l){l.forEach(f)}var d=[],c=q[1].next();I(q[0].expand());return xc(new Kd(d))},"for-each":function(q,f,I){var d=q[0].next(),c=d.keys(),l=q[1].expand()[0];if(l.evaluate){var t=[];c.forEach(function(r){t=t.concat(l.evaluate([[r],d.get(r)],f,I).expand())});return sc(t)}throw new J("Argument to map:for-each is not a function","XPTY0004",I);},get:function(q){var f=q[0].next();q=q[1].next();return sc(f.get(q))},keys:function(q){q=q[0].next();return sc(q.keys())},merge:function(q){var f=new Mc,
I=q[1]?q[1].next():null;q[0].forEachItem(function(d){d.forAllPairs(function(c){var l=n.string.fromString("duplicates");l=I&&I.containsKey(l)?I.get(l).toString():"use-first";if(!f.containsKey(c.k)||["use-last","unspecified","use-any"].includes(l))f.inSituPut(c.k,c.v);else{if("reject"===l)throw l=n.string.fromString("duplicates-error-code"),l=I.containsKey(l)?I.get(l).toString():"FOJS0003",new J("Duplicate key value '"+c.k.toString()+"'",l,v);if("combine"===l){var t=f.get(c.k);c.v.forEach(function(r){t.push(r)});
f.inSituPut(c.k,t)}}})});return xc(f)},_new:function(q){var f=new Mc;q[0]&&q[0].forEachItem(function(I){I.forAllPairs(function(d){if(f.containsKey(d.k))throw new J("Duplicate key value '"+d.k.toString()+"'","XSLT"===p.fixed.options.language?"XTDE3365":"XQDY0137",v);f.inSituPut(d.k,d.v)})});return xc(f)},put:function(q){var f=q[0].next(),I=q[1].next();q=q[2].expand();return xc(f.put(I,q))},remove:function(q){var f=q[0].next();q[1].forEachItem(function(I){f=f.remove(I)});return xc(f)},size:function(q){var f=
0;q[0].next().forAllPairs(function(){f++});return Dc(f)},"untyped-contains":function(q){var f=q[0].next();q=q[1].next();return Gc(f.containsKey(q))}};if(!B[e])throw new J("Unknown map function: "+e,"SXJS0003",v);return B[e](k,p,v)}function z(e,k,p,v){function B(c){return null===c?(I=!0,Number.NaN):c.toNumber()}function q(){var c=k[0].next();return B(c?c:Nd.Va(v)(p).next())}function f(){var c=k[1].next();return B(c?c:Nd.fh(v)(p).next())}var I=!1,d=function(){switch(e){case "pi":return Math.PI;case "exp":return Math.exp(q());
case "exp10":return Math.pow(10,q());case "log":return Math.log(q());case "log10":return Math.log(q())/Math.LN10;case "pow":var c=q(),l=f();return-1===c&&(Infinity===l||-Infinity===l)||1===c?1:Math.pow(c,l);case "sqrt":return Math.sqrt(q());case "sin":return Math.sin(q());case "cos":return Math.cos(q());case "tan":return Math.tan(q());case "asin":return Math.asin(q());case "acos":return Math.acos(q());case "atan":return Math.atan(q());case "atan2":return Math.atan2(q(),f());default:throw new J("Unknown math function "+
v.name,"XPST0017",v);}}();return I?wc:Ec(d)}var n=Ab.XS,g={};g[Ic.zb]={apply:Se.apply,"compile-XPath":function(e,k,p){var v=e[0].next().toString(),B;if(1<e.length){var q=e[1].next();q=null!==q?Nd.tb(q,!0):{}}2<e.length&&(B=e[2]);return te.find("SaxonJS").XPath.compileToXML(v,q,B,p,k)},"compile-GlobalContext":function(e,k,p){var v=e[0].next();v=null!==v?Nd.tb(v,!0):{};var B=e[1].next();B=null!==B?Nd.tb(B,!0):{};e=e[2].next();e=null!==e?Nd.tb(e,!0):{};return te.find("SaxonJS").XPath.compileGlobalContext(v,
B,e,p,k)},"discard-document":function(e,k,p){e=e[0].next();if(null===e||9!=e.nodeType||!e._saxonBaseUri)throw new J("Node supplied to saxon:discard-document() is not a document node","XPTY0004",p);p=e._saxonBaseUri;e=k.fixed.vb[p];delete k.fixed.vb[p];delete k.fixed.Cd[p];return xc(e)},doc:Se.doc,"dynamic-error-info":function(e,k){k=k.hf;if(null===k)return wc;e=e[0].next().toString();switch(e){case "description":return Cc(k.message);case "code":return xc(/^Q\{/.test(k.code)?n.QName.fromString(k.code):
n.QName.fromParts("err","http://www.w3.org/2005/xqt-errors",k.code));case "value":return k.errorObject?xc(k.errorObject):wc;case "module":return Cc(k.xsltModule||"");case "line-number":return Dc(k.xsltLineNr);case "column-number":return Dc(-1);default:throw new J("unknown error info field "+e,"SXJS0003");}},"has-uniform-namespaces":function(e){return Gc(Kc.Ah(e[0].next()))},"line-number":function(e){e=e[0].next().rl;return Dc(e||-1)},"marshal-type":function(e){e=e[0].next().toString();e=Yb(e);return xc(Nd.Fd(Nd.Ta(e))[0])},
"message-count":function(e,k){e=e[0].next();var p=0;if(e)e=e.$(),(p=k.Dc[e])||(p=0);else for(var v in k.Dc)p+=k.Dc[v];return Dc(p)},"parse-sequence-type":function(e){var k=e[0].next().toString(),p=e[1].next();e=Kc.ej(p,Ic.Qi,"ns");if(null!=e){var v=th.parseNsAttribute(e);e=function(B){return v[B]}}else e=function(B){return p.lookupNamespaceURI(B)};k=sh.parseSequenceType(k,e,function(){for(var B=p;B&&1===B.nodeType;){if(Kc.da(B).uri===Ic.dd&&B.hasAttributeNS("","xpath-default-namespace"))return B.getAttributeNS("",
"xpath-default-namespace");if(B.hasAttributeNS(Ic.dd,"xpath-default-namespace"))return B.getAttributeNS(Ic.dd,"xpath-default-namespace");B=Kc.Za(B)}});return xc(Nd.Fd(Nd.Ta(k.V()))[0])},"parse-XPath":function(e){var k=e[0].next().toString(),p="parse";1<e.length&&(p=e[1].next().toString());e=sh.process(k,"pattern.parseFast"===p,null,!0);return xc(e)},permute:function(e){var k=[];e[0].expand().forEach(function(p){return k.push({key:Math.random(),bk:p})});k=k.sort(function(p,v){return p.key-v.key});
e=k.map(function(p){return p.bk});return sc(e)},"system-id":function(e,k){e=Od(k);e=Kc.X(e)?Kc.baseURI(e):null;return xc(null===e?null:n.anyURI.fromString(e))},timestamp:function(e,k){return xc(n.Md.fromDate(new Date,-k.fixed.Lb.getTimezoneOffset()))}};g["http://exslt.org/common"]={"node-set":function(e){return e[0]}};Nd.Pj(a);return{evaluate:a,addLibrary:function(e){var k=e.namespace;e=e.signatures;var p=g[k];p||(p=g[k]={});Object.entries(e).forEach(function(v){p[v[0]]=v[1].impl})}}}();
// Input 78
var Hi={forceBaseURI:th.forceBaseURI};
// Input 79
var Ii=function(){function a(e,k){return e.Gb===k.Gb?e.$c===k.$c?e.$d===k.$d?k.Rh-e.Rh:e.$d-k.$d:e.$c-k.$c:e.Gb-k.Gb}function b(e,k){return e.Gb===k.Gb?e.$c===k.$c?0:e.$c-k.$c:e.Gb-k.Gb}function h(e,k){function p(B){return B.n?[B.n.replace(/^(\*:|Q\{.*\})/,"")]:B.u?p(B.u[0]).concat(p(B.u[1])):[]}var v=this;this.$e=e;this.Mh=e.onNo;this.Lh=k.Fb(e,"t");k.Fb(e,"F")&&(this.kg=!0);this.Ug=(e.C||[]).map(function(B){return{Zd:B,Gb:parseInt(B.prec,10),$c:parseFloat(B.prio),$d:parseInt(B.seq,10),Dj:parseInt(B.minImp,
10),Rh:parseInt(B.rank,10),pattern:ue.vd(k.ea(B,"match")),matches:Xc(B.matches||"1"),action:k.ea(B,"action")}}).sort(a).reverse();this.ec={};this.Ug.forEach(function(B){var q=B.matches.V(),f=[];if(/^N[EAP]/.test(q.p)){var I=q.p;q.n&&!/}\*$/.test(q.n)?(I+=" "+q.n.replace(/^(\*:|Q\{.*\})/,""),f=[I]):f=q.u?Ba(new Set(p(q))).concat().map(function(d){return I+" "+d}):[I]}else f=["."];f.forEach(function(d){var c=v.ec[d];c||(v.ec[d]=c=[]);c.push(B)})})}var u=Ab.XS,z=Kc.Ka,n={TC:function(e,k,p,v){if(Kc.X(e))switch(e.nodeType){case 9:case 11:case 1:p=
p.ca(!1);p.focus=Bc(z.Ma(e));k.Kb(p,v);break;case 3:case 4:v.append(e);break;case 2:v.text(e.value)}else if(u.bg.matches(e))v.text(e.toString());else if(Nd.isArray(e)){var B=[];e.value.forEach(function(q){B=B.concat(q)});e=p.ca(!1);e.focus=Bc(sc(B));k.Kb(e,v)}},SC:function(e,k,p,v){return Ke.Xh(e,p,"c",function(){var B=p.ca(!1);B.focus=Bc(z.La(e));k.Kb(B,v);B.focus=Bc(z.Ma(e));k.Kb(B,v)},v)},DC:function(e,k,p,v){Kc.X(e)&&(v.rb=Kc.baseURI(e));v.append(e)},DS:function(e,k,p,v){if(Kc.X(e))switch(e.nodeType){case 9:case 11:p=
p.ca(!1),p.focus=Bc(z.Ma(e)),k.Kb(p,v)}},SS:function(e,k,p,v){if(Kc.X(e))switch(e.nodeType){case 9:case 11:case 1:p=p.ca(!1),p.focus=Bc(z.La(e)),k.Kb(p,v),p.focus=Bc(z.Ma(e)),k.Kb(p,v)}else if(Nd.isArray(e)){var B=[];e.value.forEach(function(q){B=B.concat(q)});e=p.ca(!1);e.focus=Bc(sc(B));k.Kb(e,v)}},bubble:function(e,k,p,v){Kc.X(e)?1===e.nodeType&&(p=p.ca(!1),p.focus=Bc(z.parent(e)),k.Kb(p,v)):v.append(e)}};n.TO=n.TC;var g={1:"NE",2:"NA",3:"NT",7:"NP",8:"NC",9:"ND",11:"ND",13:"NN"};h.prototype={$e:null,
Ug:null,Mh:"TC",Lh:!1,kg:!1,Kb:function(e,k,p){var v=this;if(p){var B=!0;e.focus.forEachItem(function(q){B?B=!1:k.text(p.value,{});v.af(q,e,k)})}else e.focus.forEachItem(function(q){return v.af(q,e,k)})},xi:function(e,k){var p=e.cb;if(!p)throw new J("No current template rule","XTDE0560");return this.af(Od(e),e,k,function(v){return v.Gb>=p.Dj&&v.Gb<p.Gb})},Gj:function(e,k){var p=e.cb;if(!p)throw new J("No current template rule","XTDE0560");return this.af(Od(e),e,k,function(v){return 0>a(v,p)})},Vi:function(e,
k,p){var v=null,B=null,q=this.kg,f=[];if(Kc.X(e)){var I=g[e.nodeType],d=Kc.da(e);if(d=d?d.local:null)d=I+" "+d,this.ec[d]&&f.push(this.ec[d]);this.ec[I]&&f.push(this.ec[I]);this.ec.N&&f.push(this.ec.N)}this.ec["."]&&f.push(this.ec["."]);(function(c){c.forEach(function(l){l.forEach(function(t){if(!p||p(t))if(!v||0<a(t,v)||q&&0<=b(t,v)){var r=!1;try{r=ue.matches(t.pattern,e,k)}catch(A){}r&&(B=v,v=t)}})})})(f);if(this.kg&&v&&B&&0===b(v,B)&&v.$d!==B.$d)throw f="Multiple templates matched item ("+ib.showValue(e)+
"): ",f+=v.Zd.module+"#"+v.Zd.line,f+=" and "+B.Zd.module+"#"+B.Zd.line,new J(f,"XTDE0540");return v},Dh:function(){var e=this.$e.name;return e&&e.startsWith("Q{"+Ic.Vd+"}")},af:function(e,k,p,v){var B=k.fixed.ka;v=this.Vi(e,k,v);if(null!==v)B.fg(v.Zd,k),k.cb=v,k.ib=k.ua,v.action||ib.la("Template rule without action child"),Ke.push(v.action,k,p);else{var q;this.Dh()?q="bubble":q=this.Mh.split("+")[0];if(B=n[q])B(e,this,k,p);else throw new J("No matching template rule for "+ib.showValue(e),"XTDE0555");
}}};return h}();
// Input 80
SaxonJS=function(){function a(D){X=D;X.init();gb.setPlatform(D)}function b(D){function C(ea){switch(ea.nodeType){case 9:ea.documentElement&&C(ea.documentElement);break;case 11:case 1:var na=Kc.da(ea),x=Kc.dj(ea,"xml:space");na=11!==ea.nodeType&&y(na.uri,na.local)&&!(x&&"preserve"===x);x=ea.childNodes;for(var F=0;F<x.length;){var P=x[F];if(4===P.nodeType){var V=Kc.zc(ea).createTextNode(P.nodeValue);ea.replaceChild(V,P);P=V}3===P.nodeType?na&&""===xb.trim(P.nodeValue)?ea.removeChild(P):F++:(C(P),F++)}}}
if(D)if("strip.all"===D.N)var y=function(){return!0};else if("strip.none"===D.N)y=function(){return!1};else{var K={},U={},O={},Z={},aa={},ca=function(ea,na,x){ea[na]?parseInt(x.Gb,10)>parseInt(ea[na].Gb,10)&&(ea[na]=x):ea[na]=x};(D.C||[]).forEach(function(ea){var na=Xc(ea.test);na instanceof Yd?(ea.PREC=parseInt(ea.prec,10),ea.PRIO=2,ca(K,na.name.$(),ea)):na instanceof $d?(ea.PREC=parseInt(ea.prec,10),ea.PRIO=1,ca(U,na.uri,ea)):na instanceof Zd?(ea.PREC=parseInt(ea.prec,10),ea.PRIO=1,ca(O,na.localName,
ea)):na instanceof ud?(ea.PREC=parseInt(ea.prec,10),ea.PRIO=0,ca(Z,"*",ea)):ib.la("Unrecognised strip-space "+ea.test)});var oa=function(ea,na){var x=[],F=K["Q{"+ea+"}"+na];F&&x.push(F);(ea=U[ea])&&x.push(ea);(na=O[na])&&x.push(na);Z["*"]&&x.push(Z["*"]);x.sort(function(P,V){var R=P.PREC,w=V.PREC;return R===w?V.PRIO-P.PRIO:w-R});return 0<x.length?"s"===x[0].N:!1};y=function(ea,na){var x="Q{"+ea+"}"+na,F=aa[x];if(F)return"s"===F;ea=oa(ea,na);aa[x]=ea?"s":"p";return ea}}else y=function(){return!1};
return function(ea){C(ea);return ea}}function h(D,C,y){function K(S){S=new De(S);oa.fixed.Zi=S;return new Ke.Oc(S)}function U(){}function O(){ka&&!y.wg&&f(oa)}var Z=Nd.Db(D,"strip")[0]||Nd.Db(D,"strip.all")[0],aa=null;if(Z||ka)aa=b(Z),C&&aa&&(C=aa(C));y.gi&&y.gi(D);y.Il=null;var ca=y.async,oa=d(D,C,y);oa.fixed.Rl=oa.fixed.de.Ua.version;c(oa,function(S){return"globalParam"===S.N&&S.flags&&-1!==S.flags.indexOf("r")}).map(function(S){return S.name}).map(function(S){if("undefined"===typeof oa.fixed.options.di.get(Ab.XS.QName.fromEQName(S)))throw new J("No value has been provided for required stylesheet parameter '"+
S+"'","XTDE0050");});oa.fixed.Ne=aa;C&&(Kc.Ec(C),oa.fixed.vb[C._saxonBaseUri]=C);var ea=y.destination||"application",na=y.resultForm||"default";y.lf||(y.lf=ka?function(){return{destination:"html-page",save:function(S,ta){y.resultDocuments[S.toString()]=ta}}}:ca?function(){return{destination:"file",save:function(S,ta,wa){S=X.writeFileAsync(S,ta,wa);oa.fixed.ee.push(S)}}}:function(){return{destination:"file",save:function(S,ta,wa){X.writeFileSync(S,ta,wa)}}});var x,F={};Z=oa.fixed.de;var P=Z.xg["#unnamed"];
if(P){P=P.C;for(var V,R,w=0;w<P.length;w++){var Y=P[w].name;"parameter-document"===Y?V=P[w].value:"Q{http://saxon.sf.net/}parameter-document-base-uri"===Y?R=P[w].value:F[Y]="use-character-maps"===Y?Re.Lf(Y,P[w].value,Z):Re.Mf(Y,P[w].value)}V&&R&&(V=gb.Ob(V,R),V=xe.Lc(V,oa),Re.Ef(V.documentElement,F))}if(y.Oa)for(V=Aa(Object.entries(y.Oa)),R=V.next();!R.done;R=V.next())P=Aa(R.value),R=P.next().value,P=P.next().value,w=void 0,w="use-character-maps"===R?Array.isArray(P)?Re.Kj(P,Z):Re.zj(P):P,F[R]=Re.Df(R,
w,F[R]);if(["appendToBody","prependToBody","replaceBody"].includes(ea))if(ka){oa.ja=window.document;var ia=Fe(oa);U=function(){ia.od=new Ge(ia.od);var S=K(ia);S.Ja();return S};O=function(){ia.Ca();ia.close();var S=Q.Ma(ia.result()[0]);if(S){var ta=window.document.body;if("appendToBody"===ea)S.forEachItem(function(va){ta.appendChild(va)});else if("prependToBody"===ea){var wa=ta.firstChild;S.forEachItem(function(va){ta.insertBefore(va,wa)})}else if("replaceBody"===ea){for(;0<ta.childNodes.length;)ta.removeChild(ta.childNodes[0]);
S.forEachItem(function(va){ta.appendChild(va)})}}else throw new J("No result from transformation","SXJS0006");ka&&!y.wg&&f(oa)}}else throw new J("Destination="+ea+" is allowed only when running in the browser","SXJS0006");else if(["serialized","file","stdout"].includes(ea)){if("stdout"===ea)var T=X.getStdOut();else{var fa="",xa=!1;T={write:function(S){xa=!0;fa+=S},end:"serialized"===ea?function(){y.principalResult=xa?M(Cc(fa),na):null}:function(){var S=F.encoding||"utf-8";xa&&(ca?(S=X.writeFileAsync(new URL(y.ic),
fa,S),oa.fixed.ee.push(S)):X.writeFileSync(new URL(y.ic),fa,S))}}}U=function(){x=Re.zh(F,T,oa);return K(x)}}else if(["raw","document","application"].includes(ea)){var m="document"===ea;"application"===ea&&("undefined"!==typeof F["build-tree"]?m=F["build-tree"]:m=!0);var G=Fe(oa);U=function(){x=G;m&&(x=new He(G));return K(x)};O=function(){y.principalResult=0===G.result().length?null:M(sc(G.Pb),na);ka&&!y.wg&&f(oa,y.Ai)}}else throw new J("Invalid SaxonJS.transform() destination: "+ea+" : "+typeof ea,
"SXJS0006");Z=y.mj;if(V=y.nj)V=V.toString(),/^Q{/.test(V)||(V="Q{}"+V);(R=y.lj)?R=R.toString():D.defaultMode?R=D.defaultMode:R="";"#unnamed"===R&&(R="");""!==R&&0>R.indexOf("Q{")&&(R="Q{}"+R);P="Q{"+Ic.dd+"}initial-template";C||Z||V||r(oa,P)&&(V=P);var N=y.kj;if(N){C=y.rh?y.rh:[];D=C.length;Z=A(oa,N.toString(),D);if(!Z)throw new J("Unknown initial function "+N+"#"+D,"XTDE0041");if("PUBLIC"!==Z.Ua.vis&&"FINAL"!==Z.Ua.vis)throw new J("Uninvocable initial function "+N+"#"+D+" with visibility "+Z.Ua.vis,
"XTDE0041");var H=Z.Ua.C[0];oa.focus={};oa.ua=Z;Rd(oa);oa.sa="function";oa.ib={};oa.cb=null;var E=0;oa.pa=C.map(function(S){var ta=H.C[E++].as;ta=Wc(ta||"*");return Nd.gg(Nd.Ta(S),ta,function(){return"function parameter for "+N})});C=U();Ke.push(Nd.ea(H,"body"),oa,C);C.close()}else if(V){D=r(oa,V);if(!D)throw new J("Unknown initial template "+V,"XTDE0040");Z=D.Ua.C[0];oa.fixed.Rc?oa.focus=Ac(C):(oa.focus=Bc(wc),oa.focus.next());Nd.fg(Z,oa);oa.ua=D;oa.ib={};u(y,oa);C=U();Ke.push(Z.C[0],oa,C);C.close()}else{Array.isArray(Z)&&
aa&&Z.filter(Kc.X).map(function(S){return Kc.Ae(S)?S:S.ownerDocument}).map(function(S){return aa(S)});V=t(oa,R);if(!V)throw new J("Unknown initial mode "+R,"XTDE0045");if("PRIVATE"===V.visibility&&R!==D.defaultMode&&""!==R)throw new J("Uninvokable initial mode "+R+" with visibility "+V.Ua.vis,"XTDE0045");if(!C&&!Z)throw new J("No source input supplied for apply-templates invocation","XTDE0044");D=V.mode;oa.ua=V;oa.focus=Bc(Z?sc(Array.isArray(Z)?Z:[Z]):xc(C));C=D.$e.useAcc;null!=C&&"#all"!==C&&(oa.Qe=
C.split(/\s+/));u(y,oa);C=U();D.Kb(oa,C);C.close()}O();if(ca){var da=function(){var S=oa.fixed.ee;oa.fixed.ee=[];return Promise.all(S).then(function(){if(0<oa.fixed.ee.length)return da()}).catch(function(ta){throw ta;}).then(function(){return Promise.resolve(y)})};y.Sj=da()}}function u(D,C){D.Oe&&(ib.assert(D.Oe instanceof Mc,"options?templateParams must be an instance of HashTrie. Supplied:"+D.Oe),C.Wc=D.Oe);D.Qb&&(ib.assert(D.Oe instanceof Mc,"options?tunnelParams must be an instance of HashTrie. Supplied:"+
D.Qb),C.Qb=D.Qb)}function z(D){var C="",y;for(y in D)D.hasOwnProperty(y)&&D[y]&&(C+=y+"="+D[y].toString().substring(0,30)+"("+typeof y+"), ");return C}function n(D){var C=ka?window.document.baseURI:X.fileURL(X.cwd()),y=D.stylesheetInternal||null;if(!y){if(D.Hb){var K=X.resolveUri(D.Hb.toString(),C);D.Hb=K;K=X.readFile(K)}else if(D.Eg)K=D.Eg;else if(D.Gd){if(ka)throw new J("Cannot supply stylesheetFileName in browser","SXJS0006");K=X.readFile(D.Gd)}else throw new J("No stylesheet supplied");try{y=
JSON.parse(K)}catch(Z){throw new J("Supplied SEF is not in JSON format","SXJS0006");}}v(y);y.PRIMED||(q(y),g(y),y.PRIMED=!0);K=D.bd||null;D.bd&&D.bd.baseURI&&!D.bd._saxonBaseUri&&(K._saxonBaseUri=D.bd.baseURI);if(!K){if(D.oc){var U=X.resolveUri(D.oc.toString(),C);D.oc=U;var O=X.readFile(U)}else if(D.Cg)O=D.Cg;else if(D.Ed){if(ka)throw new J("Cannot supply sourceFileName in browser","SXJS0006");O=X.readFile(D.Ed);U=X.resolveUri(D.Ed,C)}O&&("json"===D.$h?K=we.He(O,null):(K=X.parseXmlFromString(O),U&&
(K._saxonBaseUri=K._saxonDocUri=U)))}h(y,K,D);return{principalResult:D.principalResult,masterDocument:D.masterDocument,resultDocuments:D.resultDocuments,stylesheetInternal:y}}function g(D){D.C&&D.C.forEach(function(C){C.parentNode=D;g(C)})}function e(D){if(D instanceof Mc)return D;var C=new Mc;D&&Object.keys(D).forEach(function(y){return C.inSituPut(Ab.XS.QName.fromEQName(y),Nd.Ta(D[y]))});return C}function k(D){var C={};D=Aa(Object.entries(D));for(var y=D.next();!y.done;y=D.next()){var K=Aa(y.value);
y=K.next().value;K=K.next().value;C[y]="use-character-maps"===y?K:Re.Df(y,K,null)}return C}function p(D){return{Eg:D.stylesheetText,stylesheetInternal:D.stylesheetInternal,Hb:D.stylesheetLocation,Gd:D.stylesheetFileName,di:e(D.stylesheetParams),bd:D.sourceNode,Cg:D.sourceText,oc:D.sourceLocation,Ed:D.sourceFileName,$h:D.sourceType||"xml",masterDocument:D.masterDocument,nj:D.initialTemplate,kj:D.initialFunction,rh:D.functionParams,Oe:e(D.templateParams),Qb:e(D.tunnelParams),lj:D.initialMode,vb:D.documentPool,
pc:D.textResourcePool,destination:D.destination,ic:D.baseOutputURI||(D.masterDocument?D.masterDocument._saxonBaseUri:void 0),Bf:D.output,Oa:D.outputProperties,principalResult:void 0,ig:D.deliverMessage,lf:D.deliverResultDocument,jd:D.collations,dh:D.collectionFinder,xf:D.logLevel,gi:D.traceInjector,Rc:D.globalContextItem,mj:D.initialSelection,vf:D.isDynamicStylesheet,language:D.language,Jh:D.messages,resultDocuments:D.resultDocuments,resultForm:D.resultForm,ci:D.stylesheetBaseURI,el:D.extraOptions,
wg:D.nonInteractive,Ai:D.attachEventsTo,async:D.async}}function v(D){if("package"===D.N){var C=D.target||"not specified",y=D.targetVersion||"",K=D.saxonProduct,U=D.saxonVersion||"9.8.0.5";K&&!/Saxon/.test(U)&&(U=K+" "+U);ib.Sa("SEF generated by "+U+" at "+(D.buildDateTime||"2000-01-01")+" with -target:"+C+" -relocate:"+(D.relocatable||"false"),2);if("JS"!==C||"2"!==y)throw new J("Supplied SEF is not customized for use with Saxon-JS. The option -target:JS should be set when generating the export. target is: "+
C,"SXJS0006");if("9"===U.charAt(0))throw new J("For use with Saxon-JS 2.x, SEF must be generated by Saxon-EE 10.0 or later. Supplied SEF is generated by Saxon-EE version "+U,"SXJS0006");if(D.expiry&&0>qa.md.fromString(D.expiry).compareTo(qa.md.fromDate(new Date,0)))throw new J("Development license for supplied stylesheet has expired","SXJS0006");}}function B(D){function C(Z,aa){aa<<=8;for(var ca=0;ca<Z.length;ca++)aa=(aa<<1)+Z.charCodeAt(ca);return aa}function y(Z,aa,ca){return C(Z,ca)^C(aa,ca)}function K(Z){U^=
y(Z.N,"http://ns.saxonica.com/xslt/export",O++);for(var aa in Z)Z.hasOwnProperty(aa)&&"N"!==aa&&"C"!==aa&&aa!==String.fromCharCode(931)&&(U^=y(aa,"",O),U^=C(Z[aa],O));Z.C&&Z.C.forEach(function(ca){K(ca)});U^=1}var U=0,O=0;K(D);return U}function q(D){function C(aa,ca){ca<<=8;for(var oa=0;oa<aa.length;oa++)ca=(ca<<1)+aa.charCodeAt(oa);return ca}function y(aa,ca,oa){return C(aa,oa)^C(ca,oa)}function K(aa){U^=y(aa.N,"http://ns.saxonica.com/xslt/export",O++);for(var ca in aa)!aa.hasOwnProperty(ca)||"N"===
ca||"C"===ca||"ELAB"===ca||"PUSH"===ca||"parentNode"===ca||"catch"===aa.N&&"test"==ca||ca===String.fromCharCode(931)||(U^=y(ca,"",O),U^=C(aa[ca],O));aa.C&&aa.C.forEach(function(oa){K(oa)});U^=1}var U=0,O=0;K(D);D=D[String.fromCharCode(931)]||"unspecified";var Z=(0>U?4294967295+U+1:U).toString(16);if(Z!==D){if("unspecified"===D)throw new J("No checksum found in SEF","SXJS0006");throw new J("Invalid checksum in SEF "+D+" != "+Z,"SXJS0006");}}function f(D,C){function y(U,O){function Z(ca){var oa=ca.target,
ea=D.ca(!0);ea.sa=!1;ea.se=ca;ea.focus=Bc(xc(Nd.Ta(oa)[0]));ea.ua=U;ca=Fe(D);ca=new Ke.Oc(ca);return(ea=aa.Kb(ea,ca))?ea.expand():[]}var aa=U.mode;-1==="httpabort afterprint beforeprint beforeunload hashchange languagechange message offline online pagehide pageshow popstate storage unhandledrejection unload resize focus".split(" ").indexOf(O)?C?C.addEventListener(O,Z,!1):window.document.addEventListener(O,Z,!1):window.addEventListener(O,Z,!1)}C=void 0===C?null:C;var K=D.fixed.Na;Object.keys(K).forEach(function(U){U=
K[U];U.mode&&U.mode.Dh()&&y(U,U.name.split("}on")[1])})}function I(D,C){return D.C?D.C.filter(function(y){return y.N===C}):[]}function d(D,C,y){function K(x,F){U(D,x,F)}function U(x,F,P){x.N===F&&P(x);x.C&&x.C.forEach(function(V){U(V,F,P)})}function O(x){return(x=x.name)?/^Q\{/.test(x)?x:"Q{}"+x:null}function Z(x,F){function P(V){V=I(V,"co");for(var R=0;R<V.length;R++)F(V[R])}P(x);x.C&&(x=x.C.filter(function(V){return"overridden"===V.N}),x[0]&&P(x[0]))}function aa(x,F){var P=x.C?x.C[0]:null;return null===
P?x.base?aa(F.fixed.Na[parseInt(x.base,10)].Ua,F):null:P}var ca=new mh;ca.fixed={ic:y.ic||"",Na:{},Lb:new Date,vb:{},ka:Nd,Rc:y.Rc||C,ij:"",vf:y.vf,Zf:0,options:y,yd:{},Cd:{},Th:{},staticBaseURI:"",Ml:D,pc:{},de:null,ee:[],Qj:[],depth:0};if(y.vb){var oa=y.vb;Object.keys(oa).forEach(function(x){var F=oa[x];if(Kc.Ae(F))ca.fixed.vb[x]=F;else throw new J("Supplied value for "+x+" is not a document node");})}if(y.pc){var ea=y.pc;Object.keys(ea).forEach(function(x){ca.fixed.pc[x]=ea[x].toString()})}y.jd||
(y.jd={});y.jd[re.Kd]=re.Di();y.jd[re.Xi]=re.Yg();y.jd[re.Bh]=re.yi();y.jd[re.fk]=re.Yg();K("package",function(x){var F={name:x.name,Ua:x,Jb:{},Zg:{},jb:{},xg:{},keys:{},hi:null};ca.fixed.yd[F.name]=F;ca.fixed.de||(ca.fixed.de=F);Z(x,function(P){var V=parseInt(P.id,10);ca.fixed.Na[V]={id:V,visibility:P.vis,Ua:P,Nh:F,actor:null,ef:P.binds.split(" ").map(function(R){return parseInt(R,10)})}})});K("package",function(x){var F=ca.fixed.yd[x.name];Z(x,function(ia){var T=ca.fixed.Na[parseInt(ia.id,10)];
ia=T.actor=aa(ia,ca);null!==ia&&(T.name=O(ia),"globalVariable"===ia.N||"globalParam"===ia.N?T.value=null:"mode"===ia.N&&(T.mode=new Ii(ia,Nd),null===O(ia)&&(F.hi=T.mode)),"ABSTRACT"===ia.parentNode.vis&&(T.visibility="ABSTRACT"))});for(var P=I(x,"decimalFormat"),V=0;V<P.length;V++){var R=P[V],w=O(R);F.jb[null===w?"Q{}":w]=R}P=I(x,"output");for(V=0;V<P.length;V++)R=P[V],w=R.name?O(R):"#unnamed",F.xg[w]=R;P=I(x,"key");V=Math.floor(1E8*Math.random());for(R=0;R<P.length;R++){w=P[R];var Y=O(w);F.keys[Y]?
F.keys[Y].Wd.push(w):F.keys[Y]={Gg:V+"k"+R,Wd:[w]}}P=I(x,"accumulator");V=Math.floor(1E8*Math.random());for(R=0;R<P.length;R++)w=P[R],Y=O(w),F.Jb[Y]={Gg:V+"a"+R,ti:w};x=I(x,"charMap");P={};for(V=0;V<x.length;P={Ib:P.Ib},V++)R=x[V],w=O(R),P.Ib={min:0,max:0,Aa:{}},R.C&&(R.C.forEach(function(ia){return function(T){var fa=Number(T.c);0===ia.Ib.min?ia.Ib.min=fa:fa<ia.Ib.min&&(ia.Ib.min=fa);fa>ia.Ib.max&&(ia.Ib.max=fa);ia.Ib.Aa[fa]=T.s}}(P)),55296<P.Ib.min&&(P.Ib.min=55296)),F.Zg[w]=P.Ib});K("glob",function(x){var F=
ca.fixed.Rc;if("req"===x.use&&(null===F||void 0===F))throw new J("The stylesheet requires a global context item","XTDE3086");if("pro"===x.use)ca.fixed.Rc=null;else{var P,V=x.cxt;V&&(P=Xc(V).ia());if(null!==F&&P&&!P(F))throw new J("The global context item must be an instance of "+x.type,"XTTE0590");}});ca.xb=y.Bf?[y.Bf]:[];if(!y.vf){if(y.ci)var na=y.ci;else y.Hb?na=y.Hb:y.Gd?na=X.fileURL(y.Gd):ka&&(na=window.document.baseURI);ca.fixed.staticBaseURI=na}if(ka){if(ca.masterDocument=y.masterDocument?y.masterDocument:
window.document,ca.fixed.ij=window.document.baseURI,y.Hb&&!Kc.ud(y.Hb))throw new J("stylesheetLocation should be absolute by now","SXJS0004");}else if(y.masterDocument){if(!Kc.X(y.masterDocument)||![9,11].includes(y.masterDocument.nodeType))throw new J("masterDocument must be a document node","SXJS0004");ca.masterDocument=y.masterDocument}ca.ua=null;ca.ja=X.createDocument();ca.g=ca.ja.createDocumentFragment();ca.pa=[];ca.Wc=new Mc;ca.Qb=new Mc;ca.Dc={};return ca}function c(D,C){for(var y=[],K=I(D.fixed.de.Ua,
"co"),U=0;U<K.length;U++){var O=D.fixed.Na[parseInt(K[U].id,10)],Z=O.actor;Z&&C(Z)&&y.push(O)}return y}function l(D,C){for(var y=I(D.fixed.de.Ua,"co"),K=0;K<y.length;K++){var U=D.fixed.Na[parseInt(y[K].id,10)],O=U.actor;if(O&&C(O))return U}}function t(D,C){return l(D,function(y){return"mode"===y.N&&(y.name?y.name===C:""===C)})}function r(D,C){return l(D,function(y){return"template"===y.N&&y.name===C})}function A(D,C,y){return l(D,function(K){return"function"===K.N&&K.name===C&&I(K,"arg").length===
y})}function M(D,C){function y(O){return Nd.tb(O,!0)}if("iterator"===C)return D.mapOneToOne(y);if("xdm"===C)return D.expand();for(var K=[],U;null!=(U=D.next());)K.push(y(U));switch(C){case "iterator":return sc(K);case "array":return K;default:return 0===K.length?null:1===K.length?K[0]:K}}var X={},ka="undefined"!==typeof window,qa=Ab.XS,Q=Kc.Ka,ja={atom:function(D,C){if("boolean"===typeof D)D=Ab.XS.boolean.fromBoolean(D);else if("number"===typeof D)D=Ab.XS.double.fromNumber(D);else if("string"===typeof D)D=
Ab.XS.string.fromString(D);else throw new J("Value must be boolean, number, or string","SXJS0006");if(C){var y=Ab.type[C];if(y)return y.O(D);throw new J("Unknown type "+C,"SXJS0006");}return D},Atomic:Ab,compile:function(D,C,y){C=void 0===C?{}:C;y=void 0===y?!1:y;var K=te.find("SaxonJS"),U=K.getPlatform().resource("compiler");C=Object.assign({},C);C.destination="application";C.initialMode="compile-complete";C.templateParams={"Q{}options":{traceProgress:!1,justStatic:!1}};C.stylesheetInternal=U;C.sourceNode=
D;D=K.transform(C).principalResult;if(y)return D;D=K.XPath.sefToJSON(D.firstChild,!1);y=B(D);D[String.fromCharCode(931)]=(0>y?4294967295+y+1:y).toString(16);return D},internals:{Codepoint:lb,Compare:re,Diag:ib,DomUtils:Kc,Expr:Nd,Iter:Hc,NsConst:Ic,Push:Ke,Regex:xb},transform:function(D,C){function y(ea){throw ea;}function K(ea,na){ea=new J(ea,na);if(y)y(ea);else throw ea;}C=!C||"sync"===C;ib.Sa("Transform with options: "+z(D),2);try{var U=p(D);U.Oa&&(U.outputProperties=k(U.Oa))}catch(ea){if(C)throw ea;
return Promise.reject(ea)}"function"===typeof X.deliverMessage&&X.deliverMessage(D);"number"===typeof U.xf&&ib.setLogLevel(U.xf);if(C)return n(U);U.async=!0;ka&&(D=window.document.baseURI,U.Hb&&(U.Hb=X.resolveUri(U.Hb.toString(),D)),U.oc&&(U.oc=X.resolveUri(U.oc.toString(),D)));D=[];var O=U.stylesheetInternal||null;if(!O)if(C=U.Eg)try{O=JSON.parse(C)}catch(ea){throw new J("Error parsing supplied SEF","SXJS0006");}else if(U.Gd)C=X.resourcePromise({file:U.Gd,encoding:"utf-8"}).then(function(ea){O=JSON.parse(ea)}).catch(function(ea){return Promise.reject(new J("Failed to read JSON input "+
U.Gd+": "+ea,"SXJS0006"))}),D.push(C);else if(U.Hb)C=X.resourcePromise({location:U.Hb,encoding:"utf-8"}).then(function(ea){O=JSON.parse(ea)}).catch(function(ea){return Promise.reject(new J("Failed to read JSON input "+U.Hb+": "+ea,"SXJS0006"))}),D.push(C);else{K("No stylesheet supplied","SXJS0006");return}var Z=U.bd;var aa=U.$h||"xml";Z&&U.bd.baseURI&&(Z._saxonBaseUri=U.bd.baseURI);if(!Z){C=function(ea){ea=xe.bh(xe.ah(ea));ea=X.parseXmlFromString(ea);ea._saxonBaseUri=ea._saxonDocUri=U.oc?U.oc:U.Ed;
return ea};var ca=function(ea){return we.He(ea,null)},oa="xml"===aa?C:ca;(C=U.Cg)?Z=oa(C):U.Ed?U.Ed&&(C=X.resourcePromise({file:U.Ed}).then(function(ea){return Z=oa(ea)}).catch(function(ea){return Promise.reject(new J("Failed to read "+aa.toUpperCase()+" source input ("+ea.message+")","SXJS0006"))}),D.push(C)):U.oc&&(C=X.resourcePromise({location:U.oc}).then(function(ea){Z=oa(ea)}).catch(function(ea){return Promise.reject(new J("Failed to read "+aa.toUpperCase()+" source input ("+ea.message+")","SXJS0006"))}),
D.push(C))}return Promise.all(D).then(function(){var ea=O,na=Z,x=U;if(!ea)throw new J("No stylesheet supplied","SXJS0006");try{v(ea);ea.PRIMED||(q(ea),g(ea),ea.PRIMED=!0);h(ea,na,x);var F=x.Sj}catch(P){throw console.log("Transformation failure: "+(P.getMessage?P.getMessage():P)),P;}return F})},Serializer:Re,serialize:function(D,C){var y={"iso-8859-1":"latin1","iso-646":"ascii"};D=Re.serialize(D,C);return C&&C.encoding?(C=C.encoding,C=y[C]||C,gb.makeBuffer(D,C)):D},StringWriter:Re.StringWriter,getProcessorInfo:function(){return{version:3,
vendor:"Saxonica",vendorURL:"https://www.saxonica.com/",productName:"Saxon-JS",productVersion:"2.1",isSchemaAware:!1,supportsSerialization:!0,supportsBackwardsCompatibility:!0,supportsNamespaceAxis:!0,supportsStreaming:!1,supportsDynamicEvaluation:!0,supportsHigherOrderFunctions:!0,xPathVersion:3.1,xsdVersion:1.1,releaseDate:"2021-03-02"}},getResource:function(D){var C=D.type||"text";if(D.file){var y=X.resourcePromise(D);var K=X.fileURL(D.file)}else if(D.location)if(y=D.location,
y instanceof URL||(y=X.newURL(y)),K=y.href,"file:"===y.protocol)y=X.resourcePromise(D);else if(["http:","https:"].includes(y.protocol))y=X.resourcePromise(D);else return Promise.reject(new J("Unsupported URL protocol "+y.protocol,"SXJS0006"));else if(D.text)y=Promise.resolve(D.text);else return Promise.reject(new J("No resource supplied","SXJS0006"));"xml"===C?y=y.then(function(U){try{var O=Kc.Ec(X.parseXmlFromString(U));O._saxonBaseUri=K;O._saxonDocUri=K;return Promise.resolve(O)}catch(Z){return Promise.reject(new J("Failed parsing XML in "+
K+": "+Z.message,Z.code))}}):"json"===C&&(y=y.then(function(U){try{return Promise.resolve(we.He(U,new Mc,new mh))}catch(O){return Promise.reject(new J("Failed parsing JSON in "+K+": "+O.message,O.code))}}));return y},setLogLevel:ib.setLogLevel,getLogLevel:function(){return ib.xf},setPlatform:a,getPlatform:function(){return X},getNavigator:function(){return window.navigator.userAgent},timeStamp:ka&&window.performance&&window.performance.now?window.performance.now:function(){return Date.now().valueOf()},
internalTransform:h,checkOptions:p,convertResult:M,makeAtomicValue:function(D,C){return Ab.type[D].fromString(C)},registerExtensionFunctions:function(D){rh.Wg(D);Gi.Wg(D)},getItemDetails:function(D){if(Ab.qa(D)){var C={variety:"atomic",type:D.type,value:D.toString()};"AQ"===D.code&&(C.prefix=D.prefix,C.uri=D.uri,C.local=D.local);return C}return Kc.X(D)?{variety:"node",type:D.nodeType()}:{variety:D instanceof Kd?"array":"map"}},XdmArray:Kd,XdmAtomicValue:yb,XdmAttributeNode:Jc,XdmFunction:Ld,XdmMap:Mc,
XError:J,XPath:uh,XS:qa};ka?(window.SaxonJS=ja,a(HostPlatform)):"undefined"!==typeof HostPlatform?(HostPlatform.entryPoint=ja,a(HostPlatform),module.exports=ja):console.log("Platform binding delayed");ja.Developer=Hi;Ab.Yf();xb.Yf();cd();te.register("SaxonJS",ja);return ja}();}).call(this);

